import axios from 'axios'
import { headerWithAuth } from 'lib/authFunc'
import { getFirstAndLastDate } from 'lib/helper'

const header = headerWithAuth()

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
  },
  stepper: {
    padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: '100%',
  },
});

const loadOptions = async () => {
  try {
    const uomUrl = axios(process.env.REACT_APP_API_URL + '/api/companies', header)
    const userUrl = axios(process.env.REACT_APP_API_URL + '/api/master-data/users', header)

    const [company, user] = await Promise.all([uomUrl, userUrl])
    let options = [{
      companies: company.data.data,
      users: user.data.data
    }]

    return options
  } catch (_) {
    console.log('Error')
  }
}

const defaultState = {
  loadopt: true,
  company_id: '',
  branch_id: '',
  user_name: 'all',
  date_start: getFirstAndLastDate(null, true, 'd-m-Y'),
  date_end: getFirstAndLastDate(null, false, 'd-m-Y')
}


export {
  styles,
  loadOptions,
  defaultState
}