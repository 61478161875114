import React from 'react';
import { monthList, numberFormat } from 'lib/helper'

const styles = theme => ({

    root: {
        flexGrow: 1,
        padding: theme.spacing.unit * 0.5,
    },
    formControl: {
        minWidth: '100%',
        marginTop: '6px'
    },
    paper: {
        padding: theme.spacing.unit * 2,
        marginBottom: '10px',
        color: theme.palette.text.secondary
    },
    divider: {
        border: '0.5px solid #eeeeee',
        margin: '20px 0px 20px 0px',
    },
    dividerpink: {
        background: '#f16fb0',
        margin: '20px 0px 20px 0px',
    },
    dividergreen: {
        background: '#54d244',
        margin: '20px 0px 20px 0px',
    },
    dividerorange: {
        background: '#f0a75b',
        margin: '20px 0px 20px 0px',
    },
    button: {
        margin: theme.spacing.unit,
    },
    avatar: {
        margin: 'auto',
        marginTop: '20px',
        marginBottom: '20px',
        padding: '20px',
        backgroundColor: theme.palette.secondary.main,
    },
    paper2: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    paperA1: {
        padding: theme.spacing.unit * 2,
        background: '#fb6f4f',
        margin: '10px',
    },
    paperA2: {
        padding: theme.spacing.unit * 2,
        background: '#9ba9ee',
        margin: '10px',
    },
    paperA3: {
        padding: theme.spacing.unit * 2,
        background: '#86d6fa',
        margin: '10px',
    },
    paperA4: {
        padding: theme.spacing.unit * 2,
        background: '#a5c4e8',
        marginTop: '10px',
        marginRight: '10px',
        border: '1px solid #a5c4e8'
    },
    paperA5: {
        padding: theme.spacing.unit * 2,
        background: '#f5c2c2',
        marginTop: '10px',
        marginRight: '10px',
        border: '1px solid #f5c2c2'
    },
    paperA6: {
        padding: theme.spacing.unit * 2,
        background: '#c5c5c5',
        marginTop: '10px',
        marginRight: '10px',
        border: '1px solid #c5c5c5'
    },
    paperA7: {
        padding: theme.spacing.unit * 2,
        background: '#bbded6',
        marginTop: '10px',
        marginRight: '10px',
        border: '1px solid #bbded6'
    },
    monthSum: {
        marginTop: '20px', 
        marginBottom: '20px', 
        background: 'rgb(245, 247, 255)',
        flexGrow: 1,
        padding: theme.spacing.unit * 1
    },
    calendarSum: {
        marginTop: '40px', 
        marginBottom: '20px', 
        background: 'rgb(245, 252, 255)',
        flexGrow: 1,
        padding: theme.spacing.unit * 1
    },
    customerSum: {
        marginTop: '40px', 
        marginBottom: '20px', 
        background: 'rgba(241, 249, 237, 0.72)',
        flexGrow: 1,
        padding: theme.spacing.unit * 1
    },
    progress: {
        margin: theme.spacing.unit * 2,
        position: 'absolute',
        left: '65%',
        top: '95%'
      },
      expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
      expandOpen: {
        transform: 'rotate(180deg)',
      },
      rootList: {
        width: '100%',
        overflow: 'auto',
        maxHeight: 420,
        backgroundColor: theme.palette.background.paper,
      },
      inline: {
        display: 'inline',
      },
      headPaper: {
        padding: '10px',
        backgroundColor: '#4db6ac',
        borderRadius: '0px'
      },
      card: {
        backgroundColor: '#ffffff'
      }
});

const formatOption = {
    current : [
        { value: '1', label: 'สัปดาห์นี้' },
        { value: '2', label: 'เดือน' },
        { value: '3', label: 'ประจำปี' } 
    ],
    other : [
        { value: '2', label: 'เดือน' },
        { value: '3', label: 'ประจำปี' } 
    ]
}

const getMonth = () => {
    let arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    let month = []
    arr.map(data => {
        return month = [...month, { value: data, label: monthList(data) }]
    })

    return month
}

const formatGraphTooltip = () => {
    return {
        tooltips: {
            callbacks: {
                    label: function(tooltipItem, data) {
                        let value = data.datasets[0].data[tooltipItem.index],
                            label = data.labels[tooltipItem.index]
                        return label + ' : ' + numberFormat(value);
                    }
            }
        }
    }
}


export {
    styles,
    formatOption,
    getMonth,
    formatGraphTooltip
}