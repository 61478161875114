import React from 'react';
import { Bar } from 'react-chartjs-2';

const formatData = (value, key) => {
  let data = typeof value !== 'undefined' ? parseFloat(value[key]) : null
  return Number.isNaN(data) || data === 0 ? null : data
}

const KpiBarChart = ({ data }) => {

  const initial = {
    labels: ['< 10 นาที', '10 - 30 นาที', '31 - 60 นาที', '> 60 นาที'],
    datasets: [
      {
        label: 'GI',
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: [
          formatData(data[0], 'first_tier'),
          formatData(data[0], 'second_tier'),
          formatData(data[0], 'third_tier'),
          formatData(data[0], 'fourth_tier')
        ]
      },
      {
        label: 'GR',
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,0.4)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        data: [
          formatData(data[1], 'first_tier'),
          formatData(data[1], 'second_tier'),
          formatData(data[1], 'third_tier'),
          formatData(data[1], 'fourth_tier')
        ]
      },
      {
        label: 'GT',
        backgroundColor: 'rgba(192, 119, 75, 0.4)',
        borderColor: 'rgb(192, 108, 75)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(192, 119, 75, 0.4)',
        hoverBorderColor: 'rgb(192, 108, 75)',
        data: [
          formatData(data[2], 'first_tier'),
          formatData(data[2], 'second_tier'),
          formatData(data[2], 'third_tier'),
          formatData(data[2], 'fourth_tier')
        ]
      },
    ]
  }

  return (
    <div>
      <h6>KPI</h6>
      <Bar
        data={initial}
        options={{
          maintainAspectRatio: true
        }}
      />
    </div>
  );
}

export default KpiBarChart

