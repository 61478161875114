export const defaultState = {
    id: '',
    active: '1',
    disableForm : false,
    isOpenWeb : true,
    isOpenUser : true,
    formLoading : true,
    company_id: '',
    name: '',
    search_key: '',
    code: '',
    taxid: '',
    description: '',
    address: '',
    phone: '',
    email: '',
    logo: '',
    latitude: 13.75398,
    longitude: 100.50144,
    advSearch : {
        name: '',
        search_key: '',
        company_id: ''
    }
}