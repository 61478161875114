import { createTypes, createAction } from 'lib/action'
 
const LOAD_GRS = createTypes('goodreceives', 'list')
const LOAD_GR = createTypes('goodreceive', 'show')

const CREATE_GR = createTypes('goodreceive', 'create')
const EDIT_GR = createTypes('goodreceive', 'edit')
const DELETE_GR = createTypes('goodreceive', 'delete')

const CHANGE_STATUS_GR = createTypes('goodreceive', 'change_status')

const CREATE_GR_ITEM = createTypes('goodreceivedetail', 'create')
const EDIT_GR_ITEM = createTypes('goodreceivedetail', 'edit')
const DELETE_GR_ITEM = createTypes('goodreceivedetail', 'delete')
const DELETEALL_GR_ITEM = createTypes('goodreceivedetail', 'deleteall')

const FILTER_GR = 'backup'
const SET_GR = 'api/data'
const GET_GR = 'GET_GR'
const FETCH_GRS_FROM_GR = "FETCH_GRS_FROM_GR"

const loadGoodsReceives = {
  request: term => createAction(LOAD_GRS.REQUEST, { term }),
  success: goodsreceives => createAction(LOAD_GRS.SUCCESS, { goodsreceives }),
  failure: () => createAction(LOAD_GRS.FAILURE)
}

const loadGoodsReceive = {
  request: id => createAction(LOAD_GR.REQUEST, { id }),
  success: goodsreceive => createAction(LOAD_GR.SUCCESS, { goodsreceive }),
  failure: () => createAction(LOAD_GR.FAILURE)
}

const changeStatusGR = {
  request: (id, status) => createAction(CHANGE_STATUS_GR.REQUEST, { id, status }),
  success: goodsreceive => createAction(CHANGE_STATUS_GR.SUCCESS, { goodsreceive }),
  failure: error => createAction(CHANGE_STATUS_GR.FAILURE, { error })
}

const createGoodsReceive = {
  request: goodsreceive => createAction(CREATE_GR.REQUEST, { goodsreceive }),
  success: goodsreceive => createAction(CREATE_GR.SUCCESS, { goodsreceive }),
  failure: error => createAction(CREATE_GR.FAILURE, { error })
}

const editGoodsReceive = {
  request: (id, goodsreceive) => createAction(EDIT_GR.REQUEST, { id, goodsreceive }),
  success: goodsreceive => createAction(EDIT_GR.SUCCESS, { goodsreceive }),
  failure: () => createAction(EDIT_GR.FAILURE)
}

const deleteGoodsReceive = {
  request: id => createAction(DELETE_GR.REQUEST, { id } ),
  success: id => createAction(DELETE_GR.SUCCESS, { id } ),
  failure: () => createAction(DELETE_GR.FAILURE)
}

const createDocItem = {
  request: detail => createAction(CREATE_GR_ITEM.REQUEST, { detail } ),
  success: detail => createAction(CREATE_GR_ITEM.SUCCESS, { detail } ),
  failure: () => createAction(CREATE_GR_ITEM.FAILURE)
}

const editDocItem = {
  request: (id, detail) => createAction(EDIT_GR_ITEM.REQUEST, { id, detail } ),
  success: detail => createAction(EDIT_GR_ITEM.SUCCESS, { detail } ),
  failure: () => createAction(EDIT_GR_ITEM.FAILURE)
}

const deleteDocItem = {
  request: id => createAction(DELETE_GR_ITEM.REQUEST, { id } ),
  success: data => createAction(DELETE_GR_ITEM.SUCCESS, { data } ),
  failure: () => createAction(DELETE_GR_ITEM.FAILURE)
}

const deleteAllDocItem = {
  request: id => createAction(DELETEALL_GR_ITEM.REQUEST, { id } ),
  success: data => createAction(DELETEALL_GR_ITEM.SUCCESS, { data } ),
  failure: () => createAction(DELETEALL_GR_ITEM.FAILURE)
}


const filterData = (goodsreceive, search) => ({
  type: FILTER_GR,
  payload: {
    goodsreceive,
    search 
  }
})

const loadGoodsReceiveFromArray = id => ({
  type: GET_GR,
  payload: id
})

const fecthGoodsReceiveFromArray = () => ({
  type : FETCH_GRS_FROM_GR,
  payload: {}
})


export {
  LOAD_GRS,
  LOAD_GR,
  CREATE_GR,
  EDIT_GR,
  DELETE_GR,
  CREATE_GR_ITEM,
  EDIT_GR_ITEM,
  DELETE_GR_ITEM,
  DELETEALL_GR_ITEM,
  SET_GR,
  FILTER_GR,
  GET_GR,
  FETCH_GRS_FROM_GR,
  CHANGE_STATUS_GR,
  changeStatusGR,
  loadGoodsReceives,
  loadGoodsReceive,
  loadGoodsReceiveFromArray,
  createGoodsReceive,
  editGoodsReceive,
  deleteGoodsReceive,
  filterData,
  fecthGoodsReceiveFromArray,
  createDocItem,
  editDocItem,
  deleteDocItem,
  deleteAllDocItem
}
