import React, { Fragment } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import MUIDataTable from "mui-datatables";
import { numberFormat } from 'lib/helper'
import { AnalysisTablestyle } from '../../AddonStyles'
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloudDownload from "@material-ui/icons/CloudDownload";
import { serializeObj } from 'lib/helper'
import { FORM_URL } from 'lib/defaultSystemData'
const columns = [
    {
        name: "name",
        label: "ร้านค้า",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "shop_type",
        label: "ประเภท",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "sales",
        label: "เซลล์",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "start_date",
        label: "เข้าร่วม",
        options: {
            filter: false,
            sort: true,
        }
    }
];

const LastColumn = [
    {
        name: "net",
        label: "ยอดปีนี้",
        options: {
            filter: false,
            sort: true,
            customBodyRender: (value) => {
                return numberFormat(value)
            }
        }
    },
    {
        name: "net_all",
        label: "ยอดทั้งหมด",
        options: {
            filter: false,
            sort: true,
            customBodyRender: (value) => {
                return numberFormat(value)
            }
        }
    },
]

class MapTableData extends React.Component {
    state = {
        isLoading: false
    }
    genHeader = () => {
        let except = ['last_year_q4', 'q1', 'q2', 'q3', 'q4']
        return [...columns, ...this.props.column, ...LastColumn].map(data => {
            if (except.includes(data.name)) {
                return {
                    ...data, options: {
                        filter: false,
                        sort: true,
                        customBodyRender: (value) => {
                            return numberFormat(value)
                        }
                    }
                }
            } else {
                return { ...data }
            }
        })
    }

    createForm = () => {
        return <form action={`${FORM_URL}/api/report/customer-analysis`} method="post" id="form-data">
            <input type="hidden" value={serializeObj(this.props.cond)} name="data" />
        </form>
    }

    handleExport = async () => {
        document.getElementById('form-data').submit()
    }

    render() {
        const { data } = this.props;
        const options = {
            rowsPerPageOptions: [50, 100, 200],
            filter: true,
            viewColumns: false,
            rowsPerPage: 50,
            print: false,
            selectableRows: false,
            download: false,
            customSort: (data, colIndex, order) => {
                return data.sort((a, b) => {
                    a = a.data[colIndex] || '';
                    b = b.data[colIndex] || '';
                    if (order === 'asc') {
                        return a.toString().localeCompare(b, undefined, { numeric: true });
                    }
                    return b.toString().localeCompare(a, undefined, { numeric: true });

                });
            },
            customToolbar: () => {
                return (
                    <Tooltip title="ดาวน์โหลด Excel" >
                        <IconButton onClick={this.handleExport}>
                            <CloudDownload />
                        </IconButton>
                    </Tooltip>
                );
            }
        }

        return (
            <Fragment>
                <MuiThemeProvider theme={AnalysisTablestyle}>
                    <ThemeProvider theme={AnalysisTablestyle}>
                        {this.state.isLoading && <div className="lmask" id="lmask" />}
                        <MUIDataTable
                            title={`รายการ`}
                            data={data.data}
                            columns={this.genHeader()}
                            pagination={false}
                            options={options}
                        />
                    </ThemeProvider>
                </MuiThemeProvider>
                {this.createForm()}
            </Fragment>
        );
    }
}

MapTableData.defaultProps = {
    data: {
        data: []
    },
    column: []
};

export default MapTableData;
