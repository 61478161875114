import React, { Fragment } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { defaultState } from '../defaultState'
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { getCurrentYear } from 'lib/helper'

const img = window.location.origin + '/img/bg-02.jpg'

const styles = theme => ({
  '@global': {
    body: {
      background: '#8e9eab',
      background: '-webkit-linear-gradient(to right, #eef2f3, #8e9eab)',
      background: 'linear-gradient(to right, #eef2f3, #8e9eab)'
    },
  },
  root: {
    height: '100vh',
  },
  paper: {
    margin: theme.spacing.unit * 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  paperRoot: {
    display: 'flex',
    width: '60%',
    margin: 'calc(5%)',
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
      display: 'flex',
      width: '100%',
      margin: '0px'
    },
  },
  image: {
    backgroundImage: `url(${img})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  leftBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  avatar: {
    margin: 10,
    width: 80,
    height: 80,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit * 1,
  },
  submit: {
    margin: '20px 0px 10px 0px',
  },
  rights: {
    padding: '5px',
    marginTop: 'calc(100% - 150px)'
  }
})


const FormComponent = ({ classes, values, handleChange, handleBlur }) => (
  <Grid container justify="flex-start">
    <Grid item>
      <Typography component="h5" variant="h5" gutterBottom>
      Sign In
     </Typography>
     <Typography component="p" gutterBottom style={{ marginBottom: '20px' }}>
        to Bisimo IntelSales system
     </Typography>

    </Grid>

      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label="Username"
        name="username"
        value={values.username || ''}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        value={values.password || ''}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        className={classes.submit}
        style={{ background: '#4e8df6', color: '#ffffff' }}
      >
        Sign In
      </Button>
      <Grid container justify="center">
        <Grid item className={classes.rights}>
          <Typography variant="body2" gutterBottom >
            {`© ${getCurrentYear()} Freewill FX Co., Ltd.`}
          </Typography>

        </Grid>
      </Grid>
  </Grid>
)

const LeftBar = ({ classes }) => (
  <Grid item xs={false} sm={4} md={7} className={classes.image}>
    <Grid container justify="center" spacing={8} style={{ marginTop: '30%' }} className={classes.leftBar}>
      <Grid item sm={2}>
        <Avatar className={classes.avatar} src="/img/bisimo2.png" />
      </Grid>
      <Grid item sm={12} style={{ textAlign: 'center' }}>
        <Typography component="h4" variant="h4" gutterBottom style={{ color: 'white' }}>
          Bisimo Intelsales
      </Typography>
        <Typography component="p" gutterBottom style={{ color: 'white' }}>
          Maintaining accuracy for your high-value inventory
      </Typography>
      </Grid>
    </Grid>
  </Grid>
)


function LoginForm(props) {
  const { classes } = props;
  const { onSubmit } = props
  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={defaultState}
        validationSchema={yup.object().shape({
          username: yup.string().required('กรุณากรอก'),
          password: yup.string().required('กรุณากรอก')
        })}
        onSubmit={values => onSubmit(values)}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
            <Fragment>
              <form onSubmit={handleSubmit}>
                <Grid container component="main" >
                  <CssBaseline />
                  <Grid container justify="center" alignItems="center" spacing={8}>
                    <Paper className={classes.paperRoot} elevation={1}>
                      <LeftBar classes={classes}/>
                      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={2}>
                        <div className={classes.paper}>
                          <FormComponent
                            classes={classes}
                            values={values}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                          />
                        </div>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </form>
            </Fragment>
          )}
      />
    </Fragment>
  )
}
export default withStyles(styles)(LoginForm)

