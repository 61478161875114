
import React, { Component, Fragment } from 'react';
import { DatePickerWidget } from 'lib/widgets'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import { formatDate, getCurrentYear, getToday } from 'lib/helper'
import { styles } from '../../../../reports/product/RelateFunc'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'

const defaultValue = {
    last_year_q4: false,
    q1: false,
    q2: false,
    q3: false,
    q4: false,
    start_date: getCurrentYear()+'-01-01',
    end_date: getToday()
}

class TableFilter extends Component {
    state = {...defaultValue,
        company_id: this.props.companyId,
        branch_id: this.props.branchId
    }
    handleChange = name => event => {
        switch (name) {
            case 'checkedAll':
                this.setState({
                    last_year_q4: event.target.checked,
                    q1: event.target.checked,
                    q2: event.target.checked,
                    q3: event.target.checked,
                    q4: event.target.checked,
                })
                break;
            case 'last_year_q4':
                this.setState({ last_year_q4: event.target.checked })
                break;
            case 'q1':
                this.setState({ q1: event.target.checked })
                break;
            case 'q2':
                this.setState({ q2: event.target.checked })
                break;
            case 'q3':
                this.setState({ q3: event.target.checked })
                break;
            case 'q4':
                this.setState({ q4: event.target.checked })
                break;
            default: break;
        }
    };

    clearValue = () => {
        this.setState(defaultValue)
    }

    handleSearch = () => {
        const { start_date, end_date } = this.state
        if (start_date === null && end_date !== null) {
            alert('กรุณากรอก วันที่เริ่มต้น')
            return false
        }
        if (start_date !== null && end_date === null) {
            alert('กรุณากรอก วันที่สิ้นสุด')
            return false
        }
        this.props.onSearch(this.state)
    }

    isAllCheckboxSelected = () => {
        const { last_year_q4, q1, q2, q3, q4 } = this.state
        let isTrue = last_year_q4 && q1 && q2 && q3 && q4
        return isTrue
    }

    getLastYear = () => {
        let d = new Date();
        return d.getFullYear() - 1
    }

    componentWillReceiveProps(nextProps) {
        const { companyId, branchId } = nextProps
        this.setState({ 
            company_id: companyId,
            branch_id: branchId
        })
    }

    render() {
        const { last_year_q4, q1, q2, q3, q4, start_date, end_date } = this.state
        const { classes } = this.props

        return (
            <Fragment>
                <Grid container spacing={16} justify="space-around">
                    <Grid item sm={2} xs={4}>วันที่เข้าร่วม</Grid>
                    <Grid item sm={3} xs={8}>
                        <DatePickerWidget
                            name="start_date"
                            value={start_date}
                            title="เริ่มต้น"
                            onValueChange={(value, name) => {
                                this.setState({ start_date: formatDate(value) })
                            }}
                        />
                    </Grid>
                    <Grid item sm={2} xs={4} className="text-center">ถึง</Grid>
                    <Grid item sm={3} xs={8}>
                        <DatePickerWidget
                            name="end_date"
                            value={end_date}
                            title="สิ้นสุด"
                            onValueChange={(value, name) => {
                                this.setState({ end_date: formatDate(value) })
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={16} justify="space-around">
                    <Grid item sm={2} xs={3}>ไตรมาส</Grid>
                    <Grid item sm={9} xs={9}>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value="checkedAll"
                                        checked={this.isAllCheckboxSelected()}
                                        onChange={this.handleChange('checkedAll')}
                                    />
                                }
                                label="เลือกทั้งหมด"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="last_year_q4"
                                        value="last_year_q4"
                                        checked={last_year_q4}
                                        onChange={this.handleChange('last_year_q4')}
                                    />
                                }
                                label={`Q4 ปี ${this.getLastYear()}`}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="q1"
                                        value="q1"
                                        checked={q1}
                                        onChange={this.handleChange('q1')}
                                    />
                                }
                                label="Q1"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="q2"
                                        value="q2"
                                        checked={q2}
                                        onChange={this.handleChange('q2')}
                                    />
                                }
                                label="Q2"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="q3"
                                        value="q3"
                                        checked={q3}
                                        onChange={this.handleChange('q3')}
                                    />
                                }
                                label="Q3"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="q4"
                                        value="q4"
                                        checked={q4}
                                        onChange={this.handleChange('q4')}
                                    />
                                }
                                label="Q4"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container spacing={16} justify="center">
                    <Button
                        variant="contained"
                        color="default"
                        onClick={this.clearValue}
                        className={classes.button}
                    >
                        เคลียร์
                          </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={this.handleSearch}
                        className={classes.button}
                    >
                        ตกลง
                          </Button>

                </Grid>
            </Fragment>
        );
    }
}

export default withStyles(styles)(TableFilter)