import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import debounce from 'lodash/debounce'
import * as actions from '../actions'

import { ActionBtn, ButtonTable, SearchPanel, MenuMobile } from 'lib/widgets'

import MUIDataTable from 'mui-datatables'
import { MuiThemeProvider } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Tablestyle, HistoryTbStyle } from '../../AddonStyles'

import {
  numberFormat,
  formatDate,
  serializeObj,
  createCondition
} from 'lib/helper'
import AdvanceSearch from './AdvanceSearch'
import { defaultState } from '../defaultState'

class SalesOrderTable extends PureComponent {
  state = {
    term: '',
    search: '',
    term_from_advsearch: "",
    delId: '',
    options: {},
    page: 0,
    sortName: '',
    rowsPerPage: 10,
    count: 100,
    isModalOpen: false,
    showAdvSearch: false,
    pageCond: '',
    width: window.innerWidth
  }

  componentDidMount() {
    if (typeof this.props.page === 'undefined') {
      this.fetchSalesOrders()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      typeof this.props.pageCond !== 'undefined' &&
      this.state.pageCond !== nextProps.pageCond
    ) {
      this.setState({ pageCond: nextProps.pageCond }, () => {
        this.props.loadSalesOrders('?' + serializeObj(this.props.pageCond))
      })
    }
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth })
  }

  fetchSalesOrders = async () => {
    const { term } = this.state
    this.props.loadSalesOrders(`?exclude=true&${term}`)
  }

  delaySearchData = debounce(async value => {
    this.setState({ search: value })
    const cond =
      '?exclude=relation&strict=false&' +
      createCondition({ document_no: '' }, value)
    this.props.loadSalesOrders(cond)
  }, 750)

  searchsalesorders = event => {
    return this.delaySearchData(event)
  }

  advSearch = data => {
    let cond = data.replace(/\?/g, '')
    this.props.loadSalesOrders(`?exclude=relation&${cond}`)
  }

  setAdvSearch = data => {
    this.setState({ term: data.replace(/\?/g, ''), term_from_advsearch: data.replace(/\?/g, '') })
    this.advSearch(data)
  }

  showAdvSearch = state => {
    this.setState({ showAdvSearch: state })
  }

  navigateToCreateForm = () => {
    this.props.history.push('/salesforce/sale-orders/new')
  }

  editForm = id => {
    if (typeof this.props.history === 'undefined') {
      window.open(`/salesforce/sale-orders/${id}`)
    } else {
      this.props.history.push(`/salesforce/sale-orders/${id}`)
    }
  }

  deleteSalesOrder = id => {
    const { delId } = this.state
    this.props.deleteSalesOrder(delId)
    this.setState({ isModalOpen: false })
  }

  toggleConfirmDlg = (id = '') => {
    this.setState({ isModalOpen: !this.state.isModalOpen, delId: id })
  }

  getRealCol = name => {
    let col = name
    switch (name) {
      case 'customer_name':
        col = 'customer_id'
        break
      case 'sales_name':
        col = 'user_id'
        break
      case 'payment_status':
        col = 'status'
        break
      default:
        break
    }

    return col
  }

  sortingData = (changedColumn, direction) => {
    let sortby = this.getRealCol(changedColumn)
    const { page, rowsPerPage, search } = this.state
    const sort = direction === 'ascending' ? 'asc' : 'desc'

    let condStr =
      'strict=false&' + createCondition({ document_no: '' }, search)
    if (this.state.term_from_advsearch !== "") condStr += "&" + this.state.term_from_advsearch

    let term = `?page=${page}&per_page=${rowsPerPage}&sort_by=${sortby}&sort=${sort}&${condStr}`
    this.setState(
      {
        term: term,
        sortName: changedColumn,
        options: { sort: true, sortDirection: sort }
      },
      this.advSearch(term)
    )
  }

  handleTableChange = (action, tableState) => {
    let term = ''
    let condStr =
      'strict=false&' + createCondition({ document_no: '' }, this.state.search)
    if (this.state.term_from_advsearch !== "") condStr += "&" + this.state.term_from_advsearch

    switch (action) {
      case 'changeRowsPerPage':
        term = `?per_page=${tableState.rowsPerPage}&${condStr}`
        this.setState(
          {
            term: term,
            rowsPerPage: tableState.rowsPerPage,
            page: 0
          },
          this.advSearch(term)
        )
        break
      case 'changePage':
        term = `?page=${tableState.page + 1}&per_page=${tableState.rowsPerPage
          }&${condStr}`
        this.setState(
          {
            term: term,
            page: tableState.page
          },
          this.advSearch(term)
        )
        break
      default:
        break
    }
  }

  renderTable = (isLoading, columns, salesorders, optionsTable) => {
    let title =
      typeof this.props.title !== 'undefined' ? this.props.title : 'รายการขาย'
    const isMobile = this.state.width <= 500

    if (isMobile) {
      return (
        <MenuMobile
          data={salesorders}
          columns={{
            col1: 'delivery_date',
            col2: 'document_no',
            col3: 'customer_name',
            col4: 'net'
          }}
          title={{
            title1: () => 'ทำรายการ',
            title2: () => 'เลขที่ SO',
            title3: () => 'ร้านค้า',
            title4: () => 'ยอดสุทธิ'
          }}
          url={`api/sale-orders`}
          onLoad={this.advSearch}
          cond={this.props.pageCond}
          linkUrl={`/salesforce/sale-orders`}
          hideCreate={true}
          hideAll={true}
        />
      )
    }

    if (typeof salesorders === 'object')
      return (
        <Fragment>
          {isLoading && <div className="lmask" id="lmask" />}
          <MuiThemeProvider
            theme={
              this.props.page !== 'history' ? Tablestyle() : HistoryTbStyle()
            }
          >
            <MUIDataTable
              title={`${title}`}
              data={salesorders}
              columns={columns}
              options={optionsTable}
            />
          </MuiThemeProvider>
        </Fragment>
      )
  }

  render() {
    const { isLoading, salesorders, meta } = this.props
    const { rowsPerPage, page, sortName, options } = this.state
    const count = typeof meta === 'undefined' ? 100 : meta.total

    const columns = [
      {
        name: '',
        label: '#',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return page * rowsPerPage + tableMeta.rowIndex + 1
          }
        }
      },
      {
        label: 'เลขที่ SO',
        name: 'document_no',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            console.log("dd", tableMeta)
            return <a href={`/salesforce/sale-orders/${tableMeta.rowData[8]}`}
              target="_blank" rel="noopener noreferrer">{value}</a>
          }
        }
      },
      { label: 'ร้านค้า', name: 'customer_name' },
      {
        label: 'ยอดสุทธิ',
        name: 'net',
        options: { customBodyRender: row => numberFormat(row) }
      },
      {
        label: 'ทำรายการ',
        name: 'delivery_date',
        options: { customBodyRender: row => formatDate(row) }
      },
      {
        label: 'ซิงค์รายการ',
        name: 'sync_date',
        options: { customBodyRender: row => formatDate(row) }
      },
      { label: 'ชื่อพนักงาน', name: 'sales_name' },
      { label: 'สถานะ', name: 'payment_status' },
      {
        name: 'id',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <ButtonTable
                id={value}
                onOpen={this.editForm}
                displayDel={false}
              />
            )
          }
        }
      }
    ]

    const optionsTable = {
      filter: false,
      print: false,
      download: false,
      viewColumns: false,
      filterType: 'dropdown',
      serverSide: true,
      count: count,
      page: page,
      rowsPerPage: rowsPerPage,
      selectableRows: false,
      onTableChange: this.handleTableChange,
      onSearchChange: this.searchsalesorders,
      onColumnSortChange: this.sortingData,
      textLabels: {
        body: {
          noMatch: 'Sorry, no matching records found',
          toolTip: 'Sort'
        }
      },
      onSearchClose: () => {
        this.setState({ search: '' })
        this.advSearch(this.state.term)
      },
      customToolbar: () => {
        return (
          <ActionBtn
            onCreate={this.navigateToCreateForm}
            onSearch={this.showAdvSearch}
            displayCreate={false}
          />
        )
      }
    }

    if (sortName !== '') {
      let idx = columns.findIndex(data => data.name === sortName)
      if (idx === -1) return
      columns[idx].options = options
    }

    return (
      <Fragment>
        <Fragment>
          <Grid item xs={12}>
            {this.renderTable(isLoading, columns, salesorders, optionsTable)}
          </Grid>
        </Fragment>

        <SearchPanel
          toggleDrawer={this.showAdvSearch}
          isOpen={this.state.showAdvSearch}
          onSubmit={this.setAdvSearch}
          Component={AdvanceSearch}
          formValue={defaultState.advSearch}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ salesorders }) => ({
  isLoading: salesorders.isLoading,
  salesorders: salesorders.items,
  meta: salesorders.meta,
  backupItems: salesorders.backupItems
})

const mapDispatchToProps = {
  loadSalesOrders: actions.loadSalesOrders.request,
  filterData: actions.filterData
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SalesOrderTable)
