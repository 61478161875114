import { createTypes, createAction } from 'lib/action'
 
const LOAD_SALESORDERS = createTypes('salesorders', 'list')
const LOAD_SALESORDER = createTypes('salesorders', 'show')
const CANCEL_SALESORDER = createTypes('salesorders', 'cancel')
const ACCEPT_SALESORDER = createTypes('salesorders', 'accept')
const FILTER_SALESORDER = 'backup'
const SET_SALESORDER = 'api/salesorders'
const GET_SALESORDER = 'GET_SALESORDER'
const FETCH_SALESORDERS_FROM_STORE = "FETCH_SALESORDERS_FROM_STORE"

const loadSalesOrders = {
  request: term => createAction(LOAD_SALESORDERS.REQUEST, { term }),
  success: salesorders => createAction(LOAD_SALESORDERS.SUCCESS, { salesorders }),
  failure: () => createAction(LOAD_SALESORDERS.FAILURE)
}

const loadSalesOrder = {
  request: id => createAction(LOAD_SALESORDER.REQUEST, { id }),
  success: salesorder => createAction(LOAD_SALESORDER.SUCCESS, { salesorder }),
  failure: () => createAction(LOAD_SALESORDER.FAILURE)
}

const cancelSalesOrder = {
  request: (id, salesorder) => createAction(CANCEL_SALESORDER.REQUEST, { id, salesorder }),
  success: salesorder => createAction(CANCEL_SALESORDER.SUCCESS, { salesorder }),
  failure: () => createAction(CANCEL_SALESORDER.FAILURE)
}

const acceptPayment = {
  request: collections => createAction(ACCEPT_SALESORDER.REQUEST, { collections }),
  success: collections => createAction(ACCEPT_SALESORDER.SUCCESS, { collections }),
  failure: () => createAction(ACCEPT_SALESORDER.FAILURE)
}



const filterData = (salesorders, search) => ({
  type: FILTER_SALESORDER,
  payload: {
    salesorders,
    search 
  }
})

const loadSalesOrderFromArray = id => ({
  type: GET_SALESORDER,
  payload: id
})

const fecthSalesOrderFromArray = () => ({
  type : FETCH_SALESORDERS_FROM_STORE,
  payload: {}
})


export {
  LOAD_SALESORDERS,
  LOAD_SALESORDER,
  CANCEL_SALESORDER,
  ACCEPT_SALESORDER,
  SET_SALESORDER,
  FILTER_SALESORDER,
  GET_SALESORDER,
  FETCH_SALESORDERS_FROM_STORE,
  loadSalesOrders,
  loadSalesOrder,
  loadSalesOrderFromArray,
  cancelSalesOrder,
  acceptPayment,
  filterData,
  fecthSalesOrderFromArray
}
