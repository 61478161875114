import React, { Component } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'

import { defaultState } from '../defaultState'

import { SubmitBtn, PageTitle, MapSingleMask, SelectWidget } from 'lib/widgets'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { Formstyles } from './AddonStyles'
import { genOptionList, genRelateOptionList } from 'lib/helper'
import { loadProvice } from 'lib/loadOption'

import History from './History'

class StoreForm extends Component {

  state = defaultState

  toggleFx = name => {
    let isOpen = this.state[`${name}`]
    this.setState({ [`${name}`]: !isOpen })
  }


  componentDidMount() {
    if (window.location.pathname !== '/salesforce/stores/new') {
      this.disableForm(true)
    }

    loadProvice().then(res => {
      this.setState({
        province: genOptionList(res, 'id', 'name'),
        bkprovince: res, 
        formLoading: false
      })
    })
  }

  disableForm = disableForm => {
    this.setState({ disableForm })
  }

  setRelateOption = (name, value) => {
    let items = this.state.bkprovince
    let idx = items.findIndex(data => parseInt(data.id) === parseInt(value))
    if (idx < 0) return

    this.setState({
      district: genRelateOptionList(
        [items[idx]],
        'id',
        'name',
        'district',
        'province_id'
      )
    })
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue })
  }

  componentWillReceiveProps(nextProps) {
    const { data } = nextProps
    if (data.province_id !== '')
      this.setRelateOption('province_id', data.province_id)
      this.disableForm(true)
  }


  render() {
    const { classes, data, onSubmit, isLoading } = this.props
    const { value } = this.state
    return (
      <Grid item xs={12}>
         { this.state.formLoading || isLoading ? <div className="lmask" id="lmask" /> : null }
        <AppBar position="static">
          <Tabs value={value} onChange={this.handleChange}>
            <Tab label="ข้อมูลร้านค้า" />
            <Tab label="ประวัคิการซื้อ" />
          </Tabs>
        </AppBar>
        {value === 0 && 
          <Formik
            enableReinitialize
            initialValues={data}
            validationSchema={yup.object().shape({
              name: yup.string().required('กรุณากรอก')
            })}
            onSubmit={values => onSubmit(values)}
            render={({
              values,
              errors,
              touched,
              resetForm,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue
            }) => (
              <Paper className={classes.paper}>
                <form onSubmit={handleSubmit}>
                  <fieldset disabled={this.state.disableForm}>
                    <div className={classes.root}>
                      <Grid container spacing={24}>
                        <PageTitle
                          formTitle="ร้านค้า"
                          action={this.disableForm}
                          edit={this.state.disableForm}
                          view="shop edit"
                        />
                        <Grid item xs={12}>
                          <TextField
                            required
                            name="name"
                            label="ชื่อ"
                            fullWidth
                            value={values.name || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                          {touched.name && errors.name && (
                            <span className="form-text--error">
                              {errors.username}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="nickname"
                            label="ชื่อย่อ"
                            fullWidth
                            value={values.nickname || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <SelectWidget
                            url="api/shop-types"
                            value={values.shop_type_id || ''}
                            name="shop_type_id"
                            title="ประเภทร้านค้า * "
                            onChange={setFieldValue}
                            disabled={this.state.disableForm}
                          />
                          {touched.shop_type_id && errors.shop_type_id && (
                            <span className="form-text--error">
                              {errors.shop_type_id}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <SelectWidget
                            url="api/customer-groups?exclude=relation"
                            value={values.customer_group || ''}
                            name="customer_group"
                            title="กลุ่มลูกค้า *"
                            onChange={setFieldValue}
                            disabled={this.state.disableForm}
                          />
                          {touched.customer_group && errors.customer_group && (
                            <span className="form-text--error">
                              {errors.customer_group}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="id_card"
                            label="เลขบัตรประชาชน"
                            fullWidth
                            value={values.id_card || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="taxid"
                            label="รหัสภาษี"
                            fullWidth
                            value={values.taxid || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <SelectWidget
                            url="api/master-data/users"
                            value={values.sale_profile_id || ''}
                            name="sale_profile_id"
                            title="ชื่อพนักงาน * "
                            onChange={setFieldValue}
                            disabled={this.state.disableForm}
                          />
                          {touched.sale_profile_id && errors.sale_profile_id && (
                            <span className="form-text--error">
                              {errors.sale_profile_id}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name="houseno"
                            label="บ้านเลขที่"
                            fullWidth
                            value={values.houseno || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name="soi"
                            label="ซอย"
                            fullWidth
                            value={values.soi || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12}  sm={4}>
                          <TextField
                            name="street"
                            label="ถนน"
                            fullWidth
                            value={values.street || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <SelectWidget
                            withOption={true}
                            options={this.state.province}
                            value={values.province_id || ''}
                            name="province_id"
                            title="จังหวัด *"
                            onChange={(name, value) => {
                              setFieldValue(name, value)
                              this.setRelateOption(name, value)
                            }}
                            disabled={this.state.disableForm}
                          />
                          {touched.province_id && errors.province_id && (
                            <span className="form-text--error">
                              {errors.province_id}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12}  sm={6}>
                          <SelectWidget
                            withOption={true}
                            options={this.state.district}
                            value={values.district_id || ''}
                            name="district_id"
                            title="อำเภอ *"
                            onChange={setFieldValue}
                            disabled={this.state.disableForm}
                          />
                          {touched.district_id && errors.district_id && (
                            <span className="form-text--error">
                              {errors.district_id}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="subdistrict"
                            label="ตำบล"
                            fullWidth
                            value={values.subdistrict || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="postal_code"
                            label="รหัสไปรษณีย์"
                            fullWidth
                            value={values.postal_code || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="phone"
                            label="เบอร์โทร"
                            fullWidth
                            value={values.phone || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="mobile"
                            label="มือถือ"
                            fullWidth
                            value={values.mobile || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MapSingleMask
                            isMarkerShown={true}
                            lat={values.latitude}
                            lng={values.longitude}
                            onMarkerClick={(lat, lng) => {
                              setFieldValue("latitude", lat)
                              setFieldValue("longitude", lng)
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            name="latitude"
                            label="แลตติจูด"
                            fullWidth
                            value={values.latitude || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="longitude"
                            label="ลองจิจูด"
                            fullWidth
                            value={values.longitude || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="secondary"
                                name="active"
                                value="1"
                                onChange={(e) => {
                                  let val = (e.target.checked) ? '1' : '0'
                                  setFieldValue("active", val)
                                }}
                                onBlur={handleBlur}
                                checked={
                                  values.active.toString() === '1'
                                }
                              />
                            }
                            label="Active"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="secondary"
                                name="is_favorite"
                                value="1"
                                onChange={(e) => {
                                  let val = (e.target.checked) ? '1' : '0'
                                  setFieldValue("is_favorite", val)
                                }}
                                onBlur={handleBlur}
                                checked={
                                  values.is_favorite.toString() === '1'
                                }
                              />
                            }
                            label="ร้านโปรด"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="remark"
                            label="หมายเหตุ"
                            fullWidth
                            value={values.remark || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <SubmitBtn
                            disabled={this.state.disableForm}
                            onClear={() => resetForm(defaultState)}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </fieldset>
                </form>
              </Paper>
            )}
          />
        }
        {value === 1 && <History classes={classes} />}
      </Grid>
    )
  }
}

export default withStyles(Formstyles)(StoreForm)
