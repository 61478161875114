import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import UnitTable from './UnitTable'
import UnitList from './UnitList'
import EditUnit from './EditUnit'
import CreateUnit from './CreateUnit'

import { Breadcrumb } from 'lib/widgets'
import { Layoutstyles } from './AddonStyles'

const UnitLayout = ({ classes }) => (
  <div className={classes.root}>
    <Grid container spacing={24}>
      <Grid item xs>
        <Breadcrumb
          menu="ข้อมูลหลัก"
          submenu="หน่วยนับ"
          menuLink="/master-data/units"
        />
      </Grid>
    </Grid>
    <Grid container spacing={24}>
      <Switch>
        <Route exact path="/master-data/units" component={UnitTable} />
        <Route path="/master-data/units" component={UnitList} />
      </Switch>
      <Switch>
        <Route path="/master-data/units/new" component={CreateUnit} />
        <Route path="/master-data/units/:id" component={EditUnit} />
      </Switch>
    </Grid>
  </div>
)

export default withStyles(Layoutstyles)(UnitLayout)
