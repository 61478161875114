import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import { PriceBook } from './PriceBook'
import { ProductUnits } from './ProductUnits'
import { EditTableStyle, TabStyle } from './AddonStyles'
import { MuiThemeProvider } from '@material-ui/core/styles'

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export function ProductTab(props) {

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={TabStyle()}>
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange}>
            <Tab label="หน่วยนับ" />
            <Tab label="Price book" />
          </Tabs>
        </AppBar>
        {value === 0 &&
          <TabContainer>

            <MuiThemeProvider theme={EditTableStyle()}>
              <Typography component="h2"  gutterBottom style={{ color: 'red' }}>
                กรุณากรอกข้อมูลในคอลัมน์ที่มี * ให้ครบทุกคอลัมน์
                </Typography>
              <ProductUnits
                rows={props.rows}
                ProductId={props.ProductId}
                onCreate={props.onCreate}
                onEdit={props.onEdit}
                onDelete={props.onDelete}
              />
            </MuiThemeProvider>

          </TabContainer>}
        {value === 1 &&
          <TabContainer>

            <MuiThemeProvider theme={EditTableStyle()}>
              <PriceBook
                rows={props.rowsPrice}
                ProductId={props.ProductId}
                onEdit={props.onEditPrice}
              />
            </MuiThemeProvider>

          </TabContainer>}
      </MuiThemeProvider>
    </div>
  );
}

