import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { headerWithAuth, handleUnAutherize } from 'lib/authFunc'
import * as actions from './actions'

const header = headerWithAuth()

function* loadBinDetails(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.get,
      process.env.REACT_APP_API_URL + `/api/bin-details${term}`,
      header
    )
    yield put(actions.loadBinDetails.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadBinDetails.failure(error.response))
  }
}

function* loadBinDetail(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.get, process.env.REACT_APP_API_URL + `/api/bin-details/${id}`, header)
    yield put(actions.loadBinDetail.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadBinDetail.failure(error.response))
  }
}

function* createBinDetail(action) {
  try {
    const { bindetail } = action.payload
    const res = yield call(axios.post, process.env.REACT_APP_API_URL + '/api/bin-details', { bindetail }, header)
    yield put(actions.createBinDetail.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.createBinDetail.failure(error.response))
  }
}

function* editBinDetail(action) {
  try {
    const { bindetail, id } = action.payload
    const res = yield call(axios.patch, process.env.REACT_APP_API_URL + `/api/bin-details/${id}`, bindetail, header)

    yield put(actions.editBinDetail.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.editBinDetail.failure(error.response))
  }
}

function* deleteBinDetail(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.delete, process.env.REACT_APP_API_URL + `/api/bin-details/${id}`, header)
    yield put(actions.deleteBinDetail.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.deleteBinDetail.failure(error.response))
  }
}


export default function* watchComments() {
  yield all([
    takeEvery(actions.LOAD_BINDETAILS.REQUEST, loadBinDetails),
    takeEvery(actions.LOAD_BINDETAIL.REQUEST, loadBinDetail),
    takeEvery(actions.CREATE_BINDETAIL.REQUEST, createBinDetail),
    takeEvery(actions.EDIT_BINDETAIL.REQUEST, editBinDetail),
    takeEvery(actions.DELETE_BINDETAIL.REQUEST, deleteBinDetail)
  ])
}
