import { createTypes, createAction } from 'lib/action'
 
const LOAD_PRODUCTS = createTypes('products', 'list')
const LOAD_PRODUCT = createTypes('products', 'show')
const CREATE_PRODUCT = createTypes('products', 'create')
const EDIT_PRODUCT = createTypes('products', 'edit')
const DELETE_PRODUCT = createTypes('products', 'delete')

const FILTER_PRODUCT = 'backup'
const SET_PRODUCT = 'api/products'
const GET_PRODUCT = 'GET_PRODUCT'
const FETCH_PRODUCTS_FROM_STORE = "FETCH_PRODUCTS_FROM_STORE"

const CREATE_PRODUCT_UNIT = createTypes('products_unit', 'create')
const EDIT_PRODUCT_UNIT = createTypes('products_unit', 'edit')
const DELETE_PRODUCT_UNIT = createTypes('products_unit', 'delete')
const EDIT_PRODUCT_PRICE = createTypes('products_price', 'edit')


const loadProducts = {
  request: term => createAction(LOAD_PRODUCTS.REQUEST, { term }),
  success: products => createAction(LOAD_PRODUCTS.SUCCESS, { products }),
  failure: () => createAction(LOAD_PRODUCTS.FAILURE)
}

const loadProduct = {
  request: id => createAction(LOAD_PRODUCT.REQUEST, { id }),
  success: product => createAction(LOAD_PRODUCT.SUCCESS, { product }),
  failure: () => createAction(LOAD_PRODUCT.FAILURE)
}

const createProduct = {
  request: product => createAction(CREATE_PRODUCT.REQUEST, { product }),
  success: product => createAction(CREATE_PRODUCT.SUCCESS, { product }),
  failure: error => createAction(CREATE_PRODUCT.FAILURE, { error })
}

const editProduct = {
  request: (id, product) => createAction(EDIT_PRODUCT.REQUEST, { id, product }),
  success: product => createAction(EDIT_PRODUCT.SUCCESS, { product }),
  failure: () => createAction(EDIT_PRODUCT.FAILURE)
}

const deleteProduct = {
  request: (id) => createAction(DELETE_PRODUCT.REQUEST, { id } ),
  success: id => createAction(DELETE_PRODUCT.SUCCESS, { id } ),
  failure: () => createAction(DELETE_PRODUCT.FAILURE)
}

const createUnitItem = {
  request: uom => createAction(CREATE_PRODUCT_UNIT.REQUEST, { uom }),
  success: uom => createAction(CREATE_PRODUCT_UNIT.SUCCESS, { uom }),
  failure: error => createAction(CREATE_PRODUCT_UNIT.FAILURE, { error })
}

const editUnitItem = {
  request: (id, uom) => createAction(EDIT_PRODUCT_UNIT.REQUEST, { id, uom }),
  success: uom => createAction(EDIT_PRODUCT_UNIT.SUCCESS, { uom }),
  failure: () => createAction(EDIT_PRODUCT_UNIT.FAILURE)
}

const deleteUnitItem = {
  request: (id, data) => createAction(DELETE_PRODUCT_UNIT.REQUEST, { id, data } ),
  success: data => createAction(DELETE_PRODUCT_UNIT.SUCCESS, { data } ),
  failure: () => createAction(DELETE_PRODUCT_UNIT.FAILURE)
}

const editPriceBookItem = {
  request: (id, pricebook) => createAction(EDIT_PRODUCT_PRICE.REQUEST, { id, pricebook }),
  success: pricebook => createAction(EDIT_PRODUCT_PRICE.SUCCESS, { pricebook }),
  failure: () => createAction(EDIT_PRODUCT_PRICE.FAILURE)
}

const filterData = (products, search) => ({
  type: FILTER_PRODUCT,
  payload: {
    products,
    search 
  }
})

const loadProductFromArray = id => ({
  type: GET_PRODUCT,
  payload: id
})

const fecthProductFromArray = () => ({
  type : FETCH_PRODUCTS_FROM_STORE,
  payload: {}
})


export {
  LOAD_PRODUCTS,
  LOAD_PRODUCT,
  CREATE_PRODUCT,
  EDIT_PRODUCT,
  DELETE_PRODUCT,
  SET_PRODUCT,
  FILTER_PRODUCT,
  GET_PRODUCT,
  FETCH_PRODUCTS_FROM_STORE,
  CREATE_PRODUCT_UNIT,
  EDIT_PRODUCT_UNIT,
  DELETE_PRODUCT_UNIT,
  EDIT_PRODUCT_PRICE,
  loadProducts,
  loadProduct,
  loadProductFromArray,
  createProduct,
  editProduct,
  deleteProduct,
  filterData,
  fecthProductFromArray,
  createUnitItem,
  editUnitItem,
  deleteUnitItem,
  editPriceBookItem
}
