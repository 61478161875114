import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import * as actions from '../actions'
import UserForm from './UserForm'
import { defaultState } from '../defaultState'
import { hasMessage } from 'lib/fxUI'

class CreateUser extends Component {
  createUser = async article => {
    this.props.createUser(article)
  }

  render() {
    const { successSave, isLoading, errormsg } = this.props
    return (
      <Fragment>
        {successSave !== null && hasMessage(successSave, errormsg)}
        <UserForm
          onSubmit={this.createUser}
          formAction="สร้างใหม่"
          successSave={successSave}
          isLoading={isLoading}
          user={defaultState} />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ users }) => ({
  successSave: users.successSave,
  errormsg: users.errormsg,
  isLoading: users.isLoading,
})
const mapDispatchToProps = {
  createUser: actions.createUser.request
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUser)
