import { createTypes, createAction } from 'lib/action'
 
const LOAD_UNITS = createTypes('units', 'list')
const LOAD_UNIT = createTypes('units', 'show')
const CREATE_UNIT = createTypes('units', 'create')
const EDIT_UNIT = createTypes('units', 'edit')
const DELETE_UNIT = createTypes('units', 'delete')
const FILTER_UNIT = 'backup'
const SET_UNIT = 'api/units'
const GET_UNIT = 'GET_UNIT'
const FETCH_UNITS_FROM_STORE = "FETCH_UNITS_FROM_STORE"

const loadUnits = {
  request: term => createAction(LOAD_UNITS.REQUEST, { term }),
  success: units => createAction(LOAD_UNITS.SUCCESS, { units }),
  failure: () => createAction(LOAD_UNITS.FAILURE)
}

const loadUnit = {
  request: id => createAction(LOAD_UNIT.REQUEST, { id }),
  success: unit => createAction(LOAD_UNIT.SUCCESS, { unit }),
  failure: () => createAction(LOAD_UNIT.FAILURE)
}

const createUnit = {
  request: unit => createAction(CREATE_UNIT.REQUEST, { unit }),
  success: unit => createAction(CREATE_UNIT.SUCCESS, { unit }),
  failure: error => createAction(CREATE_UNIT.FAILURE, { error })
}

const editUnit = {
  request: (id, unit) => createAction(EDIT_UNIT.REQUEST, { id, unit }),
  success: unit => createAction(EDIT_UNIT.SUCCESS, { unit }),
  failure: () => createAction(EDIT_UNIT.FAILURE)
}

const deleteUnit = {
  request: (id) => createAction(DELETE_UNIT.REQUEST, { id } ),
  success: id => createAction(DELETE_UNIT.SUCCESS, { id } ),
  failure: () => createAction(DELETE_UNIT.FAILURE)
}

const filterData = (units, search) => ({
  type: FILTER_UNIT,
  payload: {
    units,
    search 
  }
})

const loadUnitFromArray = id => ({
  type: GET_UNIT,
  payload: id
})

const fecthUnitFromArray = () => ({
  type : FETCH_UNITS_FROM_STORE,
  payload: {}
})


export {
  LOAD_UNITS,
  LOAD_UNIT,
  CREATE_UNIT,
  EDIT_UNIT,
  DELETE_UNIT,
  SET_UNIT,
  FILTER_UNIT,
  GET_UNIT,
  FETCH_UNITS_FROM_STORE,
  loadUnits,
  loadUnit,
  loadUnitFromArray,
  createUnit,
  editUnit,
  deleteUnit,
  filterData,
  fecthUnitFromArray
}
