const markerDescriptions = [
    {
      id: 1,
      size: {width: 102, height: 100},
      origin: {x: 15 / 62, y: 1},
      withText: true,
      image: require('./img/iconfinder_Finance_loan_money_1889199.svg'),
      imageClass: 'map_icon_text_red'
    },
    {
      id: 2,
      size: {width: 62, height: 60},
      origin: {x: 15 / 62, y: 1},
      withText: true,
      image: require('./img/map_icon_text_indigo.svg'),
      imageClass: 'map_icon_text_indigo'
    },
    {
      id: 3,
      size: {width: 44, height: 62},
      origin: {x: 0.37, y: 1},
      imageClass: 'map-marker__marker--as'
    },
    {
      id: 4,
      size: {width: 61, height: 65},
      origin: {x: 0.37, y: 1},
      imageClass: 'map-marker__marker--ap'
    },
    {
      id: 5,
      size: {width: 61, height: 65},
      origin: {x: 0.5, y: 1},
      image: require('./img/map_icon_std_green.svg')
    },
    {
      id: 6,
      size: {width: 61, height: 65},
      origin: {x: 24 / 61, y: 63 / 65},
      image: require('./img/map_icon_std_orange.svg'),
      hintType: 'hint--error'
    },
    {
      id: 7,
      size: {width: 61, height: 65},
      origin: {x: 0.5, y: 1},
      image: require('./img/map_icon_std_gray.svg'),
      hintType: 'hint--block'
    },
    {
      id: 8,
      size: {width: 39, height: 54},
      origin: {x: 0.5, y: 1},
      image: require('./img/map_icon_std.svg')
    }
  ];
  
  export default markerDescriptions;