import React from 'react';
import ContentLoader from 'react-content-loader'

export default () => (
	<ContentLoader 
		rtl
		height={600}
		width={300}
		speed={2}
		primaryColor="#f3f3f3"
		secondaryColor="#ecebeb"
	>
<rect x="65" y="70.27" rx="3" ry="3" width="323" height="4.42" /> 
		<rect x="9" y="100.27" rx="3" ry="3" width="388.5" height="1.86" /> 
		<circle cx="34.83" cy="60.83" r="20.7" /> 
		<rect x="66" y="52.27" rx="3" ry="3" width="323" height="4.42" /> 
		<circle cx="33.83" cy="140.83" r="20.7" /> 
		<rect x="66" y="127.27" rx="3" ry="3" width="323" height="4.42" /> 
		<rect x="66" y="147.27" rx="3" ry="3" width="323" height="4.42" /> 
		<rect x="9" y="180.27" rx="3" ry="3" width="388.5" height="1.86" /> 
		<circle cx="31.83" cy="221.83" r="20.7" /> 
		<rect x="66" y="206.27" rx="3" ry="3" width="323" height="4.42" /> 
		<rect x="66" y="228.27" rx="3" ry="3" width="323" height="4.42" /> 
		<rect x="9" y="259.27" rx="3" ry="3" width="388.5" height="1.86" /> 
		<circle cx="32.83" cy="301.83" r="20.7" /> 
		<rect x="66" y="287.27" rx="3" ry="3" width="323" height="4.42" /> 
		<rect x="66" y="306.27" rx="3" ry="3" width="323" height="4.42" /> 
		<rect x="9" y="340.27" rx="3" ry="3" width="388.5" height="1.86" /> 
		<circle cx="34.83" cy="378.83" r="20.7" /> 
		<rect x="66" y="369.27" rx="3" ry="3" width="323" height="4.42" /> 
		<rect x="66" y="387.27" rx="3" ry="3" width="323" height="4.42" /> 
		<rect x="9" y="419.27" rx="3" ry="3" width="388.5" height="1.86" />
	</ContentLoader>
)
