import { createMuiTheme } from '@material-ui/core/styles';

const Tablestyle = () => createMuiTheme({
  overrides: {
    MuiToolbar: {
      gutters: {
        paddingRight: '0px !important'
      }
    },
    MuiPaper: {
      rounded: {
        padding: '15px'
      },
      elevation4: {
        boxShadow: 'none'
      },
      elevation1: {
        marginBottom: '15px',
        boxShadow: '1px -1px 2px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05)'
      }
    },
    MuiTableCell: {
      root: {
        padding: '0px 0px 0px 4px !important',
        borderBottom: '0px'
      },
      body: {
        color: 'rgba(47, 47, 47, 0.87)',
        fontSize: '0.85rem',
        fontWeight: '100'
      },
      head: {
        color: 'rgba(0, 0, 0, 0.7)',
        fontSize: '0.85rem',
        fontWeight: '700',
      }
    },
    MuiTableRow: {
      head: {
        height: '35px',
        fontWeight: '700'
      },
      root: {
        height: '30px',
        '&:nth-of-type(even)': {
          backgroundColor: '#dce5e852'
        }
      }
    },
    MUIDataTableHeadCell: {
      fixedHeader: {
        backgroundColor: '#cfd8dc'
      }
    },
    typography: {
      useNextVariants: true,
    },
    MuiIconButton: {
      root: {
        padding: '5 10 5 10px'
      }
    },
    MuiSvgIcon: {
      root: {
        fontSize: '20px'
      }
    },
    ButtonTable: {
      button: {
        margin: '3px'
      }
    }
  },
  typography: { useNextVariants: true }
})

export {
    Tablestyle
}