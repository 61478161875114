import { getIdtoArray } from 'lib/helper'

const authHeader = () => {
  // return authorization header with jwt token
  let user = getUser()

  if (user && user.token) {
    return 'Bearer ' + user.token;
  } else {
    return {};
  }
}

const checkAction = (view) => {

  if (typeof view === 'undefined') return true

  let user = getUser()
  let auth = []

  if (user !== null) {
    auth = getIdtoArray(user.permission, 'name')
  }

  return (auth.includes(view)) ? true : false
}

const checkPermission = name => {
  let user = getUser()
  let auth = []

  if (user !== null) {
    auth = getOnlyRightsName(user.permission, 'name')
  }

  return (auth.includes(name)) ? true : false
}

const config = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, Content-Type,  X-Requested-With, Accept'
  },
}

const destroyToken = () => {
  localStorage.removeItem('user');
}

const downloadWithAuth = () => {
  return {
    'Content-Type': 'application/vnd.ms-excel',
    'Authorization': authHeader(),
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, Content-Type,  X-Requested-With, Accept',
    'responseType': 'blob'
  }
}

const getOnlyRightsName = (data) => {
  let arr = []
  if (typeof data !== 'object') return true
  Object.keys(data).reduce((obj, key) => {
    let name = data[key]['name'].split(' ')
    return (name[0] === 'dashboard') ? arr.push(data[key]['name']) : arr.push(name[0])
  }, {})
  return arr
}

const getUser = () => {
  return JSON.parse(localStorage.getItem('user'));
}

const getUserFullname = () => {
  let name = getUser() !== null ? getUser().user_fullname : ''
  return name
}

const headerUpload = () => {
  return {
    'Authorization': authHeader(),
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, Content-Type,  X-Requested-With, Accept'
  }
}

const handleUnAutherize = (error) => {
  const { status } = typeof error.response !== 'undefined' ? error.response : 0
  if (status >= 400) {
    if (status === 401) {
      alert('Token expired')
    } else if (status === 500) {
      alert('Internal Server Error : ' + error)
    } else {
      alert(error)
    }
    destroyToken();
    window.location.href = '/login'
  } else {
    return true
  }
}

const headerWithAuth = () => {
  let header = config
  header.headers['Authorization'] = authHeader()
  return header
}

export {
  authHeader,
  checkAction,
  checkPermission,
  config,
  destroyToken,
  downloadWithAuth,
  getUser,
  getUserFullname,
  headerUpload,
  handleUnAutherize,
  headerWithAuth
}
