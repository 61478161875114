import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    root: {
        padding: '10px',
    },
    small: {
        fontSize: '11px'
    },
    linerContainer: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important'
    },
    liner: {
        backgroundColor:'#7cadff'
    }
});

function PaperSheet(props) {
    const { classes, title, total, negative, positive, percent, color } = props;

    return (
        <Paper className={`card-dashboard ${classes.root}`} 
        style={{ backgroundColor: color }}
         elevation={1}>
            <Grid container spacing={16} justify="space-between" alignItems="flex-end">
                <Grid item xs={6}>
                    <Typography component="span" className={classes.small}>{title}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h5" component="h5" className={`number`}>{total}</Typography>
                </Grid>
                <Grid item xs={12} className={classes.linerContainer}>
                    <LinearProgress 
                        className={classes.liner} 
                        color="secondary" 
                        variant="determinate" 
                        value={percent} />
                </Grid>
                <Grid item xs={6}>
                    <Typography component="span" className={classes.small}>{negative}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography component="span" className={`${classes.small} number`}>{positive}</Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}

PaperSheet.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PaperSheet);