import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';

const styles = {
  root: {
    marginBottom: '1px',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  typography: { useNextVariants: true },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
};

function SearchWidget(props) {
  const { classes, onChange, onClick, onSearch, hideAdvsearch } = props;

  const advSearch = () => {
    if(typeof onSearch === 'function') {
      onSearch()
    }
  }

  return (
    <Paper className={classes.root} elevation={1}>
      { hideAdvsearch === false && 
        <IconButton className={classes.iconButton} 
            aria-label="Menu" onClick={() => onClick(false)}>
          <MenuIcon />
        </IconButton>
      }
      <InputBase className={classes.input} placeholder="ค้นหา..." onChange={onChange}/>
      <IconButton className={classes.iconButton} aria-label="Search" onClick={advSearch}>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

SearchWidget.propTypes = {
  classes: PropTypes.object.isRequired
};

SearchWidget.defaultProps = {
  hideAdvsearch: false
}

export default withStyles(styles)(SearchWidget);