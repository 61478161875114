import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import MobileGroupTable from './MobileGroupTable'
import MobileGroupList from './MobileGroupList'
import EditMobileGroup from './EditMobileGroup'
import CreateMobileGroup from './CreateMobileGroup'

import { Breadcrumb } from 'lib/widgets'
import { Layoutstyles } from './AddonStyles'

const MobileGroupLayout = ({ classes }) => (
  <div className={classes.root}>
    <Grid container spacing={24}>
      <Grid item xs>
        <Breadcrumb
          menu="ข้อมูลหลัก"
          submenu="กลุ่มสิทธิ์มือถือ"
          menuLink="/master-data/mobile-groups"
        />
      </Grid>
    </Grid>
    <Grid container spacing={24}>
      <Switch>
        <Route
          exact
          path="/master-data/mobile-groups"
          component={MobileGroupTable}
        />
        <Route path="/master-data/mobile-groups" component={MobileGroupList} />
      </Switch>
      <Switch>
        <Route
          path="/master-data/mobile-groups/new"
          component={CreateMobileGroup}
        />
        <Route
          path="/master-data/mobile-groups/:id"
          component={EditMobileGroup}
        />
      </Switch>
    </Grid>
  </div>
)
export default withStyles(Layoutstyles)(MobileGroupLayout)
