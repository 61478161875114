import React from 'react'
import Grid from '@material-ui/core/Grid'

import { SelectWidget } from 'lib/widgets'

const style = {
  marginBottom: '15px'
}
export default ({ values, classes, handleChange, handleSelect, handleDateChange }) => (
  <div style={{ padding: '10px', flexGrow: 1 }}>
    <Grid item xs={12} style={style}>
      <SelectWidget
        url="api/master-data/warehouses"
        value={values.warehouse_id}
        name="warehouse_id"
        title="คลังสินค้า"
        fullWidth
        onChange={handleSelect('warehouse_id')}
      />
    </Grid>
    <Grid item xs={12} style={style}>
      <SelectWidget
        url="api/master-data/bin"
        value={values.bin_id}
        name="bin_id"
        title="Storage Bin"
        fullWidth
        onChange={handleSelect('bin_id')}
      />
    </Grid>
  </div>
)
