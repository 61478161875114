import { createMuiTheme } from '@material-ui/core/styles';

const drawerWidth = 64
const Formstyles = theme => ({
  root: {
    width: '100%',
    maxWidth: 600,
    height: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: theme.palette.background.paper,
    margin: 'auto'
  },
  inline: {
    display: 'inline'
  },
  active: {
    backgroundColor: '#deefff'
  },
  paper: {
    padding: theme.spacing.unit * 5,
    color: theme.palette.text.secondary
  }
})

const Liststyles = theme => ({
    root: {
      width: '100%',
      position: 'relative',
      overflow: 'auto',
      maxHeight: 400,
      backgroundColor: theme.palette.background.paper
    },
    inline: {
      display: 'inline'
    },
    active: {
      backgroundColor: '#deefff'
    },
    paper: {
      padding: "0px",
      textAlign: 'center',
      color: theme.palette.text.secondary
    },
    fab: {
      position: 'absolute',
      top: theme.spacing.unit * 20,
      right: theme.spacing.unit * 10,
      zIndex: 1000,
      [theme.breakpoints.down('sm')]: {
        top: theme.spacing.unit * 15,
        right: theme.spacing.unit * 2,
      }
    },
})


const Layoutstyles = theme => ({
    root: {
      flexGrow: 1
    }
})

const Tablestyle = () => createMuiTheme({
  overrides: {
    TableContainer: {
      root: {
        height: '60vh !important'
      }
    },
    EditColumn: {
      headingCell: {
        width: '120px'
      },
      cell: {
        width: '120px'
      }
    },
    MuiTableCell: {
      root: {
        padding: '0px 5px 0px 5px !important',
        borderRight: '0.5px solid rgba(224, 224, 224, 1)',
        borderLeft: '0.5px solid rgba(224, 224, 224, 1)',
      },
      body: {
        color: 'rgba(47, 47, 47, 0.87)',
        fontSize: '0.85rem',
        fontWeight: '100'
      },
      head: {
        color: 'rgba(0, 0, 0, 0.7)',
        fontSize: '0.85rem',
        fontWeight: '100',
      }
    },
    MuiTableRow: {
      head: {
        height: '43px',
        backgroundColor: '#89bdff'
      },
      root: {
        height: '43px',
        '&:nth-of-type(even)': {
          backgroundColor: '#dce5e852'
        }
      }
    },
    MuiButton: {
      textPrimary: {
        color: '#ffffff'
      },
      text: {
        background: '#fb8c00'
      }
    },
    MuiInputBase: {
      root: {
        paddingBottom: '0px',
        paddingTop: '9px'
      }
    },
    typography: {
      useNextVariants: true,
    },
    MuiIconButton: {
      root: {
        padding: '5px'
      }
    },
    MuiSvgIcon: {
      root: {
        width: '0.75em',
        height: '0.75em'
      }
    }
  }
})

export {
    Formstyles,
    Liststyles,
    Layoutstyles,
    Tablestyle
}