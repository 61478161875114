import { createTypes, createAction } from 'lib/action'
 
const LOAD_PCS = createTypes('physicalcounts', 'list')
const LOAD_PC = createTypes('physicalcount', 'show')

const CREATE_PC = createTypes('physicalcount', 'create')
const EDIT_PC = createTypes('physicalcount', 'edit')
const DELETE_PC = createTypes('physicalcount', 'delete')

const CHANGE_STATUS_PC = createTypes('physicalcount', 'change_status')

const FILTER_PC = 'backup'
const SET_PC = 'api/data'
const GET_PC = 'GET_PC'
const FETCH_PCS_FROM_PC = "FETCH_PCS_FROM_PC"

const loadPhysicalCounts = {
  request: term => createAction(LOAD_PCS.REQUEST, { term }),
  success: physicalcounts => createAction(LOAD_PCS.SUCCESS, { physicalcounts }),
  failure: () => createAction(LOAD_PCS.FAILURE)
}

const loadPhysicalCount = {
  request: id => createAction(LOAD_PC.REQUEST, { id }),
  success: physicalcount => createAction(LOAD_PC.SUCCESS, { physicalcount }),
  failure: () => createAction(LOAD_PC.FAILURE)
}

const createPhysicalCount = {
  request: physicalcount => createAction(CREATE_PC.REQUEST, { physicalcount }),
  success: physicalcount => createAction(CREATE_PC.SUCCESS, { physicalcount }),
  failure: error => createAction(CREATE_PC.FAILURE, { error })
}

const editPhysicalCount = {
  request: (id, physicalcount) => createAction(EDIT_PC.REQUEST, { id, physicalcount }),
  success: physicalcount => createAction(EDIT_PC.SUCCESS, { physicalcount }),
  failure: (error) => createAction(EDIT_PC.FAILURE, { error })
}

const deletePhysicalCount = {
  request: id => createAction(DELETE_PC.REQUEST, { id } ),
  success: id => createAction(DELETE_PC.SUCCESS, { id } ),
  failure: () => createAction(DELETE_PC.FAILURE)
}


const changeStatusPC = {
  request: (id, status) => createAction(CHANGE_STATUS_PC.REQUEST, { id, status }),
  success: physicalcount => createAction(CHANGE_STATUS_PC.SUCCESS, { physicalcount }),
  failure: error => createAction(CHANGE_STATUS_PC.FAILURE, { error })
}


const filterData = (physicalcount, search) => ({
  type: FILTER_PC,
  payload: {
    physicalcount,
    search 
  }
})

const loadPhysicalCountFromArray = id => ({
  type: GET_PC,
  payload: id
})

const fecthPhysicalCountFromArray = () => ({
  type : FETCH_PCS_FROM_PC,
  payload: {}
})


export {
  LOAD_PCS,
  LOAD_PC,
  CREATE_PC,
  EDIT_PC,
  DELETE_PC,
  SET_PC,
  FILTER_PC,
  GET_PC,
  FETCH_PCS_FROM_PC,
  CHANGE_STATUS_PC,
  loadPhysicalCounts,
  loadPhysicalCount,
  loadPhysicalCountFromArray,
  createPhysicalCount,
  editPhysicalCount,
  deletePhysicalCount,
  filterData,
  fecthPhysicalCountFromArray,
  changeStatusPC
}
