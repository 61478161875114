import { createMuiTheme } from '@material-ui/core/styles';

const drawerWidth = 64
const Formstyles = theme => ({
  root: {
    width: '100%',
    maxWidth: 600,
    height: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: theme.palette.background.paper,
    margin: 'auto'
  },
  inline: {
    display: 'inline'
  },
  active: {
    backgroundColor: '#deefff'
  },
  paper: {
    padding: theme.spacing.unit * 5,
    color: theme.palette.text.secondary
  },
  sectionend: {
    marginBottom: `${theme.spacing.unit * 10}px`,
  },
  typography: {
    useNextVariants: true,
  },
})

const Liststyles = theme => ({
    root: {
      width: '100%',
      position: 'relative',
      overflow: 'auto',
      maxHeight: 400,
      backgroundColor: theme.palette.background.paper
    },
    inline: {
      display: 'inline'
    },
    active: {
      backgroundColor: '#deefff'
    },
    paper: {
      padding: "0px",
      textAlign: 'center',
      color: theme.palette.text.secondary
    },
    fab: {
      position: 'absolute',
      top: theme.spacing.unit * 20,
      right: theme.spacing.unit * 10,
      zIndex: 1000,
      [theme.breakpoints.down('sm')]: {
        top: theme.spacing.unit * 15,
        right: theme.spacing.unit * 2,
      }
    },
    typography: {
      useNextVariants: true,
    },
})


const Layoutstyles = theme => ({
    root: {
      flexGrow: 1
    },
    typography: {
      useNextVariants: true,
    },
})

const Tablestyle = () => createMuiTheme({
  overrides: {
    MuiPaper: {
      rounded: {
        padding: '15px'
      }
    },
    MuiTableCell: {
      root: {
        padding: '0px 0px 0px 4px !important',
      },
      body: {
        color: 'rgba(47, 47, 47, 0.87)',
        fontSize: '0.85rem',
        fontWeight: '100'
      },
      head: {
        color: 'rgba(0, 0, 0, 0.7)',
        fontSize: '0.85rem',
        fontWeight: '100',
      }
    },
    MuiTableRow: {
      head: {
        height: '30px'
      }
    },
    typography: {
      useNextVariants: true,
    },
    MuiIconButton: {
      root: {
        padding: '5px'
      }
    }
  }
})

const TabStyle = () => createMuiTheme({
  "palette": {
    "common": { "black": "#000", "white": "#fff" },
    "background": { "paper": "#fff", "default": "rgba(250, 250, 250, 1)" },
    "primary": {
      "light": "#89bdff",
      "main": "#4e8df6",
      "dark": "#0060c3",
      "contrastText": "#fff"
    },
    "secondary": {
      "light": "#ffad42",
      "main": "#f57c00",
      "dark": "#bb4d00",
      "contrastText": "rgba(255, 248, 225, 1)"
    },
    "error": {
      "light": "#e57373",
      "main": "#f44336",
      "dark": "#d32f2f",
      "contrastText": "#fff"
    },
    "text": {
      "primary": "rgba(0, 0, 0, 0.87)",
      "secondary": "rgba(0, 0, 0, 0.54)",
      "disabled": "rgba(0, 0, 0, 0.38)",
      "hint": "rgba(0, 0, 0, 0.38)"
    }
  },
  typography: {
    useNextVariants: true,
  },
})

const EditTableStyle = () => createMuiTheme({
  overrides: {
    TableContainer: {
      root: {
        height: '350px !important'
      }
    },
    EditColumn: {
      headingCell: {
        width: '120px'
      },
      cell: {
        width: '120px'
      }
    },
    MuiTableCell: {
      root: {
        padding: '0px 5px 0px 5px !important',
        borderRight: '0.5px solid rgba(224, 224, 224, 1)',
        borderLeft: '0.5px solid rgba(224, 224, 224, 1)',
      },
      body: {
        color: 'rgba(47, 47, 47, 0.87)',
        fontSize: '0.85rem',
        fontWeight: '100'
      },
      head: {
        color: 'rgba(0, 0, 0, 0.7)',
        fontSize: '0.85rem',
        fontWeight: '100',
      }
    },
    MuiTableRow: {
      head: {
        height: '43px',
        backgroundColor: '#89bdff'
      },
      root: {
        height: '43px',
        '&:nth-of-type(even)': {
          backgroundColor: '#dce5e852'
        }
      }
    },
    MuiButton: {
      textPrimary: {
        color: '#ffffff'
      },
      text: {
        background: '#fb8c00'
      }
    },
    MuiInputBase: {
      root: {
        paddingBottom: '0px',
        paddingTop: '9px'
      }
    },
    MuiIconButton: {
      root: {
        padding: '5px'
      }
    },
    MuiSvgIcon: {
      root: {
        width: '0.75em',
        height: '0.75em'
      }
    }
  },
  typography: {
    useNextVariants: true,
  },
})
export {
    Formstyles,
    Liststyles,
    Layoutstyles,
    Tablestyle,
    EditTableStyle,
    TabStyle
}