import React from 'react'
import { Switch, Route } from 'react-router-dom'
import GoodsReceiveLayout from './goodsreceive/components/GoodsReceiveLayout'
import GoodsIssueLayout from './goodsissue/components/GoodsIssueLayout'
import GoodsTransferLayout from './goodstransfer/components/GoodsTransferLayout'
import StockBalanceLayout from './stockbalance/components/StockBalanceLayout'
import PhysicalLayout from './physicalcount/components/PhysicalLayout'

import NoRights from '../ui/components/NoRights'

import { checkPermission } from 'lib/authFunc'

const getCurrentPath = () => {
  const url = window.location.href
  let rights = ""

  if(url.match(/goods-receives/g))  rights = 'goodsreceive'
  if(url.match(/goods-issues/g))  rights = 'goodsissue'
  if(url.match(/goods-transfers/g))  rights = 'goodstransfer'
  if(url.match(/stock-balances/g))  rights = 'stockbalance'

  return rights
}



export default () => {
  let rights = getCurrentPath()

  if (!checkPermission(rights) && rights !== '') {
    return <NoRights />
  }

  return <Switch>
    <Route path="/warehouse/goods-receives" component={GoodsReceiveLayout} />
    <Route path="/warehouse/goods-issues" component={GoodsIssueLayout} />
    <Route path="/warehouse/goods-transfers" component={GoodsTransferLayout} />
    <Route path="/warehouse/stock-balances" component={StockBalanceLayout} />
    <Route path="/warehouse/physical-count" component={PhysicalLayout} />
  </Switch>
}
