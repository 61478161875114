import { createTypes, createAction } from 'lib/action'
 
const LOAD_BRANCHES = createTypes('branches', 'list')
const LOAD_BRANCH = createTypes('branches', 'show')
const CREATE_BRANCH = createTypes('branches', 'create')
const EDIT_BRANCH = createTypes('branches', 'edit')
const DELETE_BRANCH = createTypes('branches', 'delete')
const FILTER_BRANCH = 'backup'
const SET_BRANCH = 'api/branches'
const GET_BRANCH = 'GET_BRANCH'
const FETCH_BRANCHES_FROM_STORE = "FETCH_BRANCHES_FROM_STORE"

const loadBranches = {
  request: term => createAction(LOAD_BRANCHES.REQUEST, { term }),
  success: branches => createAction(LOAD_BRANCHES.SUCCESS, { branches }),
  failure: () => createAction(LOAD_BRANCHES.FAILURE)
}

const loadBranch = {
  request: id => createAction(LOAD_BRANCH.REQUEST, { id }),
  success: branch => createAction(LOAD_BRANCH.SUCCESS, { branch }),
  failure: () => createAction(LOAD_BRANCH.FAILURE)
}

const createBranch = {
  request: branch => createAction(CREATE_BRANCH.REQUEST, { branch }),
  success: branch => createAction(CREATE_BRANCH.SUCCESS, { branch }),
  failure: error => createAction(CREATE_BRANCH.FAILURE, { error })
}

const editBranch = {
  request: (id, branch) => createAction(EDIT_BRANCH.REQUEST, { id, branch }),
  success: branch => createAction(EDIT_BRANCH.SUCCESS, { branch }),
  failure: () => createAction(EDIT_BRANCH.FAILURE)
}

const deleteBranch = {
  request: (id) => createAction(DELETE_BRANCH.REQUEST, { id } ),
  success: id => createAction(DELETE_BRANCH.SUCCESS, { id } ),
  failure: () => createAction(DELETE_BRANCH.FAILURE)
}

const filterData = (branches, search) => ({
  type: FILTER_BRANCH,
  payload: {
    branches,
    search 
  }
})

const loadBranchFromArray = id => ({
  type: GET_BRANCH,
  payload: id
})

const fecthBranchFromArray = () => ({
  type : FETCH_BRANCHES_FROM_STORE,
  payload: {}
})


export {
  LOAD_BRANCHES,
  LOAD_BRANCH,
  CREATE_BRANCH,
  EDIT_BRANCH,
  DELETE_BRANCH,
  SET_BRANCH,
  FILTER_BRANCH,
  GET_BRANCH,
  FETCH_BRANCHES_FROM_STORE,
  loadBranches,
  loadBranch,
  loadBranchFromArray,
  createBranch,
  editBranch,
  deleteBranch,
  filterData,
  fecthBranchFromArray
}
