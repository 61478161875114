import {   
  LOAD_WEBGROUPS,
  LOAD_WEBGROUP,
  CREATE_WEBGROUP,
  EDIT_WEBGROUP,
  DELETE_WEBGROUP,
  FILTER_WEBGROUP,
  GET_WEBGROUP,
  FETCH_WEBGROUPS_FROM_STORE
 } from './actions'

 import { dataFilter, replaceIdxValue } from 'lib/helper'

const initialState = {
  isLoading: false,
  successSave: null,
  items: [],
  backupItems:[],
  webgroup: [],
  deleteSuccess: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_WEBGROUP:
      return { ...state, 
        items: dataFilter(action.payload.webgroups, action.payload.search),
        backupItems: state.backupItems 
      }
    case GET_WEBGROUP:
      const id = state.backupItems.findIndex(webgroup => webgroup.id === action.payload)
      return {
        ...state,
        successSave: null,
        webgroup: [state.backupItems[id]]
      }
    case FETCH_WEBGROUPS_FROM_STORE:
      return { ...state,
        successSave: null,
        items: state.backupItems,
        isLoading: false }
    case LOAD_WEBGROUPS.REQUEST:
    case LOAD_WEBGROUP.REQUEST:
    case DELETE_WEBGROUP.REQUEST:
    return { ...state, 
      items:state.backupItems,
      backupItems: state.backupItems,
      isLoading: true,
      successSave: null,
      deleteSuccess: null
    }
    case CREATE_WEBGROUP.REQUEST:
    case EDIT_WEBGROUP.REQUEST:
      return { ...state, items: [], isLoading: true }
    case LOAD_WEBGROUPS.SUCCESS:
      return { ...state, 
        items:action.payload.webgroups.data,
        backupItems: action.payload.webgroups.data,
        meta:action.payload.webgroups.meta,
        isLoading: false,
      }
    case LOAD_WEBGROUP.SUCCESS:
      return { ...state, webgroup: [action.payload.webgroup], isLoading: false, successSave: null}
    case CREATE_WEBGROUP.SUCCESS:
      return {
        ...state,
        isLoading: false,
        successSave: true
      }
    case EDIT_WEBGROUP.SUCCESS:
      const idx = state.backupItems.findIndex(webgroup => webgroup.id === action.payload.webgroup.id)
      return { ...state,
        items: replaceIdxValue(state.backupItems, idx, action.payload.webgroup),
        backupItems: replaceIdxValue(state.backupItems, idx, action.payload.webgroup),
        webgroup: [action.payload.webgroup],
        isLoading: false,
        successSave: true 
      }
    case DELETE_WEBGROUP.SUCCESS:
      const index = state.backupItems.findIndex(webgroup => webgroup.id === action.payload.id)
      return { ...state.backupItems.splice(index, 1),
        items:state.backupItems,
        backupItems:state.backupItems,
        isLoading: false,
        successSave:null,
        deleteSuccess: true
      }
    case LOAD_WEBGROUPS.FAILURE:
    case LOAD_WEBGROUP.FAILURE:
    case CREATE_WEBGROUP.FAILURE:
      return { ...state, 
        items:state.backupItems,
        backupItems:state.backupItems,
        isLoading: false,
        successSave: false 
      }
    case EDIT_WEBGROUP.FAILURE:
    case DELETE_WEBGROUP.FAILURE:
      return { ...state, items: [], isLoading: false, successSave: false }
    default:
      return state
  }
}

