import React, { Component, Fragment } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'

import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

import { DatePickerWidget, SelectWidget, Breadcrumb, MultipleSelect } from 'lib/widgets'
import { styles, loadOptions, defaultState } from './RelateFunc'
import { genOptionList, genRelateOptionList, formatDate } from 'lib/helper'

import axios from 'axios'
import { headerWithAuth } from 'lib/authFunc'

class SoApp extends Component {
  _isMounted = false
  state = {
    companies: [],
    branches: [],
    users: [],
    bkcompany: [],
    company_id: '',
    isLoading: false
  }

  handleExport = async values => {
    this.setState({ isLoading: true })
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + '/api/report/sale-orders',
        values,
        headerWithAuth()
      )
      const link = document.createElement('a')
      link.href = response.data
      let fileName = `so.xlsx`
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      this.setState({ isLoading: false })
    } catch (error) {
      console.error(error)
    }
  }

  handleChange = (name, data) => {
    this.setState({ [name]: data }, () => this.setRelateOption(name))
  }

  setRelateOption = name => {
    let items = this.state.bkcompany
    let idx = items.findIndex(
      data => parseInt(data.id) === parseInt(this.state[name])
    )
    if (idx < 0) return

    this.setState({
      branches: genRelateOptionList(
        [items[idx]],
        'id',
        'name',
        'branch',
        'company_id'
      )
    })
  }

  componentDidMount() {
    this._isMounted = true

    loadOptions().then(res => {
      if (this._isMounted) {
        this.setState({
          companies: genOptionList(res[0].companies, 'id', 'name'),
          users: genOptionList(res[0].users, 'id', 'name'),
          bkcompany: res[0].companies,
          loadopt: true
        })
      }
    })

  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { classes } = this.props
    const { companies, branches, users, isLoading, loadopt } = this.state

    return (
      <Grid container spacing={24}>
        <Grid item xs={12} style={{ marginTop: '20px', marginLeft: '20px' }}>
          <Breadcrumb
            menu="รายงาน"
            submenu="รายงาน Sales Order"
            menuLink="/report/sale-orders"
          />
        </Grid>
        <Grid item xs={12}>
          <Formik
            initialValues={defaultState}
            validationSchema={yup.object().shape({
              company_id: yup.string().required('กรุณากรอก'),
              branch_id: yup.string().required('กรุณากรอก'),
              user_name: yup.array()
                .nullable()
                .required('กรุณากรอก'),
              date_start: yup.string().required('กรุณากรอก'),
              date_end: yup.string().required('กรุณากรอก')
            })}
            onSubmit={values => this.handleExport(values)}
            render={({
              values,
              errors,
              touched,
              resetForm,
              handleSubmit,
              setFieldValue
            }) => (
              <Fragment>
                {isLoading && <div className="lmask" id="lmask" />}
                <form className={classes.layout}>
                  <Paper className={classes.paper}>
                    <Typography variant="h6" gutterBottom>
                      รายงาน Sales Order
                    </Typography>
                    <Grid container spacing={24}>
                      <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl}>
                          <SelectWidget
                            withOption={true}
                            options={companies}
                            value={values.company_id || ''}
                            name="company_id"
                            title="บริษัท*"
                            disabled={this.state.disableForm}
                            onChange={(name, value) => {
                              setFieldValue(name, value)
                              this.handleChange('company_id', value)
                            }}
                            isLoading={loadopt}
                          />
                          {touched.company_id && errors.company_id && (
                            <span className="form-text--error">
                              {errors.company_id}
                            </span>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl}>
                          <SelectWidget
                            withOption={true}
                            options={branches}
                            value={values.branch_id || ''}
                            name="branch_id"
                            title="สาขา*"
                            onChange={setFieldValue}
                          />
                          {touched.branch_id && errors.branch_id && (
                            <span className="form-text--error">
                              {errors.branch_id}
                            </span>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl className={classes.formControl} style={{ maxWidth: '80%' }}>
                          <InputLabel>ชื่อพนักงาน *</InputLabel>
                          <MultipleSelect
                            name="user_name"
                            value={values.user_name || ''}
                            options={users}
                            onChange={(name, value) => {
                              setFieldValue(name, value)
                              this.handleChange('user_name', value)
                            }} />
                          {touched.user_name && errors.user_name && (
                            <span className="form-text--error">
                              {errors.user_name}
                            </span>
                          )}
                        </FormControl>
                        {/* <FormControl className={classes.formControl}>
                            <SelectWidget
                              withOption={true}
                              options={users}
                              value={values.user_name || ''}
                              name="user_name"
                              title="ชื่อพนักงาน"
                              onChange={setFieldValue}
                            />
                          </FormControl> */}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl}>
                          <DatePickerWidget
                            name="date_start"
                            title="วันเริ่มต้น *"
                            value={values.date_start || ''}
                            format="dd-MM-yyyy"
                            onValueChange={(data, name) => {
                              setFieldValue(name, formatDate(data, 'd-m-Y'))
                            }}
                          />
                          {touched.date_start && errors.date_start && (
                            <span className="form-text--error">
                              {errors.date_start}
                            </span>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl}>
                          <DatePickerWidget
                            name="date_end"
                            title="วันสิ้นสุด *"
                            value={values.date_end || ''}
                            format="dd-MM-yyyy"
                            onValueChange={(data, name) => {
                              setFieldValue(name, formatDate(data, 'd-m-Y'))
                            }}
                          />
                          {touched.date_end && errors.date_end && (
                            <span className="form-text--error">
                              {errors.date_end}
                            </span>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <div className={classes.buttons}>
                          <Button
                            variant="contained"
                            color="default"
                            onClick={() => resetForm(defaultState)}
                            className={classes.button}
                          >
                            เคลียร์
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={handleSubmit}
                            className={classes.button}
                          >
                            ตกลง
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                </form>
              </Fragment>
            )}
          />
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(SoApp)
