import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

const style = {
  marginBottom: '15px'
}

export default  ({ values, handleChange }) =>  (
  <div style={{ padding: '10px', flexGrow: 1 }}>
      <Grid item xs={12} style={style}>
        <TextField
          label="ชื่อ"
          name="name"
          fullWidth
          value={values.name}
          onChange={handleChange('name')}
        />
      </Grid>
      <Grid item xs={12} style={style}>
        <TextField
          label="ชื่อย่อ"
          name="search_key"
          fullWidth
          value={values.search_key}
          onChange={handleChange('search_key')}
        />
      </Grid>
    </div>
  )


