import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import * as actions from '../actions'
import CategoryForm from './CategoryForm'

import { defaultState } from '../defaultState'
import { hasMessage } from 'lib/fxUI'

class CreateCategory extends Component {
  createCategory = async data => {
    this.props.createCategory(data)
  }

  render() {
    const { successSave, isLoading } = this.props
    return (
      <Fragment>
        {successSave !== null && hasMessage(successSave, '')}
        <CategoryForm
          onSubmit={this.createCategory}
          formAction="สร้างใหม่"
          successSave={successSave}
          isLoading={isLoading}
          data={defaultState}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ categories }) => ({
  successSave: categories.successSave,
  isLoading: categories.isLoading
})
const mapDispatchToProps = {
  createCategory: actions.createCategory.request
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCategory)
