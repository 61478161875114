import React from 'react'
import { Switch, Route } from 'react-router-dom'

import BranchTable from './BranchTable'
import BranchList from './BranchList'
import EditBranch from './EditBranch'
import CreateBranch from './CreateBranch'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Breadcrumb } from 'lib/widgets'
import { Layoutstyles } from './AddonStyles'

const BranchLayout = ({ classes }) => (
  <div className={classes.root}>
    <Grid container spacing={24}>
      <Grid item xs>
        <Breadcrumb
          menu="ข้อมูลหลัก"
          submenu="สาขา"
          menuLink="/master-data/branches"
        />
      </Grid>
    </Grid>
    <Grid container spacing={24}>
      <Switch>
        <Route exact path="/master-data/branches" component={BranchTable} />
        <Route path="/master-data/branches" component={BranchList} />
      </Switch>
      <Switch>
        <Route path="/master-data/branches/new" component={CreateBranch} />
        <Route path="/master-data/branches/:id" component={EditBranch} />
      </Switch>
    </Grid>
  </div>
)

export default withStyles(Layoutstyles)(BranchLayout)
