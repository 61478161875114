
import React, { useState, useEffect, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { Pie } from 'react-chartjs-2';
import 'chartjs-plugin-labels';
import { getCurrentYear, monthList, getUrlParam, getCurrentMonthIdx } from 'lib/helper'

import axios from 'axios'
import { config, handleUnAutherize } from 'lib/authFunc'

import { styles, formatGraphTooltip } from './RelateFunction'

import DetailTable from './DetailTable'
import { COMPANY_ID, MAIN_BRANCH } from 'lib/defaultSystemData'

const columns = [
	{ name: 'name', label: 'ชื่อ - นามสกุล', options: { sort: false } },
	{ name: 'net_amt', label: 'ยอดขาย', options: { sort: false } },
]


function TopProductDetail(props) {
	const { classes } = props

	const [data, setData] = useState([])
	const [graph, setGraph] = useState({})
	const [loadIndecator, setLoadIndecator] = useState(true)
	const [company_id, setCompany] = useState(getUrlParam("company_id", COMPANY_ID))
	const [branch_id, setBranch] = useState(getUrlParam("branch_id", MAIN_BRANCH))
	const [format_id, setFormat] = useState(getUrlParam("format_id", 2))
	const [month_id, setMonth] = useState(getUrlParam("month_id", getCurrentMonthIdx()))

	const [loading, setLoad] = useState(false)
	const [total, setTotal] = useState(0)
	const [rowPerPage, setrowPerPage] = useState(10)
	const [page, setPage] = useState(1)
	const [year_id, setYear] = useState(new Date().getFullYear())

	const fetchData = async (data) => {

		setLoad(true)
		setLoadIndecator(true)
		setCompany(data.company_id)
		setBranch(data.branch_id)
		setMonth(data.month_id)
		setYear(data.year_id)
		setFormat(data.format_id)

		try {
			const result = await axios.post(process.env.REACT_APP_API_URL + '/api/dashboard/top-sale-details', data, config)

			if (typeof result.data !== 'undefined') {
				setData(result.data.data.data)
				setTotal(result.data.data.total)
				setrowPerPage(result.data.data.per_page)
				setPage(result.data.data.from)
				setGraphData(result.data.graph)
				setLoadIndecator(false)
			}
		} catch (error) {
			handleUnAutherize(error)
		}
	}

	const setGraphData = (data) => {

		const data2 = {
			labels: data.label,
			datasets: [{
				data: data.graph,
				backgroundColor: [
					'#FF6384',
					'#36A2EB',
					'#FFCE56',
					'#89da68'
				],
				hoverBackgroundColor: [
					'#FF6384',
					'#36A2EB',
					'#FFCE56',
					'#89da68'
				]
			}],
			options: {
				plugins: {
					labels: [
						{
							render: 'percentage',
							fontColor: '#fff',
						}
					]
				}
			}
		};

		setGraph(data2)

	}

	useEffect(() => {
		if (loading === false) {

			const data = {
				company_id: company_id,
				branch_id: branch_id,
				month_id: month_id,
				format_id: format_id,
				page: 1,
				per_page: 10,
				year_id: year_id
			}

			fetchData(data)
		}
	})

	const getPageTitle = () => {
		let title = ""
		switch (format_id) {
			case '1': title = 'ประจำสัปดาห์'; break;
			case '2': title = `ประจำเดือน ${monthList(parseInt(month_id))}`; break;
			case '3': title = 'ประจำปี'; break;
			default: break;
		}

		return title
	}

	return (
		<Fragment>
			<Paper className={classes.paper} elevation={0}>
				<Grid container>
					<Grid item sm={8} xs={12}>
						<Typography variant="h5" component="h3">
							Top Sales
						</Typography>
						<Typography component="p">
							{getPageTitle()}  {getCurrentYear()}
						</Typography>
					</Grid>
				</Grid>

				<Grid container justify="center" style={{ marginBottom: '20px' }}>
					<Grid item sm={8} xs={12}>
						<Pie data={graph} options={formatGraphTooltip()} />
					</Grid>
				</Grid>

				<DetailTable
					isLoading={loadIndecator}
					data={data}
					totalPage={total}
					title="รายงานยอดขายตามเซลล์"
					loadData={fetchData}
					columns={columns}
					branchId={branch_id}
					companyId={company_id}
					monthId={month_id}
					yearId={year_id}
					formatId={format_id}
					rowPerPage={rowPerPage}
					page={page}
					pageName="sales"
				/>

			</Paper>
		</Fragment>
	)
}



export default withStyles(styles)(TopProductDetail)