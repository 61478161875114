import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as actions from '../actions'
import BranchForm from './BranchForm'

import { hasMessage } from 'lib/fxUI'
import { defaultState } from '../defaultState'

class EditBranch extends Component {
  componentDidMount() {
    const id = this.getBranchId()
    this.props.loadBranch(id)
  }

  componentDidUpdate(prevProps) {
    const search = this.props.location.pathname
    if (search === prevProps.location.pathname) return

    const id = this.getBranchId()
    this.props.loadBranchFromArray(id)
  }

  getBranchId = () => this.props.match.params.id

  editBranch = async user => {
    this.props.editBranch(this.getBranchId(), user)
  }

  render() {
    const { branch, successSave } = this.props
    let data = []

    if (typeof branch !== 'undefined') {
      data = typeof branch[0] !== 'undefined' ? branch[0] : defaultState
    }

    if (data.length === 0) {
      return <div className="lmask" id="lmask" />
    }

    return (
      <Fragment>
        {successSave !== null && hasMessage(successSave, '')}
        <BranchForm
          onSubmit={this.editBranch}
          formAction="แก้ไข"
          data={data}
          successSave={successSave}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ branches }) => ({
  branch: branches.branch,
  successSave: branches.successSave
})

const mapDispatchToProps = {
  loadBranch: actions.loadBranch.request,
  editBranch: actions.editBranch.request,
  loadBranchFromArray: actions.loadBranchFromArray
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
)(EditBranch)
