import { fork, all } from 'redux-saga/effects'

import watchLogin from 'modules/home/login/sagas'

import watchUsers from 'modules/masterdata/user/sagas'
import watchWebGroups from 'modules/masterdata/web/sagas'
import watchMobileGroups from 'modules/masterdata/mobile/sagas'
import watchCompanies from 'modules/masterdata/company/sagas'
import watchBranches from 'modules/masterdata/branch/sagas'
import watchBrands from 'modules/masterdata/brand/sagas'
import watchCategories from 'modules/masterdata/category/sagas'
import watchUnits from 'modules/masterdata/unit/sagas'
import watchStores from 'modules/masterdata/store/sagas'
import watchBinDetails from 'modules/masterdata/bindetail/sagas'
import watchProducts from 'modules/masterdata/product/sagas'
import watchBatch from 'modules/masterdata/batch/sagas'

import watchGoodsReceives from 'modules/warehouse/goodsreceive/sagas'
import watchGoodsIssues from 'modules/warehouse/goodsissue/sagas'
import watchGoodsTransfer from 'modules/warehouse/goodstransfer/sagas'
import watchStockBalance from 'modules/warehouse/stockbalance/sagas'
import watchPhysicalCount from 'modules/warehouse/physicalcount/sagas'

import watchCustomer from 'modules/salesforce/store/sagas'
import watchSaleOrder from 'modules/salesforce/salesorder/sagas'
import watchAnalysis from 'modules/salesforce/customer/sagas'

import watchMap from 'modules/dashboard/dashboard/sagas'

export default function* rootSagas() {
  yield all([
    fork(watchLogin),

    fork(watchUsers),
    fork(watchWebGroups),
    fork(watchMobileGroups),
    fork(watchCompanies),
    fork(watchBranches),
    fork(watchBrands),
    fork(watchCategories),
    fork(watchUnits),
    fork(watchStores),
    fork(watchBinDetails),
    fork(watchProducts),
    fork(watchBatch),

    fork(watchGoodsReceives),
    fork(watchGoodsIssues),
    fork(watchGoodsTransfer),
    fork(watchStockBalance),
    fork(watchPhysicalCount),

    fork(watchCustomer),
    fork(watchSaleOrder),
    fork(watchAnalysis),

    fork(watchMap)
  ])
}
