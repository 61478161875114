export const defaultState = {
    id:'',
    active: true,
    disableForm : false,
    isOpenWeb : true,
    isOpenUser : true,
    formLoading : true,
    company_id: '',
    branch_id: '',
    name: '',
    search_key: '',
    code: '',
    description: '',
    curIdx: '',
    branchUrl: 'api/branches',
    warehouse: [],
    branches: [],
    companies: [],
    bkcompany: [], 
    binItems : [{
        id: '',
        name: '',
        rox_x: '',
        row_y: '',
        row_z:'',
        active:''
    }],
    advSearch : {
        name : '',
        company_id: '',
        branch_id: ''
    }
}