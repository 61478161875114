import React from 'react'
import { Switch, Route } from 'react-router-dom'
import TopProductDetail from './dashboard/components/TopProductDetail'
import TopSalseDetail from './dashboard/components/TopSalseDetail'
import TopCustomerDetail from './dashboard/components/TopCustomerDetail'
import DashboardLayout from './dashboard/components/DashboardLayout'
import WarehouseLayout from './warehouse/components/WarehouseLayout'

import NoRights from '../ui/components/NoRights'
import { checkPermission } from 'lib/authFunc'

const getCurrentPath = () => {
    const url = window.location.href
    let rights = ""
  
    if(url.match(/dashboard/g))  rights = 'dashboard overview'
    if(url.match(/dashboard\/overview-warehouse/g))  rights = 'dashboard overview-warehouse'
    return rights
  }

export default () => {
    let rights = getCurrentPath()

    if (!checkPermission(rights) && rights !== '') {
      return <NoRights />
    }

    return <Switch>
        <Route path="/dashboard/details/products" component={TopProductDetail} />
        <Route path="/dashboard/details/sales" component={TopSalseDetail} />
        <Route path="/dashboard/details/customer" component={TopCustomerDetail} />
        <Route path="/dashboard/overview-warehouse" component={WarehouseLayout} />
        <Route path="/dashboard" component={DashboardLayout} /> 
    </Switch>
}
