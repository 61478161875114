import {
  LOAD_BRANCHES,
  LOAD_BRANCH,
  CREATE_BRANCH,
  EDIT_BRANCH,
  DELETE_BRANCH,
  FILTER_BRANCH,
  GET_BRANCH,
  FETCH_BRANCHES_FROM_STORE
} from './actions'

import { dataFilter, replaceIdxValue } from 'lib/helper'

const initialState = {
  isLoading: false,
  successSave: null,
  items: [],
  backupItems: [],
  branch: [],
  deleteSuccess: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_BRANCH:
      return {
        ...state,
        items: dataFilter(action.payload.branches, action.payload.search),
        backupItems: state.backupItems
      }
    case GET_BRANCH:
      const id = state.backupItems.findIndex(branch => parseInt(branch.id) === parseInt(action.payload))
      return {
        ...state,
        successSave: null,
        branch: [state.backupItems[id]]
      }
    case FETCH_BRANCHES_FROM_STORE:
      return {
        ...state,
        successSave: null,
        items: state.backupItems,
        isLoading: false
      }
    case LOAD_BRANCHES.REQUEST:
    case LOAD_BRANCH.REQUEST:
    case DELETE_BRANCH.REQUEST:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: true,
        successSave: null,
        deleteSuccess: null
      }
    case CREATE_BRANCH.REQUEST:
    case EDIT_BRANCH.REQUEST:
      return { ...state, items: [], isLoading: true }
    case LOAD_BRANCHES.SUCCESS:
      return {
        ...state,
        items: action.payload.branches.data,
        backupItems: action.payload.branches.data,
        isLoading: false,
        meta: action.payload.branches.meta
      }
    case LOAD_BRANCH.SUCCESS:
      return { ...state, branch: [action.payload.branch], isLoading: false, successSave: null }
    case CREATE_BRANCH.SUCCESS:
      return {
        ...state,
        items: [action.payload.branch, ...state.backupItems],
        backupItems: [action.payload.branch, ...state.backupItems],
        isLoading: false,
        successSave: true
      }
    case EDIT_BRANCH.SUCCESS:
      const idx = state.backupItems.findIndex(branch => parseInt(branch.id) === parseInt(action.payload.branch.id))
      return {
        ...state,
        items: replaceIdxValue(state.backupItems, idx, action.payload.branch),
        backupItems: replaceIdxValue(state.backupItems, idx, action.payload.branch),
        branch: [action.payload.branch],
        isLoading: false,
        successSave: true
      }
    case DELETE_BRANCH.SUCCESS:
      const index = state.backupItems.findIndex(branch => parseInt(branch.id) === parseInt(action.payload.id))
      return {
        ...state.backupItems.splice(index, 1),
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: null,
        deleteSuccess: true
      }
    case LOAD_BRANCHES.FAILURE:
    case LOAD_BRANCH.FAILURE:
    case CREATE_BRANCH.FAILURE:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: false,
      }
    case EDIT_BRANCH.FAILURE:
    case DELETE_BRANCH.FAILURE:
      return { ...state, items: [], isLoading: false, successSave: false }
    default:
      return state
  }
}

