import React, { Component, Fragment } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { defaultState } from '../defaultState'
import { companyWithBranch } from 'lib/loadOption'
import DocItems from './DocItems'
import Typography from '@material-ui/core/Typography';

import {
  SubmitBtn,
  PageTitle,
  SelectWidget
} from 'lib/widgets'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider';

import { genOptionList, genRelateOptionList } from 'lib/helper'

import { MuiThemeProvider } from '@material-ui/core/styles'
import { Formstyles } from '../../AddonStyles'
import { EditTableStyle } from './AddonStyles'
import { config } from 'lib/authFunc'
import CountItem from './CountItem'

import axios from 'axios';

class DocForm extends Component {
  _isMounted = false
  state = defaultState

  toggleFx = name => {
    let isOpen = this.state[`${name}`]
    this.setState({ [`${name}`]: !isOpen })
  }

  setRelateOption = (name, value) => {
    let items = this.state.bkcompany
    this.setState({ company_id: value })
    let idx = items.findIndex(data => parseInt(data.id) === parseInt(value))
    if (idx < 0) return

    this.setState({
      branches: genRelateOptionList(
        [items[idx]],
        'id',
        'name',
        'branch',
        'company_id'
      )
    })
  }

  setOptionWarehouse = (branch_id) => {
    const { company_id } = this.state

    if (company_id !== '' && branch_id !== '') {

      let res = this.state.bkwarehouse.filter(data => {
        return parseInt(data.company_id) === parseInt(company_id)
          && parseInt(data.branch_id) === parseInt(branch_id)
      })
      this.setState({ warehouse: genOptionList(res, 'id', 'name') })
    }
  }

  componentDidMount() {
    this._isMounted = true
    if (window.location.pathname !== '/warehouse/physical-count/new') {
      this.disableForm(true)
    }

    companyWithBranch().then(res => {
      this.setState({
        companies: genOptionList(res, 'id', 'name'),
        bkcompany: res
      }, this.loadWarehouse)
    })
  }

  loadWarehouse = async () => {
    const result = await axios.get(process.env.REACT_APP_API_URL + '/api/master-data/warehouses', config)
    this.setState({ bkwarehouse: result.data.data }, () => {
      if (this.props.data.branch_id !== '') {
        this.setOptionWarehouse(this.props.data.branch_id)
      }
    })
  }

  getStorangeBin = async (value) => {
    const result = await axios.get(process.env.REACT_APP_API_URL + '/api/master-data/bin?warehouse_id=' + value, config)
    this.setState({ binItem: result.data.data })
  }

  resetSelect = (value) => {
    this.setState({ selectItem: [] }, () => {
      this.getStorangeBin(value)
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentWillReceiveProps(nextProps) {
    const { data } = nextProps

    if (data.company_id !== '') {

      this.setState({ disableForm: true },
        () => this.setRelateOption('company_id', data.company_id))
    }

    if (typeof data.relations !== 'undefined') {

      this.setState({ selectItem: data.relations.detail }, () => {
        this.getStorangeBin(data.warehouse_id)
      })
    }

  }

  disableForm = disableForm => {
    this.setState({ disableForm })
  }

  resetBinItem = () => {
    this.setState({ binItem: [], selectItem: [] })
  }


  render() {
    const { data, onSubmit, classes, isLoading } = this.props
    return (
      <Grid item xs={12}>
        {isLoading && this._isMounted && <div className="lmask" id="lmask" />}
        <Formik
          enableReinitialize
          initialValues={data}
          validationSchema={yup.object().shape({
            company_id: yup.string().required('กรุณากรอก'),
            branch_id: yup.string().required('กรุณากรอก'),
            warehouse_id: yup.string().required('กรุณากรอก'),
            document_type_id: yup.string().required('กรุณากรอก')
          })}
          onSubmit={values => onSubmit(values)}
          render={({
            values,
            errors,
            touched,
            resetForm,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
          }) => {
            return <Fragment>
              <Paper className={classes.paper} style={{ marginBottom: '20px' }}>
                <form onSubmit={handleSubmit}>
                  <fieldset disabled={this.state.disableForm}>
                    <div className={classes.root}>
                      <Grid container spacing={24}>
                        <PageTitle
                          formTitle="ใบนับสต๊อกสินค้า"
                          action={this.disableForm}
                          edit={this.state.disableForm}
                          id={values.id}
                          status={values.status}
                          view="physicalcount edit"
                        />
                        <Grid item xs={12} sm={6}></Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            name="order_no"
                            label="เลขที่เอกสาร"
                            fullWidth
                            value={values.order_no || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={true}
                            style={{ paddingTop: '7px' }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <SelectWidget
                            withOption={true}
                            options={this.state.companies}
                            value={values.company_id || ''}
                            name="company_id"
                            title="บริษัท*"
                            disabled={this.state.disableForm}
                            onChange={(name, value) => {
                              setFieldValue(name, value)
                              this.setRelateOption(name, value)
                            }}
                            isLoading={this.state.disableForm}
                          />
                          {touched.company_id && errors.company_id && (
                            <span className="form-text--error">
                              {errors.company_id}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <SelectWidget
                            withOption={true}
                            options={this.state.branches}
                            value={values.branch_id || ''}
                            name="branch_id"
                            title="สาขา*"
                            onChange={(name, value) => {
                              setFieldValue(name, value)
                              this.setOptionWarehouse(value)
                            }}
                            disabled={this.state.disableForm}
                          />
                          {touched.branch_id && errors.branch_id && (
                            <span className="form-text--error">
                              {errors.branch_id}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <SelectWidget
                            withOption={true}
                            options={this.state.warehouse}
                            value={values.warehouse_id || ''}
                            name="warehouse_id"
                            title="คลังสินค้า*"
                            onChange={(name, value) => {
                              setFieldValue(name, value)
                              this.resetSelect(value)
                            }}
                            disabled={this.state.disableForm}
                          />
                          {touched.warehouse_id && errors.warehouse_id && (
                            <span className="form-text--error">
                              {errors.warehouse_id}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <SelectWidget
                            withOption={false}
                            url="api/master-data/doc-types?function_type=PC"
                            value={values.document_type_id || ''}
                            name="document_type_id"
                            title="ชนิดเอกสาร *"
                            onChange={(name, value) => {
                              setFieldValue(name, value)
                            }}
                            disabled={this.state.disableForm}
                          />
                          {touched.document_type_id &&
                            errors.document_type_id && (
                              <span className="form-text--error">
                                {errors.document_type_id}
                              </span>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="เอกสารอ้างอิง"
                            name="ref_no"
                            fullWidth
                            value={values.ref_no || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="description"
                            label="รายละเอียด"
                            fullWidth
                            value={values.description || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      </Grid>
                    </div>

                    <Divider className={classes.divider} />
                    <MuiThemeProvider theme={EditTableStyle()}>
                      <DocItems
                        data={this.state.binItem}
                        selectItem={this.state.selectItem}
                        warehouse_id={values.warehouse_id}
                        handleChecked={setFieldValue}
                      />
                    </MuiThemeProvider>
                    <Divider className={classes.divider} />
                    <Grid item xs={12}>
                      <SubmitBtn
                        disabled={this.state.disableForm}
                        onClear={() => {
                          resetForm(defaultState)
                          this.resetBinItem()
                        }}
                      />
                    </Grid>


                  </fieldset>
                </form>

              </Paper>
              {values.status > 0 &&
                <Paper className={classes.paper} style={{ marginBottom: '20px' }}>
                  <Typography variant="h5" gutterBottom>
                    Physical Count
                  </Typography>
                  <MuiThemeProvider theme={EditTableStyle()}>
                    <CountItem
                      data={this.state.selectItem}
                      phyId={values.id}
                    />
                  </MuiThemeProvider>
                </Paper>
              }
            </Fragment>
          }
          }
        />

      </Grid>
    )
  }
}
export default withStyles(Formstyles)(DocForm)