import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { headerWithAuth, handleUnAutherize } from 'lib/authFunc'
import * as actions from './actions'

const header = headerWithAuth()

function* loadStores(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.get,
      process.env.REACT_APP_API_URL + `/api/customers${term}`,
      header
    )
    yield put(actions.loadStores.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadStores.failure(error.response))
  }
}

function* loadStore(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.get, process.env.REACT_APP_API_URL + `/api/customers/${id}`, header)
    yield put(actions.loadStore.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadStore.failure(error.response))
  }
}

function* editStore(action) {
  try {
    const { customer, id } = action.payload
    const res = yield call(axios.patch, process.env.REACT_APP_API_URL + `/api/customers/${id}`, customer, header)

    yield put(actions.editStore.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.editStore.failure(error.response))
  }
}


export default function* watchComments() {
  yield all([
    takeEvery(actions.LOAD_CUSTOMERS.REQUEST, loadStores),
    takeEvery(actions.LOAD_CUSTOMER.REQUEST, loadStore),
    takeEvery(actions.EDIT_CUSTOMER.REQUEST, editStore),
  ])
}
