import { createMuiTheme } from '@material-ui/core/styles';

const drawerWidth = 64
const Formstyles = theme => ({
  root: {
    width: '100%',
    maxWidth: 600,
    height: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: theme.palette.background.paper,
    margin: 'auto'
  },
  inline: {
    display: 'inline'
  },
  active: {
    backgroundColor: '#deefff'
  },
  paper: {
    padding: theme.spacing.unit * 5,
    color: theme.palette.text.secondary
  }
})


const Layoutstyles = theme => ({
    root: {
      flexGrow: 1
    }
})

const Tablestyle = () => createMuiTheme({
  overrides: {
    MuiPaper: {
      rounded: {
        padding: '15px'
      }
    },
    MuiTableCell: {
      root: {
        padding: '0px 0px 0px 4px !important',
      },
      body: {
        color: 'rgba(47, 47, 47, 0.87)',
        fontSize: '0.85rem',
        fontWeight: '100'
      },
      head: {
        color: 'rgba(0, 0, 0, 0.7)',
        fontSize: '0.85rem',
        fontWeight: '100',
      }
    },
    MuiTableRow: {
      head: {
        height: '30px'
      }
    },
    typography: {
      useNextVariants: true,
    },
    MuiIconButton: {
      root: {
        padding: '5px'
      }
    }
  }
})

export {
    Formstyles,
    Layoutstyles,
    Tablestyle
}