import { createTypes, createAction } from 'lib/action'
 
const LOAD_COMPANYS = createTypes('companies', 'list')
const LOAD_COMPANY = createTypes('companies', 'show')
const CREATE_COMPANY = createTypes('companies', 'create')
const EDIT_COMPANY = createTypes('companies', 'edit')
const DELETE_COMPANY = createTypes('companies', 'delete')
const FILTER_COMPANY = 'backup'
const SET_COMPANY = 'api/companies'
const GET_COMPANY = 'GET_COMPANY'
const FETCH_COMPANYS_FROM_STORE = "FETCH_COMPANYS_FROM_STORE"

const loadCompanies = {
  request: term => createAction(LOAD_COMPANYS.REQUEST, { term }),
  success: companies => createAction(LOAD_COMPANYS.SUCCESS, { companies }),
  failure: () => createAction(LOAD_COMPANYS.FAILURE)
}

const loadCompany = {
  request: id => createAction(LOAD_COMPANY.REQUEST, { id }),
  success: company => createAction(LOAD_COMPANY.SUCCESS, { company }),
  failure: () => createAction(LOAD_COMPANY.FAILURE)
}

const createCompany = {
  request: company => createAction(CREATE_COMPANY.REQUEST, { company }),
  success: company => createAction(CREATE_COMPANY.SUCCESS, { company }),
  failure: error => createAction(CREATE_COMPANY.FAILURE, { error })
}

const editCompany = {
  request: (id, company) => createAction(EDIT_COMPANY.REQUEST, { id, company }),
  success: company => createAction(EDIT_COMPANY.SUCCESS, { company }),
  failure: () => createAction(EDIT_COMPANY.FAILURE)
}

const deleteCompany = {
  request: (id) => createAction(DELETE_COMPANY.REQUEST, { id } ),
  success: id => createAction(DELETE_COMPANY.SUCCESS, { id } ),
  failure: () => createAction(DELETE_COMPANY.FAILURE)
}

const filterData = (companies, search) => ({
  type: FILTER_COMPANY,
  payload: {
    companies,
    search 
  }
})

const loadCompanyFromArray = id => ({
  type: GET_COMPANY,
  payload: id
})

const fecthCompanyFromArray = () => ({
  type : FETCH_COMPANYS_FROM_STORE,
  payload: {}
})


export {
  LOAD_COMPANYS,
  LOAD_COMPANY,
  CREATE_COMPANY,
  EDIT_COMPANY,
  DELETE_COMPANY,
  SET_COMPANY,
  FILTER_COMPANY,
  GET_COMPANY,
  FETCH_COMPANYS_FROM_STORE,
  loadCompanies,
  loadCompany,
  loadCompanyFromArray,
  createCompany,
  editCompany,
  deleteCompany,
  filterData,
  fecthCompanyFromArray
}
