import React, { Component } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { formatDate } from 'lib/helper'
import { defaultState } from '../defaultState'
import { companyWithBranch } from 'lib/loadOption'
import { DocItems } from './DocItems'

import {
  SubmitBtn,
  PageTitle,
  SelectWidget,
  SelectCreatable,
  DatePickerWidget,
  ConfirmDlg
} from 'lib/widgets'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import { genOptionList, genRelateOptionList } from 'lib/helper'

import { MuiThemeProvider } from '@material-ui/core/styles'
import { Formstyles, EditTableStyle } from '../../AddonStyles'

class DocForm extends Component {
  _isMounted = false
  state = defaultState

  toggleFx = name => {
    let isOpen = this.state[`${name}`]
    this.setState({ [`${name}`]: !isOpen })
  }

  setRelateOption = (name, value) => {
    let items = this.state.bkcompany
    let idx = items.findIndex(data => parseInt(data.id) === parseInt(value))
    if (idx < 0) return

    this.setState({
      branches: genRelateOptionList(
        [items[idx]],
        'id',
        'name',
        'branch',
        'company_id'
      )
    })
  }


  componentDidMount() {
    this._isMounted = true

    if (window.location.pathname !== '/warehouse/goods-issues/new') {
      this.disableForm(true)
    }

    companyWithBranch().then(res => {
      if(this._isMounted) {
        this.setState({
          companies: genOptionList(res, 'id', 'name'),
          bkcompany: res
        })
      }
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentWillReceiveProps(nextProps) {
    const { data } = nextProps
    if (data.company_id !== '') {
      this.setState({ disableForm: true }, 
        () => this.setRelateOption('company_id', data.company_id))
    }

  }

  disableForm = disableForm => {
    this.setState({ disableForm })
  }

  deleteItem = id => {
    this.props.onDeleteItem(id)
  }

  editItem = (id, data) => {
    this.props.onEditItem(id, data)
  }

  createItem = data => {
    this.props.onCreateItem(data)
  }

  setDlgContent = (content, id, companyId, branchId, refNo) => {
    this.setState({ dlgContent: content }, this.toggleConfirmDlg(id, companyId, branchId, refNo))
  }

  toggleConfirmDlg = (id, companyId, branchId, refNo) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      currentId: id,
      company_id: companyId,
      branch_id: branchId,
      ref_no: refNo
    });
  }

  deleteGoodsIssueDetail = (setFieldValue) => {
    const { company_id, branch_id, ref_no } = this.state
    if (ref_no !== false) {
      setFieldValue('ref_no', ref_no)
      this.setState({ isModalOpen: false, cnt: 1 })
    } else {
      this.setState({ isModalOpen: false, cntComp: 1 })
    }


    setFieldValue('company_id', company_id)
    this.setRelateOption('company_id', company_id)
    setFieldValue('s_branch_id', branch_id)
    this.setState({ isModalOpen: false })
  }

  render() {
    const { data, onSubmit, classes, isLoading } = this.props
    data['detailItem'] =
      typeof data.relations !== 'undefined' ? data.relations.detail : []

    return (
      <Grid item xs={12}>
        {isLoading && <div className="lmask" id="lmask" />}
        <Formik
          enableReinitialize
          initialValues={data}
          validationSchema={yup.object().shape({
            company_id: yup.string().required('กรุณากรอก'),
            branch_id: yup.string().required('กรุณากรอก'),
            document_type_id: yup.string().required('กรุณากรอก')
          })}
          onSubmit={values => onSubmit(values)}
          render={({
            values,
            errors,
            touched,
            resetForm,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
          }) => (
              <Paper className={classes.paper}>
                <form onSubmit={handleSubmit}>
                  <fieldset disabled={this.state.disableForm}>
                    <div className={classes.root}>
                      <Grid container spacing={24}>
                        <PageTitle
                          formTitle="ใบเบิกสินค้า"
                          action={this.disableForm}
                          edit={this.state.disableForm}
                          id={values.id}
                          status={values.status}
                          view="goodsissue edit"
                        />
                        <Grid item xs={12} sm={6}></Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="order_no"
                            label="เลขที่เอกสาร"
                            fullWidth
                            value={values.order_no || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={true}
                            style={{ paddingTop: '7px' }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}></Grid>
                        <Grid item xs={12} sm={6}>
                          <SelectCreatable
                            url="api/sale-orders"
                            label="document_no"
                            value={values.ref_no || ''}
                            name="ref_no"
                            title="เอกสารอ้างอิง"
                            extendOption={true}
                            onChange={(name, val, company_id, branch_id) => {
                              if (data.detailItem.length > 0 && this.state.cnt === 0) {
                                let content = "หากคุณเปลี่ยนเอกสารอ้างอิง เมื่อกดบันทึก ข้อมูลรายการสินค้าด้านล่างจะถูกลบทั้งหมด ยืนยันการเปลี่ยนแปลง?"
                                this.setDlgContent(content, data.id, company_id, branch_id, val)
                                return
                              }
                              setFieldValue(name, val)
                              setFieldValue('company_id', company_id)
                              this.setRelateOption('company_id', company_id)
                              setFieldValue('branch_id', branch_id)
                            }}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <SelectWidget
                            withOption={true}
                            options={this.state.companies}
                            value={values.company_id || ''}
                            name="company_id"
                            title="บริษัท*"
                            onChange={(name, value) => {
                              if (data.detailItem.length > 0 && this.state.cntComp === 0) {
                                let content = "หากคุณเปลี่ยนบริษัท เมื่อกดบันทึก ข้อมูลรายการสินค้าด้านล่างจะถูกลบทั้งหมด ยืนยันการเปลี่ยนแปลง?"
                                this.setDlgContent(content, data.id, value, null, false)
                                return
                              }
                              setFieldValue(name, value)
                              this.setRelateOption(name, value)
                            }}
                            disabled={this.state.disableForm}
                          />
                          {touched.company_id && errors.company_id && (
                            <span className="form-text--error">
                              {errors.company_id}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <SelectWidget
                            withOption={true}
                            options={this.state.branches}
                            value={values.branch_id || ''}
                            name="branch_id"
                            title="สาขา*"
                            onChange={setFieldValue}
                            disabled={this.state.disableForm}
                          />
                          {touched.branch_id && errors.branch_id && (
                            <span className="form-text--error">
                              {errors.branch_id}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <SelectWidget
                            url="api/master-data/process-types?function_type=GI"
                            value={values.process_type_id || ''}
                            name="process_type_id"
                            title="ประเภทการตัดสต็อก *"
                            onChange={setFieldValue}
                            disabled={this.state.disableForm}
                          />
                          {touched.process_type_id && errors.process_type_id && (
                            <span className="form-text--error">
                              {errors.process_type_id}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <SelectWidget
                            url="api/master-data/doc-types?function_type=GI"
                            value={values.document_type_id || ''}
                            name="document_type_id"
                            title="ชนิดเอกสาร *"
                            onChange={setFieldValue}
                            disabled={this.state.disableForm}
                          />
                          {touched.document_type_id &&
                            errors.document_type_id && (
                              <span className="form-text--error">
                                {errors.document_type_id}
                              </span>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <DatePickerWidget
                            title="วันที่จ่ายสินค้า"
                            name="delivery_date"
                            value={values.delivery_date || ''}
                            disabled={this.state.disableForm}
                            onValueChange={value => {
                              setFieldValue('delivery_date', formatDate(value))
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="วันที่ย้ายสินค้า"
                            fullWidth
                            value={values.movement_date || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="description"
                            label="รายละเอียด"
                            fullWidth
                            value={values.description || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="customer_name"
                            label="หุ้นส่วนทางธุรกิจ"
                            fullWidth
                            value={values.customer_name || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <SubmitBtn
                            disabled={this.state.disableForm}
                            onClear={() => resetForm(defaultState)}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </fieldset>
                </form>
                <Divider className={classes.divider} />
                <MuiThemeProvider theme={EditTableStyle()}>
                <Typography component="h2"  gutterBottom style={{ color: 'red' }}>
                  กรุณากรอกข้อมูลในคอลัมน์ที่มี * ให้ครบทุกคอลัมน์
                </Typography>
                  <DocItems
                    rows={values.detailItem}
                    docId={values.id}
                    companyId={values.company_id}
                    onCreate={this.createItem}
                    onEdit={this.editItem}
                    onDelete={this.deleteItem}
                    disabled={this.state.disableForm}
                    status={values.status}
                    processType={values.process_type_id}
                  />
                </MuiThemeProvider>
                <ConfirmDlg
                  title="ยืนยันการเปลี่ยนแปลงบริษัท"
                  content="หากคุณเปลี่ยนบริษัท ข้อมูลรายการสินค้าด้านล่างจะถูกลบทั้งหมด ยืนยันการเปลี่ยนแปลง?"
                  onToggle={this.toggleConfirmDlg}
                  isOpen={this.state.isModalOpen}
                  onConfirm={() => this.deleteGoodsIssueDetail(setFieldValue, values.company_id)}
                />
              </Paper>
            )}
        />
      </Grid>
    )
  }
}

export default withStyles(Formstyles)(DocForm)
