import React from 'react';
import Grid from '@material-ui/core/Grid'

import Typography from '@material-ui/core/Typography'
import BorderColorIcon from '@material-ui/icons/BorderColor'
import ClearIcon from '@material-ui/icons/Clear'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

import { checkAction } from 'lib/authFunc'

export default ({ formTitle, action, edit, id, status=0, view }) => (
    <Grid container justify="space-between" id="tabs1">
    <Typography variant="h6" gutterBottom>
      { formTitle }
    </Typography>
    
    { edit && parseInt(status) === 0 && checkAction(view) && 
    <Tooltip title="แก้ไข">
      <IconButton onClick={() => action(false)}>
        <BorderColorIcon />
      </IconButton>
    </Tooltip> 
    }
    { !edit && id !== '' &&
    <Tooltip title="ยกเลิก">
      <IconButton onClick={() => action(true)}>
        <ClearIcon />
      </IconButton>
    </Tooltip>  
    }


  </Grid>
)