import React, { Component, Fragment } from 'react'
import * as actions from '../actions'
import { connect } from 'react-redux'
import { compose } from 'redux'
import debounce from 'lodash/debounce'

import { formatDate, createCondition } from 'lib/helper'
import { emptySubRow } from 'lib/fxUI'

import {
  ActionBtn,
  SearchPanel,
  MenuMobile
} from 'lib/widgets'

import MUIDataTable from 'mui-datatables'
import { MuiThemeProvider } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { Tablestyle } from '../../AddonStyles'

import AdvanceSearch from './AdvanceSearch'

import { defaultState } from '../defaultState'


class StockBalanceTable extends Component {
  state = {
    term: '',
    search: '',
    term_from_advsearch: "",
    delId: '',
    options: {},
    page: 0,
    sortName: '',
    rowsPerPage: 10,
    count: 100,
    isModalOpen: false,
    showAdvSearch: false,
    width: window.innerWidth,
  }

  componentDidMount() {
    this.fetchStockBalances()
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  }

  fetchStockBalances = async () => {
    const { term } = this.state
    this.props.loadStockbalances(term)
  }

  delaySearchData = debounce(async value => {
    this.setState({ search: value })
    const cond = 'strict=false&'+createCondition({ product_name: '' }, value)
    this.props.loadStockbalances('?'+cond)
  }, 750)

  searchdata = event => {
    return this.delaySearchData(event)
  }

  showAdvSearch = state => {
    this.setState({ showAdvSearch: state })
  }

  advSearch = (data) => {
    this.props.loadStockbalances(data)
  }

  setAdvSearch = data => {
    this.setState({ term: data.replace(/\?/g, ''), term_from_advsearch: data.replace(/\?/g, '') })
    this.advSearch(data)
  }

  handleTableChange = (action, tableState) => {
    let term = ''
    let condStr = 'strict=false&'+createCondition({ product_name: '' }, this.state.search)
    if(this.state.term_from_advsearch !== "") condStr += "&"+this.state.term_from_advsearch
    switch (action) {
      case 'changeRowsPerPage':
        term = `?per_page=${tableState.rowsPerPage}&${condStr}`
        this.setState(
          {
            term: term,
            rowsPerPage: tableState.rowsPerPage,
            page: 0
          },
          this.advSearch(term)
        )
        break
      case 'changePage':
        term = `?page=${tableState.page + 1}&per_page=${tableState.rowsPerPage}&${condStr}`
        this.setState(
          {
            term: term,
            page: tableState.page
          },
          this.advSearch(term)
        )
        break
      default:
        break
    }
  }

  renderTable = (isLoading, columns, stockbalance, optionsTable) => {
    const isMobile = this.state.width <= 500

    if(isMobile) {
      return <MenuMobile 
      data={stockbalance} 
      columns={{
        col1: 'uom_name',
        col2: 'product_name',
        col3: 'quantity',
        col4: 'warehouse_name',
      }}
      title={{
        title1: () => 'หน่วยนับ',
        title2: () => 'ชื่อสินค้า',
        title3: () =>  'จำนวนทั้งหมด',
        title4: () =>  'คลังสินค้า'
      }}
      url={`api/stock-balances`}
      onLoad={this.advSearch}
      linkUrl={`#`}
      hideCreate={true}
      hideAll={true}
      />
    } 


    return <Fragment>
      { isLoading && <div className="lmask" id="lmask" /> }
      {typeof stockbalance === 'object' && (
                <MuiThemeProvider theme={Tablestyle()}>
                  <MUIDataTable
                    title={'Stock Balance'}
                    data={stockbalance}
                    columns={columns}
                    options={optionsTable}
                  />
                </MuiThemeProvider>
              )}
    </Fragment>
  }

  render() {
    const { isLoading, stockbalance, meta } = this.props
    const { rowsPerPage, page, sortName, options } = this.state
    const count = typeof meta === 'undefined' ? 100 : meta.total

    const columns = [
      {
        name: '',
        label: '#',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return tableMeta.tableState.page * rowsPerPage + tableMeta.rowIndex + 1
          }
        }
      },
      {label : 'ชื่อ', name: 'product_name', options: { filter: false, sort: false }},
      {label : 'จำนวนทั้งหมด', name: 'quantity', options: { filter: false, sort: false }},
      {label : 'หน่วยนับ', name: 'uom_name', options: { filter: false, sort: false }},
      {label : 'คลังสินค้า', name: 'warehouse_name', options: { filter: false, sort: false }}
    ]

    const subRow = [
      {label : 'สถานที่จัดเก็บ', name: 'bin_name', options: { filter: false, sort: false }},
      {label : 'Batch', name: 'batch_name', options: { filter: false, sort: false }},
      {label : 'จำนวนทั้งหมด', name: 'onhand_qty', options: { filter: false, sort: false }},
      {label : 'จำนวนที่จองแล้ว', name: 'reserved_qty', options: { filter: false, sort: false }},
      {label : 'วันที่ผลิต', name: 'manufacturing_date', options: { filter: false, sort: false }, Cell: row => ( formatDate(row.value) )},
      {label : 'วันที่หมดอายุ', name: 'expired_date', options: { filter: false, sort: false }, Cell: row => ( formatDate(row.value) )},
      {label : 'วันที่รับ', name: 'receive_date', options: { filter: false, sort: false }, Cell: row => ( formatDate(row.value) )},
    ]

    const optionsTable = {
      filter: false,
      print: false,
      download: false,
      viewColumns: false,
      filterType: 'dropdown',
      serverSide: true,
      count: count,
      page: page,
      expandableRows: true,
      rowsPerPage: rowsPerPage,
      selectableRows: false,
      onTableChange: this.handleTableChange,
      onSearchChange: this.searchdata,
      onSearchClose: () => {
        this.setState({ search : '' })
        this.advSearch(this.state.term)
      },
      textLabels: {
        body: {
          noMatch: "Sorry, no matching records found",
          toolTip: "Sort",
        }
      },
      renderExpandableRow: (rowData, rowMeta) => {
        const items = stockbalance[rowMeta.dataIndex].details
        const colSpan = rowData.length + 1
        if (items.length === 0) return emptySubRow(colSpan)

        const tableStyle = {
          margin: '20px auto 20px auto', 
          width: '95%', 
          backgroundColor: '#ffffff',
          borderCollapse: 'collapse'
        }
        
        const borderStyle = {
          border: '1px solid #c3c3c3'
        }

        return (
          <TableRow>
            <TableCell colSpan={colSpan}>
              <Table style={tableStyle}>
                <TableHead style={{ height: '30px', backgroundColor: 'rgb(188, 205, 239)', border: '1px solid #c3c3c3' }}>
                  <TableRow>
                    {subRow.map((data, i) => (
                      <TableCell key={i}  style={{ height: '30px', border: '1px solid #c3c3c3' }}>
                        {data.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((row, i) => (
                      <TableRow key={i} style={{ height: '30px', backgroundColor: '#ffffff' }}>
                        <TableCell style={borderStyle}>{row.bin_name}</TableCell>
                        <TableCell style={borderStyle}>{row.batch_name}</TableCell>
                        <TableCell style={borderStyle}>
                          {row.onhand_qty}
                        </TableCell>
                        <TableCell style={borderStyle}>{row.reserved_qty}</TableCell>
                        <TableCell style={borderStyle}>{formatDate(row.manufacturing_date)}</TableCell>
                        <TableCell style={borderStyle}>{formatDate(row.expired_date)}</TableCell>
                        <TableCell style={borderStyle}>{formatDate(row.receive_date)}</TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        )
      },
      customToolbar: () => {
        return (
          <ActionBtn
          onSearch={this.showAdvSearch}
          displayCreate={false}
        />
        );
      }
    }

    if (sortName !== '') {
      let idx = columns.findIndex(data => data.name === sortName)
      if (idx === -1) return
      columns[idx].options = options
    }

    return (
      <Fragment>
        { isLoading && <div className="lmask" id="lmask" /> }
          <Fragment>
            <Grid item xs={12}>
              { this.renderTable(isLoading, columns, stockbalance, optionsTable) }
            </Grid>
          </Fragment>

        <SearchPanel
          toggleDrawer={this.showAdvSearch}
          isOpen={this.state.showAdvSearch}
          onSubmit={this.setAdvSearch}
          Component={AdvanceSearch}
          formValue={defaultState}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ stockbalances }) => ({
  isLoading: stockbalances.isLoading,
  stockbalance: stockbalances.items,
  meta: stockbalances.meta,
  backupItems: stockbalances.backupItems
})

const mapDispatchToProps = {
  loadStockbalances: actions.loadStockbalances.request,
  filterData: actions.filterData
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(StockBalanceTable)
