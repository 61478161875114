import { createTypes, createAction } from 'lib/action'
 
const LOAD_BATCHES = createTypes('batches', 'list')
const EDIT_BATCH = createTypes('batches', 'edit')
const FILTER_BATCH = 'backup'
const SET_BATCH = 'api/batches'
const GET_BATCH = 'GET_BATCH'
const FETCH_BATCHES_FROM_STORE = "FETCH_BATCHES_FROM_STORE"

const loadBatches = {
  request: term => createAction(LOAD_BATCHES.REQUEST, { term }),
  success: batches => createAction(LOAD_BATCHES.SUCCESS, { batches }),
  failure: () => createAction(LOAD_BATCHES.FAILURE)
}

const editBatch = {
  request: (id, batch) => createAction(EDIT_BATCH.REQUEST, { id, batch }),
  success: batch => createAction(EDIT_BATCH.SUCCESS, { batch }),
  failure: () => createAction(EDIT_BATCH.FAILURE)
}

const filterData = (batches, search) => ({
  type: FILTER_BATCH,
  payload: {
    batches,
    search 
  }
})

const loadBatchFromArray = id => ({
  type: GET_BATCH,
  payload: id
})

const fecthBatchFromArray = () => ({
  type : FETCH_BATCHES_FROM_STORE,
  payload: {}
})


export {
  LOAD_BATCHES,
  EDIT_BATCH,
  SET_BATCH,
  FILTER_BATCH,
  GET_BATCH,
  FETCH_BATCHES_FROM_STORE,
  loadBatches,
  loadBatchFromArray,
  editBatch,
  filterData,
  fecthBatchFromArray
}
