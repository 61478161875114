import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import debounce from 'lodash/debounce'
import * as actions from '../actions'

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Link from '@material-ui/core/Link'
import ListSubheader from '@material-ui/core/ListSubheader'
import Paper from '@material-ui/core/Paper'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button'

import { Liststyles } from '../../AddonStyles'
import { WaitingList, SearchWidget, SearchBtn } from 'lib/widgets'
import { createCondition } from 'lib/helper'

const defaultState = {
  term: '',
  search: '',
  cond: '',
  per_page: 20,
  open: false
}

class UserList extends Component {
  state = defaultState

  componentDidMount() {
    this.fetchUsers()
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props
    const search = location.pathname
    if (search === prevProps.location.pathname) return

    if (prevProps.location.pathname !== '/master-data/users/new') {
      this.props.fecthUserFromArray()
      return
    }

    this.fetchUsers()
  }

  getUserId = () => {
    let id = window.location.pathname.match(/\d+/)
    return id !== null ? id[0] : ''
  }

  loadMoreList = () => {
    let per_page = this.state.per_page + 20
    this.setState(
      { term: `?per_page=${per_page}`, per_page },
      this.fetchUsers
    )
  }

  fetchUsers = () => {
    const { term, cond } = this.state
    if (cond !== '') {
      this.props.loadUsers(`${term}&${cond}`)
      return 
    } 

    this.props.loadUsers(`?${term}`)
  }

  delaySearchUsers = debounce(async event => {
    const { users, backupItems } = this.props
    this.setState({ search: event.target.value })

    if (
      event.target.value === '' ||
      this.state.term.length < event.target.value.length
    ) {
      this.props.filterData(backupItems, event.target.value)
      return
    }
    this.props.filterData(users, event.target.value)
  }, 750)

  searchUsers = event => {
    event.persist()
    return this.delaySearchUsers(event)
  }

  toggleSearch = (open) => {
    this.setState({ open: open, term: '', search: '', per_page: 20, cond: '' }, () => {
      if (this.state.open) {
        this.fetchUsers()
      }
    })
  }

  advSearch = () => {
    const { search } = this.state
    const cond = '?strict=false&'+createCondition({ name: '', surname: '', username: '' }, search)
    this.setState({ cond: cond })
    this.props.loadUsers(cond)
  }


  render() {
    const { classes, isLoading, users, meta } = this.props
    const to = typeof meta !== 'undefined' ? meta.to : 0
    const total = typeof meta !== 'undefined' ? meta.total : 0

    return (
      <Paper className={classes.paper}>

        <SwipeableDrawer
          onClose={() => this.toggleSearch(false)}
          onOpen={() => this.toggleSearch(true)}
          open={this.state.open}>
          <SearchWidget onChange={this.searchUsers} onClick={this.toggleSearch} onSearch={this.advSearch}/>
          {isLoading ? (
            <Fragment>
              <WaitingList />
            </Fragment>
          ) : (
              <List
                className={classes.root}
                component="nav"
                subheader={<ListSubheader component="div" />}
              >
                {typeof users === 'object' &&
                  users.map((user, i) => {
                    let cls =
                      parseInt(user.id) === parseInt(this.getUserId())
                        ? classes.active
                        : ''
                    return (
                      <Link
                        key={i}
                        component={RouterLink}
                        to={`/master-data/users/${user.id}`}
                      >
                        <ListItem alignItems="flex-start" className={cls}>
                          <ListItemText
                            primary={`ชื่อผู้ใช้งาน : ${user.username}`}
                            secondary={
                              <Fragment>
                                <Typography
                                  component="span"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  ชื่อ-นามสกุล : {user.name} {user.surname} <br />
                                  Active : {user.active_desc}
                                </Typography>
                              </Fragment>
                            }
                          />
                        </ListItem>
                        <Divider />
                      </Link>
                    )
                  })}
              </List>
            )}
          <Button
            variant="contained"
            onClick={this.loadMoreList}
            style={{ borderRadius: '0px' }}
          >
            โหลดเพิ่ม ( {to} / {total} )
          </Button>
        </SwipeableDrawer>


        <SearchBtn classes={classes} onClick={this.toggleSearch} view="user edit"/>

      </Paper>
      // </Grid>
    )
  }
}

UserList.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = ({ users }) => ({
  isLoading: users.isLoading,
  successSave: users.successSave,
  users: users.items,
  meta: users.meta,
  backupItems: users.backupItems
})

const mapDispatchToProps = {
  loadUsers: actions.loadUsers.request,
  filterData: actions.filterData,
  fecthUserFromArray: actions.fecthUserFromArray
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withStyles(Liststyles)
)(UserList)
