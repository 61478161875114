import React from 'react'
import Grid from '@material-ui/core/Grid'
import { getMonth } from './RelateFunction'
import { SelectWidget } from 'lib/widgets'

const style = {
    marginBottom: '15px'
}

const monthOpt = getMonth()
const YearOpt = () => {
  let min = new Date().getFullYear(),
      max = min,
      year = []

  for (var i = min-1; i<=max; i++){
      year = [...year, { value: i , label: i }]
  }
  return year
}


export default ({ values, classes, handleChange, handleSelect, handleDateChange }) => (
  <div style={{ padding: '10px', flexGrow: 1 }}>
    <Grid item xs={12}  style={style}>
    <SelectWidget
        url="api/companies"
        value={values.company_id}
        name="company_id"
        title="บริษัท"
        onChange={handleSelect('company_id')}
    />
    </Grid>
    <Grid item xs={12} style={style}>
    <SelectWidget
        url="api/branches"
        value={values.branch_id}
        name="branch_id"
        title="สาขา"
        onChange={handleSelect('branch_id')}
    />
    </Grid>
    <Grid item xs={12}  style={style}>
    <SelectWidget
        isClear={false}
        withOption={true}
        options={YearOpt()}
        name="year_id"
        title="ปี"
        value={values.year_id}
        onChange={handleSelect('year_id')}
    />
    </Grid>
    <Grid item xs={12}  style={style}>
    <SelectWidget
        isClear={false}
        withOption={true}
        options={monthOpt}
        name="month_id"
        title="เดือน"
        value={values.month_id}
        onChange={handleSelect('month_id')}
    />
    </Grid>
  </div>
)
