import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import * as actions from '../actions'
import CompanyForm from './CompanyForm'

import { defaultState } from '../defaultState'
import { hasMessage } from 'lib/fxUI'

class CreateCompany extends Component {
  createCompany = async article => {
    this.props.createCompany(article)
  }

  render() {
    const { successSave, isLoading } = this.props
    return (
      <Fragment>
      {successSave !== null && hasMessage(successSave, '')}
      <CompanyForm
        onSubmit={this.createCompany}
        formAction="สร้างใหม่"
        successSave={successSave}
        isLoading={isLoading}
        data={defaultState}
      />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ companies }) => ({
  isLoading: companies.isLoading,
  successSave: companies.successSave
})
const mapDispatchToProps = {
  createCompany: actions.createCompany.request
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCompany)
