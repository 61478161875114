import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    width: '110px'
  },
  input: {
    display: 'none'
  }
})

function SubmitBtn(props) {
  const { classes, onClear, disabled } = props
  return (
    <Grid
    justify="center" // Add it here :)
    container 
    spacing={24}
  >
      <Button 
        variant="contained" 
        className={classes.button}
        disabled={disabled} 
        onClick={onClear}>
        เคลียร์
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={disabled} 
        type="submit"
        className={classes.button}
      >
        บันทึก
      </Button>
    </Grid>
  )
}

SubmitBtn.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SubmitBtn)
