import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as actions from '../actions'
import BinDetailForm from './BinDetailForm'

import { hasMessage } from 'lib/fxUI'
import { defaultState } from '../defaultState'

class EditBinDetail extends Component {
  componentDidMount() {
    const id = this.getBinDetailId()
    this.props.loadBinDetail(id)
  }

  componentDidUpdate(prevProps) {
    const search = this.props.location.pathname
    if (search === prevProps.location.pathname) return

    const id = this.getBinDetailId()
    this.props.loadBinDetailFromArray(id)
  }

  getBinDetailId = () => this.props.match.params.id

  editBinDetail = async data => {
    this.props.editBinDetail(this.getBinDetailId(), data)
  }

  render() {
    const { bindetail, successSave, isLoading } = this.props
    let data = []

    if (typeof bindetail !== 'undefined') {
      data = typeof bindetail[0] !== 'undefined' ? bindetail[0] : defaultState
    }

    if (data.length === 0) {
      return <div className="lmask" id="lmask" />
    }

    return (
      <Fragment>
        {successSave !== null && hasMessage(successSave, '')}
        <BinDetailForm
          onSubmit={this.editBinDetail}
          formAction="แก้ไข"
          data={data}
          successSave={successSave}
          isLoading={isLoading}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ bindetails }) => ({
  bindetail: bindetails.bindetail,
  isLoading: bindetails.isLoading,
  successSave: bindetails.successSave
})

const mapDispatchToProps = {
  loadBinDetail: actions.loadBinDetail.request,
  editBinDetail: actions.editBinDetail.request,
  loadBinDetailFromArray: actions.loadBinDetailFromArray
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
)(EditBinDetail)
