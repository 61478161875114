import {
  LOAD_GTS,
  LOAD_GT,
  CREATE_GT,
  EDIT_GT,
  DELETE_GT,
  CREATE_GT_ITEM,
  EDIT_GT_ITEM,
  DELETE_GT_ITEM,
  DELETEALL_GT_ITEM,
  FILTER_GT,
  GET_GT,
  FETCH_GTS_FROM_GT,
  CHANGE_STATUS_GT
} from './actions'

import { dataFilter, replaceIdxValue } from 'lib/helper'

const initialState = {
  isLoading: false,
  successSave: null,
  deleteSuccess: null,
  items: [],
  backupItems: [],
  data: [],
  errorMsg: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_GT:
      return {
        ...state,
        items: dataFilter(action.payload.goodstransfer, action.payload.search),
        backupItems: state.backupItems
      }
    case GET_GT:
      const id = state.backupItems.findIndex(
        data => parseInt(data.id) === parseInt(action.payload)
      )
      return {
        ...state,
        successSave: null,
        data: [state.backupItems[id]]
      }
    case FETCH_GTS_FROM_GT:
      return {
        ...state,
        successSave: null,
        items: state.backupItems,
        isLoading: false
      }
    case CREATE_GT.REQUEST:
    case EDIT_GT.REQUEST:
    case CREATE_GT_ITEM.REQUEST:
    case EDIT_GT_ITEM.REQUEST:
    case DELETE_GT_ITEM.REQUEST:
      return { ...state, items: [], isLoading: true, successSave: null, deleteSuccess: null }
    case LOAD_GTS.REQUEST:
    case LOAD_GT.REQUEST:
    case DELETE_GT.REQUEST:
    case CHANGE_STATUS_GT.REQUEST:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: true,
        successSave: null,
        deleteSuccess: null
      }
    case LOAD_GTS.SUCCESS:
      return {
        ...state,
        items: action.payload.goodstransfers.data,
        backupItems: action.payload.goodstransfers.data,
        isLoading: false,
        meta: action.payload.goodstransfers.meta,
        successSave: null,
        deleteSuccess: null
      }
    case LOAD_GT.SUCCESS:
      return {
        ...state,
        data: [action.payload.goodstransfer],
        isLoading: false,
        successSave: null
      }
    case CREATE_GT.SUCCESS:
      return {
        ...state,
        items: [action.payload.goodstransfer, ...state.backupItems],
        backupItems: [action.payload.goodstransfer, ...state.backupItems],
        data: [action.payload.goodstransfer],
        isLoading: false,
        successSave: true
      }
    case EDIT_GT.SUCCESS:
      return {
        ...state,
        data: [action.payload.goodstransfer],
        isLoading: false,
        successSave: true
      }
    case DELETE_GT.SUCCESS:
      const index = state.backupItems.findIndex(
        data => parseInt(data.id) === parseInt(action.payload.id)
      )
      return {
        ...state.backupItems.splice(index, 1),
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: null,
        deleteSuccess: true
      }
    case CHANGE_STATUS_GT.SUCCESS: {
      const idx = state.backupItems.findIndex(
        data =>
          parseInt(data.id) ===
          parseInt(action.payload.goodstransfer.data[0].id)
      )
      return {
        ...state,
        items: replaceIdxValue(
          state.backupItems,
          idx,
          action.payload.goodstransfer.data[0]
        ),
        backupItems: replaceIdxValue(
          state.backupItems,
          idx,
          action.payload.goodstransfer.data[0]
        ),
        isLoading: false,
        successSave: true,
        deleteSuccess: null,
        errorMsg: ''
      }
    }
    case CREATE_GT_ITEM.SUCCESS:
      const createidx = state.backupItems.findIndex(
        data =>
          parseInt(data.id) === parseInt(action.payload.detail.gt_order_id)
      )
      state.backupItems[createidx].relations.detail = [...state.backupItems[createidx].relations.detail, action.payload.detail]
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        data: [state.backupItems[createidx]],
        isLoading: false,
        successSave: null
      }
    case EDIT_GT_ITEM.SUCCESS:
      const editidx = state.backupItems.findIndex(
        data =>
          parseInt(data.id) === parseInt(action.payload.detail.gt_order_id)
      )
      const binEditIdx = state.backupItems[editidx].relations.detail.findIndex(
        gi => parseInt(gi.id) === parseInt(action.payload.detail.id)
      )
      state.backupItems[editidx].relations.detail = replaceIdxValue(
        state.backupItems[editidx].relations.detail,
        binEditIdx,
        action.payload.detail
      )
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        data: [state.backupItems[editidx]],
        isLoading: false,
        successSave: null
      }
    case DELETE_GT_ITEM.SUCCESS:
      const delidx = state.backupItems.findIndex(
        data => parseInt(data.id) === parseInt(action.payload.data.gt_order_id)
      )

      state.backupItems[delidx].relations.detail = action.payload.data.detail

      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        data: [state.backupItems[delidx]],
        isLoading: false,
        successSave: null
      }
    case DELETEALL_GT_ITEM.SUCCESS:
      const gridx = state.backupItems.findIndex(
        data => parseInt(data.id) === parseInt(action.payload.id)
      )
      state.backupItems[gridx].relations.detail = []
      return {
        data: [state.backupItems[gridx]],
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: null
      }
    case LOAD_GTS.FAILURE:
    case LOAD_GT.FAILURE:
    case CREATE_GT.FAILURE:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: false
      }
    case CREATE_GT_ITEM.FAILURE:
    case EDIT_GT_ITEM.FAILURE:
    case EDIT_GT.FAILURE:
    case DELETE_GT.FAILURE:
    case DELETE_GT_ITEM.FAILURE:
    case DELETEALL_GT_ITEM.FAILURE:
      return { ...state, items: [], isLoading: false, successSave: null, deleteSuccess: false }
    case CHANGE_STATUS_GT.FAILURE:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: false,
        deleteSuccess: null,
        errorMsg: action.payload.error
      }
    default:
      return state
  }
}
