import React from 'react';
import { MenuMobile } from 'lib/widgets'

const WorkMobile = ({ data, advSearch, getColor }) => (
<MenuMobile 
      data={data}
      hideAll={true} 
      columns={{
        col1: 'created_at',
        col2: 'order_no',
        col3: 'ref_no',
        col4: 'status_name',
      }}
      title={{
        title1: () => 'เวลาที่สร้าง',
        title2: (val) => {
            return typeof getColor === 'function' ?
             <span style={{ background: getColor(val.minute), padding: '2px 15px 2px 15px' }}>
                { val.doc_type }
            </span> : val.doc_type
        },
        title3: () =>  'เลขที่อ้างอิง',
        title4: () =>  'สถานะ'
      }}
      withData={true}
      onLoad={advSearch}
      hideCreate={true}
      />
);

export default WorkMobile;