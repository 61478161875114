import React, { Component, Fragment } from 'react'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import debounce from 'lodash/debounce'
import * as actions from '../actions'

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Link from '@material-ui/core/Link'
import ListSubheader from '@material-ui/core/ListSubheader'
import Paper from '@material-ui/core/Paper'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Button from '@material-ui/core/Button'

import { Liststyles } from './AddonStyles'
import { WaitingList, SearchWidget, SearchBtn } from 'lib/widgets'
import { numberFormat } from 'lib/helper'
import { createCondition } from 'lib/helper'

const defaultState = {
  term: '',
  search: '',
  cond: '',
  per_page: 20,
  open: false
}

class SalesOrderList extends Component {
  state = defaultState

  componentDidMount() {
    this.loadSalesOrders()
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props
    const search = location.pathname
    if (search === prevProps.location.pathname) return

    if (prevProps.location.pathname !== '/salesforce/sale-orders/new') {
      this.props.fecthSalesOrderFromArray()
      return
    }

    this.loadSalesOrders()
  }

  getSalesOrderId = () => {
    let id = window.location.pathname.match(/\d+/)
    return id !== null ? id[0] : ''
  }

  loadSalesOrders = () => {
    const { term, cond } = this.state
    this.props.loadSalesOrders('?'+term+'&'+cond)
  }

  loadMoreList = () => {
    let per_page = this.state.per_page + 20
    this.setState(
      { term: `per_page=${per_page}`, per_page },
      this.loadSalesOrders
    )
  }

  delaySearchData = debounce(async event => {
    const { salesorders, backupItems } = this.props
    this.setState({ search: event.target.value })
    
    if (
      event.target.value === '' ||
      this.state.term.length < event.target.value.length
    ) {
      this.props.filterData(backupItems, event.target.value)
      return
    }

    
    this.props.filterData(salesorders, event.target.value)
  }, 750)

  searchData = event => {
    event.persist()
    return this.delaySearchData(event)
  }

  toggleSearch = open => {
    this.setState({ open: open, term: '', search: '', per_page: 20, cond: '' }, () => {
      if (this.state.open) {
        this.loadSalesOrders()
      }
    })
  }

  advSearch = () => {
    const { search } = this.state
    const cond = 'strict=false&'+createCondition({ document_no: '', net: '' }, search)
    this.setState({ cond: cond })
    this.props.loadSalesOrders('?'+cond)
  }

  render() {
    const { classes, isLoading, salesorders, meta } = this.props
    const to = typeof meta !== 'undefined' ? meta.to : 0
    const total = typeof meta !== 'undefined' ? meta.total : 0

    return (
      <Paper className={classes.paper}>

          <SwipeableDrawer
            onClose={() => this.toggleSearch(false)}
            onOpen={() => this.toggleSearch(true)}
            open={this.state.open}
          >
            <SearchWidget
              onChange={this.searchData}
              onClick={this.toggleSearch}
              onSearch={this.advSearch}
            />

        {isLoading ? (
          <Fragment>
            <WaitingList />
          </Fragment>
        ) : (

            <List
              className={classes.root}
              component="nav"
              subheader={<ListSubheader component="div" />}
            >
              {typeof salesorders === 'object' &&
                salesorders.map((data, i) => {
                  let cls =
                    parseInt(data.id) === parseInt(this.getSalesOrderId())
                      ? classes.active
                      : ''
                  return (
                    <Link
                      key={i}
                      component={RouterLink}
                      to={`/salesforce/sale-orders/${data.id}`}
                    >
                      <ListItem alignItems="flex-start" className={cls}>
                        <ListItemText
                          primary={`เลขที่ SO : ${data.document_no}`}
                          secondary={
                            <Fragment>
                              <Typography
                                component="span"
                                className={classes.inline}
                                color="textPrimary"
                              >
                                ยอดสุทธิ : {numberFormat(data.net)} <br />
                                สถานะ : {data.payment_status}
                              </Typography>
                            </Fragment>
                          }
                        />
                      </ListItem>
                      <Divider />
                    </Link>
                  )
                })}
            </List>
 )}
          <Button
            variant="contained"
            onClick={this.loadMoreList}
            style={{ borderRadius: '0px' }}
          >
            โหลดเพิ่ม ( {to} / {total} )
          </Button>
          </SwipeableDrawer>
       

        <SearchBtn classes={classes} onClick={this.toggleSearch} />
      </Paper>
    )
  }
}

const mapStateToProps = ({ salesorders }) => ({
  isLoading: salesorders.isLoading,
  salesorders: salesorders.items,
  meta: salesorders.meta,
  backupItems: salesorders.backupItems
})

const mapDispatchToProps = {
  loadSalesOrders: actions.loadSalesOrders.request,
  filterData: actions.filterData,
  fecthSalesOrderFromArray: actions.fecthSalesOrderFromArray
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withStyles(Liststyles)
)(SalesOrderList)
