
const defaultState = {
  id: '',
  disableForm: false,
  formLoading: true,
  order_no: '',
  company_id: '',
  branch_id: '',
  warehouse_id: '',
  document_type_id: '',
  ref_no: '',
  description: '',
  companies: [],
  warehouse: [],
  bkcompany: [],
  selectItem:[],
  binItem: [],
  bkwarehouse: [],
  branches: [],
  relations: {
    detail: []
  },

  advSearch : {
    company_id: '',
    branch_id: '',
    warehouse_id: '',
    order_no: ''
  }
}

const pageState = {
  id: '',
  cntComp: 0,
  cnt: 0,
  dlgContent: '',
  isModalOpen: false,
  disableForm: false,
  formLoading: true,
  company_id: '',
  s_branch_id: '',
  d_branch_id: '',
  order_no: '',
  document_type_id: '',
  process_type_id: '',
  ref_no: '',
  movement_date: '',
  description: '',
  branchUrl: 'api/branches',
  currentId: '',
  companies: [],
  bkcompany: [],
  branches: [],
}

const tableColumnExtensions = [
  { columnName: 'product_id', width: 215 },
  { columnName: 'uom_id', width: 105 },
  { columnName: 'batch_id', width: 105 },
  { columnName: 's_warehouse_id', width: 125 },
  { columnName: 's_bin_id', width: 120 },
  { columnName: 'd_warehouse_id', width: 115 },
  { columnName: 'd_bin_id', width: 135 },
  { columnName: 'order_qty', width: 90 }
]

export {
  defaultState,
  pageState,
  tableColumnExtensions
}
