import React from 'react'
import Grid from '@material-ui/core/Grid'

import TextField from '@material-ui/core/TextField'
import { SelectWidget, DatePickerWidget } from 'lib/widgets'


const style = {
  marginBottom: '15px'
}
export default ({ values, classes, handleChange, handleSelect, handleDateChange }) => (
  <div style={{ padding: '10px', flexGrow: 1 }}>
    <Grid item xs={12} style={style}>
      <SelectWidget
        url="api/companies"
        value={values.company_id}
        name="company_id"
        title="บริษัท"
        onChange={handleSelect('company_id')}
      />
    </Grid>
    <Grid item xs={12} style={style}>
      <SelectWidget
        url="api/branches"
        value={values.branch_id}
        name="branch_id"
        title="สาขา"
        onChange={handleSelect('branch_id')}
      />
    </Grid>
    <Grid item xs={12} style={style}>
      <SelectWidget
        url="api/master-data/warehouses"
        value={values.warehouse_id}
        name="warehouse_id"
        title="คลังสินค้า"
        onChange={handleSelect('warehouse_id')}
      />
    </Grid>
    <Grid item xs={12} style={style}>
      <TextField
        label="สินค้า"
        name="product_name"
        value={values.product_name}
        onChange={handleChange('product_name')}
        fullWidth
      />
    </Grid>
    <Grid item xs={12} style={style}>
      <SelectWidget
        url="api/master-data/uoms"
        value={values.uom_id}
        name="uom_id"
        title="หน่วยนับ"
        onChange={handleSelect('uom_id')}
      />
    </Grid>
    <Grid item xs={12} style={style}>
      <DatePickerWidget
        title="วันที่หมดอายุ"
        name="expire_date"
        value={values.expire_date}
        onValueChange={handleDateChange('expire_date')}
      />
    </Grid>
    <Grid item xs={12} style={style}>
      <DatePickerWidget
        title="วันที่รับ"
        name="receive_date"
        value={values.receive_date}
        onValueChange={handleDateChange('receive_date')}
      />
    </Grid>

  </div>
)
