import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import * as actions from '../actions'
import UserForm from './UserForm'
import { hasMessage } from 'lib/fxUI'
import { defaultState } from '../defaultState'


class EditUser extends Component {
  componentDidMount() {
    const id = this.getUserId()
    this.props.loadUser(id)
  }

  componentDidUpdate(prevProps) {
    const search = this.props.location.pathname
    if(search === prevProps.location.pathname) return

    const id = this.getUserId()
    this.props.loadUserFromQArray(id)
  }

  getUserId = () => this.props.match.params.id

  editUser = async user => {
    this.props.editUser(this.getUserId(), user)
  }

  render() {
    const { user, successSave, errormsg } = this.props
    let data = []

    if(typeof user !== 'undefined') {
      data = typeof user[0] !== 'undefined' ? user[0] : defaultState
    } 
   
    if(data.length === 0) {
      return <div className="lmask" id="lmask" />
    }

    return (
      <Fragment>
      { successSave !== null && hasMessage(successSave, errormsg) }
        <UserForm
            onSubmit={this.editUser}
            formAction="แก้ไข"
            user={data}
            successSave={successSave}
            errormsg={errormsg}
        />
         </Fragment>
    )
  }
}

const mapStateToProps = ({ users }) => ({
  user: users.user,
  successSave: users.successSave,
  errormsg: users.errormsg
})

const mapDispatchToProps = {
  loadUser: actions.loadUser.request,
  editUser: actions.editUser.request,
  loadUserFromQArray: actions.loadUserFromQArray
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EditUser)