import React, { Fragment, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { usePromiseTracker } from "react-promise-tracker";
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import LinearProgress from '@material-ui/core/LinearProgress';

import ActionMenu from './ActionMenu'

const useStyles = theme => ({
    root: {
        width: '100%',
        minWidth: 360,
        backgroundColor: theme.palette.background.paper,
        paddingTop: '0px',
        paddingBottom: '0px'
    },
    inline: {
        display: 'inline',
    },
    avatar: {
        width: '30px',
        height: '30px'
    },
    date: {
        textAlign: 'end',
        fontSize: '9px'
    },
    boldText: {
        fontWeight: 'bold'
    },
    girdPrimary: {
        marginBottom: '5px' 
    },
    noti: {
        paddingTop: '0px',
        paddingBottom: '0px'
    },
    progress: {
        width: 'calc(100% - 80px)',
        margin: 'auto',
    },
    loadingMargin: {
        marginTop: '50px', 
        marginBottom: '20px'
    }
});

const getComponent = (type) => {
    let img = ""

    switch(type) {
        case 1 : 
            img = "/img/exclamation-mark.png"
        break;
        case 2 : 
            img = "/img/battery.png"
            break;
        case 3 : 
            img = "/img/stopwatch.png"
            break;
        default:
            img = "/img/bell.png"
            break;
    }

    return img
}


const EmptyList = () => (
    <Fragment>
        <Divider component="li" />
        <ListItem alignItems="flex-start">
            <ListItemText primary="ไม่พบข้อมูล"  style={{ textAlign: 'center' }}/>
        </ListItem>
    </Fragment>
)


const ComponentList = ({ items, classes, onDelete, onUpdate, loadingProgress }) => {
    const { promiseInProgress } = usePromiseTracker();
    const [currentProgressValue, setProgressValue] = useState(0)

    const LoadList = () => (
        <Fragment>
            <Divider component="li" />
            <div className={classes.loadingMargin}>
            <LinearProgress variant="determinate" value={currentProgressValue} className={classes.progress}/>
            <ListItem alignItems="flex-start">
                <ListItemText primary="กำลังโหลดข้อมูล..."  style={{ textAlign: 'center' }}/>
            </ListItem>
            </div>
        </Fragment>
    )

    React.useEffect(() => {
        function progress() {
            setProgressValue(oldCompleted => {
            if (oldCompleted === 100) {
              return 0;
            }
            const diff = Math.random() * 10;
            return Math.min(oldCompleted + diff, 100);
          });
        }
    
        const timer = setInterval(progress, 500);
        return () => {
          clearInterval(timer);
        };
      }, []);

    if (promiseInProgress) return <LoadList /> 

    if (items.length === 0) {
        return <EmptyList />
    }

    return items.map((data, i) => {
        let color = (data.status === 0) ? '#e9f0f7' : ''
        return <Fragment key={i}>
        <Divider component="li" />
        <ListItem alignItems="flex-start" style={{ background: `${color}` }}>
            <ListItemAvatar className={classes.avatar}>
                <Avatar alt="Remy Sharp" src={getComponent(data.type_id)} />
            </ListItemAvatar>
            <ListItemText
                primary={
                <Grid container justify="space-between" className={classes.girdPrimary}>
                    <Grid item xs={6} className={classes.boldText}>{ data.type_name }</Grid>
                    <Grid item xs={5} className={classes.date}>{ data.created_at }</Grid>
                </Grid>
                }
                secondary={
                    <React.Fragment>
                        <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                        >
                            { data.product_name }
                        </Typography>
                        {` — ${data.description}`}
                    </React.Fragment>
                }
            />
            <ListItemSecondaryAction>
                <ActionMenu
                    status={data.status}
                    onDelete={onDelete} 
                    onUpdate={onUpdate} 
                    id={data.id}/>
            </ListItemSecondaryAction>
        </ListItem>
       </Fragment>
    }) 
}

export default withStyles(useStyles)(ComponentList)