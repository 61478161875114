import React from 'react';
import Select from 'react-select'
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
    lookupEditCell: {
      paddingTop: theme.spacing.unit * 0.875,
      paddingRight: theme.spacing.unit,
      paddingLeft: theme.spacing.unit,
    },
    dialog: {
      width: 'calc(100% - 16px)',
    },
    inputRoot: {
      width: '100%',
    },
});

const style = {
  control: styles => ({ 
    ...styles,
    borderBottom: '1px solid rgba(0, 0, 0, 0.54)',
    borderRadius: '0px',
    borderStyle: 'none',
    backgroundColor: 'white',
  })
}


const Component = ( availableColumnValues, value, onValueChange, classes, name, disabled ) => {
  let dis = typeof disabled === 'boolean' ? disabled : availableColumnValues.length > 0 ? false : true
  return       <Select
                  isDisabled={dis}
                  isLoading={availableColumnValues.length > 0 ? false : true}
                  classes={classes}
                  options={availableColumnValues}
                  value={availableColumnValues.filter((item) => parseInt(item.value) === parseInt(value) )}
                  getOptionLabel={({label}) => label}
                  getOptionValue={({value}) => value}
                  styles={style}
                  maxMenuHeight={120}
                  onChange={value => {
                    onValueChange(value.value)
                  }}
                />
}

const LookupEditCellBase = ({
    availableColumnValues, value, onValueChange, classes, withTableCell=true, name, disabled
  }) => {
      return withTableCell ?
        <TableCell className={classes.lookupEditCell}>
           { Component(availableColumnValues, value, onValueChange, classes, name, disabled) } 
        </TableCell> :   Component(availableColumnValues, value, onValueChange, classes, name, disabled) 

  };
export default withStyles(styles)(LookupEditCellBase);