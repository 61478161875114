import React from 'react';
import { Pie } from 'react-chartjs-2';

const formatData = (value, key)  => {
	let data = typeof value !== 'undefined' ? parseFloat(value[key]) : null
	return Number.isNaN(data) || data === 0 ? null : data
}

const KpiPieChart = ({ data }) => {

	const first_tier = formatData(data[0], 'first_tier') + formatData(data[1], 'first_tier') + formatData(data[2], 'first_tier')
	const second_tier = formatData(data[0], 'second_tier') + formatData(data[1], 'second_tier') + formatData(data[2], 'second_tier')
	const third_tier = formatData(data[0], 'third_tier') + formatData(data[1], 'third_tier') + formatData(data[2], 'third_tier')
	const fourth_tier = formatData(data[0], 'fourth_tier') + formatData(data[1], 'fourth_tier') + formatData(data[2], 'fourth_tier')

	const initial = {
		labels: [
			'< 10 นาที',
			'10 - 30 นาที',
			'31 - 60 นาที',
			'> 60 นาที'
		],
		datasets: [{
			data: [first_tier, second_tier, third_tier, fourth_tier],
			backgroundColor: [
				'#82c556',
				'#ffce56',
				'#ffa556',
				'#FF6384',
			],
			hoverBackgroundColor: [
				'#82c556',
				'#ffce56',
				'#ffa556',
				'#FF6384',
			]
		}]
	}

	return <div>
		<h6>KPI (%)</h6>
		<Pie data={initial} />
	</div>
}

export default KpiPieChart