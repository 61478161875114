import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as actions from '../actions'
import StoreForm from './StoreForm'
import { defaultState } from '../defaultState'

class CreateStore extends Component {
  createStore = async data => {
    this.props.createStore(data)
  }

  render() {
    const { successSave, isLoading } = this.props
    return (
      <StoreForm
        onSubmit={this.createStore}
        formAction="สร้างใหม่"
        data={defaultState}
        successSave={successSave}
        isLoading={isLoading}
      />
    )
  }
}

const mapStateToProps = ({ stores }) => ({
  successSave: stores.successSave,
  isLoading: stores.isLoading
})
const mapDispatchToProps = {
  createStore: actions.createStore.request
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateStore)
