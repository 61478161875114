import { createTypes, createAction } from 'lib/action'
 
const LOAD_USERS = createTypes('users', 'list')
const LOAD_USER = createTypes('users', 'show')
const CREATE_USER = createTypes('users', 'create')
const EDIT_USER = createTypes('users', 'edit')
const DELETE_USER = createTypes('users', 'delete')
const FILTER_USER = 'backup'
const SET_USER = 'api/users'
const GET_USER = 'GET_USER'
const FETCH_USERS_FROM_STORE = "FETCH_USERS_FROM_STORE"

const loadUsers = {
  request: term => createAction(LOAD_USERS.REQUEST, { term }),
  success: users => createAction(LOAD_USERS.SUCCESS, { users }),
  failure: () => createAction(LOAD_USERS.FAILURE)
}

const loadUser = {
  request: id => createAction(LOAD_USER.REQUEST, { id }),
  success: user => createAction(LOAD_USER.SUCCESS, { user }),
  failure: () => createAction(LOAD_USER.FAILURE)
}

const createUser = {
  request: user => createAction(CREATE_USER.REQUEST, { user }),
  success: user => createAction(CREATE_USER.SUCCESS, { user }),
  failure: error => createAction(CREATE_USER.FAILURE, { error })
}

const editUser = {
  request: (id, user) => createAction(EDIT_USER.REQUEST, { id, user }),
  success: user => createAction(EDIT_USER.SUCCESS, { user }),
  failure: error => createAction(EDIT_USER.FAILURE, { error })
}

const deleteUser = {
  request: (id) => createAction(DELETE_USER.REQUEST, { id } ),
  success: id => createAction(DELETE_USER.SUCCESS, { id } ),
  failure: () => createAction(DELETE_USER.FAILURE)
}

const filterData = (users, search) => ({
  type: FILTER_USER,
  payload: {
    users,
    search 
  }
})

const loadUserFromQArray = id => ({
  type: GET_USER,
  payload: id
})

const fecthUserFromArray = () => ({
  type : FETCH_USERS_FROM_STORE,
  payload: {}
})


export {
  LOAD_USERS,
  LOAD_USER,
  CREATE_USER,
  EDIT_USER,
  DELETE_USER,
  SET_USER,
  FILTER_USER,
  GET_USER,
  FETCH_USERS_FROM_STORE,
  loadUsers,
  loadUser,
  loadUserFromQArray,
  createUser,
  editUser,
  deleteUser,
  filterData,
  fecthUserFromArray
}
