import React, { Component, Fragment } from 'react';
import GoogleMap from 'google-map-react';

const K_MARGIN_TOP = 30;
const K_MARGIN_RIGHT = 30;
const K_MARGIN_BOTTOM = 30;
const K_MARGIN_LEFT = 30;
const K_HOVER_DISTANCE = 30;

class MapComponent extends Component {

  heatMapComponent = mapInfo => (
    <GoogleMap
        bootstrapURLKeys={{
          key: 'AIzaSyAUOISNjtxH3htrPuWyCQmiMX-Q5eJiIEA',
          language: 'th',
        }}
        center={mapInfo.center}
        zoom={mapInfo.zoom}
        margin={[K_MARGIN_TOP, K_MARGIN_RIGHT, K_MARGIN_BOTTOM, K_MARGIN_LEFT]}
        hoverDistance={K_HOVER_DISTANCE}
        distanceToMouse={this._distanceToMouse}
        heatmapLibrary={true}
        heatmap={{ positions: this.props.heatmaps }}
      >
    </GoogleMap>
  )

  render() {
    const { mapInfo } = this.props

    return (
      <Fragment>
          { this.heatMapComponent(mapInfo) }
      </Fragment>
    );
  }
}



export default MapComponent

MapComponent.defaultProps = {
  heatmaps: [
    {lat: 13.752801, lng: 100.501587},
  ]
}