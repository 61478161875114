
const defaultState = {
  id: '',
  cntComp: 0,
  cnt: 0,
  dlgContent: '',
  isModalOpen: false,
  disableForm: false,
  formLoading: true,
  company_id: '',
  s_branch_id: '',
  d_branch_id: '',
  order_no: '',
  document_type_id: '',
  process_type_id: '',
  ref_no: '',
  movement_date: '',
  description: '',
  branchUrl: 'api/branches',
  currentId: '',
  companies: [],
  bkcompany: [],
  branches: [],
  relations: {
    detail: []
  },
  columns: [
    { name: 'product_id', title: 'สินค้า * ' },
    { name: 'uom_id', title: 'หน่วยนับ * ' },
    { name: 'batch_id', title: 'Batch No.*' },
    { name: 's_warehouse_id', title: 'คลังสินค้าต้นทาง * ' },
    { name: 's_bin_id', title: 'Storage Bin ต้นทาง *' },
    { name: 'd_warehouse_id', title: 'คลังสินค้าปลายทาง * ' },
    { name: 'd_bin_id', title: 'Storage Bin ปลายทาง *' },
    { name: 'order_qty', title: 'จำนวน * ' }
  ],
  
  advSearch : {
    company_id: '',
    s_branch_id: '',
    movement_date: null,
    status: '',
    ref_no: '',
    order_no: ''
  }
}

const pageState = {
  id: '',
  cntComp: 0,
  cnt: 0,
  dlgContent: '',
  isModalOpen: false,
  disableForm: false,
  formLoading: true,
  company_id: '',
  s_branch_id: '',
  d_branch_id: '',
  order_no: '',
  document_type_id: '',
  process_type_id: '',
  ref_no: '',
  movement_date: '',
  description: '',
  branchUrl: 'api/branches',
  currentId: '',
  companies: [],
  bkcompany: [],
  branches: [],
}

const tableColumnExtensions = [
  { columnName: 'product_id', width: 215 },
  { columnName: 'uom_id', width: 105 },
  { columnName: 'batch_id', width: 105 },
  { columnName: 's_warehouse_id', width: 125 },
  { columnName: 's_bin_id', width: 120 },
  { columnName: 'd_warehouse_id', width: 115 },
  { columnName: 'd_bin_id', width: 135 },
  { columnName: 'order_qty', width: 90 }
]

export {
  defaultState,
  pageState,
  tableColumnExtensions
}
