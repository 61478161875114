export const defaultState = {
    active: '1',
    disableForm : false,
    isOpenWeb : true,
    isOpenUser : true,
    formLoading : true,
    id: '',
    name: '',
    product_category_id: '',
    brand_id: '',
    code: '',
    base_uom_id: '',
    shelf_life_days: '365',
    description: '',
    isOpenCompany: true,
    companies:[],
    checkCompanyList:[],
    pricebookItems: [],
    uomItems:[],
    advSearch: {
        company_id: '',
        name: ''
    }
}