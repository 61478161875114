import React from 'react'
import { Route } from 'react-router-dom'

import StockBalanceTable from './StockBalanceTable'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Breadcrumb } from 'lib/widgets'
import { Layoutstyles } from './AddonStyles'

const StockBalanceLayout = ({ classes }) => (
  <div className={classes.root}>
    <Grid container spacing={24}>
      <Grid item xs>
        <Breadcrumb
          menu="จัดการคลังสินค้า"
          submenu="Stock Balance"
          menuLink="/warehouse/stock-balances"
        />
      </Grid>
    </Grid>
    <Grid container spacing={24}>
      <Route
        exact
        path="/warehouse/stock-balances"
        component={StockBalanceTable}
      />
    </Grid>
  </div>
)
export default withStyles(Layoutstyles)(StockBalanceLayout)
