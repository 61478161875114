import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as actions from '../actions'
import ProductForm from './ProductForm'
import { defaultState } from '../defaultState'


class CreateProduct extends Component {
  createProduct = async data => {
    this.props.createProduct(data)
  }

  render() {
    const { successSave, isLoading } = this.props
    return (
      <ProductForm
        onSubmit={this.createProduct}
        formAction="สร้างใหม่"
        successSave={successSave}
        data={defaultState}
        isLoading={isLoading}
      />
    )
  }
}

const mapStateToProps = ({ products }) => ({
  successSave: products.successSave,
  isLoading: products.isLoading
})
const mapDispatchToProps = {
  createProduct: actions.createProduct.request,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProduct)
