import { createTypes, createAction } from 'lib/action'
 
const LOAD_WEBGROUPS = createTypes('webgroups', 'list')
const LOAD_WEBGROUP = createTypes('webgroups', 'show')
const CREATE_WEBGROUP = createTypes('webgroups', 'create')
const EDIT_WEBGROUP = createTypes('webgroups', 'edit')
const DELETE_WEBGROUP = createTypes('webgroups', 'delete')
const FILTER_WEBGROUP = 'backup'
const SET_WEBGROUP = 'api/webgroups'
const GET_WEBGROUP = 'GET_WEBGROUP'
const FETCH_WEBGROUPS_FROM_STORE = "FETCH_WEBGROUPS_FROM_STORE"

const loadWebGroups = {
  request: term => createAction(LOAD_WEBGROUPS.REQUEST, { term }),
  success: webgroups => createAction(LOAD_WEBGROUPS.SUCCESS, { webgroups }),
  failure: () => createAction(LOAD_WEBGROUPS.FAILURE)
}

const loadWebGroup = {
  request: id => createAction(LOAD_WEBGROUP.REQUEST, { id }),
  success: webgroup => createAction(LOAD_WEBGROUP.SUCCESS, { webgroup }),
  failure: () => createAction(LOAD_WEBGROUP.FAILURE)
}

const createWebGroup = {
  request: webgroup => createAction(CREATE_WEBGROUP.REQUEST, { webgroup }),
  success: webgroup => createAction(CREATE_WEBGROUP.SUCCESS, { webgroup }),
  failure: error => createAction(CREATE_WEBGROUP.FAILURE, { error })
}

const editWebGroup = {
  request: (id, webgroup) => createAction(EDIT_WEBGROUP.REQUEST, { id, webgroup }),
  success: webgroup => createAction(EDIT_WEBGROUP.SUCCESS, { webgroup }),
  failure: () => createAction(EDIT_WEBGROUP.FAILURE)
}

const deleteWebGroup = {
  request: (id) => createAction(DELETE_WEBGROUP.REQUEST, { id } ),
  success: id => createAction(DELETE_WEBGROUP.SUCCESS, { id } ),
  failure: () => createAction(DELETE_WEBGROUP.FAILURE)
}

const filterData = (webgroups, search) => ({
  type: FILTER_WEBGROUP,
  payload: {
    webgroups,
    search 
  }
})

const loadWebGroupFromArray = id => ({
  type: GET_WEBGROUP,
  payload: id
})

const fecthWebGroupFromArray = () => ({
  type : FETCH_WEBGROUPS_FROM_STORE,
  payload: {}
})


export {
  LOAD_WEBGROUPS,
  LOAD_WEBGROUP,
  CREATE_WEBGROUP,
  EDIT_WEBGROUP,
  DELETE_WEBGROUP,
  SET_WEBGROUP,
  FILTER_WEBGROUP,
  GET_WEBGROUP,
  FETCH_WEBGROUPS_FROM_STORE,
  loadWebGroups,
  loadWebGroup,
  loadWebGroupFromArray,
  createWebGroup,
  editWebGroup,
  deleteWebGroup,
  filterData,
  fecthWebGroupFromArray
}
