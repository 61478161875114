import {
  LOAD_GRS,
  LOAD_GR,
  CREATE_GR,
  EDIT_GR,
  DELETE_GR,
  CREATE_GR_ITEM,
  EDIT_GR_ITEM,
  DELETE_GR_ITEM,
  DELETEALL_GR_ITEM,
  FILTER_GR,
  GET_GR,
  FETCH_GRS_FROM_GR,
  CHANGE_STATUS_GR
} from './actions'

import { dataFilter, replaceIdxValue, replaceOnlyHeader } from 'lib/helper'

const initialState = {
  isLoading: false,
  successSave: null,
  deleteSuccess: null,
  items: [],
  backupItems: [],
  data: [],
  errorMsg: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_GR:
      return {
        ...state,
        items: dataFilter(action.payload.goodsreceive, action.payload.search),
        backupItems: state.backupItems
      }
    case GET_GR:
      const id = state.backupItems.findIndex(
        data => parseInt(data.id) === parseInt(action.payload)
      )
      return {
        ...state,
        successSave: null,
        data: [state.backupItems[id]]
      }
    case FETCH_GRS_FROM_GR:
      return {
        ...state,
        successSave: null,
        items: state.backupItems,
        isLoading: false
      }
    case CREATE_GR.REQUEST:
    case EDIT_GR.REQUEST:
    case CREATE_GR_ITEM.REQUEST:
    case EDIT_GR_ITEM.REQUEST:
    case DELETE_GR_ITEM.REQUEST:
      return { ...state, items: [], isLoading: true, successSave: null, deleteSuccess: null }
    case LOAD_GRS.REQUEST:
    case LOAD_GR.REQUEST:
    case DELETE_GR.REQUEST:
    case CHANGE_STATUS_GR.REQUEST:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: true,
        successSave: null,
        deleteSuccess: null
      }
    case LOAD_GRS.SUCCESS:
      return {
        ...state,
        items: action.payload.goodsreceives.data,
        backupItems: action.payload.goodsreceives.data,
        isLoading: false,
        meta: action.payload.goodsreceives.meta,
        successSave: null,
        deleteSuccess: null
      }
    case LOAD_GR.SUCCESS:
      return {
        ...state,
        data: [action.payload.goodsreceive],
        isLoading: false,
        successSave: null
      }
    case CREATE_GR.SUCCESS:
      return {
        ...state,
        goodsreceive: [action.payload.goodsreceive],
        items: [action.payload.goodsreceive, ...state.backupItems],
        backupItems: [action.payload.goodsreceive, ...state.backupItems],
        isLoading: false,
        successSave: true
      }
    case EDIT_GR.SUCCESS:
      const idx = state.backupItems.findIndex(
        data => parseInt(data.id) === parseInt(action.payload.goodsreceive.id)
      )
      return {
        ...state,
        items: replaceOnlyHeader(
          state.backupItems,
          idx,
          action.payload.goodsreceive,
          'detail'
        ),
        backupItems: replaceOnlyHeader(
          state.backupItems,
          idx,
          action.payload.goodsreceive,
          'detail'
        ),
        goodsreceive: [action.payload.goodsreceive],
        isLoading: false,
        successSave: true
      }
    case DELETE_GR.SUCCESS:
      const index = state.backupItems.findIndex(
        data => parseInt(data.id) === parseInt(action.payload.id)
      )
      return {
        ...state.backupItems.splice(index, 1),
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: null,
        deleteSuccess: true
      }
    case CREATE_GR_ITEM.SUCCESS:
    const createidx = state.backupItems.findIndex(
      data =>
        parseInt(data.id) === parseInt(action.payload.detail.gr_order_id)
    )
    state.backupItems[createidx].relations.detail = [...state.backupItems[createidx].relations.detail, action.payload.detail]
    return {
      ...state,
      items: state.backupItems,
      backupItems: state.backupItems,
      data: [state.backupItems[createidx]],
      isLoading: false,
      successSave: null
    }
    case EDIT_GR_ITEM.SUCCESS:
    const editidx = state.backupItems.findIndex(
      data =>
        parseInt(data.id) === parseInt(action.payload.detail.gr_order_id)
    )
    const binEditIdx = state.backupItems[editidx].relations.detail.findIndex(
      gi => parseInt(gi.id) === parseInt(action.payload.detail.id)
    )
    state.backupItems[editidx].relations.detail = replaceIdxValue(
      state.backupItems[editidx].relations.detail,
      binEditIdx,
      action.payload.detail
    )
    return {
      ...state,
      items: state.backupItems,
      backupItems: state.backupItems,
      data: [state.backupItems[editidx]],
      isLoading: false,
      successSave: null
    }
    case DELETE_GR_ITEM.SUCCESS:
    const delidx = state.backupItems.findIndex(
      data => parseInt(data.id) === parseInt(action.payload.data.gr_order_id)
    )

    state.backupItems[delidx].relations.detail = action.payload.data.detail

    return {
      ...state,
      items: state.backupItems,
      backupItems: state.backupItems,
      data: [state.backupItems[delidx]],
      isLoading: false,
      successSave: null
    }
    case DELETEALL_GR_ITEM.SUCCESS:
      return {
        data: action.payload.data,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: null
      }
    case CHANGE_STATUS_GR.SUCCESS: {
      const idx = state.backupItems.findIndex(
        data =>
          parseInt(data.id) === parseInt(action.payload.goodsreceive.data[0].id)
      )
      return {
        ...state,
        items: replaceIdxValue(
          state.backupItems,
          idx,
          action.payload.goodsreceive.data[0]
        ),
        backupItems: replaceIdxValue(
          state.backupItems,
          idx,
          action.payload.goodsreceive.data[0]
        ),
        isLoading: false,
        deleteSuccess: null,
        successSave: true,
        errorMsg: ''
      }
    }
    case LOAD_GRS.FAILURE:
    case LOAD_GR.FAILURE:
    case CREATE_GR.FAILURE:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: false,
        errorMsg: action.payload.error
      }
    case CREATE_GR_ITEM.FAILURE:
    case EDIT_GR_ITEM.FAILURE:
    case EDIT_GR.FAILURE:
    case DELETE_GR.FAILURE:
    case DELETE_GR_ITEM.FAILURE:
    case DELETEALL_GR_ITEM.FAILURE:
      return { ...state, items: [], isLoading: false, successSave: false, deleteSuccess: null }
    case CHANGE_STATUS_GR.FAILURE:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: false,
        deleteSuccess: null,
        errorMsg: action.payload.error
      }
    default:
      return state
  }
}
