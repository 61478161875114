import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { headerWithAuth, handleUnAutherize } from 'lib/authFunc'
import * as actions from './actions'

const header = headerWithAuth()

function* loadStockbalances(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.get,
      process.env.REACT_APP_API_URL + `/api/stock-balances${term}`,
      header
    )
    yield put(actions.loadStockbalances.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadStockbalances.failure(error.response))
  }
}

function* loadStockbalance(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.get, process.env.REACT_APP_API_URL + `/api/stock-balances/${id}`, header)
    yield put(actions.loadStockbalance.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadStockbalance.failure(error.response))
  }
}

export default function* watchComments() {
  yield all([
    takeEvery(actions.LOAD_STOCKBALANCES.REQUEST, loadStockbalances),
    takeEvery(actions.LOAD_STOCKBALANCE.REQUEST, loadStockbalance)
  ])
}
