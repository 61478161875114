import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { headerWithAuth, handleUnAutherize } from 'lib/authFunc'
import * as actions from './actions'

const header = headerWithAuth()

function* loadBrands(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.get,
      process.env.REACT_APP_API_URL + `/api/brands${term}`,
      header
    )
    yield put(actions.loadBrands.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadBrands.failure(error.response))
  }
}

function* loadBrand(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.get, process.env.REACT_APP_API_URL + `/api/brands/${id}`, header)
    yield put(actions.loadBrand.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadBrand.failure(error.response))
  }
}

function* createBrand(action) {
  try {
    const { brand } = action.payload
    const res = yield call(axios.post, process.env.REACT_APP_API_URL + '/api/brands', { brand }, header)
    yield put(actions.createBrand.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.createBrand.failure(error.response))
  }
}

function* editBrand(action) {
  try {
    const { brand, id } = action.payload
    const res = yield call(axios.patch, process.env.REACT_APP_API_URL + `/api/brands/${id}`, brand, header)

    yield put(actions.editBrand.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.editBrand.failure(error.response))
  }
}

function* deleteBrand(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.delete, process.env.REACT_APP_API_URL + `/api/brands/${id}`, header)
    yield put(actions.deleteBrand.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.deleteBrand.failure(error.response))
  }
}


export default function* watchComments() {
  yield all([
    takeEvery(actions.LOAD_BRANDS.REQUEST, loadBrands),
    takeEvery(actions.LOAD_BRAND.REQUEST, loadBrand),
    takeEvery(actions.CREATE_BRAND.REQUEST, createBrand),
    takeEvery(actions.EDIT_BRAND.REQUEST, editBrand),
    takeEvery(actions.DELETE_BRAND.REQUEST, deleteBrand)
  ])
}
