import React from 'react'
import { Switch, Route } from 'react-router-dom'

import BinDetailTable from './BinDetailTable'
import BinDetailList from './BinDetailList'
import EditBinDetail from './EditBinDetail'
import CreateBinDetail from './CreateBinDetail'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Breadcrumb } from 'lib/widgets'
import { Layoutstyles } from './AddonStyles'

const BinDetailLayout = ({ classes }) => (
  <div className={classes.root}>
    <Grid container spacing={24}>
      <Grid item xs>
        <Breadcrumb
          menu="ข้อมูลหลัก"
          submenu="รายละเอียด Storage Bin"
          menuLink="/master-data/storagebin-details"
        />
      </Grid>
    </Grid>
    <Grid container spacing={24}>
      <Switch>
        <Route
          exact
          path="/master-data/storagebin-details"
          component={BinDetailTable}
        />
        <Route
          path="/master-data/storagebin-details"
          component={BinDetailList}
        />
      </Switch>
      <Switch>
        <Route
          path="/master-data/storagebin-details/new"
          component={CreateBinDetail}
        />
        <Route
          path="/master-data/storagebin-details/:id"
          component={EditBinDetail}
        />
      </Switch>
    </Grid>
  </div>
)
export default withStyles(Layoutstyles)(BinDetailLayout)
