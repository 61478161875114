const defaultState = {
    id: '',
    active: '1',
    disableForm : false,
    webGroup : [],
    mobileGroup : [],
    branches : [],
    checkAllWeb : false,
    checkAllMobile : false,
    checkAllBranch : false,
    checkWebList : [],
    checkMobileList : [],
    checkBranchList : [],
    checkList: [],
    username: '',
    password: '',
    comfirmpass : '',
    employee_code: '',
    manager_id: null,
    salestype: null,
    name: '',
    surname: '',
    phone: '',
    email: '',
    is_admin: '',
    is_login_web: '',
    is_login_mobile: '',
    branch: [],
    user_group: [],
    mobile_group: [],
    isOpenBranch : true,
    isOpenWeb : true,
    isOpenMobile : true,
    formLoading : true,
    advSearch : {
        employee_code: '',
        username: '',
        name: '',
        surname: ''
    }
}


export {
    defaultState
}