import React, { Fragment } from 'react';
import { numberFormat } from 'lib/helper'

const style = {
    width: '100%',
    marginTop: '23px',
    fontSize: '12px',
    // background: '#e6e6e6',
    paddingLeft: '0px'

}
class CusGroupTable extends React.Component {
  render() {
    const { data } = this.props;
    let sum = 0;
    return (
      <Fragment>
      <table style={style}>
        <thead>
          <tr style={{ background: '#cccccc' }}>
            <th style={{ padding: '5px' }}>ประเภท</th>
            <th style={{ padding: '5px' }} className="number">จำนวน</th>
          </tr>
        </thead>
        <tbody>
          {data.map((val, i) => {
            sum += parseInt(val.amt)
            return <tr key={i}>
              <td style={{ padding: '5px' }}>{val.shopname}</td>
              <td style={{ padding: '5px' }} className="number">{val.amt}</td>
            </tr>
          })}
        </tbody>
        <tfoot>
          <tr style={{ background: '#e8e8e8' }}>
            <th style={{ padding: '5px' }}>รวม</th>
            <th style={{ padding: '5px' }} className="number">{numberFormat(sum, 0)}</th>
          </tr>
        </tfoot>
      </table>
      </Fragment>
    );
  }
}

CusGroupTable.defaultProps = {
  data: []
}

export default CusGroupTable;