import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as actions from '../actions'
import UnitForm from './UnitForm'
import { hasMessage } from 'lib/fxUI'
import { defaultState } from '../defaultState'


class EditUnit extends Component {
  componentDidMount() {
    const id = this.getUnitId()
    this.props.loadUnit(id)
  }

  componentDidUpdate(prevProps) {
    const search = this.props.location.pathname
    if (search === prevProps.location.pathname) return

    const id = this.getUnitId()
    this.props.loadUnitFromArray(id)
  }

  getUnitId = () => this.props.match.params.id

  editUnit = async data => {
    this.props.editUnit(this.getUnitId(), data)
  }

  render() {
    const { unit, successSave, isLoading } = this.props
    let data = []

    if (typeof unit !== 'undefined') {
      data = typeof unit[0] !== 'undefined' ? unit[0] : defaultState
    }

    if (data.length === 0) {
      return <div className="lmask" id="lmask" />
    }

    return (
      <Fragment>
      {successSave !== null && hasMessage(successSave, '')}
      <UnitForm
        onSubmit={this.editUnit}
        formAction="แก้ไข"
        data={data}
        successSave={successSave}
        isLoading={isLoading}
      />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ units }) => ({
  unit: units.unit,
  successSave: units.successSave,
  isLoading: units.isLoading
})

const mapDispatchToProps = {
  loadUnit: actions.loadUnit.request,
  editUnit: actions.editUnit.request,
  loadUnitFromArray: actions.loadUnitFromArray
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
)(EditUnit)
