import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import * as actions from '../actions'
import { withStyles } from '@material-ui/core/styles'
import { MapPaper } from '../../AddonStyles'

import Grid from '@material-ui/core/Grid';
import MapTableData from './MapTableData'
import CustomerMap from './CustomerMap'
import Collapse from '@material-ui/core/Collapse';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PageTitle from './PageTitle'
import QueryTitle from '../../../dashboard/dashboard/components/QueryTitle'
import TableFilter from './map/TableFilter'
import CompareFilter from './CompareFilter'
import CompareTable from './CompareTable'
import TopComponent from './TopComponent'
import PaperSheet from './PaperSheet'
import LineChart from './LineChart'
import { numberFormat, getQuarter } from 'lib/helper'

const title = [
    { title: "ลูกค้าทั้งหมด", total: 'all', pos: 'old', neg: 'new', pos_title: 'เก่า', neg_title: 'ใหม่', color: '#b9f3e9' },
    { title: "มียอดปีนี้", total: 'connect', pos: 'connect_old', neg: 'connect_new', pos_title: 'เก่า', neg_title: 'ใหม่', color: '#ffc8cb' },
    { title: "ไม่มียอดปีนี้", total: 'disconnect', pos: 'disconnect_old', neg: 'disconnect_new', pos_title: 'เก่า', neg_title: 'ใหม่', color: '#ffe3b8' },
    { title: "ลูกค้าใหม่", total: 'new', pos: 'connect_new', neg: 'disconnect_new', pos_title: 'มียอด', neg_title: 'ไม่มียอด', color: '#ffc8a4' },
]

class CustomerApp extends Component {
    state = {
        expanded1: true,
        expanded2: true,
        expanded3: true,
        expanded4: true,
        expanded5: true,
        expanded6: true,
        companyId: 1,
        branchId: 1,
        summaryCond: {
            company_id: 1,
            branch_id: 1,
        },
        compareCond: {
            branch_id: 1,
            company_id: 1,
            user_id: 'all',
            compared: 'quarter',
            [`q${getQuarter()-1}`] : `q${getQuarter()-1}`
        },
    }

    _loadMaps = data => {
        this.props.loadMaps(data)
    }

    _loadPurchase = data => {
        this.setState({ summaryCond: data }, () => this.props.loadPurchasing(data))
    }

    _loadCompared = data => {
        this.setState({ compareCond: data }, () => this.props.loadCompared(data))
    }

    handleExpandClick = name => () => {
        this.setState({ [name]: !this.state[name] })
    }

    handleChangeCompany = companyId => {
        this.setState({ companyId: companyId })
    }

    handleChangeBranch = branchId => {
        this.setState({ branchId: branchId }, this.loadData)
    }

    loadData = () => {
        let branch = { branch_id: this.state.branchId, limit: 3 }
        this.props.loadTopspend(branch)
        this.props.loadNewTopSpend(branch)
        this.props.loadLowerSpend(branch)
        this.props.loadCustomerSummary(this.state.summaryCond)
        //this.props.loadCompared(this.state.compareCond)
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        const { classes, customers, compared } = this.props
        return (
            <Fragment>
                <QueryTitle
                    onSearch={this._loadMaps}
                    onCompChange={this.handleChangeCompany}
                    onBranchChange={this.handleChangeBranch}
                    companyId={this.state.companyId}
                    branchId={this.state.branchId}
                />

                <Grid container spacing={16}>
                    {title.map(val => {
                        return <Grid item xs={12} sm={3} key={val.title}>
                            <PaperSheet
                                title={val.title}
                                color={val.color}
                                total={numberFormat(customers[val.total], 0)}
                                negative={`${val.neg_title}  ${numberFormat(customers[val.neg], 0)}`}
                                positive={`${val.pos_title}  ${numberFormat(customers[val.pos], 0)}`}
                                percent={(customers[val.neg] / customers[val.total]) * 100}
                            />
                        </Grid>
                    })}
                </Grid>

                <Grid container spacing={16}>
                    <Grid item xs={12} sm={4}>
                        <Card className={classes.card}>
                            <PageTitle
                                classes={classes}
                                handleExpandClick={this.handleExpandClick('expanded2')}
                                data={{
                                    alphabet: 'T',
                                    title: 'ลูกค้าที่มียอดสูงสุดปีนี้',
                                    subheader: 'Anual top spenders'
                                }}
                                color="#34a853"
                                expanded={this.state.expanded2}
                            />
                            <Collapse in={this.state.expanded2} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <TopComponent 
                                        {...this.props}
                                        title='ลูกค้าที่มียอดสูงสุดปีนี้'
                                        branchId={this.state.branchId}
                                        data={this.props.topspend}
                                        type="topspend"
                                        color={['#54ad54', '#82c582', '#bfeabf']}
                                         />
                                </CardContent>
                            </Collapse>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card className={classes.card}>
                            <PageTitle
                                classes={classes}
                                handleExpandClick={this.handleExpandClick('expanded3')}
                                data={{
                                    alphabet: 'N',
                                    title: 'ลูกค้าใหม่ปีนี้ที่มียอดสูงสุด',
                                    subheader: 'New customers rank'
                                }}
                                color="#fbbc05"
                                expanded={this.state.expanded3}
                            />
                            <Collapse in={this.state.expanded3} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <TopComponent 
                                        {...this.props}
                                        title='ลูกค้าใหม่ปีนี้ที่มียอดสูงสุด' 
                                        branchId={this.state.branchId}
                                        type="newrank"
                                        data={this.props.newtopspend}
                                        color={['#ffb000', '#ffcd5f', '#ffe6ae']} />
                                </CardContent>
                            </Collapse>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card className={classes.card}>
                            <PageTitle
                                classes={classes}
                                handleExpandClick={this.handleExpandClick('expanded4')}
                                data={{
                                    alphabet: 'T',
                                    title: 'ลูกค้าที่ยอดลดลงมากที่สุด',
                                    subheader: 'Most decreased customers'
                                }}
                                color="#ea4335"
                                expanded={this.state.expanded4}
                            />
                            <Collapse in={this.state.expanded4} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <TopComponent 
                                        {...this.props}
                                        title='ลูกค้าที่ยอดลดลงมากที่สุด' 
                                        branchId={this.state.branchId} 
                                        type="decrease"
                                        data={this.props.lowerspend}
                                        color={['#e83737', '#ea7373', '#fbbebe']} />
                                </CardContent>
                            </Collapse>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container spacing={16}>
                    <Grid item xs={12}>
                        <Card className={classes.card}>
                            <PageTitle
                                classes={classes}
                                handleExpandClick={this.handleExpandClick('expanded6')}
                                data={{
                                    alphabet: 'S',
                                    title: 'เปรียบเทียบยอดขาย',
                                    subheader: 'Sales Comparison'
                                }}
                                color="#4285f4"
                                expanded={this.state.expanded6}
                            />
                            <Collapse in={this.state.expanded6} timeout="auto" unmountOnExit>
                                <CardContent>
                                    {this.props.isLoadComp && <div className="lmask" id="lmask" />}
                                    <CompareFilter
                                        onSearch={this._loadCompared}
                                        companyId={this.state.companyId}
                                        branchId={this.state.branchId} />
                                    <CompareTable
                                        data={compared}
                                        column={this.props.columnCompared}
                                        cond={this.state.compareCond} />
                                </CardContent>
                            </Collapse>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container spacing={16}>
                    <Grid item xs={12}>
                        <Card className={classes.card}>
                            <PageTitle
                                classes={classes}
                                handleExpandClick={this.handleExpandClick('expanded5')}
                                data={{
                                    alphabet: 'D',
                                    title: 'แผนที่การกระจายตัวของลูกค้า',
                                    subheader: 'Customer distribution'
                                }}
                                color="#fbbc05"
                                expanded={this.state.expanded5}
                            />
                            <Collapse in={this.state.expanded5} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <CustomerMap
                                        {...this.props}
                                        companyId={this.state.companyId}
                                        branchId={this.state.branchId} />
                                </CardContent>
                            </Collapse>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container spacing={16}>
                    <Grid item xs={12}>
                        <Card className={classes.card}>
                            <PageTitle
                                classes={classes}
                                handleExpandClick={this.handleExpandClick('expanded6')}
                                data={{
                                    alphabet: 'C',
                                    title: 'สรุปข้อมูลการสั่งซื้อ',
                                    subheader: 'Customer purchase summary'
                                }}
                                color="#ea4335"
                                expanded={this.state.expanded6}
                            />
                            <Collapse in={this.state.expanded6} timeout="auto" unmountOnExit>
                                <CardContent>
                                    {this.props.isLoading && <div className="lmask" id="lmask" />}
                                    <TableFilter
                                        onSearch={this._loadPurchase}
                                        companyId={this.state.companyId}
                                        branchId={this.state.branchId} />
                                    <MapTableData
                                        data={this.props.purchase}
                                        column={this.props.column}
                                        cond={this.state.summaryCond} />
                                </CardContent>
                            </Collapse>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container spacing={16}>
                    <Grid item xs={12}>
                        <Card className={classes.card}>
                            <PageTitle
                                classes={classes}
                                handleExpandClick={this.handleExpandClick('expanded6')}
                                data={{
                                    alphabet: 'C',
                                    title: 'วิเคราะห์การเติบโตของลูกค้า',
                                    subheader: 'Customer growth analysis'
                                }}
                                color="#ea4335"
                                expanded={this.state.expanded6}
                            />
                            <Collapse in={this.state.expanded6} timeout="auto" unmountOnExit>
                                <CardContent>
                                    {this.props.isLoading && <div className="lmask" id="lmask" />}
                                    <LineChart {...this.props.line}
                                        companyId={this.state.companyId}
                                        branchId={this.state.branchId}
                                        onLoad={this.props.loadLine}
                                    />
                                </CardContent>
                            </Collapse>
                        </Card>
                    </Grid>
                </Grid>

            </Fragment>

        );
    }
}

const mapStateToProps = ({ analysis }) => ({
    line: analysis.line,
    data: analysis.dataFiltered,
    purchase: analysis.purchase,
    compared: analysis.compared,
    topspend: analysis.topspend,
    newtopspend: analysis.newtopspend,
    lowerspend: analysis.lowerspend,
    column: analysis.column,
    columnCompared: analysis.columnCompared,
    customers: analysis.customers,
    mapInfo: analysis.mapInfo,
    isLoading: analysis.isLoading,
    isLoadComp: analysis.isLoadComp,
    openBalloonIndex: analysis.openBalloonIndex,
    mode: analysis.mode,
    active: analysis.active
})

const mapDispatchToProps = {
    loadLine: actions.loadCustomerGrowthRate.request,
    loadMaps: actions.loadCustomerAnalysis.request,
    loadCompared: actions.loadCompared.request,
    loadPurchasing: actions.loadPurchasingSummary.request,
    loadTopspend: actions.loadTopspend.request,
    loadNewTopSpend: actions.loadNewTopSpend.request,
    loadLowerSpend: actions.loadLowerSpend.request,
    loadCustomerSummary: actions.loadCustomerSummary.request,
    onBoundsChange: actions.changeBounds,
    onMarkerHover: actions.markerHoverIndexChange,
    onChildClick: actions.showBallon,
    setMapMode: actions.setMapMode,
    setActive: actions.setActive
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(MapPaper)(CustomerApp))

CustomerApp.defaultProps = {
    customers: {
        all: 0,
        new: 0,
        old: 0,
        connect: 0,
        disconnect: 0,
        connect_new: 0,
        disconnect_new: 0,
        connect_old: 0,
        disconnect_old: 0
    },
    company_id: 1,
    branch_id: 1
}
