import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Grid from '@material-ui/core/Grid'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { serializeObj, formatDate } from 'lib/helper'

const useStyles = makeStyles(theme => ({
  list: {
    width: 250,
    margin: 'auto'
  },
  fullList: {
    width: 'auto'
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  button: {
    margin: theme.spacing.unit
  },
  icon: {
    fontSize: '16px'
  },
  grow: {
    flexGrow: 1,
    padding: '7px'
  }
}))

function SearchPanel(props) {
  const classes = useStyles()
  const { toggleDrawer, isOpen, onSubmit, Component, formValue } = props

  const [values, setValues] = React.useState(formValue)

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value })
  }

  const handleSelect = data => (name, value) => {
    setValues({ ...values, [name]: value })
  }

  const handleDateChange = data => (value, name) => {
    setValues({ ...values, [name]: formatDate(value) })
  }

  const clearForm = () => {
    setValues(formValue)
  }

  return (
    <Fragment>
      <SwipeableDrawer
        anchor="right"
        disableDiscovery={true}
        open={isOpen}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
        style={{ overflowX: 'hidden' }}
      >
        <Grid container spacing={24} style={{ width: '250px' }}>
          <Grid item xs={12}>
            <Grid container justify="space-between" 
              style={{ background: '#4e8df6', color: '#ffffff' }}>
              <Grid item xs={9}>
                <Typography
                  color="inherit"
                  className={classes.grow}
                  style={{ fontSize:'16px' }}
                >
                  ค้นหาละเอียด
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => toggleDrawer(false)}
                >
                  <CloseIcon className={classes.icon} />
                </IconButton>
              </Grid>
            </Grid>

            <Component 
                values={values}
                classes={classes}
                handleDateChange={handleDateChange}
                handleChange={handleChange}
                handleSelect={handleSelect}/>

            <Grid container justify="center">
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => clearForm()}
              >
                เคลียร์
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onSubmit(`?`+serializeObj(values))}
                className={classes.button}
              >
                ค้นหา
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </SwipeableDrawer>
    </Fragment>
  )
}

export default SearchPanel


