import React from 'react'
import { Switch, Route } from 'react-router-dom'

import StoreTable from './StoreTable'
import StoreList from './StoreList'
import EditStore from './EditStore'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Breadcrumb } from 'lib/widgets'
import { Layoutstyles } from './AddonStyles'

const StoreLayout = ({ classes }) => (
  <div className={classes.root}>
    <Grid container spacing={24}>
      <Grid item xs>
        <Breadcrumb
          menu="ฝ่ายขาย"
          submenu="ร้านค้า"
          menuLink="/salesforce/customers"
        />
      </Grid>
    </Grid>
    <Grid container spacing={24}>
      <Switch>
        <Route exact path="/salesforce/customers" component={StoreTable} />
        <Route path="/salesforce/customers" component={StoreList} />
      </Switch>
      <Switch>
        <Route path="/salesforce/customers/:id" component={EditStore} />
      </Switch>
    </Grid>
  </div>
)
export default withStyles(Layoutstyles)(StoreLayout)
