import React, { Component, Fragment } from 'react'
import debounce from 'lodash/debounce'

import {
  ActionBtn,
  SearchPanel,
  MenuMobile
} from 'lib/widgets'

import MUIDataTable from 'mui-datatables'
import { MuiThemeProvider } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Tablestyle } from './AddonStyles'

import AdvanceSearch from './AdvanceSearch'
import { COMPANY_ID, MAIN_BRANCH } from 'lib/defaultSystemData'
import { dataFilter, getCurrentMonthIdx, deserializeUrl } from 'lib/helper'

const advSearch = {
  company_id: COMPANY_ID,
  branch_id: MAIN_BRANCH,
  month_id: getCurrentMonthIdx(),
  year_id: new Date().getFullYear()
}

class DetailTable extends Component {
  state = {
    term: '',
    delId: '',
    options: {},
    page: 1,
    sortName: '',
    rowsPerPage: 10,
    count: 100,
    showAdvSearch: false,
    items: []
  }

  componentWillReceiveProps(nextProps) {
    let { data } = nextProps
    this.setState({ items: data })
  }

  delaySearchData = debounce(async value => {
    const { data } = this.props

    if (value === '' || this.state.term.length < value.length) {
      this.setState({ items: dataFilter(data, value) })
      return
    }

    this.setState({ term: value, items: dataFilter(this.state.items, value) })

  }, 750)

  searchwebgroups = event => {
    return this.delaySearchData(event)
  }

  showAdvSearch = state => {
    this.setState({ showAdvSearch: state })
  }

  advSearch = (data) => {
    let params = deserializeUrl(data)
    this.props.loadData(params)
  }

  setAdvSearch = data => {
    this.setState({ term: data.replace(/\?/g, '') })
    data += `&page=${this.state.page}&per_page=${this.state.rowsPerPage}&format_id=${this.props.formatId}`
    this.advSearch(data)
  }

  handleTableChange = (action, tableState) => {
    let term = ''
    const { companyId, branchId, monthId, yearId, formatId } = this.props
    const extendUrl = `company_id=${companyId}&branch_id=${branchId}&month_id=${monthId}&year_id=${yearId}&format_id=${formatId}`


    switch (action) {
      case 'changeRowsPerPage':
        term = `?per_page=${tableState.rowsPerPage}&page=1&${extendUrl}`
        this.setState(
          {
            term: term,
            rowsPerPage: tableState.rowsPerPage,
            page: 1
          },
          this.advSearch(term)
        )
        break
      case 'changePage':
        term = `?page=${tableState.page + 1}&per_page=${tableState.rowsPerPage}&${extendUrl}`
        this.setState(
          {
            term: term,
            rowsPerPage: tableState.rowsPerPage,
            page: tableState.page
          },
          this.advSearch(term)
        )
        break
      default:
        break
    }
  }

  mobileLoadMore = () => {
    let term = ''
    const { companyId, branchId, monthId, yearId, formatId } = this.props
    const extendUrl = `company_id=${companyId}&branch_id=${branchId}&month_id=${monthId}&year_id=${yearId}&format_id=${formatId}`
    term = `?page=${1}&per_page=${this.state.rowsPerPage+10}&${extendUrl}`
    this.setState(
      {
        term: term,
        rowsPerPage: this.state.rowsPerPage+10,
        page: this.state.page
      },
      this.advSearch(term)
    )
  }

  getColumnAndData = () => {
    let col = {}
    let title = {}
    const { columns } = this.props

    switch (this.props.pageName) {
      case 'customer':
      case 'sales':
        col = {
          col1: columns[1].name,
          col2: columns[0].name
        }
        title = {
          title1: () => columns[1].label,
          title2: () => 'ชื่อ',
        }
        break;
      case 'product':
        col = {
          col1: columns[1].name,
          col2: columns[0].name,
          col3: columns[2].name,
          col4: columns[3].name,
          col5: columns[4].name,
          col6: columns[5].name
        }
        title = {
          title1: () => columns[1].label,
          title2: () => columns[0].label,
          title3: () => columns[2].label,
          title4: () => columns[3].label,
          title5: () => columns[4].label,
          title6: () => columns[5].label,
        }
        break;
      default: break;
    }

    return { col: col, title: title }
  }

  renderTable = (isLoading, title, columnsList, items, optionsTable) => {
    if (window.innerWidth <= 500) {
      let info = this.getColumnAndData()
      return <Fragment>
      {isLoading && <div className="lmask" id="lmask" />}
      <MenuMobile
        data={this.props.data}
        columns={info.col}
        title={info.title}
        hideAll={true}
        withData={true}
        hideCreate={true}
        linkUrl={`#`}
      />
      </Fragment>
    }

    if (typeof items === 'object') {
      return <Fragment>
        {isLoading && <div className="lmask" id="lmask" />}
        <MuiThemeProvider theme={Tablestyle()}>
          <MUIDataTable
            title={title}
            data={items}
            columns={columnsList}
            options={optionsTable}
          />
        </MuiThemeProvider>
      </Fragment>
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    var scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    var scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
    var clientHeight = document.documentElement.clientHeight || window.innerHeight;
    var scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

    if (scrolledToBottom && window.innerWidth <= 500 ) {
      this.mobileLoadMore()
    }
  }

  render() {
    const { isLoading, totalPage, title, columns } = this.props
    const { rowsPerPage, page, sortName, options, items } = this.state
    const count = totalPage

    const columnsList = [
      {
        name: '',
        label: '#',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const { page, rowsPerPage } = tableMeta.tableState
            return page * rowsPerPage + tableMeta.rowIndex + 1
          }
        }
      }, ...columns
    ]

    const optionsTable = {
      filter: false,
      print: false,
      download: false,
      viewColumns: false,
      serverSide: true,
      count: count,
      page: page,
      rowsPerPage: rowsPerPage,
      selectableRows: false,
      onTableChange: this.handleTableChange,
      onSearchChange: this.searchwebgroups,
      onColumnSortChange: this.sortingData,
      textLabels: {
        body: {
          noMatch: "Sorry, no matching records found",
          toolTip: "Sort",
        }
      },
      customToolbar: () => {
        return (
          <ActionBtn
            onSearch={this.showAdvSearch}
            displayCreate={false}
          />
        );
      }
    }


    if (sortName !== '') {
      let idx = columns.findIndex(data => data.name === sortName)
      if (idx === -1) return
      columns[idx].options = options
    }

    return (
      <Grid container>
        <Fragment>
          <Grid item xs={12}>
            {this.renderTable(isLoading, title, columnsList, items, optionsTable)}
          </Grid>
        </Fragment>
        <SearchPanel
          toggleDrawer={this.showAdvSearch}
          isOpen={this.state.showAdvSearch}
          onSubmit={this.setAdvSearch}
          Component={AdvanceSearch}
          formValue={advSearch}
        />
      </Grid>
    )
  }
}


export default DetailTable