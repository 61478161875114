import { createTypes, createAction } from 'lib/action'
 
const LOAD_CATEGORIES = createTypes('categories', 'list')
const LOAD_CATEGORY = createTypes('categories', 'show')
const CREATE_CATEGORY = createTypes('categories', 'create')
const EDIT_CATEGORY = createTypes('categories', 'edit')
const DELETE_CATEGORY = createTypes('categories', 'delete')
const FILTER_CATEGORY = 'backup'
const SET_CATEGORY = 'api/categories'
const GET_CATEGORY = 'GET_CATEGORY'
const FETCH_CATEGORIES_FROM_STORE = "FETCH_CATEGORIES_FROM_STORE"

const loadCategories = {
  request: term => createAction(LOAD_CATEGORIES.REQUEST, { term }),
  success: categories => createAction(LOAD_CATEGORIES.SUCCESS, { categories }),
  failure: () => createAction(LOAD_CATEGORIES.FAILURE)
}

const loadCategory = {
  request: id => createAction(LOAD_CATEGORY.REQUEST, { id }),
  success: category => createAction(LOAD_CATEGORY.SUCCESS, { category }),
  failure: () => createAction(LOAD_CATEGORY.FAILURE)
}

const createCategory = {
  request: category => createAction(CREATE_CATEGORY.REQUEST, { category }),
  success: category => createAction(CREATE_CATEGORY.SUCCESS, { category }),
  failure: error => createAction(CREATE_CATEGORY.FAILURE, { error })
}

const editCategory = {
  request: (id, category) => createAction(EDIT_CATEGORY.REQUEST, { id, category }),
  success: category => createAction(EDIT_CATEGORY.SUCCESS, { category }),
  failure: () => createAction(EDIT_CATEGORY.FAILURE)
}

const deleteCategory = {
  request: (id) => createAction(DELETE_CATEGORY.REQUEST, { id } ),
  success: id => createAction(DELETE_CATEGORY.SUCCESS, { id } ),
  failure: () => createAction(DELETE_CATEGORY.FAILURE)
}

const filterData = (categories, search) => ({
  type: FILTER_CATEGORY,
  payload: {
    categories,
    search 
  }
})

const loadCategoryFromArray = id => ({
  type: GET_CATEGORY,
  payload: id
})

const fecthCategoryFromArray = () => ({
  type : FETCH_CATEGORIES_FROM_STORE,
  payload: {}
})


export {
  LOAD_CATEGORIES,
  LOAD_CATEGORY,
  CREATE_CATEGORY,
  EDIT_CATEGORY,
  DELETE_CATEGORY,
  SET_CATEGORY,
  FILTER_CATEGORY,
  GET_CATEGORY,
  FETCH_CATEGORIES_FROM_STORE,
  loadCategories,
  loadCategory,
  loadCategoryFromArray,
  createCategory,
  editCategory,
  deleteCategory,
  filterData,
  fecthCategoryFromArray
}
