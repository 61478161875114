import {   
  LOAD_CUSTOMERS,
  LOAD_CUSTOMER,
  EDIT_CUSTOMER,
  FILTER_CUSTOMER,
  GET_CUSTOMER,
  FETCH_CUSTOMERS_FROM_CUSTOMER
 } from './actions'

 import { dataFilter, replaceIdxValue } from 'lib/helper'

const initialState = {
  isLoading: false,
  successSave: null,
  items: [],
  backupItems:[],
  customer: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_CUSTOMER:
      return { ...state, 
        items: dataFilter(action.payload.customers, action.payload.search),
        backupItems: state.backupItems 
      }
    case GET_CUSTOMER:
      const id = state.backupItems.findIndex(customer => parseInt(customer.id) === parseInt(action.payload) )
      return {
        ...state,
        successSave: null,
        customer: [state.backupItems[id]]
      }
    case FETCH_CUSTOMERS_FROM_CUSTOMER:
      return { ...state,
        successSave: null,
        items: state.backupItems,
        isLoading: false }
    case LOAD_CUSTOMERS.REQUEST:
    case LOAD_CUSTOMER.REQUEST:
    return {
      ...state,
      items: state.backupItems,
      backupItems: state.backupItems,
      isLoading: true,
      successSave: null
    }
    case EDIT_CUSTOMER.REQUEST:
      return { ...state, items: [], isLoading: true }
    case LOAD_CUSTOMERS.SUCCESS:
      return { ...state, 
        items:action.payload.customers.data,
        backupItems: action.payload.customers.data,
        isLoading: false,
        meta: action.payload.customers.meta
      }
    case LOAD_CUSTOMER.SUCCESS:
      return { ...state, customer: [action.payload.customer], isLoading: false, successSave: null}
    case EDIT_CUSTOMER.SUCCESS:
      const idx = state.backupItems.findIndex(customer => parseInt(customer.id) === parseInt(action.payload.customer.id) )
      return { ...state,
        items: replaceIdxValue(state.backupItems, idx, action.payload.customer),
        backupItems: replaceIdxValue(state.backupItems, idx, action.payload.customer),
        customer: [action.payload.customer],
        isLoading: false,
        successSave: true 
      }
    case LOAD_CUSTOMERS.FAILURE:
    case LOAD_CUSTOMER.FAILURE:
    case EDIT_CUSTOMER.FAILURE:
      return { ...state, items: [], isLoading: false, successSave: false }
    default:
      return state
  }
}

