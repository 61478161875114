import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import debounce from 'lodash/debounce'
import * as actions from '../actions'

import {
  ActionBtn,
  ButtonTable,
  ConfirmDlg,
  SearchPanel,
  MenuMobile
} from 'lib/widgets'

import MUIDataTable from 'mui-datatables'
import { MuiThemeProvider } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Tablestyle } from '../../AddonStyles'

import AdvanceSearch from './AdvanceSearch'
import { defaultState } from '../defaultState'
import { hasMessage } from 'lib/fxUI'
import { createCondition } from 'lib/helper'

class UnitTable extends Component {
  state = {
    term: '',
    term_from_advsearch: "",
    search: '',
    delId: '',
    options: {},
    page: 0,
    sortName: '',
    rowsPerPage: 10,
    count: 100,
    isModalOpen: false,
    showAdvSearch: false,
    deleteSuccess: null,
    width: window.innerWidth
  }

  componentDidMount() {
    this.fetchUnits()
  }

  componentWillReceiveProps(nextProps) {
    const { deleteSuccess } = nextProps

    this.setState({ deleteSuccess })
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  }

  fetchUnits = async () => {
    const { term } = this.state
    this.props.loadUnits(term)
  }

  delaySearchData = debounce(async value => {
    this.setState({ search: value })
    const cond = 'strict=false&'+createCondition({ name: '' }, value)
    this.props.loadUnits('?'+cond)
  }, 750)

  searchunits = event => {
    return this.delaySearchData(event)
  }

  advSearch = data => {
    this.props.loadUnits(data)
  }

  setAdvSearch = data => {
    this.setState({ term: data.replace(/\?/g, ''), term_from_advsearch: data.replace(/\?/g, '') })
    this.advSearch(data)
  }

  showAdvSearch = state => {
    this.setState({ showAdvSearch: state })
  }

  navigateToCreateForm = () => {
    this.props.history.push('/master-data/units/new')
  }

  editwebgroupForm = id => {
    this.props.history.push(`/master-data/units/${id}`)
  }

  deleteUnit = id => {
    const { delId } = this.state
    this.props.deleteUnit(delId)
    this.setState({ isModalOpen: false })
  }

  toggleConfirmDlg = (id = '') => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      delId: id,
      deleteSuccess: null
    })
  }

  sortingData = (changedColumn, direction) => {
    let sortby = (changedColumn === 'company_name') ? 'company_id' : changedColumn
    const { page, rowsPerPage, search } = this.state
    const sort = direction === 'ascending' ? 'asc' : 'desc'
    let condStr = 'strict=false&'+createCondition({ name: '' }, search)
    if(this.state.term_from_advsearch !== "") condStr += "&"+this.state.term_from_advsearch
    let term = `?page=${page}&per_page=${rowsPerPage}&sort_by=${sortby}&sort=${sort}&${condStr}`
    this.setState(
      {
        term: term,
        sortName: changedColumn,
        options: { sort: true, sortDirection: sort }
      },
      this.advSearch(term)
    )
  }

  handleTableChange = (action, tableState) => {
    let term = ''
    let condStr = 'strict=false&'+createCondition({ name: '' }, this.state.search)
    if(this.state.term_from_advsearch !== "") condStr += "&"+this.state.term_from_advsearch
    switch (action) {
      case 'changeRowsPerPage':
        term = `?per_page=${tableState.rowsPerPage}&${condStr}`
        this.setState(
          {
            term: term,
            rowsPerPage: tableState.rowsPerPage,
            page: 0
          },
          this.advSearch(term)
        )
        break
      case 'changePage':
        term = `?page=${tableState.page + 1}&per_page=${tableState.rowsPerPage}&${condStr}`
        this.setState(
          {
            term: term,
            page: tableState.page
          },
          this.advSearch(term)
        )
        break
      default:
        break
    }
  }

  resetAlertState = () => {
    this.setState({ deleteSuccess: null }, this.fetchUnits)
  }

  renderTable = (isLoading, columns, units, optionsTable) => {

    const isMobile = this.state.width <= 500

    if(isMobile) {
      return <MenuMobile 
      data={units} 
      columns={{
        col1: 'active_desc',
        col2: 'name',
        col3: 'search_key',
        col4: 'company_name',
      }}
      title={{
        title1: () => 'สถานะ',
        title2: () => 'ชื่อ',
        title3: () =>  'ชื่อย่อ',
        title4: () =>  'บริษัท'
      }}
      url={`api/uoms`}
      onLoad={this.advSearch}
      onDelete={this.toggleConfirmDlg}
      deleteSuccess={this.props.deleteSuccess}
      linkUrl={`/master-data/units`}
      />
    } 

    if(typeof units === 'object')
      return <Fragment>
      { isLoading && <div className="lmask" id="lmask" /> }
      <MuiThemeProvider theme={Tablestyle()}>
        <MUIDataTable
          title={'ข้อมูลหน่วยนับ'}
          data={units}
          columns={columns}
          options={optionsTable}
        />
      </MuiThemeProvider>
      </Fragment>
  }

  render() {
    const { isLoading, units, meta } = this.props
    const { rowsPerPage, page, sortName, options, deleteSuccess } = this.state
    const count = typeof meta === 'undefined' ? 100 : meta.total

    const columns = [
      {
        name: '',
        label: '#',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const { page, rowsPerPage } = tableMeta.tableState
            return page * rowsPerPage + tableMeta.rowIndex + 1
          }
        }
      },
      { name: 'name', label: 'ชื่อ' },
      { name: 'search_key', label: 'ชื่อย่อ' },
      { name: 'company_name', label: 'บริษัท' },
      { name: 'description', label: 'รายละเอียด', options: { sort: false } },
      { name: 'active_desc', label: 'สถานะ', options: { sort: false } },
      {
        name: 'id',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <ButtonTable
                id={value}
                view="uom delete"
                onOpen={this.editwebgroupForm}
                onDelete={() => this.toggleConfirmDlg(value)}
              />
            )
          }
        }
      }
    ]

    const optionsTable = {
      filter: false,
      print: false,
      download: false,
      viewColumns: false,
      filterType: 'dropdown',
      serverSide: true,
      count: count,
      page: page,
      rowsPerPage: rowsPerPage,
      selectableRows: false,
      onTableChange: this.handleTableChange,
      onSearchChange: this.searchunits,
      onColumnSortChange: this.sortingData,
      textLabels: {
        body: {
          noMatch: "Sorry, no matching records found",
          toolTip: "Sort",
        }
      },
      onSearchClose: () => {
        this.setState({ search : '' })
        this.advSearch(this.state.term)
      },
      customToolbar: () => {
        return (
          <ActionBtn
          onCreate={this.navigateToCreateForm}
          onSearch={this.showAdvSearch}
          view="uom edit"
        />
        );
      }
    }

    if (sortName !== '') {
      let idx = columns.findIndex(data => data.name === sortName)
      if (idx === -1) return
      columns[idx].options = options
    }

    return (
      <Fragment>
        {deleteSuccess !== null && hasMessage(deleteSuccess, 'ลบข้อมูลสำเร็จ', this.resetAlertState)}
        <Fragment>
          <Grid item xs={12}>
            { this.renderTable(isLoading, columns, units, optionsTable) }
            <ConfirmDlg
              title="ยืนยันการลบข้อมูล"
              content="คุณต้องการลบข้อมูลนี้ ?"
              onToggle={this.toggleConfirmDlg}
              onConfirm={this.deleteUnit}
              isOpen={this.state.isModalOpen}
            />
          </Grid>
        </Fragment>
        <SearchPanel
          toggleDrawer={this.showAdvSearch}
          isOpen={this.state.showAdvSearch}
          onSubmit={this.setAdvSearch}
          Component={AdvanceSearch}
          formValue={defaultState.advSearch}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ units }) => ({
  isLoading: units.isLoading,
  units: units.items,
  meta: units.meta,
  backupItems: units.backupItems,
  deleteSuccess: units.deleteSuccess
})

const mapDispatchToProps = {
  loadUnits: actions.loadUnits.request,
  deleteUnit: actions.deleteUnit.request,
  filterData: actions.filterData
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UnitTable)
