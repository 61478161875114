import axios from 'axios'
import { headerWithAuth } from 'lib/authFunc'
import { getFirstAndLastDate } from 'lib/helper'

const header = headerWithAuth()

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
  },
  stepper: {
    padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: '100%',
  },
});

const loadOptions = async () => {
  try {
    const companyUrl = axios(process.env.REACT_APP_API_URL + '/api/companies', header)
    const customerGroupUrl = axios(process.env.REACT_APP_API_URL + '/api/master-data/customer-groups', header)
    const categoryUrl = axios(process.env.REACT_APP_API_URL + '/api/master-data/product-categories', header)


    const [company, customergroup, category] = await Promise.all([
      companyUrl,
      customerGroupUrl,
      categoryUrl
    ])

    let options = [{
      companies: company.data.data,
      customergroups: customergroup.data.data,
      categories: category.data.data
    }]

    return options
  } catch (_) {
    console.log('Error')
  }
}

const defaultState = {
  loadopt: true,
  company_id: '',
  branch_id: '',
  customer_group_id: '',
  shop_id: 'all',
  product_type_id: 'all',
  date_start: getFirstAndLastDate(null, true, 'd-m-Y'),
  date_end: getFirstAndLastDate(null, false, 'd-m-Y'),
  companies: [],
  branches: [],
  customergroup: [],
  shop: [],
  category: [],
  bkcustomergroup: [],
  bkcompany: [],
  customer_group: 'all',
  isLoading: false
}


export {
  styles,
  loadOptions,
  defaultState
}