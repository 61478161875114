import React, { PureComponent } from 'react'
import DateFnsUtils from '@date-io/date-fns';
import thLocale from "date-fns/locale/th";
import { DatePicker, MuiPickersUtilsProvider  } from 'material-ui-pickers'

const getMaskFromFormat = format => {
    let mask = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]
    if(format !== 'yyyy-MM-dd')
        mask = [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    return mask
}
class DatePickerWidget extends PureComponent {
    state = { 
        date: ''
    }
    onChangeDate = (date) => {
        this.setState({ date })
        if ( typeof this.props.onValueChange === 'function' ) {
            this.props.onValueChange(date, this.props.name)
        }
    }

    componentDidMount() {
        let { value, format } = this.props

        if(format !== 'yyyy-MM-dd')
          value = value.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")

        let newDate = value !== null ? new Date(value) : value
        this.setState({ date: newDate })
    }

    componentWillReceiveProps(nextProps) {
        let { value, format } = nextProps
        if(format !== 'yyyy-MM-dd')
          value = value.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")

          let newDate = value !== null ? new Date(value) : value
          this.setState({ date: newDate })
    }

    render() {
        const { date } = this.state
        const { format, disableFuture, disablePast, showTodayButton } = this.props
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={thLocale}>
                <div className="picker">
                    <DatePicker 
                        keyboard
                        disableFuture={disableFuture}
                        disablePast={disablePast}
                        showTodayButton={showTodayButton}
                        name={this.props.name} 
                        label={this.props.title}
                        format={this.props.format}
                        value={date}
                        cancelLabel="ยกเลิก"
                        okLabel="ตกลง"
                        todayLabel="วันนี้"
                        fullWidth={this.props.fullWidth} 
                        mask={value =>
                            value ? getMaskFromFormat(format) : []
                        }
                        onChange={this.onChangeDate}
                        disabled={this.props.disabled}/>
                </div>
            </MuiPickersUtilsProvider >
        );
    }
}

export default DatePickerWidget;

DatePickerWidget.defaultProps = {
    disabled: false,
    format: "yyyy-MM-dd",
    value: '',
    fullWidth: true,
    disableFuture: false,
    disablePast: false,
    showTodayButton: true
}