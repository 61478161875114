import {
  LOAD_CATEGORIES,
  LOAD_CATEGORY,
  CREATE_CATEGORY,
  EDIT_CATEGORY,
  DELETE_CATEGORY,
  FILTER_CATEGORY,
  GET_CATEGORY,
  FETCH_CATEGORIES_FROM_STORE
} from './actions'

import { dataFilter, replaceIdxValue } from 'lib/helper'

const initialState = {
  isLoading: false,
  successSave: null,
  items: [],
  backupItems: [],
  category: [],
  deleteSuccess: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_CATEGORY:
      return {
        ...state,
        items: dataFilter(action.payload.categories, action.payload.search),
        backupItems: state.backupItems
      }
    case GET_CATEGORY:
      const id = state.backupItems.findIndex(category => parseInt(category.id) === parseInt(action.payload))
      return {
        ...state,
        successSave: null,
        category: [state.backupItems[id]]
      }
    case FETCH_CATEGORIES_FROM_STORE:
      return {
        ...state,
        successSave: null,
        items: state.backupItems,
        isLoading: false
      }
    case LOAD_CATEGORIES.REQUEST:
    case LOAD_CATEGORY.REQUEST:
    case DELETE_CATEGORY.REQUEST:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: true,
        successSave: null,
        deleteSuccess: null
      }
    case CREATE_CATEGORY.REQUEST:
    case EDIT_CATEGORY.REQUEST:
      return { ...state, items: [], isLoading: true }
    case LOAD_CATEGORIES.SUCCESS:
      return {
        ...state,
        items: action.payload.categories.data,
        backupItems: action.payload.categories.data,
        isLoading: false,
        meta: action.payload.categories.meta
      }
    case LOAD_CATEGORY.SUCCESS:
      return { ...state, category: [action.payload.category], isLoading: false, successSave: null }
    case CREATE_CATEGORY.SUCCESS:
      return {
        ...state,
        items: [action.payload.category, ...state.backupItems],
        backupItems: [action.payload.category, ...state.backupItems],
        isLoading: false,
        successSave: true
      }
    case EDIT_CATEGORY.SUCCESS:
      const idx = state.backupItems.findIndex(category => parseInt(category.id) === parseInt(action.payload.category.id))
      return {
        ...state,
        items: replaceIdxValue(state.backupItems, idx, action.payload.category),
        backupItems: replaceIdxValue(state.backupItems, idx, action.payload.category),
        category: [action.payload.category],
        isLoading: false,
        successSave: true
      }
    case DELETE_CATEGORY.SUCCESS:
      const index = state.backupItems.findIndex(category => parseInt(category.id) === parseInt(action.payload.id))
      return {
        ...state.backupItems.splice(index, 1),
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: null,
        deleteSuccess: true
      }
    case LOAD_CATEGORIES.FAILURE:
    case LOAD_CATEGORY.FAILURE:
    case CREATE_CATEGORY.FAILURE:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: false
      }
    case EDIT_CATEGORY.FAILURE:
    case DELETE_CATEGORY.FAILURE:
      return { ...state, items: [], isLoading: false, successSave: false }
    default:
      return state
  }
}

