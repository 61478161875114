import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as actions from '../actions'
import SalesOrderForm from './SalesOrderForm'
import { defaultState } from '../defaultState'
import { hasMessage } from 'lib/fxUI'

class EditSalesOrder extends Component {
  componentDidMount() {
    const id = this.getSalesOrderId()
    this.props.loadSalesOrder(id)
  }

  componentDidUpdate(prevProps) {
    const search = this.props.location.pathname
    if (search === prevProps.location.pathname) return

    const id = this.getSalesOrderId()
    this.props.loadSalesOrderFromArray(id)
  }

  getSalesOrderId = () => this.props.match.params.id

  cancelSalesOrder = async data => {
    this.props.cancelSalesOrder(this.getSalesOrderId(), data)
  }

  acceptPayment = async data => {
    this.props.acceptPayment(data)
  }

  render() {
    const { salesorder, cancelSuccess, acceptSuccess, isLoading } = this.props
    let data = []

    if (typeof salesorder !== 'undefined') {
      data = typeof salesorder[0] !== 'undefined' ? salesorder[0] : defaultState
    }

    if (data.length === 0) {
      return <div className="lmask" id="lmask" />
    }

    let acceptMsg = acceptSuccess ? 'รับเงินสำเร็จ' : 'บันทึกรับเงินไม่สำเร็จ'
    let cancelMsg = cancelSuccess ? 'ยกเลิก SO สำเร็จ' : 'ยกเลิก SOไม่สำเร็จ'

    return (
      <Fragment>
        { acceptSuccess !== null && hasMessage(true, acceptMsg) }
        { cancelSuccess !== null && hasMessage(cancelSuccess, cancelMsg) }
        <SalesOrderForm
          formAction="แก้ไข"
          data={data}
          acceptPayment={this.acceptPayment}
          cancelSalesOrder={this.cancelSalesOrder}
          isLoading={isLoading}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ salesorders }) => ({
  salesorder: salesorders.salesorder,
  isLoading: salesorders.isLoading,
  cancelSuccess: salesorders.cancelSuccess,
  acceptSuccess: salesorders.acceptSuccess
})

const mapDispatchToProps = {
  loadSalesOrder: actions.loadSalesOrder.request,
  acceptPayment: actions.acceptPayment.request,
  cancelSalesOrder: actions.cancelSalesOrder.request,
  loadSalesOrderFromArray: actions.loadSalesOrderFromArray
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
)(EditSalesOrder)
