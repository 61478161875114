import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as actions from '../actions'
import StoreForm from './StoreForm'

import { defaultState } from '../defaultState'
import { hasMessage } from 'lib/fxUI'

class EditStore extends Component {
  componentDidMount() {
    const id = this.getStoreId()
    this.props.loadStore(id)
  }

  componentDidUpdate(prevProps) {
    const search = this.props.location.pathname
    if (search === prevProps.location.pathname) return

    const id = this.getStoreId()
    this.props.loadStoreFromArray(id)
  }

  getStoreId = () => this.props.match.params.id

  editStore = async data => {
    this.props.editStore(this.getStoreId(), data)
  }

  createStorageBin = async data => {
    this.props.createStorageBin(data)
  }

  editStorageBin = async (id, data) => {
    this.props.editStorageBin(id, data)
  }

  deleteStorageBin = async (id, data) => {
    this.props.deleteStorageBin(id, data)
  }

  render() {
    const { store, successSave, isLoading } = this.props
    let data = []

    if(typeof store !== 'undefined') {
      data = typeof store[0] !== 'undefined' ? store[0] : defaultState
    } 

    if(data.length === 0) {
      return <div className="lmask" id="lmask" />
    }
    
    return (
      <Fragment>
        {successSave !== null && hasMessage(successSave, '')}
        <StoreForm
          onSubmit={this.editStore}
          onCreateItem={this.createStorageBin}
          onEditItem={this.editStorageBin}
          onDeleteItem={this.deleteStorageBin}
          formAction="แก้ไข"
          isLoading={isLoading}
          data={data}
          successSave={successSave}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ stores }) => ({
  store: stores.store,
  successSave: stores.successSave,
  isLoading: stores.isLoading,
})

const mapDispatchToProps = {
  loadStore: actions.loadStore.request,
  editStore: actions.editStore.request,
  createStorageBin: actions.createStorageBin.request,
  editStorageBin: actions.editStorageBin.request,
  deleteStorageBin: actions.deleteStorageBin.request,
  loadStoreFromArray: actions.loadStoreFromArray
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
)(EditStore)
