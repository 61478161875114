import React from 'react';
import { numberFormat } from 'lib/helper'

const WebComponent = ({ data }) => {
  let lineNo = 1
  let sumTotal = 0, sumNet = 0, sumDiscount = 0, sumQty = 0

  return <div className="col-md-12">
    <div className="panel panel-default">
      <div className="panel-heading">
        <h6 className="panel-title">
          <strong>รายการขาย</strong>
        </h6>
      </div>
      <div className="panel-body">
        <div className="table-responsive">
          <table className="table table-condensed">
            <thead>
              <tr>
                <td>
                  <strong>#</strong>
                </td>
                <td className="text-center">
                  <strong>รายการสินค้า</strong>
                </td>
                <td className="text-center">
                  <strong>จำนวนสินค้า</strong>
                </td>
                <td className="text-right">
                  <strong>ราคา/หน่วย</strong>
                </td>
                <td className="text-right">
                  <strong>ราคารวม</strong>
                </td>
                <td className="text-right">
                  <strong>ส่วนลด</strong>
                </td>
                <td className="text-right">
                  <strong>แถม</strong>
                </td>
                <td className="text-right">
                  <strong>สุทธิ</strong>
                </td>
              </tr>
            </thead>
            <tbody>
              {typeof data.relations !== 'undefined' && data.relations.detail.map((item, i) => {

                sumTotal += parseFloat(item.total_price)
                sumNet += parseFloat(item.net_price)
                sumDiscount += parseFloat(item.discount)
                sumQty += parseFloat(item.qty)

                return <tr key={i}>
                  <td>{lineNo++}</td>
                  <td>{item.product_name}</td>
                  <td className="text-right">{item.qty}</td>
                  <td className="text-right">{numberFormat(item.price)}</td>
                  <td className="text-right">{numberFormat(item.total_price)}</td>
                  <td className="text-right">{numberFormat(item.discount)}</td>
                  <td className="text-right">{item.free_qty}</td>
                  <td className="text-right">{numberFormat(item.net_price)}</td>
                </tr>
              })}
              <tr>
                <td className="thick-line" colSpan="4">
                  <strong>สินค้าทั้งหมด {sumQty} รายการ </strong>
                </td>
                <td className="thick-line" />
                <td className="thick-line text-center" colSpan="2">
                  <strong>มูลค่าสินค้า</strong>
                </td>
                <td className="thick-line text-right">{numberFormat(sumTotal)}</td>
              </tr>
              <tr>
                <td className="no-line" colSpan="5" />
                <td className="no-line text-center" colSpan="2">
                  <strong>ส่วนลด</strong>
                </td>
                <td className="no-line text-right">{numberFormat(sumDiscount)}</td>
              </tr>
              <tr>
                <td className="no-line" colSpan="5" />
                <td className="no-line text-center" colSpan="2">
                  <strong>ยอดสุทธิ</strong>
                </td>
                <td className="no-line text-right">{numberFormat(sumNet)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
}

const style = {
  width: '100%',
  borderBottom: '1px solid #d4d4d4',
  marginBottom: '10px',
  marginTop: '10px'
}

const tableBorderNone = {
  width: '100%',
  borderBottom: '0px',
  marginBottom: '10px',
  marginTop: '10px'
}

const padding = {
  paddingTop: '3px',
  paddingBottom: '3px'
}

const sumText = {
  textDecorationLine: 'underline',
  textDecorationStyle: 'double',
  fontWeight: '700'
}

const MobileComponent = ({ data }) => {
  let lineNo = 1
  let sumTotal = 0, sumNet = 0, sumDiscount = 0, sumQty = 0

  return <div className="col-md-12">
    <hr />
    <div className="panel panel-default" style={{ marginTop: '25px', marginBottom: '25px' }}>
      <div className="panel-heading">
        <h6 className="panel-title">
          <strong>รายการขาย</strong>
        </h6>
      </div>
      <div className="panel-body">
        {typeof data.relations !== 'undefined' && data.relations.detail.map((item, i) => {

          sumTotal += parseFloat(item.total_price)
          sumNet += parseFloat(item.net_price)
          sumDiscount += parseFloat(item.discount)
          sumQty += parseFloat(item.qty)
          return <table key={i} style={style}>
            <tbody>
                <tr>
                  <td colSpan={3} style={padding}>
                    {lineNo++}&nbsp;&nbsp;{item.product_name}
                  </td>
                </tr>
                <tr>
                  <td style={padding}>ราคา: {numberFormat(item.price)}</td>
                  <td style={padding}>จำนวน: {item.qty}</td>
                  <td style={padding} className="text-right">รวม: {numberFormat(item.total_price)}</td>
                </tr>
                <tr>
                  <td style={padding}>ส่วนลด: {numberFormat(item.discount)}</td>
                  <td style={padding}>แถม: {item.free_qty}</td>
                  <td style={padding} className="text-right"><b>สุทธิ: {numberFormat(item.net_price)}</b></td>
                </tr>
            </tbody>
          </table>
        })}
          <table style={tableBorderNone}>
            <tbody>
              <tr>
                <td style={padding}>
                  <strong>รวม {sumQty} รายการ </strong>
                </td>
                <td className="no-line" colSpan="5"/>
                <td style={padding} className="text-center">
                  <strong>มูลค่า</strong>
                </td>
                <td style={padding} className="text-right">{numberFormat(sumTotal)}</td>
              </tr>
              <tr>
                <td style={padding} colSpan="5" />
                <td style={padding} className="text-center" colSpan="2">
                  <strong>ส่วนลด</strong>
                </td>
                <td style={padding} className="text-right">{numberFormat(sumDiscount)}</td>
              </tr>
              <tr>
                <td style={padding} colSpan="5" />
                <td style={padding} className="text-center" colSpan="2">
                  <strong>ยอดสุทธิ</strong>
                </td>
                <td style={padding} className="text-right">
                  <span style={sumText}>{numberFormat(sumNet)}</span>
                </td>
              </tr>
            </tbody>
          </table>
      </div>
    </div>
    <hr />
  </div>
}




export default ({ data }) => {
  return window.innerWidth <= 500 ?
    <MobileComponent data={data} /> :
    <WebComponent data={data} />
}