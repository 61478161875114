import React, { Fragment } from 'react'
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom'

import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Home from '@material-ui/icons/Home';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider';

import { Categories } from './MenuList'
import { MenuStyle } from './AddonStyle'

const theme = createMuiTheme({
  overrides: {
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: '#26282e',
          color: '#ffffff'
        },
        '&:focus': {
          backgroundColor: '#26282e',
          color: '#ffffff'
        },
      }
    },
  },
  typography: { useNextVariants: true }
})

export default () => {
  const classes = MenuStyle();
  const [openSF, setOpenSF] = React.useState(false);
  const [openWH, setOpenWH] = React.useState(false);
  const [openDB, setOpenDB] = React.useState(false);
  const [openRP, setOpenRP] = React.useState(false);
  const [openMT, setOpenMT] = React.useState(false);
  const [selected, setSelected] = React.useState('')

  function handleClickSF() {
    setOpenSF(!openSF);
  }

  function handleClickWH() {
    setOpenWH(!openWH);
  }

  function handleClickDB() {
    setOpenDB(!openDB);
  }

  function handleClickRP() {
    setOpenRP(!openRP);
  }

  function handleClickMT() {
    setOpenMT(!openMT);
  }

  
  const handleSelect = name => value => {
    setSelected(name)
  }

  function checkIsOpen(name) {
    switch (name) {
      case 'openSF': return openSF ? <ExpandLess /> : <ExpandMore />;
      case 'openWH': return openWH ? <ExpandLess /> : <ExpandMore />;
      case 'openDB': return openDB ? <ExpandLess /> : <ExpandMore />;
      case 'openRP': return openRP ? <ExpandLess /> : <ExpandMore />;
      case 'openMT': return openMT ? <ExpandLess /> : <ExpandMore />;
      default: break;
    }
  }

  function checkIsCollaspe(name) {
    switch (name) {
      case 'openSF': return openSF;
      case 'openWH': return openWH;
      case 'openDB': return openDB;
      case 'openRP': return openRP;
      case 'openMT': return openMT;
      default: break;
    }
  }

  return (

    <MuiThemeProvider theme={theme}>
      <MenuItem button onClick={handleSelect('home')} selected={selected === 'home'}>
        <ListItemIcon style={{ color: '#9db0bf' }}>
          <Home />
        </ListItemIcon>
        <Link component={RouterLink} to="/home">
          <ListItemText primary={<span style={{ color: '#9db0bf' }}>หน้าแรก</span>} style={{ padding: '0 16px', color: '#9db0bf' }}/>
        </Link>
      </MenuItem>
      {Categories(localStorage.getItem('user')).map((data, i) => {
        return <List key={i} component="nav" className={classes.root} >
          <MenuItem style={{ color: '#9db0bf' }} button onClick={() => {
            switch (data.open) {
              case 'openSF': return handleClickSF();
              case 'openWH': return handleClickWH();
              case 'openDB': return handleClickDB();
              case 'openRP': return handleClickRP();
              case 'openMT': return handleClickMT();
              default: break;
            }
          }
          }>
            <ListItemIcon style={{ color: '#9db0bf' }}> 
              {data.icon}
            </ListItemIcon>
            <ListItemText  inset primary={<span style={{ color: '#9db0bf' }}>{data.title}</span>} />
            {checkIsOpen(data.open)}
          </MenuItem>
          <Collapse in={checkIsCollaspe(data.open)} timeout="auto" unmountOnExit>
            <List component="nav" disablePadding>
              {data.menu.map((item, idx) => {
                return <Fragment key={idx}>
                <MenuItem  button className={classes.nested} 
                  onClick={handleSelect(item.title)} selected={selected === item.title}>
                  <Link component={RouterLink} to={`${item.url}`}>
                    <ListItemText inset primary={<span style={{ color: '#9db0bf' }}>{item.title}</span>} />
                  </Link>
                </MenuItem>
                 { item.title === 'ผู้ใช้งาน' && <Divider /> }
                 { item.title === 'การจัดการ Batch' && <Divider /> }
                 </Fragment>
              })}
            </List>
          </Collapse>
        </List>
      })}
    </MuiThemeProvider>
  );
}

