import { createTypes, createAction } from 'lib/action'
 
const LOAD_GIS = createTypes('goodsissues', 'list')
const LOAD_GI = createTypes('goodsissue', 'show')

const CREATE_GI = createTypes('goodsissue', 'create')
const EDIT_GI = createTypes('goodsissue', 'edit')
const DELETE_GI = createTypes('goodsissue', 'delete')

const CHANGE_STATUS_GI = createTypes('goodsissue', 'change_status')

const CREATE_GI_ITEM = createTypes('goodsissuedetail', 'create')
const EDIT_GI_ITEM = createTypes('goodsissuedetail', 'edit')
const DELETE_GI_ITEM = createTypes('goodsissuedetail', 'delete')
const DELETEALL_GI_ITEM = createTypes('goodsissuedetail', 'deleteall')

const FILTER_GI = 'backup'
const SET_GI = 'api/data'
const GET_GI = 'GET_GI'
const FETCH_GIS_FROM_GI = "FETCH_GIS_FROM_GI"

const loadGoodsIssues = {
  request: term => createAction(LOAD_GIS.REQUEST, { term }),
  success: goodsissues => createAction(LOAD_GIS.SUCCESS, { goodsissues }),
  failure: () => createAction(LOAD_GIS.FAILURE)
}

const loadGoodsIssue = {
  request: id => createAction(LOAD_GI.REQUEST, { id }),
  success: goodsissue => createAction(LOAD_GI.SUCCESS, { goodsissue }),
  failure: () => createAction(LOAD_GI.FAILURE)
}

const createGoodsIssue = {
  request: goodsissue => createAction(CREATE_GI.REQUEST, { goodsissue }),
  success: goodsissue => createAction(CREATE_GI.SUCCESS, { goodsissue }),
  failure: error => createAction(CREATE_GI.FAILURE, { error })
}

const editGoodsIssue = {
  request: (id, goodsissue) => createAction(EDIT_GI.REQUEST, { id, goodsissue }),
  success: goodsissue => createAction(EDIT_GI.SUCCESS, { goodsissue }),
  failure: () => createAction(EDIT_GI.FAILURE)
}

const deleteGoodsIssue = {
  request: id => createAction(DELETE_GI.REQUEST, { id } ),
  success: id => createAction(DELETE_GI.SUCCESS, { id } ),
  failure: () => createAction(DELETE_GI.FAILURE)
}

const changeStatusGI = {
  request: (id, status) => createAction(CHANGE_STATUS_GI.REQUEST, { id, status }),
  success: goodsissue => createAction(CHANGE_STATUS_GI.SUCCESS, { goodsissue }),
  failure: error => createAction(CHANGE_STATUS_GI.FAILURE, { error })
}

const createDocItem = {
  request: detail => createAction(CREATE_GI_ITEM.REQUEST, { detail } ),
  success: detail => createAction(CREATE_GI_ITEM.SUCCESS, { detail } ),
  failure: () => createAction(CREATE_GI_ITEM.FAILURE)
}

const editDocItem = {
  request: (id, detail) => createAction(EDIT_GI_ITEM.REQUEST, { id, detail } ),
  success: detail => createAction(EDIT_GI_ITEM.SUCCESS, { detail } ),
  failure: () => createAction(EDIT_GI_ITEM.FAILURE)
}

const deleteDocItem = {
  request: id  => createAction(DELETE_GI_ITEM.REQUEST, { id } ),
  success: data => createAction(DELETE_GI_ITEM.SUCCESS, { data } ),
  failure: () => createAction(DELETE_GI_ITEM.FAILURE)
}

const deleteAllDocItem = {
  request: id => createAction(DELETEALL_GI_ITEM.REQUEST, { id } ),
  success: id => createAction(DELETEALL_GI_ITEM.SUCCESS, { id } ),
  failure: () => createAction(DELETEALL_GI_ITEM.FAILURE)
}


const filterData = (goodsissue, search) => ({
  type: FILTER_GI,
  payload: {
    goodsissue,
    search 
  }
})

const loadGoodsIssueFromArray = id => ({
  type: GET_GI,
  payload: id
})

const fecthGoodsIssueFromArray = () => ({
  type : FETCH_GIS_FROM_GI,
  payload: {}
})


export {
  LOAD_GIS,
  LOAD_GI,
  CREATE_GI,
  EDIT_GI,
  DELETE_GI,
  CREATE_GI_ITEM,
  EDIT_GI_ITEM,
  DELETE_GI_ITEM,
  DELETEALL_GI_ITEM,
  SET_GI,
  FILTER_GI,
  GET_GI,
  FETCH_GIS_FROM_GI,
  CHANGE_STATUS_GI,
  loadGoodsIssues,
  loadGoodsIssue,
  loadGoodsIssueFromArray,
  createGoodsIssue,
  editGoodsIssue,
  deleteGoodsIssue,
  filterData,
  fecthGoodsIssueFromArray,
  createDocItem,
  editDocItem,
  deleteDocItem,
  deleteAllDocItem,
  changeStatusGI
}
