import { combineReducers } from 'redux'

import logins from 'modules/home/login/reducer' 

import users from 'modules/masterdata/user/reducer'
import webgroups from 'modules/masterdata/web/reducer'
import mobilegroups from 'modules/masterdata/mobile/reducer'
import companies from 'modules/masterdata/company/reducer'
import branches from 'modules/masterdata/branch/reducer'
import brands from 'modules/masterdata/brand/reducer'
import categories from 'modules/masterdata/category/reducer'
import units from 'modules/masterdata/unit/reducer'
import stores from 'modules/masterdata/store/reducer'
import bindetails from 'modules/masterdata/bindetail/reducer'
import products from 'modules/masterdata/product/reducer'
import batches from 'modules/masterdata/batch/reducer'

import goodsreceives from 'modules/warehouse/goodsreceive/reducer'
import goodsissues from 'modules/warehouse/goodsissue/reducer'
import goodstransfers from 'modules/warehouse/goodstransfer/reducer'
import stockbalances from 'modules/warehouse/stockbalance/reducer'
import physicalcounts from 'modules/warehouse/physicalcount/reducer'

import customers from 'modules/salesforce/store/reducer'
import salesorders from 'modules/salesforce/salesorder/reducer'
import analysis from 'modules/salesforce/customer/reducer'

import maps from 'modules/dashboard/dashboard/reducer'

export default combineReducers({
  users,
  webgroups,
  mobilegroups,
  companies,
  branches,
  brands,
  categories,
  units,
  stores,
  bindetails,
  products,
  batches,
  goodsreceives,
  goodsissues,
  goodstransfers,
  stockbalances,
  customers,
  salesorders,
  logins,
  physicalcounts,
  maps,
  analysis
})
