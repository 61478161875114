import { LOGIN } from './actions'


const initialState = {
  loginSuccess: null,
  isLoading: null,
  auth: null,
  message: '',
  error: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN.REQUEST:
      return { ...state, isLoading: true, loginSuccess: null }
    case LOGIN.SUCCESS:
      return { ...state,
        auth: action.payload.userslogin,
        loginSuccess: true,
        isLoading: false
      }
    case LOGIN.FAILURE:
      const { message, error } = action.payload.err
      return { ...state, loginSuccess: false, message, error, isLoading: false }
    default:
      return state
  }
}

