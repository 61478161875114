import {
  LOAD_PCS,
  LOAD_PC,
  CREATE_PC,
  EDIT_PC,
  DELETE_PC,
  FILTER_PC,
  GET_PC,
  FETCH_PCS_FROM_PC,
  CHANGE_STATUS_PC
} from './actions'

import { dataFilter, replaceIdxValue } from 'lib/helper'

const initialState = {
  isLoading: false,
  successSave: null,
  deleteSuccess: null,
  items: [],
  backupItems: [],
  data: [],
  errorMsg: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_PC:
      return {
        ...state,
        items: dataFilter(action.payload.physicalcount, action.payload.search),
        backupItems: state.backupItems
      }
    case GET_PC:
      const id = state.backupItems.findIndex(
        data => parseInt(data.id) === parseInt(action.payload)
      )
      return {
        ...state,
        successSave: null,
        data: [state.backupItems[id]]
      }
    case FETCH_PCS_FROM_PC:
      return {
        ...state,
        successSave: null,
        items: state.backupItems,
        isLoading: false
      }
    case CREATE_PC.REQUEST:
    case EDIT_PC.REQUEST:
    case LOAD_PCS.REQUEST:
    case LOAD_PC.REQUEST:
    case DELETE_PC.REQUEST:
        return { ...state, items: [], isLoading: true, successSave: null, deleteSuccess: null }
    case CHANGE_STATUS_PC.REQUEST:
          return {
            ...state,
            items: state.backupItems,
            backupItems: state.backupItems,
            isLoading: true,
            successSave: null,
            deleteSuccess: null
          }
    case LOAD_PCS.SUCCESS:
      return {
        ...state,
        items: action.payload.physicalcounts.data,
        backupItems: action.payload.physicalcounts.data,
        isLoading: false,
        meta: action.payload.physicalcounts.meta,
        successSave: null,
        deleteSuccess: null
      }
    case LOAD_PC.SUCCESS:
      return {
        ...state,
        data: [action.payload.physicalcount],
        isLoading: false,
        successSave: null
      }
    case CREATE_PC.SUCCESS:
      return {
        ...state,
        items: [action.payload.physicalcount, ...state.backupItems],
        backupItems: [action.payload.physicalcount, ...state.backupItems],
        data: [action.payload.physicalcount],
        isLoading: false,
        successSave: true
      }
    case EDIT_PC.SUCCESS:
      return {
        ...state,
        data: [action.payload.physicalcount],
        isLoading: false,
        successSave: true
      }
    case DELETE_PC.SUCCESS:
      const index = state.backupItems.findIndex(
        data => parseInt(data.id) === parseInt(action.payload.id)
      )
      return {
        ...state.backupItems.splice(index, 1),
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: null,
        deleteSuccess: true
      }
      case CHANGE_STATUS_PC.SUCCESS: {
        const idx = state.backupItems.findIndex(
          data =>
            parseInt(data.id) ===
            parseInt(action.payload.physicalcount.data[0].id)
        )
        return {
          ...state,
          items: replaceIdxValue(
            state.backupItems,
            idx,
            action.payload.physicalcount.data[0]
          ),
          backupItems: replaceIdxValue(
            state.backupItems,
            idx,
            action.payload.physicalcount.data[0]
          ),
          isLoading: false,
          successSave: true,
          deleteSuccess: null,
          errorMsg: ''
        }
      }
    case LOAD_PCS.FAILURE:
    case LOAD_PC.FAILURE:
    case CREATE_PC.FAILURE:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: false
      }
    case CHANGE_STATUS_PC.FAILURE:
        return {
          ...state,
          items: state.backupItems,
          backupItems: state.backupItems,
          isLoading: false,
          successSave: false,
          deleteSuccess: null,
          errorMsg: action.payload.error
    }
    case EDIT_PC.FAILURE:
    case DELETE_PC.FAILURE:
      return { ...state, items: [], isLoading: false, successSave: null, deleteSuccess: false, }
    default:
      return state
  }
}
