import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import debounce from 'lodash/debounce'
import * as actions from '../actions'

import {
  ActionBtn,
  ButtonTable,
  SearchPanel,
  MenuMobile
} from 'lib/widgets'
import { createCondition } from 'lib/helper'
import MUIDataTable from 'mui-datatables'
import { MuiThemeProvider } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Tablestyle } from '../../AddonStyles'

import AdvanceSearch from './AdvanceSearch'
import { defaultState } from '../defaultState'

class StoreTable extends Component {
  state = {
    term: '',
    search: '',
    term_from_advsearch: "",
    delId: '',
    options: {},
    page: 0,
    sortName: '',
    rowsPerPage: 10,
    count: 100,
    isModalOpen: false,
    showAdvSearch: false,
    width: window.innerWidth,
  }

  componentDidMount() {
    this.fetchStores()
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  }

  fetchStores = async () => {
    const { term } = this.state
    this.props.loadStores(term)
  }

  delaySearchData = debounce(async value => {
    this.setState({ search: value })
    const cond = 'strict=false&'+createCondition({ name: '' }, value)
    this.props.loadStores('?'+cond)
  }, 750)

  searchcustomers = event => {
    return this.delaySearchData(event)
  }

  advSearch = (data) => {
    this.props.loadStores(data)
  }

  setAdvSearch = data => {
    this.setState({ term: data.replace(/\?/g, ''), term_from_advsearch: data.replace(/\?/g, '') })
    this.advSearch(data)
  }

  showAdvSearch = state => {
    this.setState({ showAdvSearch: state })
  }

  navigateToCreateForm = () => {
    this.props.history.push('/salesforce/customers/new')
  }

  editForm = (id) => {
    this.props.history.push(`/salesforce/customers/${id}`)
  }

  deleteStore = (id) => {
    const { delId } = this.state
    this.props.deleteStore(delId)
    this.setState({ isModalOpen : false })
  }

  toggleConfirmDlg = (id='') => {
    this.setState({ isModalOpen: !this.state.isModalOpen, delId : id });
  }

  getRealCol = name => {
    let col = name
    switch(name) {
      case 'group_name' : col = 'customer_group'; break;
      case 'type_name' : col = 'shop_type_id'; break;
      case 'province' : col = 'province_id'; break;
      case 'district' : col = 'district_id'; break;
      default: break;
    }

    return col
  }

  sortingData = (changedColumn, direction) => {
    let sortby = this.getRealCol(changedColumn)
    const { page, rowsPerPage, search } = this.state
    const sort = direction === 'ascending' ? 'asc' : 'desc'
    let condStr = 'strict=false&'+createCondition({ name: '' }, search)
    if(this.state.term_from_advsearch !== "") condStr += "&"+this.state.term_from_advsearch

    let term = `?page=${page}&per_page=${rowsPerPage}&sort_by=${sortby}&sort=${sort}&${condStr}`
    this.setState(
      {
        term: term,
        sortName: changedColumn,
        options: { sort: true, sortDirection: sort }
      },
      this.advSearch(term)
    )
  }

  handleTableChange = (action, tableState) => {
    let term = ''
    let condStr = 'strict=false&'+createCondition({ name: '' }, this.state.search)
    if(this.state.term_from_advsearch !== "") condStr += "&"+this.state.term_from_advsearch

    switch (action) {
      case 'changeRowsPerPage':
        term = `?per_page=${tableState.rowsPerPage}&${condStr}`
        this.setState(
          {
            term: term,
            rowsPerPage: tableState.rowsPerPage,
            page: 0
          },
          this.advSearch(term)
        )
        break
      case 'changePage':
        term = `?page=${tableState.page + 1}&per_page=${tableState.rowsPerPage}&${condStr}`
        this.setState(
          {
            term: term,
            page: tableState.page
          },
          this.advSearch(term)
        )
        break
      default:
        break
    }
  }

  renderTable = (isLoading, columns, customers, optionsTable) => {

    const isMobile = this.state.width <= 500

    if(isMobile) {
      return <MenuMobile 
      data={customers} 
      columns={{
        col1: 'active_desc',
        col2: 'name',
        col3: 'group_name',
        col4: 'type_name',
      }}
      title={{
        title1: () => 'สถานะ',
        title2: () => 'ชื่อร้านค้า',
        title3: () =>  'กลุ่มลูกค้า',
        title4: () =>  'ประเภทร้านค้า'
      }}
      url={`api/customers`}
      onLoad={this.advSearch}
      linkUrl={`/salesforce/customers`}
      hideCreate={true}
      hideAll={true}
      />
    } 

    if(typeof customers === 'object')
      return <Fragment>
      { isLoading && <div className="lmask" id="lmask" /> }
      <MuiThemeProvider theme={Tablestyle()}>
        <MUIDataTable
          title={'ร้านค้า'}
          data={customers}
          columns={columns}
          options={optionsTable}
        />
      </MuiThemeProvider>
    </Fragment>
  }

  render() {
    const { isLoading, customers, meta } = this.props
    const { rowsPerPage, page, sortName, options } = this.state
    const count = typeof meta === 'undefined' ? 100 : meta.total

    const columns = [
      {
        name: '',
        label: '#',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return page * rowsPerPage + tableMeta.rowIndex + 1
          }
        }
      },
      {label : 'ชื่อ', name: 'name'},
      {label : 'กลุ่มลูกค้า', name: 'group_name'},
      {label : 'ประเภทร้านค้า', name: 'type_name'},
      {label : 'จังหวัด', name: 'province'},
      {label : 'อำเภอ', name: 'district'},
      {label : 'เบอร์โทร', name: 'phone', options: { sort: false }},
      {label : 'สถานะ', name: 'active_desc', options: { sort: false }},
      {
        name: 'id',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <ButtonTable
                id={value}
                displayDel={false}
                onOpen={this.editForm}
                onDelete={() => this.toggleConfirmDlg(value)}
                view="shop delete"
              />
            )
          }
        }
      }
    ]

    const optionsTable = {
      filter: false,
      print: false,
      download: false,
      viewColumns: false,
      filterType: 'dropdown',
      serverSide: true,
      count: count,
      page: page,
      rowsPerPage: rowsPerPage,
      selectableRows: false,
      onTableChange: this.handleTableChange,
      onSearchChange: this.searchcustomers,
      onColumnSortChange: this.sortingData,
      textLabels: {
        body: {
          noMatch: "Sorry, no matching records found",
          toolTip: "Sort",
        }
      },
      onSearchClose: () => {
        this.setState({ search : '' })
        this.advSearch(this.state.term)
      },
      customToolbar: () => {
        return (
          <ActionBtn
          onCreate={this.navigateToCreateForm}
          onSearch={this.showAdvSearch}
          displayCreate={false}
          view="shop edit"
        />
        );
      }
    }

    if (sortName !== '') {
      let idx = columns.findIndex(data => data.name === sortName)
      if (idx === -1) return
      columns[idx].options = options
    }

    return (
      <Fragment>
            <Grid item xs={12}>
              { this.renderTable(isLoading, columns, customers, optionsTable) }
            </Grid>
   
        <SearchPanel
          toggleDrawer={this.showAdvSearch}
          isOpen={this.state.showAdvSearch}
          onSubmit={this.setAdvSearch}
          Component={AdvanceSearch}
          formValue={defaultState.advSearch}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ customers }) => ({
  isLoading: customers.isLoading,
  customers: customers.items,
  meta: customers.meta,
  backupItems: customers.backupItems
})

const mapDispatchToProps = {
  loadStores: actions.loadStores.request,
  filterData: actions.filterData
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(StoreTable)
