import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import SwipeableViews from 'react-swipeable-views';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import { COMPANY_ID, MAIN_BRANCH, PUSHER_KEY } from 'lib/defaultSystemData'
import Pusher from 'pusher-js'
import QueryTitle from '../../dashboard/components/QueryTitle'
import { Breadcrumb } from 'lib/widgets'
import { styles } from '../../dashboard/components/RelateFunction'

import KpiBarChart from './KpiBarChart'
import KpiPieChart from './KpiPieChart'
import SummaryPieChart from './SummaryPieChart'
import WorkTable from './WorkTable'
import CompleteTable from './CompleteTable'
import CustomizedExpansionPanel from './CustomizedExpansionPanel'

import { Loading } from 'lib/widgets'

import axios from 'axios'
import { config, handleUnAutherize } from 'lib/authFunc'

function TabContainer({ children, dir }) {
    return (
        <Typography component="div" dir={dir} style={{ padding: 8 * 3, background: '#ffffff' }}>
            {children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
}

function WarehouseLayout(props) {
    const [value, setValue] = useState(0)
    const { classes, theme } = props;

    const [company_id, setCompanyId] = useState(COMPANY_ID)
    const [branch_id, setBranchId] = useState(MAIN_BRANCH)
    const [triggerLoad, setTriggerLoad] = useState(true)

    const [kpi, setKPI] = useState([])
    const [onProcess, setOnProcess] = useState([])
    const [finishedJob, setFinishedJob] = useState([])
    var pusher = null

    const loadData = async () => {
        setTriggerLoad(false)
        let range = getDateRage(value)
        let cond = `date_range=${range}&branch_id=${branch_id}`
        try {

            const kpiUrl = axios(process.env.REACT_APP_API_URL + `/api/overview-warehouse/graph-summary?${cond}`, config)
            const onProcessUrl = axios(process.env.REACT_APP_API_URL + `/api/overview-warehouse/pending-work?${cond}`, config)
            const finishUrl = axios(process.env.REACT_APP_API_URL + `/api/overview-warehouse/work?${cond}`, config)

            const [kpiData, onProcessData, finsishData] = await Promise.all([kpiUrl, onProcessUrl, finishUrl])

            setKPI(kpiData.data)
            setOnProcess(onProcessData.data)
            setFinishedJob(finsishData.data)
            //setTriggerLoad(true)
        } catch (error) {
            handleUnAutherize(error)
            console.log('Error')
        }
    }

    const getDateRage = idx => {
        let range = '24hr'
        switch (idx) {
            case 1: range = '7day'; break;
            case 2: range = '1m'; break;
            default: break;
        }

        return range
    }

    const setCompValue = val => {
        setCompanyId(val)
        setTriggerLoad(true)
    }

    const setBranchValue = val => {
        setBranchId(val)
        setTriggerLoad(true)
    }

    const handleChange = (event, value) => {
        setValue(value)
        setTriggerLoad(true)
    }

    const handleChangeIndex = index => {
        setValue(index)
    }

    const notify = () => {
        pusher = new Pusher(PUSHER_KEY, {
            cluster: 'ap1',
            encrypted: false
        });

        var channel = pusher.subscribe('jobwarehouse-notify');

        console.log('notication connected')

        channel.bind(`App\\Events\\JobWareHouseNotify`, function (data) {
            console.log('event trigger !!!')
            loadData()
        })
    }

    useEffect(() => {
        notify()
        if (triggerLoad) trackPromise(loadData())
        return async function cleanup() {
            console.log('disconnect to notification')
            pusher.disconnect()
        };
    })

    const WebComponent = () => (
        <Fragment>
            <Grid container spacing={24}>
                <Grid item md={12}>
                    <WorkTableComponent />
                </Grid>
            </Grid>
            <Grid container spacing={24}>
                <Grid item md={12}>
                    <CompleteTableComponent />
                </Grid>
            </Grid>
        </Fragment>
    )

    const WorkTableComponent = () => (
        <WorkTable data={onProcess} />
    )

    const CompleteTableComponent = () => (
        <CompleteTable data={finishedJob} />
    )

    const MobileComponent = () => (
        <Grid container spacing={24}>
            <Grid item xs={12}>
                <CustomizedExpansionPanel
                    WorkTableComponent={<Grid container spacing={24}><WorkTableComponent /></Grid>}
                    CompleteTableComponent={<Grid container spacing={24}><CompleteTableComponent /></Grid>}
                />
            </Grid>
        </Grid>
    )

    const { promiseInProgress } = usePromiseTracker();

    const Component = () => {
        if (promiseInProgress) return <Loading />
        return <Fragment>
            <Grid container spacing={24}>
                <Grid item xs={12} sm={6} md={4} style={{ backgroundColor: '#c0efff' }}>
                    <SummaryPieChart
                        onProcess={onProcess.length}
                        finishedJob={finishedJob.length} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} style={{ backgroundColor: '#c4dfe6' }}>
                    <KpiPieChart data={kpi} />
                </Grid>
                <Grid item xs={12} sm={12} md={4} style={{ backgroundColor: '#afcadd' }}>
                    <KpiBarChart data={kpi} />
                </Grid>

                {window.innerWidth > 500 ? <WebComponent /> : <MobileComponent />}

            </Grid>

        </Fragment>
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={16}>
                <Grid item xs>
                    <Breadcrumb
                        menu="กระดานข้อมูลคลังสินค้า"
                        submenu=""
                        menuLink="/dashboard/overview-warehouse"
                    />
                </Grid>
            </Grid>
            <QueryTitle  {...props}
                companyId={company_id}
                branchId={branch_id}
                onCompChange={setCompValue}
                onBranchChange={setBranchValue}
            />

            <AppBar position="static" >
                <Tabs
                    value={value}
                    onChange={handleChange}

                    variant="fullWidth"
                >
                    <Tab label="วันนี้" />
                    <Tab label="สัปดาห์นี้" />
                    <Tab label="เดือนนี้" />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabContainer dir={theme.direction}>
                    <Component />
                </TabContainer>
                <TabContainer dir={theme.direction}>
                    <Component />
                </TabContainer>
                <TabContainer dir={theme.direction}>
                    <Component />
                </TabContainer>
            </SwipeableViews>
        </div>
    );
}


WarehouseLayout.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(WarehouseLayout);