import React from 'react'
import { Switch, Route } from 'react-router-dom'
import BrandTable from './BrandTable'
import BrandList from './BrandList'
import EditBrand from './EditBrand'
import CreateBrand from './CreateBrand'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Breadcrumb } from 'lib/widgets'
import { Layoutstyles } from './AddonStyles'

const BrandLayout = ({ classes }) => (
  <div className={classes.root}>
    <Grid container spacing={24}>
      <Grid item xs>
        <Breadcrumb
          menu="ข้อมูลหลัก"
          submenu="ยี่ห้อ"
          menuLink="/master-data/brands"
        />
      </Grid>
    </Grid>
    <Grid container spacing={24}>
      <Switch>
        <Route exact path="/master-data/brands" component={BrandTable} />
        <Route path="/master-data/brands" component={BrandList} />
      </Switch>
      <Switch>
        <Route path="/master-data/brands/new" component={CreateBrand} />
        <Route path="/master-data/brands/:id" component={EditBrand} />
      </Switch>
    </Grid>
  </div>
)
export default withStyles(Layoutstyles)(BrandLayout)
