import {   
  LOAD_MOBILEGROUPS,
  LOAD_MOBILEGROUP,
  CREATE_MOBILEGROUP,
  EDIT_MOBILEGROUP,
  DELETE_MOBILEGROUP,
  FILTER_MOBILEGROUP,
  GET_MOBILEGROUP,
  FETCH_MOBILEGROUPS_FROM_STORE
 } from './actions'

 import { dataFilter, replaceIdxValue } from 'lib/helper'

const initialState = {
  isLoading: false,
  successSave: null,
  items: [],
  backupItems:[],
  mobilegroup: [],
  deleteSuccess: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_MOBILEGROUP:
      return { ...state, 
        items: dataFilter(action.payload.mobilegroups, action.payload.search),
        backupItems: state.backupItems 
      }
    case GET_MOBILEGROUP:
      const id = state.backupItems.findIndex(mobilegroup => mobilegroup.id === action.payload)
      return {
        ...state,
        successSave: null,
        mobilegroup: [state.backupItems[id]]
      }
    case FETCH_MOBILEGROUPS_FROM_STORE:
      return { ...state,
        successSave: null,
        items: state.backupItems,
        isLoading: false }
    case LOAD_MOBILEGROUPS.REQUEST:
    case LOAD_MOBILEGROUP.REQUEST:
    case DELETE_MOBILEGROUP.REQUEST:
    return { ...state, 
      items:state.backupItems,
      backupItems: state.backupItems,
      isLoading: true,
      successSave: null,
      deleteSuccess: null
    }
    case CREATE_MOBILEGROUP.REQUEST:
    case EDIT_MOBILEGROUP.REQUEST:
      return { ...state, items: [], isLoading: true }
    case LOAD_MOBILEGROUPS.SUCCESS:
      return { ...state, 
        items:action.payload.mobilegroups.data,
        backupItems: action.payload.mobilegroups.data,
        isLoading: false,
        meta: action.payload.mobilegroups.meta
      }
    case LOAD_MOBILEGROUP.SUCCESS:
      return { ...state, mobilegroup: [action.payload.mobilegroup], isLoading: false, successSave: null}
    case CREATE_MOBILEGROUP.SUCCESS:
      return {
        ...state,
        items: [action.payload.mobilegroup, ...state.backupItems],
        backupItems: [action.payload.mobilegroup, ...state.backupItems],
        isLoading: false,
        successSave: true
      }
    case EDIT_MOBILEGROUP.SUCCESS:
      const idx = state.backupItems.findIndex(mobilegroup => mobilegroup.id === action.payload.mobilegroup.id)
      return { ...state,
        items: replaceIdxValue(state.backupItems, idx, action.payload.mobilegroup),
        backupItems: replaceIdxValue(state.backupItems, idx, action.payload.mobilegroup),
        mobilegroup: [action.payload.mobilegroup],
        isLoading: false,
        successSave: true 
      }
    case DELETE_MOBILEGROUP.SUCCESS:
      const index = state.backupItems.findIndex(mobilegroup => mobilegroup.id === action.payload.id)
      return { ...state.backupItems.splice(index, 1),
        items:state.backupItems,
        backupItems:state.backupItems,
        isLoading: false,
        successSave:null,
        deleteSuccess: true
      }
    case LOAD_MOBILEGROUPS.FAILURE:
    case LOAD_MOBILEGROUP.FAILURE:
    case CREATE_MOBILEGROUP.FAILURE:
      return { ...state, 
        items:state.backupItems,
        backupItems:state.backupItems,
        isLoading: false,
        successSave: false 
      }
    case EDIT_MOBILEGROUP.FAILURE:
    case DELETE_MOBILEGROUP.FAILURE:
      return { ...state, items: [], isLoading: false, successSave: false }
    default:
      return state
  }
}

