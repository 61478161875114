import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as actions from '../actions'
import MobileGroupForm from './MobileGroupForm'

import { hasMessage } from 'lib/fxUI'
import { defaultState } from '../defaultState'

class EditMobileGroup extends Component {
  componentDidMount() {
    const id = this.getMobileGroupId()
    this.props.loadMobileGroup(id)
  }

  componentDidUpdate(prevProps) {
    const search = this.props.location.pathname
    if (search === prevProps.location.pathname) return

    const id = this.getMobileGroupId()
    this.props.loadMobileGroupFromArray(id)
  }

  getMobileGroupId = () => this.props.match.params.id

  editMobileGroup = async user => {
    this.props.editMobileGroup(this.getMobileGroupId(), user)
  }

  render() {
    const { mobilegroup, successSave } = this.props
    let data = []

    if(typeof mobilegroup !== 'undefined') {
      data = typeof mobilegroup[0] !== 'undefined' ? mobilegroup[0] : defaultState
    } 

    if(data.length === 0) {
      return <div className="lmask" id="lmask" />
    }

    return (
      <Fragment>
        {successSave !== null && hasMessage(successSave, '')}
        <MobileGroupForm
          onSubmit={this.editMobileGroup}
          formAction="แก้ไข"
          mobilegroup={data}
          successSave={successSave}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ mobilegroups }) => ({
  mobilegroup: mobilegroups.mobilegroup,
  successSave: mobilegroups.successSave
})

const mapDispatchToProps = {
  loadMobileGroup: actions.loadMobileGroup.request,
  editMobileGroup: actions.editMobileGroup.request,
  loadMobileGroupFromArray: actions.loadMobileGroupFromArray
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
)(EditMobileGroup)
