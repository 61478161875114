import React from 'react'
import { Switch, Route } from 'react-router-dom'

import CategoryTable from './CategoryTable'
import CategoryList from './CategoryList'
import EditCategory from './EditCategory'
import CreateCategory from './CreateCategory'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Breadcrumb } from 'lib/widgets'
import { Layoutstyles } from './AddonStyles'

const CategoryLayout = ({ classes }) => (
  <div className={classes.root}>
    <Grid container spacing={24}>
      <Grid item xs>
        <Breadcrumb
          menu="ข้อมูลหลัก"
          submenu="ประเภทสินค้า"
          menuLink="/master-data/categories"
        />
      </Grid>
    </Grid>
    <Grid container spacing={24}>
      <Switch>
        <Route exact path="/master-data/categories" component={CategoryTable} />
        <Route path="/master-data/categories" component={CategoryList} />
      </Switch>
      <Switch>
        <Route path="/master-data/categories/new" component={CreateCategory} />
        <Route path="/master-data/categories/:id" component={EditCategory} />
      </Switch>
    </Grid>
  </div>
)
export default withStyles(Layoutstyles)(CategoryLayout)
