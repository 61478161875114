import React, { Component, Fragment } from 'react'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import debounce from 'lodash/debounce'
import * as actions from '../actions'

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Link from '@material-ui/core/Link'
import ListSubheader from '@material-ui/core/ListSubheader'
import Paper from '@material-ui/core/Paper'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button'

import { Liststyles } from '../../AddonStyles'
import { WaitingList, SearchWidget, SearchBtn } from 'lib/widgets'
import { createCondition } from 'lib/helper'

const defaultState = {
  term: '',
  search: '',
  cond: '',
  per_page: 20,
  open: false
}

class WebGroupList extends Component {
  state = defaultState

  componentDidMount() {
    this.loadWebGroups()
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props
    const search = location.pathname
    if (search === prevProps.location.pathname) return

    if (prevProps.location.pathname !== '/master-data/web-groups/new') {
      this.props.fecthWebGroupFromArray()
      return
    }

    this.loadWebGroups()
  }

  getUserId = () => {
    let id = window.location.pathname.match(/\d+/)
    return (id !== null) ? id[0] : ''
  }

  loadMoreList = () => {
    let per_page = this.state.per_page + 20
    this.setState(
      { term: `per_page=${per_page}`, per_page },
      this.loadWebGroups
    )
  }

  loadWebGroups = () => {
    const { term, cond } = this.state
    this.props.loadWebGroups('?'+term+'&'+cond)
  }

  delaySearchData = debounce(async event => {
    const { webgroups, backupItems } = this.props
    this.setState({ search: event.target.value })

    if (event.target.value === '' || this.state.term.length < event.target.value.length) {
      this.props.filterData(backupItems, event.target.value)
      return
    }
    
    this.props.filterData(webgroups, event.target.value)
  }, 750)

  searchData = event => {
    event.persist()
    return this.delaySearchData(event)
  }

  toggleSearch = (open) => {
    this.setState({ open: open, term: '', search: '', per_page: 20, cond: '' }, () => {
      if (this.state.open) {
        this.loadWebGroups()
      }
    })
  }

  advSearch = () => {
    const { search } = this.state
    const cond = 'strict=false&'+createCondition({ name: '' }, search)
    this.setState({ cond: cond })
    this.props.loadWebGroups('?'+cond)
  }

  render() {
    const { classes, isLoading, webgroups } = this.props
    return (
      // <Grid item xs={12} md={4}>
      <Paper className={classes.paper}>
        <SwipeableDrawer
          onClose={() => this.toggleSearch(false)}
          onOpen={() => this.toggleSearch(true)}
          open={this.state.open}>
          <SearchWidget onChange={this.searchData} onClick={this.toggleSearch} onSearch={this.advSearch} />
          {isLoading ? (
            <Fragment>
              <WaitingList />
            </Fragment>
          ) : (
              <List
                className={classes.root}
                component="nav"
                subheader={<ListSubheader component="div" />}
              >
                {typeof webgroups === 'object' &&
                  webgroups.map((data, i) => {
                    let cls =
                      parseInt(data.id) === parseInt(this.getUserId())
                        ? classes.active
                        : ''
                    return (
                      <Link
                        key={i}
                        component={RouterLink}
                        to={`/master-data/web-groups/${data.id}`}
                      >
                        <ListItem alignItems="flex-start" className={cls}>
                          <ListItemText
                            primary={`สิทธิ์ : ${data.name}`}
                            secondary={
                              <Fragment>
                                <Typography
                                  component="span"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  Active : {data.active_desc}
                                </Typography>
                              </Fragment>
                            }
                          />
                        </ListItem>
                        <Divider />
                      </Link>
                    )
                  })}
              </List>
            )}
          <Button
            variant="contained"
            onClick={this.loadMoreList}
            style={{ borderRadius: '0px' }}
          >
            โหลดเพิ่ม
          </Button>
        </SwipeableDrawer>

        <SearchBtn classes={classes} onClick={this.toggleSearch} view="group edit"/>

      </Paper>
      // </Grid>
    )
  }
}

const mapStateToProps = ({ webgroups }) => ({
  isLoading: webgroups.isLoading,
  successSave: webgroups.successSave,
  webgroups: webgroups.items,
  backupItems: webgroups.backupItems
})

const mapDispatchToProps = {
  loadWebGroups: actions.loadWebGroups.request,
  filterData: actions.filterData,
  fecthWebGroupFromArray: actions.fecthWebGroupFromArray
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withStyles(Liststyles)
)(WebGroupList)
