import React, { Component, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import MapComponent from './map/MapComponent'
import MapFilter from './map/MapFilter'

export default class CustomerMap extends Component {
    loadMarker = (val, checkype) => {
        this._loadMaps(val, checkype)
    }

    _loadMaps = (val) => {
        const data = {
            company_id: this.props.companyId,
            branch_id: this.props.branchId,
            check_type: 'net',
        }

        this.props.loadMaps({...data, ...val})
    }

    handleChange = name => event => {
        this.props.setMapMode({ [name]: event.target.checked });
    };

    // componentDidMount() {
    //     const data = {
    //         company_id: this.props.companyId,
    //         branch_id: this.props.branchId,
    //         check_type: 'net',
    //         old: true,
    //         old_connect: true,
    //         old_disconnect: true,
    //         new: true,
    //         new_connect: true,
    //         new_disconnect: true,
    //     }
    //     this.props.loadMaps(data)
    // }

    render() {
        const { isLoading } = this.props
        return (
            <Fragment>
                <MapFilter onFilter={this._loadMaps}/>
                <Grid container spacing={24}>
                    <Grid item xs={12} style={{ height: '100vh', width: '100%' }}>
                        {isLoading && <div className="lmask" id="lmask" />}
                        <MapComponent {...this.props} />
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

CustomerMap.defaultProps = {
    customers: {
        connect: 0,
        disconnect: 0
    }
}
