import React from 'react';
import { formatDate } from 'lib/helper'

const WebComponent = ({ data, fulladdr }) => (
  <div className="col-xs-12 col-md-12 col-print-12">
  <div className="invoice-title">
    <h2>ใบสั่งซื้อ</h2>
    <h3 className="pull-right">Order # { data.document_no } </h3>
  </div>
  <hr />
  <div className="row">
    <div className="col-xs-6 col-md-6 col-print-6">
      <address>
        <strong>ลูกค้า:</strong>
        <br />
        { data.customer_name }
        <br />
        { fulladdr[0] }
        <br />
        { fulladdr[1] }
        <br />
        { data.mobile }
      </address>
    </div>
    <div className="col-xs-6 col-md-6 col-print-6 text-right">
      <address>
        <strong>Order Date:</strong>
        <br />
        { formatDate(data.delivery_date) } 
        <br />
      </address>
    </div>
  </div>
  <div className="row">
    <div className="col-xs-6 col-md-6">
    </div>
    <div className="col-xs-6 col-md-6 text-right">
      <address>
        <strong>ผู้ขาย:</strong>
        <br />
        { data.sales_name }
        <br />
        { data.sales_contact }
        <br />
      </address>
    </div>
  </div>
</div>
)

const MobileComponent = ({ data, fulladdr }) => (
  <div className="col-xs-12 col-md-12 col-print-12">
  <div className="invoice-title">
    <h3>ใบสั่งซื้อ</h3>
    <h6 className="pull-right">Order # { data.document_no } </h6>
  </div>
  <hr />
  <div className="row">
    <div className="col-xs-12 col-md-6 col-print-6">
      <address>
        <strong>ลูกค้า:</strong>
        <br />
        { data.customer_name }
        <br />
        { fulladdr[0] }
        <br />
        { fulladdr[1] }
        <br />
        { data.mobile }
      </address>
    </div>
  </div>

  <div className="row">
    <div className="col-xs-3 col-md-6  col-print-6">
      <address>
          <strong>Order Date:</strong>
          <br />
          { formatDate(data.delivery_date) } 
          <br />
        </address>
    </div>
    <div className="col-xs-6 col-md-6  col-print-6">
      <address>
        <strong>ผู้ขาย:</strong>
        <br />
        { data.sales_name }
        <br />
        { data.sales_contact }
        <br />
      </address>
    </div>
  </div>
</div>
)

export default ({ data, fulladdr }) => {
  return window.innerWidth <= 500 ? 
    <MobileComponent data={data} fulladdr={fulladdr}/> :
    <WebComponent data={data} fulladdr={fulladdr}/>
}