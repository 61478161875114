import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as actions from '../actions'
import CompanyForm from './CompanyForm'
import { hasMessage } from 'lib/fxUI'
import { defaultState } from '../defaultState'

class EditCompany extends Component {
  componentDidMount() {
    const id = this.getCompanyId()
    this.props.loadCompany(id)
  }

  componentDidUpdate(prevProps) {
    const search = this.props.location.pathname
    if (search === prevProps.location.pathname) return

    const id = this.getCompanyId()
    this.props.loadCompanyFromArray(id)
  }

  getCompanyId = () => this.props.match.params.id

  editCompany = async user => {
    this.props.editCompany(this.getCompanyId(), user)
  }

  render() {
    const { company, successSave } = this.props
    let data = []

    if (typeof company !== 'undefined') {
      data = typeof company[0] !== 'undefined' ? company[0] : defaultState
    }

    if (data.length === 0) {
      return <div className="lmask" id="lmask" />
    }

    return (
      <Fragment>
        {successSave !== null && hasMessage(successSave, '')}
        <CompanyForm
          onSubmit={this.editCompany}
          formAction="แก้ไข"
          data={data}
          successSave={successSave}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ companies }) => ({
  company: companies.company,
  isLoading: companies.isLoading,
  successSave: companies.successSave
})

const mapDispatchToProps = {
  loadCompany: actions.loadCompany.request,
  editCompany: actions.editCompany.request,
  loadCompanyFromArray: actions.loadCompanyFromArray
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
)(EditCompany)
