import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { CardWidget } from 'lib/widgets'

import classnames from 'classnames';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { checkPermission } from 'lib/authFunc'
import { Categories } from './MenuList'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  card: {
    backgroundColor: '#ffffff'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});


const HomeMenu = (props) => {
  const { classes } = props
  const [expandedSF, setExpandedSF] = useState(true);
  const [expandedWH, setExpandedWH] = useState(true);
  const [expandedMD, setExpandedMD] = useState(true);
  const [expandedRP, setExpandedRP] = useState(true);
  const [expandedDB, setExpandedDB] = useState(true);


  const handleExpandClick = (name) => {
    switch (name) {
      case 'SF': setExpandedSF(!expandedSF); break;
      case 'WH': setExpandedWH(!expandedWH); break;
      case 'MD': setExpandedMD(!expandedMD); break;
      case 'RP': setExpandedRP(!expandedRP); break;
      case 'DB': setExpandedDB(!expandedDB); break;
      default: break;
    }

  }

  const checkModule = (module) => {
    let user = localStorage.getItem('user')
    let res = Categories(user).filter((item) => {
      return item.title === `${module}`
    })

    return res.length > 0 ? true : false
  }

  return (
    <div className={classes.root}>
      {checkModule('Dashboard') &&
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <CardDashboardComponent classes={classes}
              expanded={expandedDB}
              handleExpandClick={handleExpandClick}
            />
          </Grid>
        </Grid>
      }

      {checkModule("Salesforce") &&
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <CardSalesForceComponent classes={classes}
              expanded={expandedSF}
              handleExpandClick={handleExpandClick}
            />
          </Grid>
        </Grid>
      }


      {checkModule("Warehouse") &&
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <CardWarehouseComponent classes={classes}
              expanded={expandedWH}
              handleExpandClick={handleExpandClick}
            />
          </Grid>
        </Grid>
      }

      {checkModule("รายงาน") &&
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <CardReportComponent classes={classes}
              expanded={expandedRP}
              handleExpandClick={handleExpandClick}
            />
          </Grid>
        </Grid>
      }

      {checkModule("ข้อมูลหลัก") &&
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <CardMasterComponent classes={classes}
              expanded={expandedMD}
              handleExpandClick={handleExpandClick}
            />
          </Grid>
        </Grid>
      }
    </div>
  )
}

const CardHeaderComponent = ({ classes, expanded, handleExpandClick, data, color }) => (
  <CardHeader
    avatar={
      <Avatar aria-label="Recipe" style={{ backgroundColor: `${color}` }}>
        {data.alphabet}
      </Avatar>
    }
    action={
      <IconButton
        className={classnames(classes.expand, {
          [classes.expandOpen]: expanded,
        })}
        onClick={handleExpandClick}
        aria-expanded={expanded}
        aria-label="Show more"
      >
        <ExpandMoreIcon />
      </IconButton>
    }
    title={data.title}
    subheader={data.subheader}
  />
)

const CardSalesForceComponent = ({ classes, expanded, handleExpandClick }) => (
  <Card className={classes.card}>
    <CardHeaderComponent classes={classes}
      expanded={expanded}
      color="#ea4335"
      handleExpandClick={() => handleExpandClick('SF')}
      data={{
        alphabet: 'S',
        title: 'SALES FORCE',
        subheader: 'จัดการฝ่ายขาย'
      }}
    />
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <CardContent>
        <Grid container spacing={24}>

          {checkPermission('salesorder') &&
            <Grid item xs={12} sm={4}>
              <CardWidget title="Sales order"
                subtitle="รายงานการขาย"
                img="/img/banking.png"
                url="/salesforce/sale-orders"
              />
            </Grid>
          }

          {checkPermission('shop') &&
            <Grid item xs={12} sm={4}>
              <CardWidget title="Customer"
                subtitle="ร้านค้า"
                img="/img/map.png"
                url="/salesforce/customers"
              />
            </Grid>
          }

          {checkPermission('customers-analytic') &&
            <Grid item xs={12} sm={4}>
              <CardWidget title="Customer Analytics"
                subtitle="วิเคราะห์ข้อมูลลูกค้า"
                img="/img/analysis.png"
                url="/salesforce/customers-analytic"
              />
            </Grid>
          }
        </Grid>
      </CardContent>
    </Collapse>
  </Card>
)

const CardWarehouseComponent = ({ classes, expanded, handleExpandClick }) => (
  <Card className={classes.card}>
    <CardHeaderComponent classes={classes}
      expanded={expanded}
      color="#fbbc05"
      handleExpandClick={() => handleExpandClick('WH')}
      data={{
        alphabet: 'W',
        title: 'WAREHOUSE',
        subheader: 'จัดการคลังสินค้า'
      }}
    />
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <CardContent>
        <Grid container spacing={24}>
          {checkPermission('goodsreceive') &&
            <Grid item xs={12} sm={4}>
              <CardWidget title="Goods Receive"
                subtitle=" ใบรับสินค้าเข้า"
                img="/img/import.png"
                url="/warehouse/goods-receives"
              />
            </Grid>
          }

          {checkPermission('goodstransfer') &&
            <Grid item xs={12} sm={4}>
              <CardWidget title="Goods Transfer"
                subtitle="ใบย้ายสินค้า"
                img="/img/archive.png"
                url="/warehouse/goods-transfers"
              />
            </Grid>
          }

          {checkPermission('goodsissue') &&
            <Grid item xs={12} sm={4}>
              <CardWidget title="Goods Issue"
                subtitle="ใบเบิกสินค้า"
                img="/img/export.png"
                url="/warehouse/goods-issues"
              />
            </Grid>
          }

          {checkPermission('stockbalance') &&
            <Grid item xs={12} sm={4}>
              <CardWidget title="Stock Balance"
                subtitle="สินค้าคงเหลือ"
                img="/img/warehouse.png"
                url="/warehouse/stock-balances"
              />
            </Grid>
          }

          {checkPermission('physicalcount') &&
            <Grid item xs={12} sm={4}>
              <CardWidget title="Physical Count"
                subtitle="นับสต๊อก"
                img="/img/test.png"
                url="/warehouse/physical-count"
              />
            </Grid>
          }
        </Grid>
      </CardContent>
    </Collapse>
  </Card>
)

const CardDashboardComponent = ({ classes, expanded, handleExpandClick }) => (
  <Card className={classes.card}>
    <CardHeaderComponent classes={classes}
      expanded={expanded}
      color="#4285f4"
      handleExpandClick={() => handleExpandClick('DB')}
      data={{
        alphabet: 'D',
        title: 'DASHBOARD',
        subheader: 'กระดานข้อมูล'
      }}
    />
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <CardContent>
        <Grid container spacing={24}>
          {checkPermission('dashboard overview') &&
            <Grid item xs={12} sm={4}>
              <CardWidget title="Dashboard"
                subtitle=" กระดานข้อมูลขาย"
                img="/img/home.png"
                url="/dashboard"
              />
            </Grid>
          }

          {checkPermission('dashboard overview-warehouse') &&
            <Grid item xs={12} sm={4}>
              <CardWidget title="WareHouse"
                subtitle=" กระดานข้อมูลคลังสินค้า"
                img="/img/statistics.png"
                url="/dashboard/overview-warehouse"
              />
            </Grid>
          }
        </Grid>
      </CardContent>
    </Collapse>
  </Card>
)

const CardReportComponent = ({ classes, expanded, handleExpandClick }) => (
  <Card className={classes.card}>
    <CardHeaderComponent classes={classes}
      expanded={expanded}
      color="#7989d0"
      handleExpandClick={() => handleExpandClick('RP')}
      data={{
        alphabet: 'R',
        title: 'REPORT',
        subheader: 'รายงานในระบบ'
      }}
    />
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <CardContent>
        <Grid container spacing={24}>

          <Grid item xs={12} sm={4}>
            <CardWidget title="Sales"
              subtitle="ยอดขายแยกตามพนักงาน"
              img="/img/chart.png"
              url="/report/sales"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CardWidget title="Customer"
              subtitle="ยอดขายแยกตามร้านค้า"
              img="/img/chart.png"
              url="/report/stores"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CardWidget title="Product"
              subtitle="ยอดขายแยกตามสินค้า"
              img="/img/chart.png"
              url="/report/products"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CardWidget title="Sales Order"
              subtitle="รายงานการขาย"
              img="/img/chart.png"
              url="/report/sale-orders"
            />
          </Grid>

        </Grid>
      </CardContent>
    </Collapse>
  </Card>
)

const CardMasterComponent = ({ classes, expanded, handleExpandClick }) => (
  <Card className={classes.card}>
    <CardHeaderComponent classes={classes}
      expanded={expanded}
      color="#34a853"
      handleExpandClick={() => handleExpandClick('MD')}
      data={{
        alphabet: 'M',
        title: 'MASTER DATA',
        subheader: 'ข้อมูลหลัก'
      }}
    />
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <CardContent>
        <Grid container spacing={24}>
          {checkPermission('group') &&
            <Grid item xs={12} sm={4}>
              <CardWidget title="Web Group"
                subtitle="กลุ่มสิทธิ์เว็บ"
                img="/img/domain.png"
                url="/master-data/web-groups"
              />
            </Grid>
          }
          {checkPermission('groupmobile') &&
            <Grid item xs={12} sm={4}>
              <CardWidget title="Mobile Group"
                subtitle="กลุ่มสิทธิ์มือถือ"
                img="/img/smartphone.png"
                url="/master-data/mobile-groups"
              />
            </Grid>
          }
          {checkPermission('user') &&
            <Grid item xs={12} sm={4}>
              <CardWidget title="User"
                subtitle="ผู้ใช้งาน"
                img="/img/user.png"
                url="/master-data/users"
              />
            </Grid>
          }
          {checkPermission('company') &&
            <Grid item xs={12} sm={4}>
              <CardWidget title="Company"
                subtitle="บริษัท"
                img="/img/enterprise.png"
                url="/master-data/companies"
              />
            </Grid>
          }
          {checkPermission('branch') &&
            <Grid item xs={12} sm={4}>
              <CardWidget title="Branch"
                subtitle="สาขา"
                img="/img/department.png"
                url="/master-data/branches"
              />
            </Grid>
          }
          {checkPermission('whbin') &&
            <Grid item xs={12} sm={4}>
              <CardWidget title="Warehouse"
                subtitle="คลังสินค้า"
                img="/img/warehouse.png"
                url="/master-data/stores"
              />
            </Grid>
          }
          {checkPermission("storagebindetail") &&
            <Grid item xs={12} sm={4}>
              <CardWidget title="Storage Bin"
                subtitle="จัดการ Storage Bin"
                img="/img/boxes.png"
                url="/master-data/storagebin-details"
              />
            </Grid>
          }
          {checkPermission('batch') &&
            <Grid item xs={12} sm={4}>
              <CardWidget title="Batch"
                subtitle="จัดการ Batch"
                img="/img/delivery.png"
                url="/master-data/batches"
              />
            </Grid>
          }
          {checkPermission('uom') &&
            <Grid item xs={12} sm={4}>
              <CardWidget title="UOM"
                subtitle="หน่วยนับ"
                img="/img/design.png"
                url="/master-data/units"
              />
            </Grid>
          }
          {checkPermission('brand') &&
            <Grid item xs={12} sm={4}>
              <CardWidget title="Brand"
                subtitle="ยี่ห้อ"
                img="/img/diploma.png"
                url="/master-data/brands"
              />
            </Grid>
          }
          {checkPermission('productcat') &&
            <Grid item xs={12} sm={4}>
              <CardWidget title="Product Category"
                subtitle="ประเภทสินค้า"
                img="/img/office-material.png"
                url="/master-data/categories"
              />
            </Grid>
          }
          {checkPermission('product') &&
            <Grid item xs={12} sm={4}>
              <CardWidget title="Product"
                subtitle="สินค้า"
                img="/img/barcode.png"
                url="/master-data/products"
              />
            </Grid>
          }
        </Grid>
      </CardContent>
    </Collapse>
  </Card>
)





export default withStyles(styles)(HomeMenu);