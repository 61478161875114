import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddBox from "@material-ui/icons/AddBox";
import HelpOutline from "@material-ui/icons/HelpOutline";
import { checkAction } from 'lib/authFunc'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    fontSize: '14px'
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
});

function ActionBtn(props) {
  const { onCreate, onSearch, displayCreate = true, view } = props;

  return (
    <Fragment>

      <Tooltip title="ค้นหาละเอียด" style={{ margin: '10px' }}>
        <IconButton onClick={() => onSearch(true)}>
          <HelpOutline />
        </IconButton>
      </Tooltip>
      {displayCreate && checkAction(view) &&
        <Tooltip title="สร้างใหม่" style={{ margin: '10px' }}>
          <IconButton onClick={onCreate} color="primary">
            <AddBox style={{ fontSize: '30px' }} />
          </IconButton>
        </Tooltip>
      }
      {/* {crossIcon} สินค้าไม่ครบ &nbsp;&nbsp;&nbsp; {checkIcon} สินค้าครบ */}
    </Fragment>
  )
}

ActionBtn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActionBtn);