import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import debounce from 'lodash/debounce'
import * as actions from '../actions'
import { BatchTable } from './BatchTable'

import { MuiThemeProvider } from '@material-ui/core/styles'
import { Tablestyle } from './AddonStyles'

class BatchList extends Component {
  state = {
    term: ''
  }

  componentDidMount() {
    this.loadBatches()
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props
    const search = location.pathname
    if (search === prevProps.location.pathname) return

    if (prevProps.location.pathname !== '/master-data/batches/new') {
      this.props.fecthBatchFromArray()
      return
    }

    this.loadBatches()
  }

  getBatchId = () => {
    let id = window.location.pathname.match(/\d+/)
    return (id !== null) ? parseInt(id[0]) : ''
  }

  loadBatches = () => {
    const { term } = this.state
    this.props.loadBatches(term)
  }

  delaySearchData = debounce(async event => {
    const { batches, backupItems } = this.props

    if (event.target.value === '' || this.state.term.length < event.target.value.length) {
      this.props.filterData(backupItems, event.target.value)
      return
    }

    this.setState({ term: event.target.value })
    this.props.filterData(batches, event.target.value)
  }, 750)

  searchData = event => {
    event.persist()
    return this.delaySearchData(event)
  }

  handleEdit = async (id, data) => {
    this.props.editBatch(id, data)
  }

  render() {
    const { isLoading, batches } = this.props
    return (
      <Fragment>
        <MuiThemeProvider theme={Tablestyle()}>
            {isLoading && <div className="lmask" id="lmask"></div>}
            <BatchTable rows={batches}
              onEdit={this.handleEdit} />
        </MuiThemeProvider>
      </Fragment>
    )
  }

}

const mapStateToProps = ({ batches }) => ({
  isLoading: batches.isLoading,
  batches: batches.items,
  backupItems: batches.backupItems
})

const mapDispatchToProps = {
  loadBatches: actions.loadBatches.request,
  filterData: actions.filterData,
  fecthBatchFromArray: actions.fecthBatchFromArray,
  editBatch: actions.editBatch.request
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
)(BatchList)
