import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { headerWithAuth, handleUnAutherize } from 'lib/authFunc'
import * as actions from './actions'

const header = headerWithAuth()

function* loadUnits(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.get,
      process.env.REACT_APP_API_URL + `/api/uoms${term}`,
      header
    )
    yield put(actions.loadUnits.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadUnits.failure(error.response))
  }
}

function* loadUnit(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.get, process.env.REACT_APP_API_URL + `/api/uoms/${id}`, header)
    yield put(actions.loadUnit.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadUnit.failure(error.response))
  }
}

function* createUnit(action) {
  try {
    const { unit } = action.payload
    const res = yield call(axios.post, process.env.REACT_APP_API_URL + '/api/uoms', { unit }, header)
    yield put(actions.createUnit.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.createUnit.failure(error))
  }
}

function* editUnit(action) {
  try {
    const { unit, id } = action.payload
    const res = yield call(axios.patch, process.env.REACT_APP_API_URL + `/api/uoms/${id}`, unit, header)

    yield put(actions.editUnit.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.editUnit.failure(error.response))
  }
}

function* deleteUnit(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.delete, process.env.REACT_APP_API_URL + `/api/uoms/${id}`, header)
    yield put(actions.deleteUnit.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.deleteUnit.failure(error.response))
  }
}


export default function* watchComments() {
  yield all([
    takeEvery(actions.LOAD_UNITS.REQUEST, loadUnits),
    takeEvery(actions.LOAD_UNIT.REQUEST, loadUnit),
    takeEvery(actions.CREATE_UNIT.REQUEST, createUnit),
    takeEvery(actions.EDIT_UNIT.REQUEST, editUnit),
    takeEvery(actions.DELETE_UNIT.REQUEST, deleteUnit)
  ])
}
