import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './RelateFunction'
import { MultipleSelect, SelectWidget } from 'lib/widgets'
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

import { genOptionList, genRelateOptionList, getIdtoArray } from 'lib/helper'
import { companyWithBranch } from 'lib/loadOption'


function QueryTitle(props) {

    const { classes } = props
    const [branchOpt, setBranchOpt] = useState([])
    const [compOpt, setCompOpt] = useState([])

    const [company_id, setCompany] = useState(props.companyId)
    const [branch_id, setBranch] = useState(props.branchId)

    const getOpt = () => {
        companyWithBranch().then(res => {
            let comp = genOptionList(res, 'id', 'name'),
                branch = genRelateOptionList(res, 'id', 'name', 'branch', 'company_id')
            setCompOpt(comp)
            setBranchOpt(branch)
        })
    }

    const changeToId = (value) => {
        const results = branchOpt.filter((member) => {
            if(!value.includes(member.label)) return false
            return member
        })

        return getIdtoArray(results, 'value').join()
    }

    useEffect(() => {
        if (typeof branchOpt === 'undefined') return
        if (branchOpt.length === 0) {
            getOpt()
        } 
    }, [])

    return (
            <Grid container justify="flex-end" spacing={16} style={{ marginBottom: '10px' }}>

                    <Grid item sm={4} xs={12}>
                        <SelectWidget
                            isClear={false}
                            withOption={true}
                            options={compOpt}
                            value={company_id}
                            title="บริษัท"
                            onChange={(name, value) => {
                                props.onCompChange(value)
                                setCompany(value)
                            }}
                        />
                    </Grid>

                    <Grid item sm={4} xs={12}>
                        <FormControl className={classes.formControl}>
                            <InputLabel>สาขา</InputLabel>
                            <MultipleSelect
                                defaultValue={branch_id}
                                options={branchOpt}
                                onChange={(name, value) => {
                                    if(value.length > 0) props.onBranchChange(changeToId(value))
                                    setBranch(value)
                                }}
                            />
                        </FormControl>
                    </Grid>
            </Grid>
    )
}

export default withStyles(styles)(QueryTitle);