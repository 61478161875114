import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { push } from 'connected-react-router'
import { headerWithAuth, handleUnAutherize } from 'lib/authFunc'
import * as actions from './actions'

const header = headerWithAuth()

function* loadGoodsReceives(action) {
  try {
    const { term } = action.payload
    const res = yield call(axios.get, process.env.REACT_APP_API_URL + `/api/goodsreceives${term}`, header)
    yield put(actions.loadGoodsReceives.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadGoodsReceives.failure(error.response))
  }
}

function* loadGoodsReceive(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.get, process.env.REACT_APP_API_URL + `/api/goodsreceives/${id}`, header)
    yield put(actions.loadGoodsReceive.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadGoodsReceive.failure(error.response))
  }
}

function* createGoodsReceive(action) {
  try {
    const { goodsreceive } = action.payload
    const res = yield call(
      axios.post,
      process.env.REACT_APP_API_URL + '/api/goodsreceives',
      { goodsreceive },
      header
    )
    yield put(push(`/warehouse/goods-receives/${res.data.id}`))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.createGoodsReceive.failure(error))
  }
}

function* editGoodsReceive(action) {
  try {
    const { goodsreceive, id } = action.payload
    const res = yield call(
      axios.patch,
      process.env.REACT_APP_API_URL + `/api/goodsreceives/${id}`,
      goodsreceive,
      header
    )

    yield put(actions.editGoodsReceive.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.editGoodsReceive.failure(error.response))
  }
}

function* changeStatusGR(action) {
  try {
    const { status, id } = action.payload
    const res = yield call(
      axios.patch,
      process.env.REACT_APP_API_URL + `/api/goodsreceives/change-status/${id}/${status}`,
      {},
      header
    )

    if (typeof res.data.error !== 'undefined') {
      console.log('err')
      yield put(actions.changeStatusGR.failure(res.data.error))
    } else {
      console.log('ok')
      yield put(actions.changeStatusGR.success(res.data))
    }
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.changeStatusGR.failure(error.response))
  }
}

function* deleteGoodsReceive(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.delete, process.env.REACT_APP_API_URL + `/api/goodsreceives/${id}`, header)
    yield put(actions.deleteGoodsReceive.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.deleteGoodsReceive.failure(error.response))
  }
}

function* createDocItem(action) {
  try {
    const { detail } = action.payload
    const res = yield call(
      axios.post,
      process.env.REACT_APP_API_URL + '/api/goodsreceive-details',
      { detail },
      header
    )
    yield put(actions.createDocItem.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.createDocItem.failure(error))
  }
}

function* editDocItem(action) {
  try {
    const { detail, id } = action.payload
    const res = yield call(
      axios.patch,
      process.env.REACT_APP_API_URL + `/api/goodsreceive-details/item/${id}`,
      detail,
      header
    )

    yield put(actions.editDocItem.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.editDocItem.failure(error.response))
  }
}

function* deleteDocItem(action) {
  try {
    const { id } = action.payload
    const res = yield call(
      axios.delete,
      process.env.REACT_APP_API_URL + `/api/goodsreceive-details/item/${id}`,
      header
    )
    yield put(actions.deleteDocItem.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.deleteDocItem.failure(error.response))
  }
}

function* deleteAllDocItem(action) {
  try {
    const { id } = action.payload
    const res = yield call(
      axios.delete,
      process.env.REACT_APP_API_URL + `/api/goodsreceive-details/delete-all/${id}`,
      header
    )
    yield put(actions.deleteAllDocItem.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.deleteAllDocItem.failure(error.response))
  }
}

export default function* watchComments() {
  yield all([
    takeEvery(actions.LOAD_GRS.REQUEST, loadGoodsReceives),
    takeEvery(actions.LOAD_GR.REQUEST, loadGoodsReceive),
    takeEvery(actions.CREATE_GR.REQUEST, createGoodsReceive),
    takeEvery(actions.EDIT_GR.REQUEST, editGoodsReceive),
    takeEvery(actions.DELETE_GR.REQUEST, deleteGoodsReceive),
    takeEvery(actions.CREATE_GR_ITEM.REQUEST, createDocItem),
    takeEvery(actions.EDIT_GR_ITEM.REQUEST, editDocItem),
    takeEvery(actions.DELETE_GR_ITEM.REQUEST, deleteDocItem),
    takeEvery(actions.DELETEALL_GR_ITEM.REQUEST, deleteAllDocItem),
    takeEvery(actions.CHANGE_STATUS_GR.REQUEST, changeStatusGR)
  ])
}
