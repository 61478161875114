import React, { Fragment, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';

import LooksOne from '@material-ui/icons/LooksOne';
import LooksTwo from '@material-ui/icons/LooksTwo';
import Looks3 from '@material-ui/icons/Looks3';
import Button from '@material-ui/core/Button';

import { numberFormat } from 'lib/helper'
import TopDialog from './TopDialog'

const root = {
  width: '100%',
  backgroundColor: 'white',
}

const inline = {
  display: 'inline'
}

const toptext = {
  width: '250px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}

function TopComponent(props) {
  const { data, color, branchId, type, title } = props
  const [open, setOpen] = useState(false)

  const checkUndefined = (data, name, isNum = false) => {
    return typeof data !== 'undefined' ? data[name] : isNum ? 0 : '-'
  }

  const handleOpenDlg = () => () => {
    setOpen(true)
    switch (type) {
      case 'topspend': return props.loadTopspend({ branch_id: branchId })
      case 'newrank': return props.loadNewTopSpend({ branch_id: branchId })
      case 'decrease': return props.loadLowerSpend({ branch_id: branchId })
      default: break;
    }

  }

  const handleClose = () => () => {
    setOpen(false)
  }

  return (
    <Fragment>
      <List style={root} >
        <ListItem alignItems="flex-start">
          <ListItemIcon>
            <LooksOne style={{ color: color[0] }} />
          </ListItemIcon>
          <ListItemText
            primary={<Typography style={toptext}>{checkUndefined(data[0], 'name')}</Typography>}
            secondary={
              <React.Fragment>
                <Typography component="span" style={inline} color="textPrimary">
                  ยอดเงิน  {numberFormat(checkUndefined(data[0], 'net', true))}  บาท
              </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemIcon>
            <LooksTwo style={{ color: color[1] }} />
          </ListItemIcon>
          <ListItemText
            primary={<Typography style={toptext}>{checkUndefined(data[1], 'name')}</Typography>}
            secondary={
              <React.Fragment>
                <Typography component="span" style={inline} color="textPrimary">
                  ยอดเงิน  {numberFormat(checkUndefined(data[1], 'net', true))}  บาท
              </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemIcon>
            <Looks3 style={{ color: color[2] }} />
          </ListItemIcon>
          <ListItemText
            primary={<Typography style={toptext}>{checkUndefined(data[2], 'name')}</Typography>}
            secondary={
              <React.Fragment>
                <Typography component="span" style={inline} color="textPrimary">
                  ยอดเงิน  {numberFormat(checkUndefined(data[2], 'net', true))}  บาท
              </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={
              <Button variant="outlined" fullWidth onClick={handleOpenDlg()}>
                ดูทั้งหมด
        </Button>}
          />
        </ListItem>
      </List>
      <TopDialog open={open} onClose={handleClose()} data={data} title={title} />
    </Fragment>
  );
}

export default TopComponent;