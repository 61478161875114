import { createTypes, createAction } from 'lib/action'
 
const LOAD_STOCKBALANCES = createTypes('stockbalances', 'list')
const LOAD_STOCKBALANCE = createTypes('stockbalance', 'show')

const FILTER_STOCKBALANCE = 'backup'
const SET_STOCKBALANCE = 'api/data'
const GET_STOCKBALANCE = 'GET_STOCKBALANCE'
const FETCH_STOCKBALANCES_FROM_STOCKBALANCE = "FETCH_STOCKBALANCES_FROM_STOCKBALANCE"

const loadStockbalances = {
  request: term => createAction(LOAD_STOCKBALANCES.REQUEST, { term }),
  success: stockbalances => createAction(LOAD_STOCKBALANCES.SUCCESS, { stockbalances }),
  failure: () => createAction(LOAD_STOCKBALANCES.FAILURE)
}

const loadStockbalance = {
  request: id => createAction(LOAD_STOCKBALANCE.REQUEST, { id }),
  success: stockbalance => createAction(LOAD_STOCKBALANCE.SUCCESS, { stockbalance }),
  failure: () => createAction(LOAD_STOCKBALANCE.FAILURE)
}

const filterData = (stockbalance, search) => ({
  type: FILTER_STOCKBALANCE,
  payload: {
    stockbalance,
    search 
  }
})

const loadStockbalanceFromArray = id => ({
  type: GET_STOCKBALANCE,
  payload: id
})

const fecthStockbalanceFromArray = () => ({
  type : FETCH_STOCKBALANCES_FROM_STOCKBALANCE,
  payload: {}
})


export {
  LOAD_STOCKBALANCES,
  LOAD_STOCKBALANCE,
  SET_STOCKBALANCE,
  FILTER_STOCKBALANCE,
  GET_STOCKBALANCE,
  FETCH_STOCKBALANCES_FROM_STOCKBALANCE,
  loadStockbalances,
  loadStockbalance,
  loadStockbalanceFromArray,
  filterData,
  fecthStockbalanceFromArray,
}
