import React from 'react'
import Grid from '@material-ui/core/Grid'

import TextField from '@material-ui/core/TextField'
import { SelectWidget, DatePickerWidget } from 'lib/widgets'

const style = {
    marginBottom: '15px'
}
export default ({ values, classes, handleChange, handleSelect, handleDateChange }) => (
  <div style={{ padding: '10px', flexGrow: 1 }}>
      <Grid item xs={12}  style={style}>
      <TextField
        label="เลขใบ Sale Order"
        name="document_no"
        value={values.document_no}
        onChange={handleChange('document_no')}
        fullWidth
      />
    </Grid>
    <Grid item xs={12}  style={style}>
      <TextField
        label="ชื่อร้านค้า"
        name="shop_name"
        value={values.shop_name}
        onChange={handleChange('shop_name')}
        fullWidth
      />
    </Grid>
    <Grid item xs={12}  style={style}>
    <SelectWidget
        url="api/companies"
        value={values.company_id}
        name="company_id"
        title="บริษัท"
        onChange={handleSelect('company_id')}
    />
    </Grid>
    <Grid item xs={12} style={style}>
    <SelectWidget
        url="api/master-data/users"
        value={values.user_id}
        name="user_id"
        title="ชื่อพนักงาน"
        onChange={handleSelect('user_id')}
    />
    </Grid>
    <Grid item xs={12}  style={style}>
      <DatePickerWidget
        title="วันเริ่มต้น"
        name="start_date"
        value={values.start_date}
        onValueChange={handleDateChange('start_date')}
      />
    </Grid>
    <Grid item xs={12}  style={style}>
      <DatePickerWidget
        title="วันสิ้นสุด"
        name="end_date"
        value={values.end_date}
        onValueChange={handleDateChange('end_date')}
      />
    </Grid>
    
  </div>
)
