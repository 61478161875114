import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const apiKey = "AIzaSyAUOISNjtxH3htrPuWyCQmiMX-Q5eJiIEA"

const AnyReactComponent = ({ text }) => (
  <div><div className="pin"></div><div className="pulse"></div></div>
)  

class MapSingleMask extends Component {
  
  static defaultProps = {
    center: {
      lat: 13.75398,
      lng: 100.50144
    },
    zoom: 7
  }

  constructor(props) {
    super(props);
    this.searchbar = React.createRef();
    this.state = {
      mapsApiLoaded: false,
      mapInstance: null,
      mapsapi: null,
    }
  }

  handleMapClick = ref => {
    this.setState({
      lat: ref.lat,
      lng: ref.lng,
      center: {
        lat: ref.lat,
        lng: ref.lng
      }
    })

    this.props.onMarkerClick(ref.lat, ref.lng)
  }

  apiLoaded= (map, maps) => {
    map.controls[maps.ControlPosition.TOP_LEFT].push(this.searchbar.current);
  
    this.setState({
      mapsApiLoaded: true,
      mapInstance: map,
      mapsapi: maps,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      lat: nextProps.lat,
      lng: nextProps.lng,
      center: nextProps.center
    })

  }

  render() {
    return (
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: apiKey, libraries: ['places'] }}
          defaultCenter={this.state.center}
          defaultZoom={this.props.zoom}
          onClick={this.handleMapClick}
        >
          <AnyReactComponent
            lat={this.state.lat}
            lng={this.state.lng}
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default MapSingleMask;