export const defaultState = {
    active: true,
    disableForm : false,
    isOpen: false,
    document_no: '',
    full_addr: '',
    delivery_date: '',
    customer_name: '',
    contact: '',
    sales_name: '',
    sales_contact: '',
    payment_status: '',
    paymentItem : [{
        type: 1, 
        payment: '', 
        remark: '' 
    }],
    advSearch : {
        document_no : '',
        user_id: '',
        start_date: null,
        end_date: null,
        company_id: '',
        shop_name: ''
    }
}