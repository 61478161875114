import { createMuiTheme } from '@material-ui/core/styles';

const drawerWidth = 64
const Formstyles = theme => ({
  root: {
    width: '100%',
    maxWidth: 600,
    height: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: theme.palette.background.paper,
    margin: 'auto'
  },
  inline: {
    display: 'inline'
  },
  active: {
    backgroundColor: '#deefff'
  },
  paper: {
    padding: theme.spacing.unit * 5,
    color: theme.palette.text.secondary
  }
})

const Liststyles = theme => ({
  root: {
    position: 'relative',
    overflow: 'auto',
    maxHeight: 400,
    width: 300,
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: 'inline'
  },
  active: {
    backgroundColor: '#deefff'
  },
  paper: {
    padding: "0px",
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  fab: {
    position: 'absolute',
    top: theme.spacing.unit * 20,
    right: '40px !important',
    zIndex: 1000,
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing.unit * 21,
      right: theme.spacing.unit * 2
    }
  },
  fabcreate: {
    position: 'absolute',
    top: theme.spacing.unit * 26,
    right: '40px !important',
    zIndex: 1000,
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing.unit * 4,
      right: theme.spacing.unit * 2
    }
  },
  fabpayment: {
    position: 'absolute',
    top: theme.spacing.unit * 30,
    right: '40px !important',
    zIndex: 1000,
    width: '90px !important',
    backgroundColor: '#4caf50',
    color: '#f1f8e9',
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing.unit * 15,
      right: theme.spacing.unit * 2
    }
  },
  typography: {
    useNextVariants: true,
  }
})


const Layoutstyles = theme => ({
    root: {
      flexGrow: 1
    }
})

const Tablestyle = () => createMuiTheme({
  overrides: {
    MuiToolbar: {
      gutters: {
        paddingRight: '0px !important'
      }
    },
    MuiPaper: {
      rounded: {
        padding: '15px'
      }
    },
    MuiTableCell: {
      root: {
        padding: '0px 0px 0px 4px !important',
        borderBottom: '0px'
      },
      body: {
        color: 'rgba(47, 47, 47, 0.87)',
        fontSize: '0.85rem',
        fontWeight: '100'
      },
      head: {
        color: 'rgba(0, 0, 0, 0.7)',
        fontSize: '0.85rem',
        fontWeight: '700',
      }
    },
    MuiTableRow: {
      head: {
        height: '35px',
        fontWeight: '700'
      },
      root: {
        height: '30px',
        '&:nth-of-type(even)': {
          backgroundColor: '#dce5e852'
        }
      }
    },
    MUIDataTableHeadCell: {
      fixedHeader: {
        backgroundColor: '#cfd8dc'
      }
    },
    typography: {
      useNextVariants: true,
    },
    MuiIconButton: {
      root: {
        padding: '5 10 5 10px'
      }
    },
    MuiSvgIcon: {
      root: {
        fontSize: '20px'
      }
    },
    ButtonTable: {
      button: {
        margin: '3px'
      }
    }
  },
  typography: { useNextVariants: true },
  palette: {
    primary: {
      light: "#89bdff",
      main: "#4e8df6",
      dark: "#0060c3",
      contrastText: "#fff"
    }
  }
})

export {
    Formstyles,
    Liststyles,
    Layoutstyles,
    Tablestyle
}