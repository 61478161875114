import React from 'react'
import { Switch, Route } from 'react-router-dom'

import GoodsReceiveTable from './GoodsReceiveTable'
import GoodsReceiveList from './GoodsReceiveList'
import EditDoc from './EditDoc'
import CreateDoc from './CreateDoc'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Breadcrumb } from 'lib/widgets'
import { Layoutstyles } from './AddonStyles'

const GoodsReceiveLayout = ({ classes }) => (
  <div className={classes.root}>
    <Grid container spacing={24}>
      <Grid item xs>
        <Breadcrumb
          menu="จัดการคลังสินค้า"
          submenu="ใบรับสินค้า"
          menuLink="/warehouse/goods-receives"
        />
      </Grid>
    </Grid>
    <Grid container spacing={24}>
      <Switch>
        <Route
          exact
          path="/warehouse/goods-receives"
          component={GoodsReceiveTable}
        />
        <Route path="/warehouse/goods-receives" component={GoodsReceiveList} />
      </Switch>
      <Switch>
        <Route path="/warehouse/goods-receives/new" component={CreateDoc} />
        <Route path="/warehouse/goods-receives/:id" component={EditDoc} />
      </Switch>
    </Grid>
  </div>
)
export default withStyles(Layoutstyles)(GoodsReceiveLayout)
