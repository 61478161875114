const initDate = new Date()

const defaultState = {
    id: '',
    isModalOpen: false,
    disableForm: false,
    formLoading: true,
    company_id: '',
    branch_id: '',
    order_no: null,
    document_type_id: '',
    ref_no: '',
    delivery_date: initDate,
    description: '',
    customer_name: '',
    branchUrl: 'api/branches',
    currentId: '',
    companies: [],
    bkcompany: [],
    branches: [],
    relations: {
    detail: []
  },
  columns: [
    { name: 'product_id', title: 'สินค้า * ', required: true },
    { name: 'order_qty', title: 'จำนวน * ', required: true },
    { name: 'uom_id', title: 'หน่วยนับ * ', required: true },
    { name: 'external_batch', title: 'Batch No.' },
    { name: 'warehouse_id', title: 'คลังสินค้า * ', required: true },
    { name: 'bin_id', title: 'Storage Bin *', required: true },
    { name: 'manufacturing_date', title: 'วันที่ผลิต' },
    { name: 'expired_date', title: 'วันที่หมดอายุ' }
  ],
  
  advSearch : {
    company_id: '',
    branch_id: '',
    delivery_date: null,
    status: '',
    ref_no: '',
    order_no: ''
  }
}

const tableColumnExtensions = [
  { columnName: 'product_id', width: 225 },
  { columnName: 'order_qty', width: 80 },
  { columnName: 'uom_id', width: 105 },
  { columnName: 'external_batch', width: 80 },
  { columnName: 'warehouse_id', width: 130 },
  { columnName: 'bin_id', width: 130 },
  { columnName: 'manufacturing_date', width: 128 },
  { columnName: 'expired_date', width: 128 },
]

export {
  tableColumnExtensions,
  defaultState
}