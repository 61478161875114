import React, { useState, useEffect, Fragment } from 'react';

import AttachMoney from '@material-ui/icons/AttachMoney'
import ShoppingCart from '@material-ui/icons/ShoppingCart'
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline'
import PlusOne from '@material-ui/icons/PlusOne'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Line } from 'react-chartjs-2';
import { SelectWidget, Loading } from 'lib/widgets'
import { numberFormat, getCurrentMonthIdx, dynamicColors } from 'lib/helper'
import axios from 'axios'
import { config } from 'lib/authFunc'
import { getMonth } from './RelateFunction'

import TopProduct from './TopProduct'
import TopSales from './TopSales'
import TopCustomer from './TopCustomer'

const monthOpt = getMonth()

const options = {
    scales: {
        yAxes: [{
            ticks: {
                callback(value) {
                    return numberFormat(value)
                }
            }
        }]
    },
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                let value = tooltipItem.value,
                    label = data.datasets[tooltipItem.datasetIndex].label
                return label + ' : ' + numberFormat(value);
            }
        }
    }
}

export default ({ classes, option, companyId, branchId }) => {

    const [month_id, setMonth] = useState(getCurrentMonthIdx())
    const [format_id, setFormat] = useState('2')
    const [isLoading, setLoad] = useState(false)
    const [company_id, setCompany] = useState('')
    const [branch_id, setBranch] = useState('')

    const [graph, setGraph] = useState({})
    const [data, setData] = useState({
        discount: 0,
        net: 0,
        total_free: 0,
        total_qty: 0
    })

    const fetchData = async (val, month) => {

        const data = {
            company_id: companyId,
            branch_id: branchId,
            month_id: month,
            format: val
        }

        setLoad(true)
        setCompany(companyId)
        setBranch(branchId)

        const result = await axios.post(process.env.REACT_APP_API_URL + '/api/dashboard/graph-summary', data, config)
        if (typeof result.data !== 'undefined') {
            genGraphData(result.data.graph)
            setData(result.data.data)
            setLoad(false)
        }
    }

    const genGraphData = values => {
        const data = {
            labels: values.label,
            datasets: genDataSet(values)
        }
        setGraph(data)
    }

    const genDataSet = (values) => {
        let data = []

        for (var i = 0; i <= values.data.branch.length; i++) {
            if (typeof values.data.branch[i] !== 'undefined') {
                let color = (i === 0) ? '#4bc0c0' : dynamicColors()
                data = [...data, {
                    label: values.data.branch[i],
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: color,
                    borderColor: color,
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: color,
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: color,
                    pointHoverBorderColor: color,
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: values.data[i]
                }]
            }
        }

        return data
    }

    useEffect(() => {
        if (graph.length === 0 & isLoading === false || (companyId !== company_id || branchId !== branch_id)) fetchData(format_id, month_id)
    })

    return <Fragment>
        <Grid container spacing={16} justify="flex-end">
            <Grid item xs={12} sm={3}>
                <SelectWidget
                    isClear={false}
                    withOption={true}
                    options={option.current}
                    value={format_id}
                    title="รูปแบบ"
                    disabled={isLoading}
                    onChange={(name, val) => {
                        setFormat(val)
                        if (val === '1') {
                            setMonth(getCurrentMonthIdx())
                            fetchData(val, getCurrentMonthIdx())
                            return
                        }

                        fetchData(val, month_id)
                    }}
                />
            </Grid>
            <Grid item sm={1}></Grid>
            <Grid item xs={12} sm={3}>
                <SelectWidget
                    isClear={false}
                    withOption={true}
                    options={monthOpt}
                    title="เดือน"
                    value={month_id}
                    disabled={isLoading || parseInt(format_id) !== 2}
                    onChange={(name, val) => {
                        setMonth(val)
                        fetchData(format_id, val)
                    }}
                />
            </Grid>
        </Grid>
        <Grid container spacing={16}>
            <Grid item sm={3} xs={12}>

                <Grid container style={{ background: 'rgb(251, 194, 181)', padding: '10px', border: '1px solid rgb(251, 194, 181)', marginBottom: '20px' }}>
                    <Grid item xs={4} sm={3}>
                        <AttachMoney style={{ color: '#fb6340' }} />
                    </Grid>
                    <Grid item xs={8} sm={9}>
                        <Typography variant="h6" component="h6"> ยอดขายสุทธิ</Typography>
                        <Typography component="p"> {numberFormat(data.net)} บาท</Typography>
                    </Grid>
                </Grid>

                <Grid container style={{ background: '#bdeaff', padding: '10px', border: '1px solid #bdeaff', marginBottom: '20px' }}>
                    <Grid item xs={4} sm={3}>
                        <RemoveCircleOutline style={{ color: '#4fc3f7' }} />
                    </Grid>
                    <Grid item xs={8} sm={9}>
                        <Typography variant="h6" component="h6"> ส่วนลดสุทธิ</Typography>
                        <Typography component="p"> {numberFormat(data.discount)} บาท</Typography>
                    </Grid>
                </Grid>

                <Grid container style={{ background: '#b2c7ff', padding: '10px', border: '1px solid #b2c7ff', marginBottom: '20px' }}>
                    <Grid item xs={4} sm={3}>
                        <ShoppingCart style={{ color: '#2962ff' }} />
                    </Grid>
                    <Grid item xs={8} sm={9}>
                        <Typography variant="h6" component="h6"> จำนวนที่ขายทั้งหมด</Typography>
                        <Typography component="p"> {numberFormat(data.total_qty, 0)} รายการ</Typography>
                    </Grid>
                </Grid>

                <Grid container style={{ background: '#cfd2e6', padding: '10px', border: '1px solid #cfd2e6', marginBottom: '20px' }}>
                    <Grid item xs={4} sm={3}>
                        <PlusOne style={{ color: '#5f76e4' }} />
                    </Grid>
                    <Grid item xs={8} sm={9}>
                        <Typography variant="h6" component="h6"> จำนวนที่แถมทั้งหมด</Typography>
                        <Typography component="p"> {numberFormat(data.total_free, 0)} รายการ</Typography>
                    </Grid>
                </Grid>

            </Grid>

            <Grid item sm={9} xs={12}>
                {isLoading ? <Loading /> : <Line data={graph} options={options} />}
            </Grid>
        </Grid>
        <Grid container spacing={16} style={{ marginTop: '20px' }}>
            <Grid item xs={12} sm={4}>
                <TopProduct
                    classes={classes}
                    companyId={companyId}
                    branchId={branchId}
                    MonthId={month_id}
                    forMat={format_id}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TopSales
                    classes={classes}
                    companyId={companyId}
                    branchId={branchId}
                    MonthId={month_id}
                    forMat={format_id}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TopCustomer
                    classes={classes}
                    companyId={companyId}
                    branchId={branchId}
                    MonthId={month_id}
                    forMat={format_id}
                />
            </Grid>
        </Grid>
    </Fragment>
}
