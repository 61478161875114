import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { headerWithAuth, handleUnAutherize } from 'lib/authFunc'
import * as actions from './actions'

import { push } from 'connected-react-router'

const header = headerWithAuth()

function* loadProducts(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.get,
      process.env.REACT_APP_API_URL + `/api/products${term}`,
      header
    )
    yield put(actions.loadProducts.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadProducts.failure(error.response))
  }
}

function* loadProduct(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.get, process.env.REACT_APP_API_URL + `/api/products/${id}`, header)
    yield put(actions.loadProduct.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadProduct.failure(error.response))
  }
}

function* createProduct(action) {
  try {
    const { product } = action.payload
    const res = yield call(axios.post, process.env.REACT_APP_API_URL + '/api/products', { product }, header)
    yield put(push(`/master-data/products/${res.data.id}`))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.createProduct.failure(error))
  }
}

function* editProduct(action) {
  try {
    const { product, id } = action.payload
    const res = yield call(axios.patch, process.env.REACT_APP_API_URL + `/api/products/${id}`, product, header)
    yield put(actions.editProduct.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.editProduct.failure(error.response))
  }
}

function* deleteProduct(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.delete, process.env.REACT_APP_API_URL + `/api/products/${id}`, header)
    yield put(actions.deleteProduct.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.deleteProduct.failure(error.response))
  }
}

function* createUnitItem(action) {
  try {
    const { uom } = action.payload
    const res = yield call(axios.post, process.env.REACT_APP_API_URL + '/api/product-uoms', { uom }, header)
    yield put(actions.createUnitItem.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.createUnitItem.failure(error))
  }
}

function* editUnitItem(action) {
  try {
    const { uom, id } = action.payload
    const res = yield call(axios.patch, process.env.REACT_APP_API_URL + `/api/product-uoms/${id}`, uom, header)

    yield put(actions.editUnitItem.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.editUnitItem.failure(error.response))
  }
}

function* deleteUnitItem(action) {
  try {
    const { id, data } = action.payload
    const res = yield call(axios.patch, process.env.REACT_APP_API_URL + `/api/product-uoms/delete/${id}`, data, header)
    yield put(actions.deleteUnitItem.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.deleteUnitItem.failure(error.response))
  }
}

function* editPriceBookItem(action) {
  try {
    const { pricebook, id } = action.payload
    const res = yield call(axios.patch, process.env.REACT_APP_API_URL + `/api/pricebook-products/${id}`, pricebook, header)

    yield put(actions.editPriceBookItem.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.editPriceBookItem.failure(error.response))
  }
}

export default function* watchComments() {
  yield all([
    takeEvery(actions.LOAD_PRODUCTS.REQUEST, loadProducts),
    takeEvery(actions.LOAD_PRODUCT.REQUEST, loadProduct),
    takeEvery(actions.CREATE_PRODUCT.REQUEST, createProduct),
    takeEvery(actions.EDIT_PRODUCT.REQUEST, editProduct),
    takeEvery(actions.DELETE_PRODUCT.REQUEST, deleteProduct),

    takeEvery(actions.CREATE_PRODUCT_UNIT.REQUEST, createUnitItem),
    takeEvery(actions.EDIT_PRODUCT_UNIT.REQUEST, editUnitItem),
    takeEvery(actions.DELETE_PRODUCT_UNIT.REQUEST, deleteUnitItem),
    takeEvery(actions.EDIT_PRODUCT_PRICE.REQUEST, editPriceBookItem),
  ])
}
