import React, { Component } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { defaultState } from '../defaultState'

import { SubmitBtn, PageTitle, MapSingleMask } from 'lib/widgets'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import { Formstyles } from './AddonStyles'


class CompanyForm extends Component {

  state = defaultState

  toggleFx = name => {
    let isOpen = this.state[`${name}`]
    this.setState({ [`${name}`]: !isOpen })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.disableForm === false
      && window.location.pathname !== '/master-data/companies/new') {
      this.disableForm(true)
    }
  }

  componentDidMount() {
    if (window.location.pathname !== '/master-data/companies/new') {
      this.disableForm(true)
    }
  }

  disableForm = (disableForm) => {
    this.setState({ disableForm })
  }


  render() {
    const { classes, data, onSubmit, isLoading } = this.props
    return (
      <Grid item xs={12}>
        {isLoading && <div className="lmask" id="lmask" />}
        <Formik
          enableReinitialize
          initialValues={data}
          validationSchema={yup.object().shape({
            name: yup.string().required('กรุณากรอก')
          })}
          onSubmit={(values, { resetForm }) => {
            onSubmit(values)
            if (values.id === '')
              resetForm(defaultState)
          }}
          render={({
            values,
            errors,
            touched,
            resetForm,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
          }) => {
              return <Paper className={classes.paper}>
                <form onSubmit={handleSubmit}>
                  <fieldset disabled={this.state.disableForm}>
                    <div className={classes.root}>
                      <Grid container spacing={24}>
                        <PageTitle
                          formTitle="บริษัท"
                          action={this.disableForm}
                          edit={this.state.disableForm}
                          id={values.id}
                          view="company edit"
                        />
                        <Grid item xs={12} sm={6}></Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="code"
                            label="รหัส"
                            fullWidth
                            value={values.code || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            name="name"
                            label="ชื่อ"
                            fullWidth
                            value={values.name || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                          {touched.name && errors.name && (
                            <span className="form-text--error">
                              {errors.username}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="search_key"
                            label="ชื่อย่อ"
                            fullWidth
                            value={values.search_key || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12}  sm={6}>
                          <TextField
                            name="phone"
                            label="เบอร์โทร"
                            fullWidth
                            value={values.phone || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12}  sm={6}>
                          <TextField
                            name="email"
                            label="อีเมล์"
                            fullWidth
                            value={values.email || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="taxid"
                            label="เลขที่เสียภาษี"
                            fullWidth
                            value={values.taxid || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="description"
                            label="รายละเอียด"
                            fullWidth
                            value={values.description || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <span style={{ paddingRight: '20px' }}>Logo บริษัท: </span>
                          <input
                            accept="image/*"
                            className={classes.input}
                            name="image"
                            type="file"
                            onChange={(event) => {
                              let file = event.target.files[0]
                              setFieldValue("image", file);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="address"
                            label="ที่อยู่"
                            fullWidth
                            value={values.address || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MapSingleMask
                            lat={values.latitude}
                            lng={values.longitude}
                            center={{ lat: parseFloat(values.latitude), lng: parseFloat(values.longitude) }}
                            onMarkerClick={(e) => {
                              console.log('Test Map', e)
                            }} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="latitude"
                            label="แลตติจูด"
                            fullWidth
                            value={values.latitude || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="longitude"
                            label="ลองจิจูด"
                            fullWidth
                            value={values.longitude || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.state.disableForm}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="secondary"
                                name="active"
                                value="1"
                                onChange={(e) => {
                                  let val = (e.target.checked) ? '1' : '0'
                                  setFieldValue("active", val)
                                }}
                                onBlur={handleBlur}
                                checked={
                                  values.active.toString() === '1'
                                }
                              />
                            }
                            label="Active"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <SubmitBtn
                            disabled={this.state.disableForm}
                            onClear={() => resetForm(defaultState)}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </fieldset>
                </form>
              </Paper>
          }
          }
        />
      </Grid>
    )
  }
}
export default withStyles(Formstyles)(CompanyForm)