import * as React from 'react';
import { 
  EditingState,
  SortingState,
  IntegratedSorting,
  FilteringState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  TableColumnReordering,
  TableFilterRow,
  PagingPanel
} from '@devexpress/dx-react-grid-material-ui';

import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import TableCell from '@material-ui/core/TableCell';

import { DatePickerWidget } from 'lib/widgets'
import { formatDate } from 'lib/helper'

const getRowId = row => row.id;

const EditButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Edit row">
    <EditIcon />
  </IconButton>
);

const CommitButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Save changes">
    <SaveIcon />
  </IconButton>
);

const CancelButton = ({ onExecute}) => (
  <IconButton color="secondary" onClick={onExecute} title="Cancel changes">
    <CancelIcon />
  </IconButton>
);

const commandComponents = {
  edit: EditButton,
  commit: CommitButton,
  cancel: CancelButton,
};

const dateInput = ['manufacturing_date', 'expired_date', 'receive_date']

const EditCell = (props) => {
  const { column, onValueChange, value } = props
  if (dateInput.includes(column.name)) {
    return <TableCell>
              <DatePickerWidget 
                name={column.name}
                value={value} 
                onValueChange={value => {
                  onValueChange(formatDate(value))
                }}/>
            </TableCell>
  }
  return <TableEditRow.Cell {...props} />;
};


export class BatchTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      currentUrl: '',
      columns:[
        { name: 'external_batch', title: 'Batch No.' },
        { name: 'product_name', title: 'สินค้า' },
        { name: 'manufacturing_date', title: 'วันที่ผลิต' },
        { name: 'expired_date', title: 'วันที่หมดอายุ' },
        { name: 'receive_date', title: 'วันที่รับ' }
      ],
      editingStateColumnExtensions: [
        { columnName: 'external_batch', editingEnabled: false, width: 225 },
        { columnName: 'product_name', editingEnabled: false, width: 225 },
        { columnName: 'manufacturing_date', width: 105 },
        { columnName: 'expired_date', width: 106 },
        { columnName: 'receive_date', width: 125 }
      ],
      pageSize: 0,
      pageSizes: [5, 10, 0],
    }
  }

  command = ({ id, onExecute }) => {
    const CommandButton = commandComponents[id];
    return (
      <CommandButton
        onExecute={onExecute}
      />
    );
  }

  commitChanges = ({ changed }) => {
    let { rows } = this.state;
   
    if (changed) {
      rows = rows.map(row => {
        if(changed[row.id]) {
          this.props.onEdit(row.id, changed[row.id])
          return { ...row, ...changed[row.id] }
        }
        return row
      })
    }

    this.setState({ rows });
  }

  changeCurrentPage = currentPage => this.setState({ currentPage });
  changePageSize = pageSize => this.setState({ pageSize })

  componentDidMount() {
    this.setState({ rows: this.props.rows })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ rows: nextProps.rows })
  }

  render() {
    const { 
      rows, 
      columns,
      editingStateColumnExtensions,
      pageSizes,
      pageSize,
      currentPage 
    } = this.state

    return (
      <Paper>
        <Grid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
        >
          <EditingState
            onCommitChanges={this.commitChanges}
            defaultEditingRowIds={[0]}
            columnExtensions={editingStateColumnExtensions}
          />
          <FilteringState />
          <SortingState
            defaultSorting={[
              { columnName: 'external_batch', direction: 'asc' },
            ]}
          />
         
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={this.changeCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={this.changePageSize}
          />
          <IntegratedPaging />
          <IntegratedSorting />
          <IntegratedFiltering /> 
          <VirtualTable/>
          <TableHeaderRow showSortingControls/>
          <TableColumnReordering defaultOrder={columns.map(column => column.name)} />
          <TableFilterRow />
          <TableEditRow cellComponent={EditCell}/>
          <TableEditColumn
            width={80}
            showEditCommand
            commandComponent={this.command}
          />
          <PagingPanel
            pageSizes={pageSizes}
          />
        </Grid>
      </Paper>
    );
  }
}

BatchTable.defaultProps = {
  data : []
}
