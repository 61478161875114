import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { push } from 'connected-react-router'
import { headerWithAuth, handleUnAutherize } from 'lib/authFunc'
import * as actions from './actions'

const header = headerWithAuth()

function* loadWebGroups(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.get,
      process.env.REACT_APP_API_URL + `/api/user-groups${term}`,
      header
    )
    yield put(actions.loadWebGroups.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadWebGroups.failure(error.response))
  }
}

function* loadWebGroup(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.get, process.env.REACT_APP_API_URL + `/api/user-groups/${id}`, header)
    yield put(actions.loadWebGroup.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadWebGroup.failure(error.response))
  }
}

function* createWebGroup(action) {
  try {
    const { webgroup } = action.payload
    const res = yield call(axios.post, process.env.REACT_APP_API_URL + '/api/user-groups', { webgroup }, header)
    yield put(push(`/master-data/web-groups/${res.data.id}`))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.createWebGroup.failure(error))
  }
}

function* editWebGroup(action) {
  try {
    const { webgroup, id } = action.payload
    const res = yield call(axios.patch, process.env.REACT_APP_API_URL + `/api/user-groups/${id}`, webgroup, header)

    yield put(actions.editWebGroup.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.editWebGroup.failure(error.response))
  }
}

function* deleteWebGroup(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.delete, process.env.REACT_APP_API_URL + `/api/user-groups/${id}`, header)
    yield put(actions.deleteWebGroup.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.deleteWebGroup.failure(error.response))
  }
}


export default function* watchComments() {
  yield all([
    takeEvery(actions.LOAD_WEBGROUPS.REQUEST, loadWebGroups),
    takeEvery(actions.LOAD_WEBGROUP.REQUEST, loadWebGroup),
    takeEvery(actions.CREATE_WEBGROUP.REQUEST, createWebGroup),
    takeEvery(actions.EDIT_WEBGROUP.REQUEST, editWebGroup),
    takeEvery(actions.DELETE_WEBGROUP.REQUEST, deleteWebGroup)
  ])
}
