import {   
  LOAD_SALESORDERS,
  LOAD_SALESORDER,
  CANCEL_SALESORDER,
  ACCEPT_SALESORDER,
  FILTER_SALESORDER,
  GET_SALESORDER,
  FETCH_SALESORDERS_FROM_STORE
 } from './actions'

 import { dataFilter, replaceIdxValue } from 'lib/helper'

const initialState = {
  isLoading: false,
  cancelSuccess: null,
  acceptSuccess: null,
  items: [],
  backupItems:[],
  salesorder: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_SALESORDER:
      return { ...state, 
        items: dataFilter(action.payload.salesorders, action.payload.search),
        backupItems: state.backupItems 
      }
    case GET_SALESORDER:
      const id = state.backupItems.findIndex(salesorder => parseInt(salesorder.id) === parseInt(action.payload) )
      return {
        ...state,
        successSave: null,
        salesorder: [state.backupItems[id]]
      }
    case FETCH_SALESORDERS_FROM_STORE:
      return { ...state,
        successSave: null,
        items: state.backupItems,
        isLoading: false }
    case LOAD_SALESORDERS.REQUEST:
    case LOAD_SALESORDER.REQUEST:
    return {
      ...state,
      items: state.backupItems,
      backupItems: state.backupItems,
      isLoading: true,
      successSave: null
    }
    case CANCEL_SALESORDER.REQUEST:
    case ACCEPT_SALESORDER.REQUEST:
      return { ...state, items: [], isLoading: true }
    case LOAD_SALESORDERS.SUCCESS:
      return { ...state, 
        items:action.payload.salesorders.data,
        backupItems: action.payload.salesorders.data,
        isLoading: false,
        meta: action.payload.salesorders.meta
      }
    case LOAD_SALESORDER.SUCCESS:
      return { ...state, salesorder: [action.payload.salesorder], isLoading: false, successSave: null}
    case CANCEL_SALESORDER.SUCCESS:
    const idx = state.backupItems.findIndex(company => parseInt(company.id) === parseInt(action.payload.salesorder.id) )
      return { ...state,
        items: replaceIdxValue(state.backupItems, idx, action.payload.salesorder),
        backupItems: replaceIdxValue(state.backupItems, idx, action.payload.salesorder),
        salesorder: [action.payload.salesorder],
        cancelSuccess: true, 
        isLoading: false 
      }
    case ACCEPT_SALESORDER.SUCCESS: 
      return { ...state, 
        items: state.backupItems,
        salesorder: [action.payload.collections],
        acceptSuccess: true, 
        isLoading: false }
    case LOAD_SALESORDERS.FAILURE:
    case LOAD_SALESORDER.FAILURE:
    case CANCEL_SALESORDER.FAILURE:
      return { ...state, cancelSuccess: true }
    case ACCEPT_SALESORDER.FAILURE:
      return { ...state, acceptSuccess: false }
    default:
      return state
  }
}

