import React, { Component, Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'

import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Header from './Header'
import Sidebar from './Sidebar'

import { PrivateRoute } from './PrivateRoute'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'

import { styles } from './AddonStyle'

import Home from 'modules/home/Home'
import Salesforce from 'modules/salesforce/Salesforce'
import Warehouse from 'modules/warehouse/Warehouse'
import Dashboard from 'modules/dashboard/Dashboard'
import Report from 'modules/reports/Report'
import Masterdata from 'modules/masterdata/Masterdata'
import Typography from '@material-ui/core/Typography'



import HomeMenu from './HomeMenu'
import AllNotiList from './noti/AllNotiList'
import NoRights from './NoRights'


const theme = createMuiTheme({
  overrides: {
    MuiCardMedia: {
      root: {
        backgroundSize: '70px'
      }
    },
    MuiTypography: {
      body1: {
        fontSize: '0.85rem',
      }
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: '#d6d6d6',
        },
        '&:focus': {
          backgroundColor: '#d6d6d6',
        },
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
        background: ' #e3f2fd8c'
      },
      elevation4: {
        boxShadow: 'none'
      },
      rounded: {
        borderRadius: '0px'
      }
    },
    MuiDrawer: {
      paperAnchorRight: {
        overflowX: 'hidden'
      },
      docked: {
        height: '100vh'
      },
      paperAnchorDockedLeft: {
        borderRight: 'none'
      }
    },
  },
  palette: {
    background: { paper: "#fff", default: "#e9eef3" },
    common: { black: "#000", white: "#fff" },
    primary: {
      light: "#89bdff",
      main: "#4e8df6",
      dark: "#0060c3",
      contrastText: "#fff"
    },
    secondary: {
      light: "#ffad42",
      main: "#f57c00",
      dark: "#bb4d00",
      contrastText: "rgba(255, 248, 225, 1)"
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff"
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)"
    }
  },
  typography: { useNextVariants: true },
})


class Layout extends Component {
  state = {
    open: window.innerWidth > 500 ? true : false
  }
  handleDrawerOpen = () => {
    this.setState({ open: true })
  }

  handleDrawerClose = () => {
    this.setState({ open: false })
  }

  render() {
    const { classes } = this.props
    let url = window.location.protocol + '//' + window.location.host + '/'

    if (window.location.href.match(/login/g) || window.location.href === url) {
      return <Route path="/" component={Home} />
    }

    return (
      <Fragment>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <div className={classes.root}>
                <CssBaseline />
                <Header
                  id="nav-header"
                  open={this.state.open}
                  handleDrawerOpen={this.handleDrawerOpen}
                />
                <Drawer
                  variant={window.innerWidth > 500 ? "permanent" : "temporary"}
                  id="nav-drawer"
                  classes={{
                    paper: classNames(
                      classes.drawerPaper,
                      !this.state.open && classes.drawerPaperClose
                    )
                  }}
                  open={this.state.open}
                >
                  <div className={classes.toolbarIcon}>
                    <div className={classes.logo}>
                      <Typography variant="h6" color="inherit" noWrap>
                        <img width={40} src="/img/bisimo2.png" alt="" />
                        <span className={classes.tagline}>BISIMO</span>
                      </Typography>
                    </div>
                    <IconButton onClick={this.handleDrawerClose} style={{ color: '#9db0bf' }}>
                      <ChevronLeftIcon />
                    </IconButton>
                  </div>
                  <Sidebar id="nav-sider" />
                </Drawer>
                <main className={classes.content}>
                  <div className={classes.appBarSpacer} />
                  <Switch>
                    <PrivateRoute path="/home" component={HomeMenu} />
                    <PrivateRoute path="/salesforce" component={Salesforce} />
                    <PrivateRoute path="/master-data" component={Masterdata} />
                    <PrivateRoute path="/warehouse" component={Warehouse} />
                    <PrivateRoute path="/report" component={Report} />
                    <PrivateRoute path="/dashboard" component={Dashboard} />
                    <PrivateRoute path="/notification" component={AllNotiList} />
                    <PrivateRoute path="/*" component={NoRights} />
                  </Switch>
                </main>
              </div>
            </ThemeProvider>
          </MuiThemeProvider>
      </Fragment>
    )
  }
}

export default withStyles(styles)(Layout)
