import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import * as actions from '../actions'
import WebGroupForm from './WebGroupForm'
import { hasMessage } from 'lib/fxUI'
import { defaultState } from '../defaultState'

class CreateWebGroup extends Component {
  createWebGroup = async article => {
    this.props.createWebGroup(article)
  }

  render() {
    const { successSave, isLoading } = this.props
    return (
    <Fragment>
      { successSave !== null && hasMessage(successSave, '') }
      <WebGroupForm
        webgroup={defaultState}
        onSubmit={this.createWebGroup}
        formAction="สร้างใหม่"
        successSave={successSave}
        isLoading={isLoading}
      />
    </Fragment>
    )
  }
}

const mapStateToProps = ({ webgroups }) => ({
  isLoading: webgroups.isLoading,
  successSave: webgroups.successSave
})
const mapDispatchToProps = {
  createWebGroup: actions.createWebGroup.request
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateWebGroup)
