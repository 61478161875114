import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import SearchIcon from '@material-ui/icons/Search'

import { checkAction } from 'lib/authFunc'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    '@media (max-width: 959.95px)' : {
      padding: '0px',
      margin: '0px'
    }
  }
})

function ButtonTable(props) {
  const { classes, onOpen, onDelete, id, displayDel = true, delDisable = false, view } = props
  return (
    <Fragment>
      <IconButton
        color="primary"
        className={classes.button}
        onClick={() => onOpen(id)}
      >
        <SearchIcon />
      </IconButton>
      {displayDel && checkAction(view) && (
        <IconButton
          disabled={delDisable}
          color="secondary"
          className={classes.button}
          onClick={() => onDelete(id)}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Fragment>
  )
}

ButtonTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ButtonTable)
