import React, { Fragment } from 'react'
import Fab from '@material-ui/core/Fab'
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'
import { checkAction } from 'lib/authFunc'

const createDoc = () => {
  let url = window.location.href
  let newUrl = ''

  if (url.match(/\d+$/g)) {
    newUrl = url.replace(/\d+$/, 'new')
    window.location.href = newUrl
  }
}

const checkShowCreate = view => {
  let url = window.location.href
  if(url.match(/salesforce/g)) return false

  return url.match(/\d+$/g) && checkAction(view)  ? true : false
}

const SearchBtn = ({
  classes,
  onClick,
  onPayment,
  onCancel,
  isSearch = true,
  view,
  displayPrint = false
}) => (
  <Fragment>
    {isSearch && (
      <Fab
        id="action_search"
        size="small"
        variant="extended"
        className={classes.fab}
        color="primary"
        onClick={() => onClick(true)}
      >
        <SearchIcon />
        &nbsp;&nbsp;ค้นหา&nbsp;&nbsp;
      </Fab>
    )}

    {checkShowCreate() && (
      <Fab
        id="action_create"
        size="small"
        variant="extended"
        className={classes.fabcreate}
        color="secondary"
        onClick={() => createDoc()}
      >
        <AddIcon />
        &nbsp;&nbsp;&nbsp;สรัาง&nbsp;&nbsp;&nbsp;
      </Fab>
    )}

    {typeof onPayment === 'function' && (
      <Fab
        id="action_payment"
        size="small"
        variant="extended"
        className={classes.fabpayment}
        onClick={() => onPayment(true)}
      >
        &nbsp;&nbsp;ชำระเงิน&nbsp;&nbsp;
      </Fab>
    )}
    {typeof onCancel === 'function' && checkAction("salesorder cancelso") && (
      <Fab
        id="action_cancel"
        color="secondary"
        size="small"
        variant="extended"
        className={classes.fabcancel}
        onClick={() => onCancel(true)}
      >
        &nbsp;&nbsp;ยกเลิก&nbsp;&nbsp;
      </Fab>
    )}


  {window.location.href.match(/sale-orders/g) && displayPrint === true && (
    <Fab
        id="action_cancel"
        size="small"
        variant="extended"
        className={classes.fabprint}
        onClick={() => window.print()}
      >
        &nbsp;พิมพ์&nbsp;
      </Fab>
  )}
 
  </Fragment> 
)

export default SearchBtn

