import {
  LOAD_BINDETAILS,
  LOAD_BINDETAIL,
  CREATE_BINDETAIL,
  EDIT_BINDETAIL,
  DELETE_BINDETAIL,
  FILTER_BINDETAIL,
  GET_BINDETAIL,
  FETCH_BINDETAILS_FROM_BINDETAIL
} from './actions'

import { dataFilter, replaceIdxValue } from 'lib/helper'

const initialState = {
  isLoading: false,
  successSave: null,
  items: [],
  backupItems: [],
  bindetail: [],
  deleteSuccess: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_BINDETAIL:
      return {
        ...state,
        items: dataFilter(action.payload.bindetails, action.payload.search),
        backupItems: state.backupItems
      }
    case GET_BINDETAIL:
      const id = state.backupItems.findIndex(bindetail => parseInt(bindetail.id) === parseInt(action.payload))
      return {
        ...state,
        successSave: null,
        bindetail: [state.backupItems[id]]
      }
    case FETCH_BINDETAILS_FROM_BINDETAIL:
      return {
        ...state,
        successSave: null,
        items: state.backupItems,
        isLoading: false
      }
    case LOAD_BINDETAILS.REQUEST:
    case LOAD_BINDETAIL.REQUEST:
    case DELETE_BINDETAIL.REQUEST:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: true,
        successSave: null,
        deleteSuccess: null
      }
    case CREATE_BINDETAIL.REQUEST:
    case EDIT_BINDETAIL.REQUEST:
      return { ...state, items: [], isLoading: true }
    case LOAD_BINDETAILS.SUCCESS:
      return {
        ...state,
        items: action.payload.bindetails.data,
        backupItems: action.payload.bindetails.data,
        isLoading: false,
        meta: action.payload.bindetails.meta
      }
    case LOAD_BINDETAIL.SUCCESS:
      return { ...state, bindetail: [action.payload.bindetail], isLoading: false, successSave: null }
    case CREATE_BINDETAIL.SUCCESS:
      return {
        ...state,
        items: [action.payload.bindetail, ...state.backupItems],
        backupItems: [action.payload.bindetail, ...state.backupItems],
        isLoading: false,
        successSave: true
      }
    case EDIT_BINDETAIL.SUCCESS:
      const idx = state.backupItems.findIndex(bindetail => parseInt(bindetail.id) === parseInt(action.payload.bindetail.id))
      return {
        ...state,
        items: replaceIdxValue(state.backupItems, idx, action.payload.bindetail),
        backupItems: replaceIdxValue(state.backupItems, idx, action.payload.bindetail),
        bindetail: [action.payload.bindetail],
        isLoading: false,
        successSave: true
      }
    case DELETE_BINDETAIL.SUCCESS:
      const index = state.backupItems.findIndex(bindetail => parseInt(bindetail.id) === parseInt(action.payload.id))
      return {
        ...state.backupItems.splice(index, 1),
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: null,
        deleteSuccess: true
      }
    case LOAD_BINDETAILS.FAILURE:
    case LOAD_BINDETAIL.FAILURE:
    case CREATE_BINDETAIL.FAILURE:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: false
      }
    case EDIT_BINDETAIL.FAILURE:
    case DELETE_BINDETAIL.FAILURE:
      return { ...state, items: [], isLoading: false, successSave: false }
    default:
      return state
  }
}

