import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import MUIDataTable from "mui-datatables";

import Grid from '@material-ui/core/Grid';

import { numberFormat } from 'lib/helper'

const styles = {
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
};

const columns = [
    {
        name: "name",
        label: "ร้านค้า",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "shop_type",
        label: "ประเภท",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "start_date",
        label: "เข้าร่วม",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "sales",
        label: "เซลล์",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "net",
        label: "ยอดเงิน",
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                return numberFormat(value)
            }
        }
    }
];

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class TopDialog extends React.Component {
    state = {
        open: false,
        isLoading: false
    };

    handleClickOpen = () => {
        this.setState({ open: true, isLoading: true });
    };

    handlePrint = () => {
        window.print()
    }

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes, data } = this.props;
        const options = {
            filter: false,
            viewColumns: false,
            print: false,
            download: false,
            selectableRows: false,
            customSort: (data, colIndex, order) => {
                return data.sort((a, b) => {
                    a = a.data[colIndex] || '';
                    b = b.data[colIndex] || '';
                    if (order === 'asc') {
                        return a - b || a.toString().localeCompare(b, undefined, { numeric: true });
                    }
                    return b - a || b.toString().localeCompare(a, undefined, { numeric: true });

                });
            }
        }

        return (
            <Fragment>
                <div style={{ textAlign: 'right', marginBottom: '10px' }}>
                    <Dialog
                        fullScreen
                        open={this.props.open}
                        onClose={this.props.onClose}
                        TransitionComponent={Transition}
                    >
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <Typography variant="h6" color="inherit" className={classes.flex}>

                                </Typography>
                                <IconButton color="inherit" onClick={this.props.onClose} aria-label="Close">
                                    <CloseIcon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <Grid>
                            <MUIDataTable
                                title={`ข้อมูล${this.props.title}`}
                                data={data}
                                columns={columns}
                                pagination={false}
                                options={options}
                            />
                        </Grid>
                    </Dialog>
                </div>
            </Fragment>
        );
    }
}

export default withStyles(styles)(TopDialog);