import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import * as actions from '../actions'
import BinDetailForm from './BinDetailForm'

import { defaultState } from '../defaultState'
import { hasMessage } from 'lib/fxUI'

class CreateBinDetail extends Component {
  createBinDetail = async data => {
    this.props.createBinDetail(data)
  }

  render() {
    const { successSave, isLoading } = this.props
    return (
      <Fragment>
      {successSave !== null && hasMessage(successSave, '')}
      <BinDetailForm
        onSubmit={this.createBinDetail}
        formAction="สร้างใหม่"
        successSave={successSave}
        isLoading={isLoading}
        data={defaultState}
      />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ bindetails }) => ({
  successSave: bindetails.successSave,
  isLoading: bindetails.isLoading
})
const mapDispatchToProps = {
  createBinDetail: actions.createBinDetail.request
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateBinDetail)
