import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

import { config } from 'lib/authFunc'

function* login(action) {
  try {
    const { userslogin } = action.payload
    const res = yield call(axios.post, process.env.REACT_APP_API_URL + '/api/login', { userslogin }, config.headers)
    localStorage.setItem('user', JSON.stringify(res.data))
    window.location.href = '/home'
  } catch (error) {
    yield put(actions.login.failure(error.response.data))
  }
}

export default function* watchComments() {
  yield all([
    takeEvery(actions.LOGIN.REQUEST, login)
  ])
}
