import {
  LOAD_USERS,
  LOAD_USER,
  CREATE_USER,
  EDIT_USER,
  DELETE_USER,
  FILTER_USER,
  GET_USER,
  FETCH_USERS_FROM_STORE
} from './actions'

import { dataFilter, replaceIdxValue } from 'lib/helper'

const initialState = {
  isLoading: false,
  successSave: null,
  errormsg: '',
  items: [],
  backupItems: [],
  user: [],
  deleteSuccess: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_USER:
      return {
        ...state,
        items: dataFilter(action.payload.users, action.payload.search),
        backupItems: state.backupItems
      }
    case GET_USER:
      const id = state.backupItems.findIndex(user => user.id === action.payload)
      return {
        ...state,
        successSave: null,
        user: [state.backupItems[id]]
      }
    case FETCH_USERS_FROM_STORE:
      return {
        ...state,
        successSave: null,
        items: state.backupItems,
        isLoading: false
      }
    case LOAD_USERS.REQUEST:
    case LOAD_USER.REQUEST:
    case DELETE_USER.REQUEST:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: true,
        successSave: null,
        deleteSuccess: null
      }
    case CREATE_USER.REQUEST:
    case EDIT_USER.REQUEST:
      return { ...state, items: [], isLoading: true }
    case LOAD_USERS.SUCCESS:
      return {
        ...state,
        items: action.payload.users.data,
        backupItems: action.payload.users.data,
        isLoading: false,
        meta: action.payload.users.meta
      }
    case LOAD_USER.SUCCESS:
      return { ...state, user: [action.payload.user], isLoading: false, successSave: null }
    case CREATE_USER.SUCCESS:
      return {
        items: [action.payload.user, ...state.backupItems],
        backupItems: [action.payload.user, ...state.backupItems],
        isLoading: false,
        successSave: true,
        errormsg: ''
      }
    case EDIT_USER.SUCCESS:
      const idx = state.backupItems.findIndex(user => user.id === action.payload.user.id)
      return {
        ...state,
        items: replaceIdxValue(state.backupItems, idx, action.payload.user),
        backupItems: replaceIdxValue(state.backupItems, idx, action.payload.user),
        user: [action.payload.user],
        isLoading: false,
        successSave: true,
        errormsg: ''
      }
    case DELETE_USER.SUCCESS:
      const index = state.backupItems.findIndex(user => user.id === action.payload.id)
      return {
        ...state.backupItems.splice(index, 1),
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: null,
        deleteSuccess: true
      }
    case LOAD_USERS.FAILURE:
    case LOAD_USER.FAILURE:
    case CREATE_USER.FAILURE:
    case EDIT_USER.FAILURE:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: false,
        errormsg: action.payload.error
      }
    case DELETE_USER.FAILURE:
      return { ...state, items: [], isLoading: false, successSave: false }
    default:
      return state
  }
}

