import {
  LOAD_UNITS,
  LOAD_UNIT,
  CREATE_UNIT,
  EDIT_UNIT,
  DELETE_UNIT,
  FILTER_UNIT,
  GET_UNIT,
  FETCH_UNITS_FROM_STORE
} from './actions'

import { dataFilter, replaceIdxValue } from 'lib/helper'

const initialState = {
  isLoading: false,
  successSave: null,
  items: [],
  backupItems: [],
  unit: [],
  deleteSuccess: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_UNIT:
      return {
        ...state,
        items: dataFilter(action.payload.units, action.payload.search),
        backupItems: state.backupItems
      }
    case GET_UNIT:
      const id = state.backupItems.findIndex(unit => parseInt(unit.id) === parseInt(action.payload))
      return {
        ...state,
        successSave: null,
        unit: [state.backupItems[id]]
      }
    case FETCH_UNITS_FROM_STORE:
      return {
        ...state,
        successSave: null,
        items: state.backupItems,
        isLoading: false
      }
    case LOAD_UNITS.REQUEST:
    case LOAD_UNIT.REQUEST:
    case DELETE_UNIT.REQUEST:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: true,
        successSave: null,
        deleteSuccess: null
      }
    case CREATE_UNIT.REQUEST:
    case EDIT_UNIT.REQUEST:
      return { ...state, items: [], isLoading: true }
    case LOAD_UNITS.SUCCESS:
      return {
        ...state,
        items: action.payload.units.data,
        backupItems: action.payload.units.data,
        meta: action.payload.units.meta,
        isLoading: false
      }
    case LOAD_UNIT.SUCCESS:
      return { ...state, unit: [action.payload.unit], isLoading: false, successSave: null }
    case CREATE_UNIT.SUCCESS:
      return {
        ...state,
        items: [action.payload.unit, ...state.backupItems],
        backupItems: [action.payload.unit, ...state.backupItems],
        isLoading: false,
        successSave: true
      }
    case EDIT_UNIT.SUCCESS:
      const idx = state.backupItems.findIndex(unit => parseInt(unit.id) === parseInt(action.payload.unit.id))
      return {
        ...state,
        items: replaceIdxValue(state.backupItems, idx, action.payload.unit),
        backupItems: replaceIdxValue(state.backupItems, idx, action.payload.unit),
        unit: [action.payload.unit],
        isLoading: false,
        successSave: true
      }
    case DELETE_UNIT.SUCCESS:
      const index = state.backupItems.findIndex(unit => parseInt(unit.id) === parseInt(action.payload.id))
      return {
        ...state.backupItems.splice(index, 1),
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: null,
        deleteSuccess: true
      }
    case LOAD_UNITS.FAILURE:
    case LOAD_UNIT.FAILURE:
    case CREATE_UNIT.FAILURE:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: false
      }
    case EDIT_UNIT.FAILURE:
    case DELETE_UNIT.FAILURE:
      return { ...state, items: [], isLoading: false, successSave: false }
    default:
      return state
  }
}

