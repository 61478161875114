/** DATE FUNCTION ***/
const addDate = (date, days) => {
  let newDate = new Date(date);
  let val = new Date(date);
  return val.setDate(newDate.getDate() + days);
}
const calcDiffDate = (start, end) => {
  const diffTime = Math.abs(new Date(start) - new Date(end));
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays
}

const formatDate = (date, format) => {
  if (date === null) return ''
    let newDate = new Date(date.toString().replace(/-/g, '/')),
    year = newDate.getFullYear(),
    month = newDate.getMonth() + 1,
    dt = newDate.getDate()

  if (dt < 10) {
    dt = '0' + dt
  }
  if (month < 10) {
    month = '0' + month
  }

  if (format === 'd-m-Y') return dt + '-' + month + '-' + year
  else return year + '-' + month + '-' + dt
}

const getCurrentYear = () => {
  var d = new Date();
  return d.getFullYear();
}

const getCurrentMonth = () => {
  var d = new Date();
  return d.getMonth();
}

const getCurrentMonthIdx = () => {
  let d = new Date()
  return d.getMonth()
}

const getFirstAndLastDate = (date, isFirst, format) => {
  if (date === null) date = new Date()
  let day = new Date(date.getFullYear(), date.getMonth(), 1)
  if (!isFirst) day = new Date(date.getFullYear(), date.getMonth() + 1, 0)

  return formatDate(day, format)
}

const getMonthTH = () => {
  let idx = getCurrentMonthIdx(), month = ''
  month = monthList(idx)
  return month
}

const getToday = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  
  return yyyy + '-' + mm + '-' + dd;
}

const getQuarter = (val = null) => {
  let date = val
  if(date === null) date = new Date()
  let month = date.getMonth() + 1;
  return (Math.ceil(month / 3));
}

const monthList = (idx) => {
  let month = ''
  switch(idx) {
    case 0 : month = 'มกราคม'; break;
    case 1 : month = 'กุมภาพันธ์'; break;
    case 2 : month = 'มีนาคม'; break;
    case 3 : month = 'เมษายน'; break;
    case 4 : month = 'พฤษภาคม'; break;
    case 5 : month = 'มิถุนายน'; break;
    case 6 : month = 'กรกฎาคม'; break;
    case 7 : month = 'สิงหาคม'; break;
    case 8 : month = 'กันยายน'; break;
    case 9 : month = 'ตุลาคม'; break;
    case 10 : month = 'พฤศจิกายน'; break;
    case 11 : month = 'ธันวาคม'; break;
    default:break;
  }
  return month
}
/** END DATE FUNCTION ***/


/** NUMBER FUNCTION ***/
const numberFormat = (number, decimal=2) => {
  if (typeof number !== 'string' && typeof number !== 'number') return
  return parseFloat(removeComma(number))
    .toFixed(decimal)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const removeComma = number => {
  return number.toString().replace(/,/g, '')
}
/** END NUMBER FUNCTION ***/


/** URL FUNCTION ***/
const deserializeUrl = (url) => {
  let str = url.replace(/\?/, '').split('&')

  let params = {}
  
  str.map(data => {
      let string = data.split('=')
      return params[string[0]] = string[1]
  })

  return params

}

const getUrlLastSegment = (url='') => {
  let urlStr = (url === '') ? window.location.href : url
  return urlStr.match(/\/([a-z0-9_-]*[\/]?)$/g)[0].replace(/\//g, '')
}

const getUrlParam = (parameter, defaultvalue) => {
  var urlparameter = defaultvalue;
  if(window.location.href.indexOf(parameter) > -1){
        urlparameter = getUrlVars()[parameter];
     }
  return urlparameter;
}

const getUrlVars = () => {
  var vars = {};

  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
      vars[key] = value;
  });

  return vars;
}
/** END URL FUNCTION ***/

/** GENERAL FUNCTION ***/
const createCondition = (data, search) => {
  return Object.keys(data)
  .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(search))
  .join('&')
}

const dataFilter = (items, search, searchKey) => {
  let nesStr = search.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
  var reg = new RegExp(nesStr, 'i')
  let output = []

  if (typeof items === 'undefined') return

  if(typeof searchKey === 'undefined') {
     output = items.filter(
      obj => Object.values(obj).findIndex(val => String(val).match(reg)) > -1
    )
  } else {
    output = items.filter(
      obj => obj[searchKey].match(reg)
    )
  }

  return output
}

const displayOptionLabel = (arr, currentItem, name) => {
  
  if (typeof currentItem[name] === 'undefined' || typeof arr !== 'object')
    return
  let id = arr.findIndex(
    data => parseInt(data.value) === parseInt(currentItem[name])
  )
  if (id === -1) return

  return arr[id].label
}

const dynamicColors = () => {
  var r = Math.floor(Math.random() * 255);
  var g = Math.floor(Math.random() * 255);
  var b = Math.floor(Math.random() * 255);
  return "rgb(" + r + "," + g + "," + b + ")";
}

const getIdtoArray = (data, keyValu='id') => {
  let arr = []
  Object.keys(data).reduce((obj, key) => {
    if(keyValu === 'id') {
      return arr.push(parseInt(data[key][keyValu]))
    } 

    return arr.push(data[key][keyValu])
    
  }, {})
  return arr
}

const genOptionList = (arr, value, label) => {
  let options = []
  if (typeof arr !== 'object') return

  arr.map(data => {
    return options.push({ value: data[value], label: data[label] })
  })

  return options
}

const genRelateOptionList = (arr, value, label, master = '', fk = 'id') => {
  let options = []
  if (typeof arr !== 'object') return
  arr.map(data => {
    data.relations[master].map(item => {
      return options.push({
        value: item[value],
        label: item[label],
        fk: item[fk]
      })
    })
  })

  return options
}

const replaceIdxValue = (arr, index, newVal) => {
  if (index === -1) return
  return [...arr.slice(0, index), newVal, ...arr.slice(index + 1)]
}

const replaceKeyValue = (rows, data, curIdx) => {
  let idx = rows.findIndex(data => parseInt(data.id) === parseInt(curIdx))
  if(idx === -1) return 
  let newVal = rows[idx]
  let key = Object.keys(data[curIdx])
  
  key.map(name => {
     if(name.match(/[0-9]/)) return true
     newVal[name] = data[curIdx][name]
  })

  return newVal
}

const replaceOnlyHeader = (arr, index, newVal, detail) => {
  if (index === -1) return
  var oldItem = {
    ...newVal,
    relations: { [detail]: arr[index].relations[detail] }
  }
  return [...arr.slice(0, index), oldItem, ...arr.slice(index + 1)]
}

const serializeObj = obj =>
  Object.keys(obj)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
    .join('&')


/** END GENERAL FUNCTION ***/

export {
  addDate,
  calcDiffDate,
  createCondition,
  dataFilter,
  deserializeUrl,
  displayOptionLabel,
  dynamicColors,
  formatDate,
  getCurrentMonth,
  getCurrentMonthIdx,
  getCurrentYear, 
  getFirstAndLastDate,
  getIdtoArray,
  getMonthTH,
  genOptionList,
  genRelateOptionList,
  getToday,
  getUrlLastSegment,
  getUrlParam,
  getQuarter,
  monthList,
  numberFormat,
  removeComma,
  replaceIdxValue,
  replaceKeyValue,
  replaceOnlyHeader,
  serializeObj,
}
