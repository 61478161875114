import * as React from 'react';
import { EditingState } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';

import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { genOptionList } from 'lib/helper'
import {
  loadOptions,
  Cell,
  commandComponents,
  getRowId
} from './RelateFunc'

import { LookupEditCell, Number } from 'lib/widgets'
import { checkAction } from 'lib/authFunc'

const editingStateColumnExtensions = [
  { columnName: 'uom_id', width: 100 },
  { columnName: 'code', width: 100 },
  { columnName: 'description', width: 240 },
  { columnName: 'barcode', width: 120 },
  { columnName: 'cost', width: 50 },
  { columnName: 'weight', width: 50 },
  { columnName: 'from_qty', width: 50 },
  { columnName: 'from_uom_id', width: 100 },
  { columnName: 'lower_qty', width: 50 },
  { columnName: 'upper_qty', width: 50 },
  { columnName: 'default_wh', width: 130 },
  { columnName: 'is_favorite', width: 30 },
]

export class ProductUnits extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      currentUrl: '',
      columns: [
        { name: 'uom_id', title: 'หน่วยนับ' },
        { name: 'code', title: 'รหัส' },
        { name: 'description', title: 'รายละเอียด' },
        { name: 'barcode', title: 'บาร์โค้ด *' },
        { name: 'cost', title: 'ต้นทุน' },
        { name: 'weight', title: 'น้ำหนัก' },
        { name: 'from_qty', title: 'จากจำนวน *' },
        { name: 'from_uom_id', title: 'จากหน่วยนับ *' },
        { name: 'lower_qty', title: 'จำนวนต่ำสุด' },
        { name: 'upper_qty', title: 'จำนวนสูงสุด' },
        { name: 'default_wh', title: 'คลังสินค้า' },
        { name: 'is_favorite', title: 'สินค้าโปรด' },
      ],
      uom_id: [],
      default_wh: [],
      curIdx: '',
      disableSave: true,
      disableEdit: false,
      isLoading: true,
    }
  }

  command = ({ id, onExecute }, disableSave, disableEdit, disableDel) => {
    const CommandButton = commandComponents[id];
    return (
      <CommandButton
        onExecute={onExecute}
        disabled={disableSave}
        disableEdit={disableEdit}
        disableDel={this.state.rows.length === 1}
      />
    );
  }

  commitChanges = ({ added, changed, deleted }) => {
    let { rows, uom_id, default_wh } = this.state;
    if (added) {
      const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      rows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          lineNo: startingAddedId + index,
          product_id: this.props.ProductId,
          ...row,
        })),
      ];
      this.props.onCreate(rows[rows.length - 1])
      const currentItem = rows[rows.length - 1]
      const whIdx = default_wh.findIndex(data => data.value === currentItem.default_wh)
      const frmIdx = uom_id.findIndex(data => parseInt(data.value) === currentItem.from_uom_id)
      rows[rows.length - 1].uom_name = uom_id[uom_id.findIndex(data => parseInt(data.value) === currentItem.uom_id)].label
      rows[rows.length - 1].from_uom_name = (frmIdx !== -1) ? uom_id[frmIdx].label : ''
      rows[rows.length - 1].warehouse_name = (whIdx !== -1) ? default_wh[whIdx].label : ''
    }
    if (changed) {
      rows = rows.map(row => {
        if (changed[row.id]) {
          let data = changed[row.id]
          data.product_id = this.props.ProductId
          data.old_uom = row.uom_id
          this.props.onEdit(row.id, data)
          return { ...row, ...changed[row.id] }
        }
        return row
      })
    }
    if (deleted) {
      const deletedSet = new Set(deleted);
      let idx = rows.findIndex(row => parseInt(row.id) === parseInt(deleted[0]))

      this.props.onDelete(deleted[0], rows[idx])
      rows = rows.filter(row => !deletedSet.has(row.id));
    }
    this.setState({ rows: changeRowId(rows) });
  }

  getAvoidUomId = () => {
    return this.state.rows.map(val => val.uom_id)
  }

  editCell = (props) => {
    let { column, value, onValueChange, row } = props;
    const { lineNo } = row
    const rowState = this.state.rows

    if (column.name === 'default_wh' || column.name === 'uom_id') {
      let availableColumnValues = this.state[column.name];

      if (column.name === 'uom_id') {
        let useId = this.getAvoidUomId()
        useId.map(val => {
          let idx = availableColumnValues.findIndex(a => a.value.toString() === val.toString())
          availableColumnValues[idx].isDisabled = true
          return true
        })
      }

      if (availableColumnValues) {
        return <LookupEditCell {...props}
          availableColumnValues={availableColumnValues}
          disabled={lineNo === 1 && column.name === 'uom_id' ? true : false} />;
      }
    }

    if (lineNo === 1) {
      if (column.name === 'from_qty' || column.name === 'from_uom_id')
        return <TableCell></TableCell>
    }

    if (lineNo !== 1) {
      if (column.name === 'from_uom_id') {
        let lastLine = typeof lineNo === 'undefined' ? rowState[rowState.length - 1].lineNo : lineNo - 1,
          idx = rowState.findIndex(item => parseInt(item.lineNo) === parseInt(lastLine)),
          uomIdx = this.state.uom_id.findIndex(data => data.value === rowState[idx].uom_id)

        const { label } = this.state.uom_id[uomIdx]
        return <TableCell>
          <Select
            value={value}
            onChange={event => onValueChange(event.target.value, 'from_uom_id')}
            style={{ width: '100%' }}
            input={(
              <Input
                name="from_uom_id"
              />
            )}
          >
            <MenuItem value=""></MenuItem>
            <MenuItem value={rowState[idx].uom_id}>{label}</MenuItem>
          </Select>
        </TableCell>
      }
    }

    if (column.name === 'is_favorite') {
      return <TableCell>
        <Checkbox
          color="primary"
          value="1"
          onChange={event => onValueChange(event.target.checked === true ? 1 : -1)}
          checked={value === 1} />
      </TableCell>
    }

    const numberInput = ['from_qty', 'cost', 'weight', 'lower_qty', 'upper_qty']

    if (numberInput.includes(column.name)) {
      return <TableCell>
        <Number
          value={value}
          onChange={value => {
            onValueChange(value, column.name)
          }
          }
        />
      </TableCell>
    }

    return <TableEditRow.Cell {...props} />;
  }

  componentWillReceiveProps(nextProps) {
    const { rows } = nextProps
    this.setState({ rows: changeRowId(rows), currentUrl: window.location.pathname })
  }

  componentDidMount() {
    this.setState({
      rows: changeRowId(this.props.rows),
      currentUrl: window.location.pathname,
      disableDel: this.props.rows[0].id
    }, () => {
      loadOptions().then(res => {
        this.setState({
          uom_id: genOptionList(res[0].uom_id, 'id', 'name'),
          default_wh: genOptionList(res[0].default_wh, 'id', 'name'),
          isLoading: false
        })
      })
    })
  }

  validateRow = (addedRows, action) => {
    const { curIdx, rows } = this.state
    let cnt = 0
    let data = (action === 'edit') ? addedRows[curIdx] : addedRows[0]
    let idx = rows.findIndex(data => parseInt(data.id) === parseInt(curIdx))
    let arrName = ['uom_id', 'barcode', 'from_qty', 'from_uom_id']

    this.setState({ disableSave: true, disableEdit: true })

    if (typeof data === 'undefined') {
      this.setState({ disableEdit: false })
      return
    }

    if (action === 'edit') {
      let idx = rows.findIndex(data => data.id === curIdx)
      let res = rows[idx]

      arrName.map(name => {
        if (data.hasOwnProperty(name) === false) {
          data[name] = res[name]
        }
        return true
      })
    }

    if (typeof data.uom_id !== 'undefined') {
      if (data.uom_id !== '') cnt++
    }

    if (typeof data.barcode !== 'undefined') {
      if (data.barcode !== '') cnt++
    }

    if (typeof data.from_qty !== 'undefined') {
      if (data.from_qty !== '') cnt++
    }

    if (typeof data.from_uom_id !== 'undefined') {
      if (data.from_uom_id !== '') cnt++
    }

    // if(typeof data.lower_qty !== 'undefined') {

    //   if(data.lower_qty >= 0 && data.lower_qty !== null && data.lower_qty !== '') cnt++
    // }

    // if(typeof data.upper_qty !== 'undefined') {
    //   if(data.upper_qty >= 0 && data.upper_qty !== null && data.upper_qty !== '') cnt++
    // }

    if (idx > -1) {
      if (rows[idx].from_uom_id === '') {
        if (typeof data.barcode !== 'undefined') {
          if (data.barcode !== '') this.setState({ disableSave: false })
        }
      }
    }

    if (cnt >= 4) {
      this.setState({ disableSave: false })
    }

  }

  getEditID = data => {
    this.setState({ curIdx: data[0] })
  }

  render() {
    const { rows, columns, disableSave, disableEdit, disableDel } = this.state;
    return (
      <Grid
        rows={rows}
        columns={columns}
        getRowId={getRowId}
      >
        <EditingState
          onCommitChanges={this.commitChanges}
          columnExtensions={editingStateColumnExtensions}
          onAddedRowsChange={(addedRows) => this.validateRow(addedRows, 'add')}
          onRowChangesChange={(addedRows) => this.validateRow(addedRows, 'edit')}
          onEditingRowIdsChange={this.getEditID}
        />
        <Table cellComponent={Cell} width={1086} />
        <TableHeaderRow />
        <TableEditRow cellComponent={this.editCell} />
        {this.props.ProductId > 0 && checkAction("product edit") && this.state.isLoading === false &&
          <TableEditColumn
            width={80}
            showAddCommand
            showEditCommand
            showDeleteCommand
            commandComponent={props => this.command(props, disableSave, disableEdit, disableDel)}
          />}
      </Grid>
    );
  }
}

ProductUnits.defaultProps = {
  rows: []
}

const changeRowId = (rows) => {
  return rows.map((data, i) => {
    data.lineNo = i + 1
    return data
  })
}
