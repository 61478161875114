import {
  LOAD_BATCHES,
  EDIT_BATCH,
  FILTER_BATCH,
  GET_BATCH,
  FETCH_BATCHES_FROM_STORE
} from './actions'

import { dataFilter, replaceIdxValue } from 'lib/helper'

const initialState = {
  isLoading: false,
  successSave: null,
  items: [],
  backupItems: [],
  batch: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_BATCH:
      return {
        ...state,
        items: dataFilter(action.payload.batches, action.payload.search),
        backupItems: state.backupItems
      }
    case GET_BATCH:
      const id = state.backupItems.findIndex(batch => parseInt(batch.id) === parseInt(action.payload))
      return {
        ...state,
        successSave: null,
        batch: [state.backupItems[id]]
      }
    case FETCH_BATCHES_FROM_STORE:
      return {
        ...state,
        successSave: null,
        items: state.backupItems,
        isLoading: false
      }
    case LOAD_BATCHES.REQUEST:
    case EDIT_BATCH.REQUEST:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: true,
        successSave: null
      }
    case LOAD_BATCHES.SUCCESS:
      return {
        ...state,
        items: action.payload.batches.data,
        backupItems: action.payload.batches.data,
        isLoading: false,
        meta: action.payload.batches.meta
      }
    case EDIT_BATCH.SUCCESS:
      const idx = state.backupItems.findIndex(batch => parseInt(batch.id) === parseInt(action.payload.batch.id))
      return {
        ...state,
        items: replaceIdxValue(state.backupItems, idx, action.payload.batch),
        backupItems: replaceIdxValue(state.backupItems, idx, action.payload.batch),
        batch: [action.payload.batch],
        isLoading: false,
        successSave: true
      }
    case LOAD_BATCHES.FAILURE:
    case EDIT_BATCH.FAILURE:
      return { ...state, items: [], isLoading: false, successSave: false }
    default:
      return state
  }
}

