import React from 'react'
import { Switch, Route } from 'react-router-dom'

import ProductApp from './product/ProductApp'
import SalesApp from './sales/SalesApp'
import StoreApp from './store/StoreApp'
import SoApp from './so/SoApp'

export default () => (
<Switch>
  <Route path="/report/products" component={ProductApp} />
  <Route path="/report/sales" component={SalesApp} />
  <Route path="/report/stores" component={StoreApp} />
  <Route path="/report/sale-orders" component={SoApp} />
</Switch>
)