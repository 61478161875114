import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as actions from '../actions'
import WebGroupForm from './WebGroupForm'
import { hasMessage } from 'lib/fxUI'
import { defaultState } from '../defaultState'

class EditWebGroup extends Component {
  componentDidMount() {
    const id = this.getWebGroupId()
    this.props.loadWebGroup(id)
  }

  componentDidUpdate(prevProps) {
    const search = this.props.location.pathname
    if (search === prevProps.location.pathname) return

    const id = this.getWebGroupId()
    this.props.loadWebGroupFromArray(id)
  }

  getWebGroupId = () => this.props.match.params.id

  editWebGroup = async user => {
    this.props.editWebGroup(this.getWebGroupId(), user)
  }

  render() {
    const { webgroup, successSave } = this.props
    let data = []

    if (typeof webgroup !== 'undefined') {
      data = typeof webgroup[0] !== 'undefined' ? webgroup[0] : defaultState
    }

    if (data.length === 0) {
      return <div className="lmask" id="lmask" />
    }

    return (
      <Fragment>
        {successSave !== null && hasMessage(successSave, '')}
        <WebGroupForm
          onSubmit={this.editWebGroup}
          formAction="แก้ไข"
          webgroup={data}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ webgroups }) => ({
  webgroup: webgroups.webgroup,
  successSave: webgroups.successSave
})

const mapDispatchToProps = {
  loadWebGroup: actions.loadWebGroup.request,
  editWebGroup: actions.editWebGroup.request,
  loadWebGroupFromArray: actions.loadWebGroupFromArray
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
)(EditWebGroup)
