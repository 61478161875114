import {
  LOAD_COMPANYS,
  LOAD_COMPANY,
  CREATE_COMPANY,
  EDIT_COMPANY,
  DELETE_COMPANY,
  FILTER_COMPANY,
  GET_COMPANY,
  FETCH_COMPANYS_FROM_STORE
} from './actions'

import { dataFilter, replaceIdxValue } from 'lib/helper'

const initialState = {
  isLoading: false,
  successSave: null,
  items: [],
  backupItems: [],
  company: [],
  deleteSuccess: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_COMPANY:
      return {
        ...state,
        items: dataFilter(action.payload.companies, action.payload.search),
        backupItems: state.backupItems
      }
    case GET_COMPANY:
      const id = state.backupItems.findIndex(company => parseInt(company.id) === parseInt(action.payload))
      return {
        ...state,
        successSave: null,
        company: [state.backupItems[id]]
      }
    case FETCH_COMPANYS_FROM_STORE:
      return {
        ...state,
        successSave: null,
        items: state.backupItems,
        isLoading: false
      }
    case LOAD_COMPANYS.REQUEST:
    case LOAD_COMPANY.REQUEST:
    case DELETE_COMPANY.REQUEST:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: true,
        successSave: null,
        deleteSuccess: null
      }
    case CREATE_COMPANY.REQUEST:
    case EDIT_COMPANY.REQUEST:
      return { ...state, items: [], isLoading: true }
    case LOAD_COMPANYS.SUCCESS:
      return {
        ...state,
        items: action.payload.companies.data,
        backupItems: action.payload.companies.data,
        isLoading: false,
        meta: action.payload.companies.meta
      }
    case LOAD_COMPANY.SUCCESS:
      return { ...state, company: [action.payload.company], isLoading: false, successSave: null }
    case CREATE_COMPANY.SUCCESS:
      return {
        ...state,
        items: [action.payload.company, ...state.backupItems],
        backupItems: [action.payload.company, ...state.backupItems],
        isLoading: false,
        successSave: true
      }
    case EDIT_COMPANY.SUCCESS:
      const idx = state.backupItems.findIndex(company => parseInt(company.id) === parseInt(action.payload.company.id))
      return {
        ...state,
        items: replaceIdxValue(state.backupItems, idx, action.payload.company),
        backupItems: replaceIdxValue(state.backupItems, idx, action.payload.company),
        company: [action.payload.company],
        isLoading: false,
        successSave: true
      }
    case DELETE_COMPANY.SUCCESS:
      const index = state.backupItems.findIndex(company => parseInt(company.id) === parseInt(action.payload.id))
      return {
        ...state.backupItems.splice(index, 1),
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: null,
        deleteSuccess: true
      }
    case LOAD_COMPANYS.FAILURE:
    case LOAD_COMPANY.FAILURE:
    case CREATE_COMPANY.FAILURE:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: false
      }
    case EDIT_COMPANY.FAILURE:
    case DELETE_COMPANY.FAILURE:
      return { ...state, items: [], isLoading: false, successSave: false }
    default:
      return state
  }
}

