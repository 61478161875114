import React from 'react';
import { Alert } from 'lib/widgets'
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const hasMessage = (successSave, errormsg, resetState) => {
    if (successSave !== null) {
      if (successSave) {
       let msg = errormsg === '' ? 'บันทึกสำเร็จ' : errormsg
        return <Alert message={msg} 
          type="success" 
          open={true} 
          onClose={resetState}/>
      }
      if (!successSave)
        return (
          <Alert
            message={`เกิดข้อผิดพลาด :: ${errormsg}`}
            type="error" 
            onClose={resetState}
            open={true}
          />
        )
    }
  }

  const emptySubRow = colSpan => {
    return (
      <TableRow>
        <TableCell colSpan={colSpan}>
          ไม่พบข้อมูล
        </TableCell>
      </TableRow>
    );
  }

export {
    hasMessage,
    emptySubRow
}