export const defaultState = {
    id: '',
    active: '1',
    disableForm : false,
    isOpenWeb : true,
    isOpenUser : true,
    name: '',
    search_key: '',
    code: '',
    taxid: '',
    description: '',
    address: '',
    phone: '',
    email: '',
    logo: '',
    latitude: 13.75398,
    longitude: 100.50144,
    image: null,
    advSearch: {
        name: '',
        search_key: '', 
    }
}