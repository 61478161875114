import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import * as actions from '../actions'
import LoginForm from './LoginForm'

import { destroyToken } from 'lib/authFunc'
import { hasMessage } from 'lib/fxUI'

class LoginApp extends Component {
  login = async data => {
    this.props.login(data)
  }

  loginSuccess = () => {
    this.props.history.push('/home')
  }

  componentDidMount() {
    destroyToken()
  }

  render() {
    const { message, loginSuccess, isLoading } = this.props
    return (
      <Fragment>
      { loginSuccess !== 'null' ? hasMessage(loginSuccess, message) : null }
      { isLoading && <div className="lmask" id="lmask" /> }
        <LoginForm 
          onSubmit={this.login} />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ logins }) => ({
  loginSuccess: logins.loginSuccess,
  isLoading: logins.isLoading,
  message: logins.message,
  error: logins.error,
  auth: logins.auth
})

const mapDispatchToProps = {
  login: actions.login.request
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginApp)
