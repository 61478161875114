import { createTypes, createAction } from 'lib/action'
 
const LOAD_BINDETAILS = createTypes('bindetails', 'list')
const LOAD_BINDETAIL = createTypes('bindetails', 'show')
const CREATE_BINDETAIL = createTypes('bindetails', 'create')
const EDIT_BINDETAIL = createTypes('bindetails', 'edit')
const DELETE_BINDETAIL = createTypes('bindetails', 'delete')
const FILTER_BINDETAIL = 'backup'
const SET_BINDETAIL = 'api/bindetails'
const GET_BINDETAIL = 'GET_BINDETAIL'
const FETCH_BINDETAILS_FROM_BINDETAIL = "FETCH_BINDETAILS_FROM_BINDETAIL"

const loadBinDetails = {
  request: term => createAction(LOAD_BINDETAILS.REQUEST, { term }),
  success: bindetails => createAction(LOAD_BINDETAILS.SUCCESS, { bindetails }),
  failure: () => createAction(LOAD_BINDETAILS.FAILURE)
}

const loadBinDetail = {
  request: id => createAction(LOAD_BINDETAIL.REQUEST, { id }),
  success: bindetail => createAction(LOAD_BINDETAIL.SUCCESS, { bindetail }),
  failure: () => createAction(LOAD_BINDETAIL.FAILURE)
}

const createBinDetail = {
  request: bindetail => createAction(CREATE_BINDETAIL.REQUEST, { bindetail }),
  success: bindetail => createAction(CREATE_BINDETAIL.SUCCESS, { bindetail }),
  failure: error => createAction(CREATE_BINDETAIL.FAILURE, { error })
}

const editBinDetail = {
  request: (id, bindetail) => createAction(EDIT_BINDETAIL.REQUEST, { id, bindetail }),
  success: bindetail => createAction(EDIT_BINDETAIL.SUCCESS, { bindetail }),
  failure: () => createAction(EDIT_BINDETAIL.FAILURE)
}

const deleteBinDetail = {
  request: (id) => createAction(DELETE_BINDETAIL.REQUEST, { id } ),
  success: id => createAction(DELETE_BINDETAIL.SUCCESS, { id } ),
  failure: () => createAction(DELETE_BINDETAIL.FAILURE)
}

const filterData = (bindetails, search) => ({
  type: FILTER_BINDETAIL,
  payload: {
    bindetails,
    search 
  }
})

const loadBinDetailFromArray = id => ({
  type: GET_BINDETAIL,
  payload: id
})

const fecthBinDetailFromArray = () => ({
  type : FETCH_BINDETAILS_FROM_BINDETAIL,
  payload: {}
})


export {
  LOAD_BINDETAILS,
  LOAD_BINDETAIL,
  CREATE_BINDETAIL,
  EDIT_BINDETAIL,
  DELETE_BINDETAIL,
  SET_BINDETAIL,
  FILTER_BINDETAIL,
  GET_BINDETAIL,
  FETCH_BINDETAILS_FROM_BINDETAIL,
  loadBinDetails,
  loadBinDetail,
  loadBinDetailFromArray,
  createBinDetail,
  editBinDetail,
  deleteBinDetail,
  filterData,
  fecthBinDetailFromArray
}
