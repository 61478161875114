import React, { Component, Fragment } from 'react';
import GoogleMap from 'google-map-react';
import Marker from './Marker';
import markerDescriptions from './marker_descriptions';
import { customDistanceToMouse } from './custom_distance';
import supercluster from 'points-cluster';
import ClusterMarker from './markers/ClusterMarker';
import { numberFormat } from 'lib/helper'

const K_MARGIN_TOP = 30;
const K_MARGIN_RIGHT = 30;
const K_MARGIN_BOTTOM = 30;
const K_MARGIN_LEFT = 30;
const K_HOVER_DISTANCE = 30;

class MapComponent extends Component {
  state = {
    clusters: [],
    heatMap: [],
    value: 0
  }

  handleChangeTab = (event, value) => {
    this.setState({ value });
  };

  _onBoundsChange = ({ center, zoom, bounds, marginBounds }) => {
    if (typeof this.props.data.data !== 'undefined') {
       this.getCluster(bounds, zoom)
    }
  }

  getCluster = (bounds, zoom) => {
    const cl = this.generateCluster()
    const clusters = cl({ bounds, zoom })
      .map(({ wx, wy, numPoints, points }) => ({
        lat: wy,
        lng: wx,
        text: numPoints,
        numPoints,
        points: points,
        id: `${numPoints}_${points[0].id}`,
      }));

    this.setState({ clusters: clusters, heatMap: this.props.heatmaps })
  }

  generateCluster = () => {
    return supercluster(this.props.data.data)
  }

  _onChildClick = (key, childProps) => {
    const markerId = childProps.marker.id;
    if (this.props.onChildClick) {
      this.props.onChildClick(markerId);
    }
  }

  _onChildMouseEnter = (key, childProps) => {
    const markerId = childProps.marker.id;
    const index = this.props.data.data.findIndex(m => m.id === markerId);
    if (this.props.onMarkerHover) {
      this.props.onMarkerHover(index);
    }
  }

  _onChildMouseLeave = () => {
    if (this.props.onMarkerHover) {
      this.props.onMarkerHover(-1);
    }
  }

  _onBalloonCloseClick = () => {
    if (this.props.onChildClick) {
      this.props.onChildClick(-1);
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  }

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  _distanceToMouse = customDistanceToMouse;

  render() {
    const { mapInfo, openBalloonIndex, data } = this.props

    const { clusters } = this.state
    const Markers = clusters
      .map(({ id, numPoints, ...marker }, index) => (
        numPoints === 1
          ? <Marker
            key={index}
            lat={marker.lat}
            lng={marker.lng}
            showBallon={marker.points[0].id === openBalloonIndex}
            onCloseClick={this._onBalloonCloseClick}
            {...markerDescriptions[marker.points[0].type]}
            marker={marker.points[0]} />
          : <ClusterMarker key={id} {...marker} />
      ))

    return (
      <Fragment>
         <div style={{ position: 'relative' }}>
            <div className="map-card-1">
                <p>จำนวนทั้งหมด &nbsp;<b>{ numberFormat(data.total_customer, 0) }</b>&nbsp; ร้าน</p>
                <p>ยอดปีนี้ทั้งสิ้น &nbsp;<b>{ numberFormat(data.total_amt) }</b>&nbsp; บาท</p>
            </div>
         </div>
        <GoogleMap
          bootstrapURLKeys={{
            key: 'AIzaSyAUOISNjtxH3htrPuWyCQmiMX-Q5eJiIEA',
            language: 'th',
            //region: 'ru',
          }}
          center={mapInfo.center}
          zoom={mapInfo.zoom}
          onChange={this._onBoundsChange}
          onChildClick={this._onChildClick}
          onChildMouseEnter={this._onChildMouseEnter}
          onChildMouseLeave={this._onChildMouseLeave}
          margin={[K_MARGIN_TOP, K_MARGIN_RIGHT, K_MARGIN_BOTTOM, K_MARGIN_LEFT]}
          hoverDistance={K_HOVER_DISTANCE}
          distanceToMouse={this._distanceToMouse}
        >
          { Markers }
        </GoogleMap>
      </Fragment>
    );
  }
}



export default MapComponent

MapComponent.defaultProps = {
  data: [],
  heatmaps: [],
}