import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'

import history from 'lib/history'
import configureStore from 'modules/configureStore'
import Layout from 'modules/ui/components/Layout'
import 'theme/main.scss'

const store = configureStore()

export default () => (
  <Router>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Layout />
      </ConnectedRouter>
    </Provider>
  </Router>
)
