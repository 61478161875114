import React, { Component } from 'react'
import $ from 'jquery'
import { numberFormat, removeComma } from 'lib/helper'
import { SelectUncontrol } from 'lib/widgets'
import { defaultState } from '../defaultState'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'

import Paper from '@material-ui/core/Paper'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'

import DeleteIcon from '@material-ui/icons/Delete'


const useStyles = theme => ({
  appBar: {
    position: 'relative'
  },
  flex: {
    flex: 1
  },
  button: {
    margin: theme.spacing.unit
  },
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    margin: '10px 30px 10px 30px',
    color: theme.palette.text.secondary
  }
})

function Transition(props) {
  return <Slide direction="up" {...props} />
}

class PaymentModal extends Component {
  state = {
    modal: false,
    amount: this.props.total,
    items: defaultState.paymentItem,
    validAmt: '',
    validType: '',
    disabledBtn: true
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }))
  }

  addItem = () => {
    this.setState(prevState => ({
      items: [
        ...prevState.items,
        ...defaultState.paymentItem.map((row, index) => ({
          ...row
        }))
      ],
      validAmt: '',
      validType: '',
      disabledBtn: true
    }))
  }

  removeItem = idx => {
    const { items } = this.state
    items.splice(idx, 1)
    this.setState({ items: items }, this.checkValid)
  }

  checkValid = () => {
    let cnt = true, amt = true, sum = 0

    $("input[name^=type]").each(function(idx, val) {
      if(val.value === '') {
        cnt = false
        return false
      } 
    })

    $('input[name^=payment]').each((idx, val) => {
        if(parseFloat(val.value) > 0) sum += parseFloat(val.value)
        if(parseFloat(val.value) <= 0 || val.value === '') {
          amt = false
          return false
        } 
    })

    let validAmt = ( (sum <= parseFloat(this.props.total) ) && amt === true)  ? true : false
    this.setState({ validAmt: validAmt, validType: cnt }, this.setDisableBtn)
  }

  acceptPayment = () => {
    let data = $('#form_payment').serialize()
    this.props.acceptPayment(data)
  }

  sumAmt = e => {
    const { amount } = this.state
    let val = e.target.value === '' ? 0 : e.target.value
    let amt = parseFloat(removeComma(amount)) - parseFloat(removeComma(val))
    this.setState({ amount: amt })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      modal: nextProps.isOpen,
      amount: nextProps.total,
      items: defaultState.paymentItem,
      validAmt: '',
      validType: '',
      disabledBtn: true
    })
  }

  setTypes = (bool) => {
    this.setState({ validType: bool }, this.checkValid)
  }

  setAmt = (bool) => {
    this.setState({ validAmt: bool }, this.checkValid)
  }

  setDisableBtn = () => {
    const { validType, validAmt } = this.state
    let disabled = (validType === true && validAmt === true) ? false : true
    this.setState({ disabledBtn: disabled })
  }

  render() {
    const { total, classes } = this.props
    const { modal, disabledBtn } = this.state

    return (
      <div>
        <Dialog
          fullScreen
          open={modal}
          onClose={this.toggle}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.toggle}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography color="inherit" className={classes.flex}>
                จำนวนเงินคงค้าง {numberFormat(total)} บาท
              </Typography>
              <Button color="inherit" onClick={this.acceptPayment} disabled={disabledBtn}>
                รับชำระเงิน
              </Button>
            </Toolbar>
          </AppBar>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Typography component="h2" variant="h6" style={{ color: 'red', margin: '20px 30px 20px 30px' }} >
                กรุณากรอกข้อมูลที่มี * ให้ครบทุกช่อง
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={24}>
            <Grid item xs={12}>

              <form id="form_payment">
                {this.state.items.map((data, i) => {
                  return (
                    <CreateItem
                      key={i}
                      total={this.state.amount}
                      idx={i}
                      soId={this.props.soId}
                      onChange={this.handleChange}
                      sumAmt={this.sumAmt}
                      removeItem={this.removeItem}
                      classes={classes}
                      setTypes={this.setTypes}
                      setAmt={this.setAmt}
                    />
                  )
                })}
              </form>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                className={classes.button}
                onClick={this.addItem}
                disabled={disabledBtn}
              >
                เพิ่มการชำระเงิน
              </Button>
            </Grid>
          </Grid>
        </Dialog>
      </div>
    )
  }
}

const CreateItem = ({ classes, total, idx, removeItem, sumAmt, soId, setTypes, setAmt }) => {
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={24}>
        <Grid item xs={12} sm={3} style={{ margin: 'auto' }}>
          <SelectUncontrol
            url="api/collection-types"
            name={`type[${idx}]`}
            title="ประเภทการชำระ * "
            uncontrol={true}
            onChange={(name, val) => {
              (val !== '') ? setTypes(true) : setTypes(false)
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            defaultValue={numberFormat(total)}
            label="จำนวนเงิน * "
            margin="normal"
            name={`payment[${idx}]`}
            onBlur={e => {
              (e.target.value !== '') ? setAmt(true) : setAmt(false)
              sumAmt(e)
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            defaultValue=""
            label="หมายเหตุ"
            margin="normal"
            name={`remark[${idx}]`}
            fullWidth
          />
          <input
            type="hidden"
            name={`salesorder_id[${idx}]`}
            defaultValue={soId}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <IconButton
            className={classes.button}
            color="secondary"
            onClick={() => removeItem(idx)}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default withStyles(useStyles)(PaymentModal)
