import React from 'react';
import { EditingState } from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn
} from '@devexpress/dx-react-grid-material-ui';
import TableCell from '@material-ui/core/TableCell';

import { genOptionList, genRelateOptionList, displayOptionLabel } from 'lib/helper'
import { defaultState, tableColumnExtensions } from '../defaultState'

import {
  loadOptions,
  commandComponents,
  getRowId,
  checkAvailable,
  Cell
} from './RelateFunc'

import { LookupEditCell, Number } from 'lib/widgets'

export class DocItems extends React.PureComponent {
  constructor(props) {
    super(props);
    this._prdId = 0
    this._swarehouseId = 0
    this._dwarehouseId = 0
    this._isMounted = false
    this.state = {
      rows: defaultState.relations.detail,
      currentUrl: '',
      company_id: '',
      sWarehouse: '',
      dWarehouse: '',
      columns: defaultState.columns,
      prd_option: 'name',
      uom_id: [],
      s_warehouse_id: [],
      d_warehouse_id: [],
      product_id: [],
      s_bin_id: [],
      d_bin_id: [],
      batch_id: [],
      editingStateColumnExtensions: [
        { columnName: 'batch_id', editingEnabled: false },
        { columnName: 's_bin_id', editingEnabled: false },
      ],
      disableSave: true,
      disabledEdit: false,
      isLoading: true,
      textQty: '',
      onhand_qty: 0,
      c_prd: '',
      c_uom: '',
      c_batch: '',
      c_swarehouse: '',
      c_sbin: '',
      curIdx: ''
    }
  }

  changeProductOptions = value => {
    this.setState({ ...this.state, prd_option: value })
  }

  editCell = props => {
    const { column, value, onValueChange } = props
    const { prd_option, product_name_list, product_code, isLoadOpt } = this.state
    let selectName = ['s_warehouse_id', 'd_warehouse_id', 'product_id']
    let relateSelectName = ['d_bin_id', 'uom_id']

    if (this.props.processType.toString() === '1') {
      selectName = ['s_warehouse_id', 'd_warehouse_id', 'product_id']
      relateSelectName = ['d_bin_id', 'uom_id', 's_bin_id', 'batch_id']
    }




    if (selectName.includes(column.name)) {
      let availableColumnValues = this.state[column.name];
      if (column.name === 'product_id') {
        availableColumnValues = (prd_option === 'name') ? product_name_list : product_code
      }

      if (availableColumnValues) {
        if (column.name === 'product_id') {
          this._prdId = value
          return (
            <TableCell>
              <LookupEditCell
                {...props}
                availableColumnValues={availableColumnValues}
                withTableCell={false}
                disabled={isLoadOpt} />
            </TableCell>
          )
        }
        if (column.name === 's_warehouse_id') this._swarehouseId = value
        if (column.name === 'd_warehouse_id') this._dwarehouseId = value

        return <LookupEditCell {...props} availableColumnValues={availableColumnValues} />
      }
    }

    if (relateSelectName.includes(column.name)) {
      let fk = '';

      switch (column.name) {
        case 'uom_id': fk = this._prdId; break;
        case 's_bin_id': fk = this._swarehouseId; break;
        case 'd_bin_id': fk = this._dwarehouseId; break;
        case 'batch_id': fk = this._prdId; break;
        default: break;
      }

      var availableColumnValues = this.state[column.name].filter((data) => {
        return parseInt(data.fk) === parseInt(fk)
      })

      if (availableColumnValues) {
        return <LookupEditCell {...props} availableColumnValues={availableColumnValues} />;
      }
    }

    if (column.name === 'order_qty') {
      return <TableCell>
        <Number
          value={value}
          onChange={value => {
            onValueChange(value, 'order_qty')
          }
          }
        />
        <span className="form-text--error">{this.state.textQty}</span>
      </TableCell>
    }

    return <TableEditRow.Cell {...props} />;
  }

  command = ({ id, onExecute, ...restProps }, disableSave, disabledEdit) => {
    const CommandButton = commandComponents[id];
    return (
      <CommandButton
        onExecute={onExecute}
        disabledSave={disableSave}
        disabledEdit={disabledEdit}
        disabledDelete={disabledEdit}
        disabledAdd={disabledEdit}
      />
    );
  }

  commitChanges = ({ added, changed, deleted }) => {
    let {
      rows,
      uom_id,
      batch_id,
      product_name_list,
      s_warehouse_id,
      s_bin_id,
      d_warehouse_id,
      d_bin_id,
    } = this.state;
    if (added) {
      const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      rows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          line_no: startingAddedId + index,
          gt_order_id: this.props.docId,
          ...row,
        })),
      ];
      this.props.onCreate(rows[rows.length - 1])
      const currentItem = rows[rows.length - 1]
      rows[rows.length - 1].uom_name = displayOptionLabel(uom_id, currentItem, 'uom_id')
      rows[rows.length - 1].batch_name = displayOptionLabel(batch_id, currentItem, 'batch_id')
      rows[rows.length - 1].product_name = displayOptionLabel(product_name_list, currentItem, 'product_id')
      rows[rows.length - 1].s_warehouse_name = displayOptionLabel(s_warehouse_id, currentItem, 's_warehouse_id')
      rows[rows.length - 1].storagebin_name = displayOptionLabel(s_bin_id, currentItem, 's_bin_id')
      rows[rows.length - 1].d_warehouse_name = displayOptionLabel(d_warehouse_id, currentItem, 'd_warehouse_id')
      rows[rows.length - 1].d_storagebin_name = displayOptionLabel(d_bin_id, currentItem, 'd_bin_id')
    }
    if (changed) {
      rows = rows.map(row => {
        if (changed[row.id]) {
          if (typeof changed[row.id].uom_id !== 'undefined') changed[row.id].uom_name = displayOptionLabel(uom_id, changed[row.id], 'uom_id')
          if (typeof changed[row.id].batch_id !== 'undefined') changed[row.id].batch_name = displayOptionLabel(batch_id, changed[row.id], 'batch_id')
          if (typeof changed[row.id].product_id !== 'undefined') changed[row.id].product_name = displayOptionLabel(product_name_list, changed[row.id], 'product_id')
          if (typeof changed[row.id].s_warehouse_id !== 'undefined') changed[row.id].s_warehouse_name = displayOptionLabel(s_warehouse_id, changed[row.id], 's_warehouse_id')
          if (typeof changed[row.id].s_bin_id !== 'undefined') changed[row.id].storagebin_name = displayOptionLabel(s_bin_id, changed[row.id], 'bin_id')
          if (typeof changed[row.id].d_warehouse_id !== 'undefined') changed[row.id].d_warehouse_name = displayOptionLabel(d_warehouse_id, changed[row.id], 'd_warehouse_id')
          if (typeof changed[row.id].d_bin_id !== 'undefined') changed[row.id].d_storagebin_name = displayOptionLabel(d_bin_id, changed[row.id], 'd_bin_id')
          this.props.onEdit(row.id, changed[row.id])
          return { ...row, ...changed[row.id] }
        }
        return row
      })
    }
    if (deleted) {
      const deletedSet = new Set(deleted);
      rows = rows.filter(row => !deletedSet.has(row.id));
      this.props.onDelete(deleted[0])
    }
    this.setState({ ...this.state, rows: rows });
  }

  prepareOptionList = (companyId, sWarehouse, dWarehouse) => {
    loadOptions(companyId, sWarehouse, dWarehouse).then(res => {
      this.setState({
        s_warehouse_id: genOptionList(res[0].s_warehouse_id, 'id', 'name'),
        d_warehouse_id: genOptionList(res[0].d_warehouse_id, 'id', 'name'),
        product_name_list: genOptionList(res[0].product_id, 'id', 'name'),
        product_code: genOptionList(res[0].product_id, 'id', 'code'),
        uom_id: genRelateOptionList(res[0].product_id, 'uom_id', 'uom_name', 'uom', 'product_id'),
        batch_id: genRelateOptionList(res[0].product_id, 'id', 'name', 'batch', 'product_id'),
        s_bin_id: genRelateOptionList(res[0].s_warehouse_id, 'id', 'name', 'bin', 'warehouse_id'),
        d_bin_id: genRelateOptionList(res[0].d_warehouse_id, 'id', 'name', 'bin', 'warehouse_id'),
        company_id: this.props.companyId,
        isLoading: false,
      })
    })
  }

  componentDidUpdate() {
    const { currentUrl } = this.state

    if (currentUrl !== window.location.pathname) {
      this.setState({
        rows: this.props.rows,
        prevState: this.props.rows,
        currentUrl: window.location.pathname,
        company_id: this.props.companyId
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { companyId, rows, processType, sWarehouse, dWarehouse } = nextProps
    let editingStateColumnExtensions = [
      { columnName: 'batch_id', editingEnabled: false },
      { columnName: 's_bin_id', editingEnabled: false },
    ]

    if (processType.toString() === '1') {
      editingStateColumnExtensions = [
        { columnName: 'batch_id', editingEnabled: true },
        { columnName: 's_bin_id', editingEnabled: true },
      ]
    }

    this.setState({
      rows: rows,
      prevState: rows,
      editingStateColumnExtensions,
      currentUrl: window.location.pathname,
      company_id: companyId,
      sWarehouse,
      dWarehouse,
      processType
    })

    if (companyId === this.state.company_id &&
      sWarehouse === this.state.sWarehouse &&
      dWarehouse === this.state.dWarehouse) return

    if (companyId !== '' && sWarehouse !== "" && dWarehouse !== "") {
      this.prepareOptionList(companyId, sWarehouse, dWarehouse)
    }
  }


  componentDidMount() {
    const { rows, companyId, sWarehouse, dWarehouse } = this.props
    this._isMounted = true
    if (this._isMounted) {
      this.setState({
        rows: changeRowId(rows),
        currentUrl: window.location.pathname
      }, () => {
        this.prepareOptionList(companyId, sWarehouse, dWarehouse)
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  IsUndefined = (data) => {
    return typeof data === 'undefined' ? true : false
  }

  validateRow = (addedRows, action) => {

    const { processType, onhand_qty, curIdx, rows } = this.state
    let cnt = 0, cntPrd = 0
    let data = (action === 'edit') ? addedRows[curIdx] : addedRows[0]
    let type = parseInt(processType)

    let arrName = ['product_id', 'uom_id', 'order_qty', 's_warehouse_id', 'd_warehouse_id', 'd_bin_id']
    let checkStock = ['product_id', 'uom_id', 's_warehouse_id']

    if (type === 1) {
      arrName = ['product_id', 'uom_id', 'order_qty', 's_warehouse_id', 's_bin_id', 'batch_id', 'd_warehouse_id', 'd_bin_id']
      checkStock = ['product_id', 'uom_id', 's_warehouse_id', 'batch_id', 's_bin_id']
    }

    this.setState({ disableSave: true, disabledEdit: true })

    if (this.IsUndefined(data)) {
      this.setState({
        textQty: '',
        disabledEdit: false,
        c_prd: '',
        c_uom: '',
        c_batch: '',
        c_swarehouse: '',
        c_sbin: '',
      })
      return
    }

    if (action === 'edit') {
      let idx = rows.findIndex(data => data.id === curIdx)
      let res = rows[idx]

      arrName.map(name => {
        if (data.hasOwnProperty(name) === false) {
          data[name] = res[name]
        }
        return true
      })
    }

    const { product_id, uom_id, s_warehouse_id, batch_id, s_bin_id, order_qty } = data

    arrName.map(name => {
      if (data.hasOwnProperty(name) === false) return true
      if (data[name] !== '' && data[name] !== null) {
        cnt++
        if (checkStock.includes(name)) {
          cntPrd++
          this.setState({
            c_prd: product_id,
            c_uom: uom_id,
            c_batch: batch_id,
            c_warehouse: s_warehouse_id,
            c_bin: s_bin_id
          })
        }
      }
      return true
    })


    if ((type === 1 && cntPrd === 5) || (type !== 1 && cntPrd === 3)) {
      let isValueChange = this.checkDiffValue(data, processType)
      let disableSave = true

      if (isValueChange) {
        checkAvailable(product_id, uom_id, s_warehouse_id, processType, batch_id, s_bin_id).then(res => {
          let qty = res.data[0].total_base_qty
          this.setState({ textQty: `คงเหลือ ${qty} รายการ`, onhand_qty: qty })

          if (parseFloat(qty) >= parseFloat(order_qty) && parseFloat(order_qty) > 0) {
            if (cnt === 6 && type !== 1) disableSave = false
            if (cnt === 8 && type === 1) disableSave = false
          }
          this.setState({ disableSave: disableSave })
        })
      } else {
        if (parseFloat(onhand_qty) >= parseFloat(order_qty) && parseFloat(order_qty) > 0) {
          if (cnt === 6 && type !== 1) disableSave = false
          if (cnt === 8 && type === 1) disableSave = false
        }

        this.setState({ disableSave: disableSave })
      }

    }

  }

  checkDiffValue = (data, processType) => {
    const { c_prd, c_uom, c_batch, c_swarehouse, c_sbin } = this.state
    const { product_id, uom_id, batch_id, s_warehouse_id, s_bin_id } = data
    let cnt = 0

    if (parseInt(processType) === 1) {
      if (this.compareValue(c_prd, product_id)) cnt++
      if (this.compareValue(c_uom, uom_id)) cnt++
      if (this.compareValue(c_batch, batch_id)) cnt++
      if (this.compareValue(c_swarehouse, s_warehouse_id)) cnt++
      if (this.compareValue(c_sbin, s_bin_id)) cnt++

      return (cnt < 5) ? true : false

    } else {
      if (this.compareValue(c_prd, product_id)) cnt++
      if (this.compareValue(c_uom, uom_id)) cnt++
      if (this.compareValue(c_swarehouse, s_warehouse_id)) cnt++

      return (cnt < 3) ? true : false
    }
  }

  compareValue = (dataA, dataB) => {
    return dataA === dataB
  }

  getEditID = data => {
    this.setState({ curIdx: data[0] })
  }


  render() {

    const { rows, columns, editingStateColumnExtensions } = this.state;
    return (
      <Grid
        rows={rows}
        columns={columns}
        getRowId={getRowId}
      >
        <EditingState
          onCommitChanges={this.commitChanges}
          columnExtensions={editingStateColumnExtensions}
          onAddedRowsChange={(addedRows) => this.validateRow(addedRows, 'add')}
          onRowChangesChange={(addedRows) => this.validateRow(addedRows, 'edit')}
          onEditingRowIdsChange={this.getEditID}
        />
        <VirtualTable
          cellComponent={Cell}
          columnExtensions={tableColumnExtensions}
        />
        <TableHeaderRow />
        <TableEditRow cellComponent={this.editCell} />
        {this.props.docId > 0 && this.props.status === 0 && this.props.disabled
          && this.state.isLoading === false &&
          <TableEditColumn
            width={80}
            showAddCommand
            showEditCommand
            showDeleteCommand
            commandComponent={props => this.command(props, this.state.disableSave, this.state.disabledEdit)}
          />}
      </Grid>
    );
  }
}

DocItems.defaultProps = {
  data: [],
  status: 0
}

const changeRowId = (rows) => {
  return rows.map((data, i) => {
    data.id = i + 1
    return data
  })
}

