import * as React from 'react';
import { EditingState } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn
} from '@devexpress/dx-react-grid-material-ui';

import { 
  commandComponents,
  getRowId,
  Cell,
  loadOptions
} from './RelateFunc'

import { LookupEditCell, Number } from 'lib/widgets'

import { genOptionList } from 'lib/helper'
import { checkAction } from 'lib/authFunc'
import TableCell from '@material-ui/core/TableCell';


const editingStateColumnExtensions =  [
  { columnName: 'uom_id', width: 100 },
  { columnName: 'price', width: 100 },
  { columnName: 'min_price', width: 100 },
  { columnName: 'max_price', width: 100 }
]

export class PriceBook extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      currentUrl: '',
      columns:[
        { name: 'uom_id', title: 'หน่วยนับ' },
        { name: 'price', title: 'ราคาขาย' },
        { name: 'min_price', title: 'ราคาขายต่ำสุด' },
        { name: 'max_price', title: 'ราคาขายสูงสุด' }
      ],
      uom_id: []
    }
  }

  command = ({ id, onExecute }) => {
    const CommandButton = commandComponents[id];
    return (
      <CommandButton
        onExecute={onExecute}
      />
    );
  }

  editCell = (props) => {
    const { column, value, onValueChange } = props;
  
    if(column.name === 'uom_id') {
        let availableColumnValues = this.state[column.name];
        if (availableColumnValues) {
          return <LookupEditCell {...props} availableColumnValues={availableColumnValues} disabled={true}/>;
        }
    }

    return <TableCell>
      <Number
        value={value}
          onChange={value => {
            onValueChange(value, column.name)
          }
        }
      />
    </TableCell>
  }

  commitChanges = ({ changed }) => {
    let { rows } = this.state;

    if (changed) {
      rows = rows.map(row => {
        if(changed[row.id]) {
          this.props.onEdit(row.id, changed[row.id])
          return { ...row, ...changed[row.id] }
        }
        return row
      })
    }

    this.setState({ rows });
  }

  // componentWillReceiveProps(nextProps) {
  //   const { rows } = nextProps
  //   this.setState({ rows: rows, currentUrl: window.location.pathname })
  // }

  componentDidUpdate() {
    const { currentUrl, rows } = this.state
    if(rows.length === 0 || currentUrl !== window.location.pathname) {
      this.setState({ rows: this.props.rows, currentUrl: window.location.pathname })
    }
  }

  componentDidMount() {
    this.setState({ 
      rows: this.props.rows,
      currentUrl: window.location.pathname 
    }, () => {
        loadOptions().then(res => {
          this.setState({ 
            uom_id: genOptionList(res[0].uom_id, 'id', 'name'), 
          })
      })
    })
  }

  render() {
    const { rows, columns } = this.state;

    return (
        <Grid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
        >
          <EditingState
            onCommitChanges={this.commitChanges}
            defaultEditingRowIds={[0]}
            columnExtensions={editingStateColumnExtensions}
          />
          <Table cellComponent={Cell}/>
          <TableHeaderRow />
          <TableEditRow cellComponent={this.editCell}/>
          { this.props.ProductId > 0 && checkAction("product edit") &&
           <TableEditColumn
            width={80}
            showEditCommand
            commandComponent={this.command}
          /> }
        </Grid>
    );
  }
}

PriceBook.defaultProps = {
  rows : []
}

