import React from 'react'
import Grid from '@material-ui/core/Grid'

import TextField from '@material-ui/core/TextField'
import { SelectWidget, DatePickerWidget } from 'lib/widgets'

const style = {
  marginBottom: '15px'
}
export default ({ values, classes, handleChange, handleSelect, handleDateChange }) => (
  <div style={{ padding: '10px', flexGrow: 1 }}>
    <Grid item xs={12} style={style}>
      <SelectWidget
        url="api/companies"
        value={values.company_id}
        name="company_id"
        title="บริษัท"
        onChange={handleSelect('company_id')}
      />
    </Grid>
    <Grid item xs={12} style={style}>
      <SelectWidget
        url="api/branches"
        value={values.s_branch_id}
        name="s_branch_id"
        title="สาขา"
        onChange={handleSelect('s_branch_id')}
      />
    </Grid>
    <Grid item xs={12} style={style}>
      <TextField
        label="เลขที่เอกสาร"
        name="order_no"
        value={values.order_no}
        onChange={handleChange('order_no')}
        fullWidth
      />
    </Grid>
    <Grid item xs={12} style={style}>
      <TextField
        label="เอกสารอ้างอิง"
        name="ref_no"
        value={values.ref_no}
        onChange={handleChange('ref_no')}
        fullWidth
      />
    </Grid>
    <Grid item xs={12} style={style}>
      <SelectWidget
        url="api/master-data/doc-status"
        value={values.status}
        name="status"
        title="สถานะ"
        onChange={handleSelect('status')}
      />
    </Grid>
    <Grid item xs={12} style={style}>
      <DatePickerWidget
        title="วันที่ทำรายการ"
        name="movement_date"
        value={values.movement_date}
        onValueChange={handleDateChange('movement_date')}
      />
    </Grid>

  </div>
)
