import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import * as actions from '../actions'
import UnitForm from './UnitForm'
import { defaultState } from '../defaultState'
import { hasMessage } from 'lib/fxUI'


class CreateUnit extends Component {
  createUnit = async data => {
    this.props.createUnit(data)
  }

  render() {
    const { successSave, isLoading } = this.props
    return (
      <Fragment>
        {successSave !== null && hasMessage(successSave, '')}
        <UnitForm
          onSubmit={this.createUnit}
          formAction="สร้างใหม่"
          successSave={successSave}
          isLoading={isLoading}
          data={defaultState}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ units }) => ({
  successSave: units.successSave,
  isLoading: units.isLoading
})
const mapDispatchToProps = {
  createUnit: actions.createUnit.request
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUnit)
