import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import * as actions from '../actions'
import BranchForm from './BranchForm'

import { defaultState } from '../defaultState'
import { hasMessage } from 'lib/fxUI'

class CreateBranch extends Component {
  createBranch = async data => {
    this.props.createBranch(data)
  }

  render() {
    const { successSave, isLoading } = this.props
    return (
      <Fragment>
        {successSave !== null && hasMessage(successSave, '')}
        <BranchForm
          onSubmit={this.createBranch}
          formAction="สร้างใหม่"
          successSave={successSave}
          isLoading={isLoading}
          data={defaultState}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ branches }) => ({
  isLoading: branches.isLoading,
  successSave: branches.successSave
})
const mapDispatchToProps = {
  createBranch: actions.createBranch.request
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateBranch)
