import React from 'react'
import { Switch, Route } from 'react-router-dom'
import SalesOrderLayout from './salesorder/components/SalesOrderLayout'
import StoreLayout from './store/components/StoreLayout'
import CustomerLayout from './customer/components/CustomerLayout'
import NoRights from '../ui/components/NoRights'

import { checkPermission } from 'lib/authFunc'

const getCurrentPath = () => {
  const url = window.location.href
  let rights = ""

  if (url.match(/customers/g)) rights = 'shop'

  return rights
}


export default () => {
  let rights = getCurrentPath()

  if (!checkPermission(rights) && rights !== '') {
    return <NoRights />
  }

  return <Switch>
    <Route path="/salesforce/sale-orders" component={SalesOrderLayout} />
    <Route path="/salesforce/customers" component={StoreLayout} />
    <Route path="/salesforce/customers-analytic" component={CustomerLayout} />
  </Switch>
}