import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import SubTable from './SubTable'

import { config } from 'lib/authFunc'
import axios from 'axios'
import { hasMessage } from 'lib/fxUI'

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 10,
  },
}))(MuiExpansionPanelDetails);


function CountItem(props) {
  const { data, phyId } = props
  const [load, setLoad] = useState(false)
  const [saveSuccess, setSaveSuccess] = useState(null)
  const [items, setItems] = useState([])
  const [expanded, setExpanded] = useState('panel1');

  const getBinId = async () => {
    setLoad(true)
    const result = await axios.post(process.env.REACT_APP_API_URL + '/api/physical-counts-getdetail', { phy_id: phyId }, config)
    if (typeof result.data.relations !== 'undefined') {
      setItems(result.data.relations.detailGroup)
    }
  }

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  }

  const handleStatus = e => {
    let idx = items.findIndex(data => parseInt(data.id) === parseInt(e.target.value))
    let value = e.target.value.split('-')
    let is_accept = e.target.checked ? 1 : 0
    items[idx].is_accept = is_accept
    setItems(items)

    let data = {
      id: value[0],
      is_accept: is_accept,
      phy_id: value[1]
    }

    const res = axios.post(process.env.REACT_APP_API_URL + '/api/physical-counts-accept', data, config)
    res.then(data => {
      if (data.status === 200)
        setSaveSuccess(true)
      else
        setSaveSuccess(false)
    })
  }

  useEffect(() => {
    if (data.length > 0 && load === false) {
      setLoad(true)
      getBinId()
    }
  })

  return (
    items.map((data, i) => {
      return <div key={i}>
        {saveSuccess !== null && hasMessage(saveSuccess, '')}
        <ExpansionPanel square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
            <Grid container spacing={8} justify="space-between">
              <Grid item xs={1}>
                <Typography>#{i + 1}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Storage Bin : {data.storagebin_name}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>สถานะ : {data.is_count}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>ทำรายการล่าสุด : {data.created_at}</Typography>
              </Grid>
              <Grid item xs={1}>
                <FormControlLabel control={
                  <Checkbox
                    checked={data.is_accept === 1}
                    onChange={(e) => handleStatus(e)}
                    value={data.id + '-' + data.phy_id}
                  />
                } label="Accept" />
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <SubTable items={data.detail} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    })
  )
}

export default CountItem
