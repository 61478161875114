import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

const ITEM_HEIGHT = 48;
const theme = createMuiTheme({
  overrides: {
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: '#d6d6d6',
        }
      }
    }
  },
  typography: { useNextVariants: true }
})

class ActionMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  }

  handleRead = (status) => {
    const { id, onUpdate } = this.props
    onUpdate(id, status)
    this.handleClose()
  }

  handleDelete = () => {
    const { id, onDelete } = this.props
    onDelete(id)
    this.handleClose()
  }

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <MuiThemeProvider theme={theme}>
        <IconButton
          aria-label="More"
          aria-owns={open ? 'long-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 200,
            },
          }}
        >
        { this.props.status === 0 ? 
          <MenuItem  onClick={() => this.handleRead(1)} style={{ paddingTop: '5px', paddingBottom: '5px' }}>
            อ่านแล้ว
          </MenuItem> :
          <MenuItem  onClick={() => this.handleRead(0)} style={{ paddingTop: '5px', paddingBottom: '5px' }}>
            ทำเครื่องหมายว่ายังไม่ได้อ่าน
          </MenuItem>
        }
          <MenuItem  onClick={this.handleDelete} style={{ paddingTop: '5px', paddingBottom: '5px' }}>
            ลบการแจ้งเตือน
          </MenuItem>
        </Menu>
      </MuiThemeProvider>
    );
  }
}

export default ActionMenu;