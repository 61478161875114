import React from 'react';
import { numberFormat } from 'lib/helper'

export default ({ data, NetAmt }) => {
  let paid = 0, net = 0

  if (typeof data.summary !== 'undefined' && data.summary.length > 0) {
    paid =  data.summary[0].paid
    net = NetAmt//data.relations.detail.reduce((total, item) => parseInt(total) + parseInt(item.net_price), 0);
  }

return <div className="col-md-12" id="summary">
      <div className="panel panel-default">
        <div className="panel-heading">
          <h6 className="panel-title">
            <strong>สรุปการชำระเงิน</strong>
          </h6>
        </div>
        <div className="panel-body">
          <div className="table-responsive">
            <table className="table table-condensed">
              <tbody>
                <tr>
                    <td className="thick-line">ยอดสุทธิ</td>
                    <td className="thick-line text-right">
                      <span style={{ fontWeight: 'bold' }}>{ numberFormat(net) } </span>
                    </td>
                </tr>
                <tr>
                    <td>จ่ายแล้ว</td>
                    <td className="text-right"> 
                      <span style={{ color:'green', fontWeight: 'bold' }}> { numberFormat(paid) } </span>
                    </td>
                </tr>
                { typeof data.summary !== 'undefined' && data.summary.map((item, i) => (
                    <tr key={i}>
                      <td><span style={{ paddingLeft:'40px' }}>{ item.name }</span></td>
                      <td className="text-right"> { numberFormat(item.amount) } </td>
                    </tr>
                  ))       
                }
                <tr>
                    <td>คงค้าง</td>
                    <td className="text-right"> <span style={{ color:'red', fontWeight: 'bold' }}> { numberFormat(net - paid) } </span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
}