import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import StoreTable from './StoreTable'
import StoreList from './StoreList'
import EditStore from './EditStore'
import CreateStore from './CreateStore'

import { Breadcrumb } from 'lib/widgets'
import { Layoutstyles } from './AddonStyles'

const StoreLayout = ({ classes }) => (
  <div className={classes.root}>
    <Grid container spacing={24}>
      <Grid item xs>
        <Breadcrumb
          menu="ข้อมูลหลัก"
          submenu="คลังสินค้า"
          menuLink="/master-data/stores"
        />
      </Grid>
    </Grid>
    <Grid container spacing={24}>
      <Switch>
        <Route exact path="/master-data/stores" component={StoreTable} />
        <Route path="/master-data/stores" component={StoreList} />
      </Switch>
      <Switch>
        <Route path="/master-data/stores/new" component={CreateStore} />
        <Route path="/master-data/stores/:id" component={EditStore} />
      </Switch>
    </Grid>
  </div>
)

export default withStyles(Layoutstyles)(StoreLayout)