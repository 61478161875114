import React from 'react';
import AssessmentIcon from '@material-ui/icons/Assessment';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DescriptionIcon from '@material-ui/icons/Description';
import StoreIcon from '@material-ui/icons/Store';
import SettingsIcon from '@material-ui/icons/Settings';


const subMenuReport = [
    { title: 'ยอดขายแยกตามพนักงาน', url: '/report/sales', perm: 'report' },
    { title: 'ยอดขายแยกตามร้านค้า', url: '/report/stores', perm: 'report' },
    { title: 'ยอดขายแยกตามสินค้า', url: '/report/products', perm: 'report' },
    { title: 'Sales Order', url: '/report/sale-orders', perm: 'report' }
]


const menu = [
    { title: 'กระดานข้อมูลขาย', url: '/dashboard', perm: 'overview', menu: 'dashboard' },
    { title: 'กระดานข้อมูลคลังสินค้า', url: '/dashboard/overview-warehouse', perm: 'overview-warehouse', menu: 'dashboard' },
    { title: 'รายงานการขาย', url: '/salesforce/sale-orders', perm: 'salesorder', menu: 'sales_force' },
    { title: 'ร้านค้า', url: '/salesforce/customers', perm: 'shop', menu: 'sales_force' },
    { title: 'วิเคราะห์ข้อมูลลูกค้า', url: '/salesforce/customers-analytic', perm: 'customers-analytic', menu: 'sales_force' },
    { title: 'Goods Receive', url: '/warehouse/goods-receives', perm: 'goodsreceive', menu: 'warehouse' },
    { title: 'Goods Transfer', url: '/warehouse/goods-transfers', perm: 'goodstransfer', menu: 'warehouse' },
    { title: 'Goods Issue', url: '/warehouse/goods-issues', perm: 'goodsissue', menu: 'warehouse' },
    { title: 'Stock Balance', url: '/warehouse/stock-balances', perm: 'stockbalance', menu: 'warehouse' },
    { title: 'Physical Count', url: '/warehouse/physical-count', perm: 'physicalcount', menu: 'warehouse' },
    { title: 'กลุ่มสิทธิ์เว็บ', url: '/master-data/web-groups', perm: 'group', menu: 'master_data' },
    { title: 'กลุ่มสิทธิ์มือถือ', url: '/master-data/mobile-groups', perm: 'groupmobile', menu: 'master_data' },
    { title: 'ผู้ใช้งาน', url: '/master-data/users', perm: 'user', menu: 'master_data' },
    { title: 'คลังสินค้า', url: '/master-data/stores', perm: 'whbin', menu: 'master_data' },
    { title: 'การจัดการ Storage Bin', url: '/master-data/storagebin-details', perm: 'storagebindetail', menu: 'master_data' },
    { title: 'การจัดการ Batch', url: '/master-data/batches', perm: 'batch', menu: 'master_data' },
    { title: 'บริษัท', url: '/master-data/companies', perm: 'company', menu: 'master_data' },
    { title: 'สาขา', url: '/master-data/branches', perm: 'branch', menu: 'master_data' },
    { title: 'หน่วยนับ', url: '/master-data/units', perm: 'uom', menu: 'master_data' },
    { title: 'ยี่ห้อ', url: '/master-data/brands', perm: 'brand', menu: 'master_data' },
    { title: 'ประเภทสินค้า', url: '/master-data/categories', perm: 'productcat', menu: 'master_data' },
    { title: 'สินค้า', url: '/master-data/products', perm: 'product', menu: 'master_data' },
]

const Categories = (data) => {
    let user = JSON.parse(data);
    let auth = []
    if (user !== null) {
        auth = user.permission
    }

    let subMenuSalesforce = [],
        subMenuWarehouse = [],
        subMenuMaster = [],
        subMenuDashboard = []

    let currentMenu = ""

    menu.map(name => {
        auth.map(item => {
            if (currentMenu === name.perm)
                return true

            let rx = new RegExp(name.perm)
            let auth = item.name.split(' ')

            let authName = (name.menu !== 'dashboard') ? auth[0] : auth[1]

            if (authName.match(rx)) {
                switch (name.menu) {
                    case 'dashboard':
                        subMenuDashboard = [...subMenuDashboard, name]; break;
                    case 'sales_force':
                        subMenuSalesforce = [...subMenuSalesforce, name]; break;
                    case 'warehouse':
                        subMenuWarehouse = [...subMenuWarehouse, name]; break;
                    case 'master_data':
                        subMenuMaster = [...subMenuMaster, name]; break;
                    default: break;
                }
                currentMenu = name.perm
            }
            return true
        })
        return true
    })

    let Categories = []

    if (subMenuDashboard.length > 0) {
        Categories = [
            ...Categories,
            { title: 'Dashboard', icon: <DashboardIcon />, menu: subMenuDashboard, open: 'openDB' }
        ]
    }

    if (subMenuSalesforce.length > 0) {
        Categories = [
            ...Categories,
            { title: 'Salesforce', icon: <DescriptionIcon />, menu: subMenuSalesforce, open: 'openSF' }
        ]
    }

    if (subMenuWarehouse.length > 0) {
        Categories = [
            ...Categories,
            { title: 'Warehouse', icon: <StoreIcon />, menu: subMenuWarehouse, open: 'openWH' }
        ]
    }

    Categories = [
        ...Categories,
        { title: 'รายงาน', icon: <AssessmentIcon />, menu: subMenuReport, open: 'openRP' },
    ]

    if (subMenuMaster.length > 0) {
        Categories = [
            ...Categories,
            { title: 'ข้อมูลหลัก', icon: <SettingsIcon />, menu: subMenuMaster, open: 'openMT' }
        ]
    }

    return Categories

}

export {
    Categories
}