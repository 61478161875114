import React from 'react'
import { Switch, Route } from 'react-router-dom'
import GoodsIssueTable from './GoodsIssueTable'
import GoodsIssueList from './GoodsIssueList'
import EditDoc from './EditDoc'
import CreateDoc from './CreateDoc'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Breadcrumb } from 'lib/widgets'
import { Layoutstyles } from './AddonStyles'

const GoodsIssueLayout = ({ classes }) => (
  <div className={classes.root}>
    <Grid container spacing={24}>
      <Grid item xs>
        <Breadcrumb
          menu="จัดการคลังสินค้า"
          submenu="ใบเบิกสินค้า"
          menuLink="/warehouse/goods-issues"
        />
      </Grid>
    </Grid>
    <Grid container spacing={24}>
      <Switch>
        <Route
          exact
          path="/warehouse/goods-issues"
          component={GoodsIssueTable}
        />
        <Route path="/warehouse/goods-issues" component={GoodsIssueList} />
      </Switch>
      <Switch>
        <Route path="/warehouse/goods-issues/new" component={CreateDoc} />
        <Route path="/warehouse/goods-issues/:id" component={EditDoc} />
      </Switch>
    </Grid>
  </div>
)
export default withStyles(Layoutstyles)(GoodsIssueLayout)
