import {
  LOAD_MAP,
  LOAD_LINE,
  LOAD_PURCHASE,
  LOAD_TOPSPEND,
  LOAD_NEW_TOPSPEND,
  LOAD_LOWERSPEND,
  LOAD_CUSTOMER,
  LOAD_COMPARED,
  CHANGE_BOUNDS,
  MARKER_HOVER_INDEX_CHANGE,
  SHOW_BALLON,
  SET_MAP_MODE,
  SET_ACTIVE_TYPE
} from './actions'

const initialState = {
  isLoading: false,
  isLoadComp: false,
  successSave: null,
  active: 'all_total',
  title: '',
  column: [],
  columnCompared: [],
  data: [],
  line: {
    label: [],
    data: [],
    detail: []
  },
  compared: [],
  purchase: [],
  topspend: [],
  newtopspend: [],
  lowerspend: [],
  mode: { heat: true, marker: false },
  dataFiltered: [],
  mapInfo: {
    center: [14.8971921, 100.83273],
    bounds: [20.44215889, 99.88108819, 5.77434, 101.07231],
    marginBounds: [20.44215889, 99.88108819, 5.77434, 101.07231],
    zoom: 5.5
  },
  openBalloonIndex: -1,
  hoverMarkerIndex: -1,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MAP_MODE:
      const { mode } = action.payload;
      return {
        ...state, mode: { ...state.mode, ...mode }
      }
    case SET_ACTIVE_TYPE:
      const { active } = action.payload;
      return {
        ...state, active
      }
    case CHANGE_BOUNDS:
      const { center, zoom, bounds, marginBounds } = action.payload;
      return {
        ...state,
        mapInfo: {
          center, zoom, bounds, marginBounds
        },
        openBalloonIndex: -1,
        dataFiltered: state.data
      }
    case MARKER_HOVER_INDEX_CHANGE:
      const { hoverMarkerIndex } = action.payload;
      return {
        ...state,
        hoverMarkerIndex: hoverMarkerIndex
      }
    case SHOW_BALLON:
      const { openBalloonIndex } = action.payload;
      return {
        ...state,
        openBalloonIndex: openBalloonIndex === state.openBalloonIndex ? -1 : openBalloonIndex
      }
    case LOAD_MAP.REQUEST:
    case LOAD_LINE.REQUEST:
    case LOAD_PURCHASE.REQUEST:
    case LOAD_CUSTOMER.REQUEST:
    case LOAD_TOPSPEND.REQUEST:
    case LOAD_NEW_TOPSPEND.REQUEST:
    case LOAD_LOWERSPEND.REQUEST:
      return { ...state, items: [], isLoading: true }
    case LOAD_COMPARED.REQUEST:
      return { ...state, items: [], isLoadComp: true }
    case LOAD_CUSTOMER.SUCCESS:
      return {
        ...state,
        isLoading: false,
        customers: action.payload.maps
      }
    case LOAD_MAP.SUCCESS:
      const { maps } = action.payload;
      return {
        ...state,
        isLoading: false,
        dataFiltered: maps,
        mapInfo: {
          ...state.mapInfo,
          zoom: 5.5,
          center: maps.center
        },
      }
    case LOAD_LINE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        line: action.payload.maps
      }
    case LOAD_COMPARED.SUCCESS:
      return {
        ...state,
        isLoadComp: false,
        compared: action.payload.maps,
        columnCompared: action.payload.maps.column
      }
    case LOAD_PURCHASE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        purchase: action.payload.maps,
        column: action.payload.maps.column
      }
    case LOAD_TOPSPEND.SUCCESS:
      return {
        ...state,
        isLoading: false,
        topspend: action.payload.maps
      }
    case LOAD_NEW_TOPSPEND.SUCCESS:
      return {
        ...state,
        isLoading: false,
        newtopspend: action.payload.maps
      }
    case LOAD_LOWERSPEND.SUCCESS:
      return {
        ...state,
        isLoading: false,
        lowerspend: action.payload.maps
      }
    case LOAD_COMPARED.FAILURE:
    case LOAD_MAP.FAILURE:
    case LOAD_LINE.FAILURE:
    case LOAD_PURCHASE.FAILURE:
    case LOAD_TOPSPEND.FAILURE:
    case LOAD_NEW_TOPSPEND.FAILURE:
    case LOAD_LOWERSPEND.FAILURE:
    case LOAD_CUSTOMER.FAILURE:
      return { ...state, items: [], isLoading: false }
    default:
      return state
  }
}

