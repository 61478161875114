import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as actions from '../actions'
import DocForm from './DocForm'
import { defaultState } from '../defaultState'

class CreateDoc extends Component {
  createDoc = async data => {
    this.props.createGoodsTransfer(data)
  }

  render() {
    const { successSave, isLoading } = this.props
    return (
      <DocForm
        onSubmit={this.createDoc}
        formAction="สร้างใหม่"
        data={defaultState}
        successSave={successSave}
        isLoading={isLoading}
      />
    )
  }
}

const mapStateToProps = ({ goodstransfers }) => ({
  goodstransfer: goodstransfers,
  isLoading: goodstransfers.isLoading,
  successSave: goodstransfers.successSave
})
const mapDispatchToProps = {
  createGoodsTransfer: actions.createGoodsTransfer.request
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateDoc)
