import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

const style = {
  marginBottom: '15px'
}

export default  ({ values, handleChange }) =>  (
  <div style={{ padding: '10px', flexGrow: 1 }}>
      <Grid item xs={12} style={style}>
        <TextField
          label="รหัส"
          name="employee_code"
          value={values.employee_code}
          fullWidth
          onChange={handleChange('employee_code')}
        />
      </Grid>
      <Grid item xs={12} style={style}>
        <TextField
          label="ชื่อผู้ใช้งาน"
          name="username"
          value={values.username}
          fullWidth
          onChange={handleChange('username')}
        />
      </Grid>
      <Grid item xs={12} style={style}>
        <TextField
          label="ชื่อ"
          name="name"
          value={values.name}
          fullWidth
          onChange={handleChange('name')}
        />
      </Grid>
      <Grid item xs={12} style={style}>
        <TextField
          label="นามสกุล"
          name="surname"
          value={values.surname}
          fullWidth
          onChange={handleChange('surname')}
        />
      </Grid>
    </div>
  )


