import {
  LOAD_STOCKBALANCES,
  LOAD_STOCKBALANCE,
  FILTER_STOCKBALANCE,
  GET_STOCKBALANCE,
  FETCH_STOCKBALANCES_FROM_STOCKBALANCE
} from './actions'

import { dataFilter } from 'lib/helper'

const initialState = {
  isLoading: false,
  successSave: null,
  items: [],
  backupItems: [],
  data: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_STOCKBALANCE:
      return {
        ...state,
        items: dataFilter(action.payload.stockbalance, action.payload.search),
        backupItems: state.backupItems
      }
    case GET_STOCKBALANCE:
      const id = state.backupItems.findIndex(data => parseInt(data.id) === parseInt(action.payload))
      return {
        ...state,
        successSave: null,
        data: [state.backupItems[id]]
      }
    case FETCH_STOCKBALANCES_FROM_STOCKBALANCE:
      return {
        ...state,
        successSave: null,
        items: state.backupItems,
        isLoading: false
      }
    case LOAD_STOCKBALANCES.REQUEST:
    case LOAD_STOCKBALANCE.REQUEST:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: true
      }
    case LOAD_STOCKBALANCES.SUCCESS:
      return {
        ...state,
        items: action.payload.stockbalances.data,
        backupItems: action.payload.stockbalances.data,
        isLoading: false,
        meta: action.payload.stockbalances.meta
      }
    case LOAD_STOCKBALANCE.SUCCESS:
      return {
        ...state,
        data: [action.payload.stockbalance],
        isLoading: false,
        successSave: null
      }
    case LOAD_STOCKBALANCES.FAILURE:
    case LOAD_STOCKBALANCE.FAILURE:
      return { ...state, items: [], isLoading: false, successSave: false }
    default:
      return state
  }
}

