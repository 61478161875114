import React, { useState, useEffect } from 'react'
import CreatableSelect from 'react-select/lib/Creatable'
import { makeStyles, useTheme } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'

import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import { emphasize } from '@material-ui/core/styles/colorManipulator'

import axios from 'axios'
import { config } from 'lib/authFunc'

const createOption = (label) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ''),
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  input: {
    display: 'flex',
    padding: 0
  },
  typography: { useNextVariants: true },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 1000,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing.unit * 2
  }
}))

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  )
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  )
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  )
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
}

function SelectCreatable(props) {
  const { title, url, label, withOption, options, onChange, name, value, disabled, extendOption } = props
  const classes = useStyles()
  const theme = useTheme()
  const [single, setSingle] = useState(value)
  const [data, setData] = useState([])
  const [isLoading, setLoad] = useState(false)


  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit'
      }
    })
  }

  const fetchData = async () => {
    setLoad(true)
    const result = await axios.get(process.env.REACT_APP_API_URL + "/" + url, config)
    if (typeof result.data.data !== 'undefined') {
      setData(result.data.data)
      setLoad(false)
      setSingle(value)
    }
  }

  useEffect(() => {
    if (!withOption) fetchData()
  }, [withOption, options])

  const genList = items => {
    let arr = [],
      labelOpt = typeof label !== 'undefined' ? label : 'name'
    if (typeof items === 'object') {
      Object.keys(items).reduce((obj, key) => {
        if (extendOption) {
          return arr.push({
            value: items[key].id.toString(),
            label: items[key][`${labelOpt}`],
            company_id: items[key].company_id,
            branch_id: items[key].branch_id,
          })
        } else {
          return arr.push({
            value: items[key].id.toString(),
            label: items[key][`${labelOpt}`]
          })
        }
      }, {})
      return arr
    }
  }

  function handleChangeSingle(value) {
    setSingle(value)
    if (typeof onChange === 'function') {
      const val = value === null ? '' : value.value
      if (extendOption) {
        if (value !== null) {
          onChange(name, val, value.company_id, value.branch_id)
        } else {
          onChange(name, val, '', '')
        }

      } else {
        onChange(name, val)
      }
    }
  }

  let optionsList = withOption ? options : genList(data)
  let idx = optionsList.findIndex(val => parseInt(val.value) === parseInt(value))
  if (idx === -1 && value !== '') {
    optionsList = [...optionsList, createOption(value)]
  }

  return (
    <div className={classes.root}>
      <CreatableSelect
        isClearable
        isDisabled={disabled}
        isLoading={isLoading}
        classes={classes}
        styles={selectStyles}
        options={optionsList}
        components={components}
        textFieldProps={{
          label: title,
          InputLabelProps: {
            shrink: true
          },
          disabled: disabled
        }}
        value={optionsList.filter((item) => item.label === value || parseInt(item.value) === parseInt(value))}
        getOptionLabel={({ label }) => label}
        getOptionValue={({ value }) => value}
        onChange={handleChangeSingle}
      />
    </div>
  )
}

export default SelectCreatable
