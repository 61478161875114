import { createTypes, createAction } from 'lib/action'
 
const LOGIN = createTypes('userslogin', 'login')

const login = {
  request: userslogin => createAction(LOGIN.REQUEST, { userslogin }),
  success: userslogin => createAction(LOGIN.SUCCESS, { userslogin }),
  failure: err => createAction(LOGIN.FAILURE, { err })
}


export {
  LOGIN,
  login
}
