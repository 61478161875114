import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { headerWithAuth, handleUnAutherize } from 'lib/authFunc'
import * as actions from './actions'
import { push } from 'connected-react-router'

const header = headerWithAuth()

function* loadGoodsIssues(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.get,
      process.env.REACT_APP_API_URL + `/api/goodsissues${term}`,
      header
    )
    yield put(actions.loadGoodsIssues.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadGoodsIssues.failure(error.response))
  }
}

function* loadGoodsIssue(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.get, process.env.REACT_APP_API_URL + `/api/goodsissues/${id}`, header)
    yield put(actions.loadGoodsIssue.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadGoodsIssue.failure(error.response))
  }
}

function* createGoodsIssue(action) {
  try {
    const { goodsissue } = action.payload
    const res = yield call(axios.post, process.env.REACT_APP_API_URL + '/api/goodsissues', { goodsissue }, header)
    yield put(push(`/warehouse/goods-issues/${res.data.id}`))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.createGoodsIssue.failure(error.response))
  }
}

function* editGoodsIssue(action) {
  try {
    const { goodsissue, id } = action.payload
    const res = yield call(axios.patch, process.env.REACT_APP_API_URL + `/api/goodsissues/${id}`, goodsissue, header)

    yield put(actions.editGoodsIssue.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.editGoodsIssue.failure(error.response))
  }
}

function* deleteGoodsIssue(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.delete, process.env.REACT_APP_API_URL + `/api/goodsissues/${id}`, header)
    yield put(actions.deleteGoodsIssue.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.deleteGoodsIssue.failure(error.response))
  }
}

function* createDocItem(action) {
  try {
    const { detail } = action.payload
    const res = yield call(axios.post, process.env.REACT_APP_API_URL + '/api/goodsissue-details', { detail }, header)
    yield put(actions.createDocItem.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.createDocItem.failure(error.response))
  }
}

function* editDocItem(action) {
  try {
    const { detail, id } = action.payload
    const res = yield call(axios.patch, process.env.REACT_APP_API_URL + `/api/goodsissue-details/item/${id}`, detail, header)

    yield put(actions.editDocItem.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.editDocItem.failure(error.response))
  }
}

function* deleteDocItem(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.delete, process.env.REACT_APP_API_URL + `/api/goodsissue-details/item/${id}`, header)
    yield put(actions.deleteDocItem.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.deleteDocItem.failure(error.response))
  }
}


function* deleteAllDocItem(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.delete, process.env.REACT_APP_API_URL + `/api/goodsissue-details/delete-all/${id}`, header)
    yield put(actions.deleteAllDocItem.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.deleteAllDocItem.failure(error.response))
  }
}

function* changeStatusGI(action) {
  try {
    const { status, id } = action.payload
    const res = yield call(axios.patch, process.env.REACT_APP_API_URL + `/api/goodsissues/change-status/${id}/${status}`, {}, header)
    if (typeof res.data.error !== 'undefined') {
      yield put(actions.changeStatusGI.failure(res.data.error))
    } else {
      yield put(actions.changeStatusGI.success(res.data))
    }
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.changeStatusGI.failure(error.response))
  }
}

export default function* watchComments() {
  yield all([
    takeEvery(actions.LOAD_GIS.REQUEST, loadGoodsIssues),
    takeEvery(actions.LOAD_GI.REQUEST, loadGoodsIssue),
    takeEvery(actions.CREATE_GI.REQUEST, createGoodsIssue),
    takeEvery(actions.EDIT_GI.REQUEST, editGoodsIssue),
    takeEvery(actions.DELETE_GI.REQUEST, deleteGoodsIssue),
    takeEvery(actions.CREATE_GI_ITEM.REQUEST, createDocItem),
    takeEvery(actions.EDIT_GI_ITEM.REQUEST, editDocItem),
    takeEvery(actions.DELETE_GI_ITEM.REQUEST, deleteDocItem),
    takeEvery(actions.DELETEALL_GI_ITEM.REQUEST, deleteAllDocItem),
    takeEvery(actions.CHANGE_STATUS_GI.REQUEST, changeStatusGI)
  ])
}
