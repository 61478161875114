export const defaultState = {
    active: '1',
    disableForm : false,
    isOpenWeb : true,
    isOpenUser : true,
    formLoading : true,
    id: '',
    company_id: '',
    name: '',
    search_key: '',
    advSearch: {
        name: '',
        search_key: '', 
        company_id: ''
    }
}