import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Breadcrumb } from 'lib/widgets'

import SaleSummary from './SaleSummary'
import Calendar from './Calendar'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import CustomerHeatMap from './CustomerHeatMap'
import QueryTitle from './QueryTitle'
import MonthSummary from './MonthSummary'
import PageTitle from './PageTitle'

import { styles, formatOption } from './RelateFunction'
import { COMPANY_ID, MAIN_BRANCH } from 'lib/defaultSystemData'

import { getCurrentMonthIdx } from 'lib/helper'

import Collapse from '@material-ui/core/Collapse';

function DashboardLayout(props) {
    const { classes } = props;

    const [subtitle, setSub] = useState("")
    const [company_id, setCompanyId] = useState(COMPANY_ID)
    const [branch_id, setBranchId] = useState(MAIN_BRANCH)
    const [month_id, setMonthId] = useState(getCurrentMonthIdx())

    const [expandedMonth, setExpandedMonth] = useState(true);
    const [expandedCalendar, setExpandedCalendar] = useState(true);
    const [expandedCustomer, setExpandedCustomer] = useState(true);

    const setCompValue = val => {
        setCompanyId(val)
    }

    const setSubtitle = title => {
        setSub(title)
    }

    const setBranchValue = val => {
        setBranchId(val)
    }

    const setMonthValue = val => {
        setMonthId(val)
    }

    const handleExpandClick = name => {
        switch (name) {
            case 'month':
                setExpandedMonth(!expandedMonth);
                break;
            case 'calendar':
                setExpandedCalendar(!expandedCalendar);
                break;
            case 'customer':
                setExpandedCustomer(!expandedCustomer);
                break;
            default: break;
        }
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={16}>
                <Grid item xs>
                    <Breadcrumb
                        menu="กระดานข้อมูล"
                        submenu=""
                        menuLink="/dashboard"
                    />
                </Grid>
            </Grid>

            <QueryTitle  {...props}
                companyId={company_id}
                branchId={branch_id}
                MonthId={month_id}
                onCompChange={setCompValue}
                onBranchChange={setBranchValue}
                onMonthChange={setMonthValue}
                setSubtitle={setSubtitle}
            /> 

            <MonthSummary {...props}
                companyId={company_id}
                branchId={branch_id}
                MonthId={month_id} />

                <Grid container spacing={16}>
                    <Grid item xs={12}>
                        <Card className={classes.card}>
                            <PageTitle 
                                classes={classes}
                                handleExpandClick={() => handleExpandClick('month')}
                                data={{
                                alphabet: 'M',
                                title: 'สรุปยอดขาย',
                                subheader: 'Sales Summary'
                                }}
                                color="#4285f4"
                                expanded={expandedMonth} 
                            />
                            <Collapse in={expandedMonth} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <SaleSummary
                                        {...props}
                                        option={formatOption}
                                        companyId={company_id}
                                        branchId={branch_id}
                                    />
                                </CardContent>
                            </Collapse>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container spacing={16}> 
                    <Grid item xs={12}>
                        <Card className={classes.card}>
                            <PageTitle 
                                classes={classes}
                                handleExpandClick={() => handleExpandClick('calendar')}
                                data={{
                                alphabet: 'C',
                                title: 'สรุปยอดขายแยกตามวัน',
                                subheader: 'Calendar Summary'
                                }}
                                color="#34a853"
                                expanded={expandedCalendar} 
                            />
                            <Collapse in={expandedCalendar} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <Grid container spacing={16}>
                                        <Calendar
                                            {...props}
                                            companyId={company_id}
                                            branchId={branch_id}
                                            MonthId={month_id}
                                        />
                                    </Grid>

                                </CardContent>
                            </Collapse>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container spacing={16}>
                    <Grid item xs={12}>
                        <Card className={classes.card}>
                            <PageTitle 
                                classes={classes}
                                handleExpandClick={() => handleExpandClick('customer')}
                                data={{
                                alphabet: 'C',
                                title: 'แผนที่แสดงการกระจายตัวของลูกค้า',
                                subheader: 'Customers Heatmap'
                                }}
                                color="#fbbc05"
                                expanded={expandedCustomer} 
                            />
                            <Collapse in={expandedCustomer} timeout="auto" unmountOnExit>
                                <CardContent style={{ marginBottom: '80px' }}>
                                    <Grid container spacing={16}>
                                        <CustomerHeatMap {...props} option={formatOption}
                                            companyId={company_id}
                                            branchId={branch_id}
                                            MonthId={month_id} />
                                    </Grid>
                                </CardContent>
                            </Collapse>
                        </Card>
                    </Grid>
                </Grid>

        </div>
    );
}

DashboardLayout.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DashboardLayout);