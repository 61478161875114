import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as actions from '../actions'
import BrandForm from './BrandForm'

import { hasMessage } from 'lib/fxUI'
import { defaultState } from '../defaultState'

class EditBrand extends Component {
  componentDidMount() {
    const id = this.getBrandId()
    this.props.loadBrand(id)
  }

  componentDidUpdate(prevProps) {
    const search = this.props.location.pathname
    if (search === prevProps.location.pathname) return

    const id = this.getBrandId()
    this.props.loadBrandFromArray(id)
  }

  getBrandId = () => this.props.match.params.id

  editBrand = async data => {
    this.props.editBrand(this.getBrandId(), data)
  }

  render() {
    const { brand, successSave, isLoading } = this.props
    let data = []

    if (typeof brand !== 'undefined') {
      data = typeof brand[0] !== 'undefined' ? brand[0] : defaultState
    }

    if (data.length === 0) {
      return <div className="lmask" id="lmask" />
    }

    return (
      <Fragment>
        {successSave !== null && hasMessage(successSave, '')}
        <BrandForm
          onSubmit={this.editBrand}
          formAction="แก้ไข"
          data={data}
          successSave={successSave}
          isLoading={isLoading}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ brands }) => ({
  brand: brands.brand,
  isLoading: brands.isLoading,
  successSave: brands.successSave
})

const mapDispatchToProps = {
  loadBrand: actions.loadBrand.request,
  editBrand: actions.editBrand.request,
  loadBrandFromArray: actions.loadBrandFromArray
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
)(EditBrand)
