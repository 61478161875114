import {
  LOAD_MAP,
  CHANGE_BOUNDS,
  MARKER_HOVER_INDEX_CHANGE,
  SHOW_BALLON,
  SET_MAP_MODE,
  SET_ACTIVE_TYPE
} from './actions'


const initialState = {
  isLoading: false,
  successSave: null,
  active: 'all_total',
  title: '',
  data: [],
  heatmaps: [
    { lat: 13.752801, lng: 100.501587 },
  ],
  mode: { heat: true, marker: false },
  dataFiltered: [],
  mapInfo: {
    center: [14.8971921, 100.83273],
    // set for server rendering for popular screen res
    bounds: [20.44215889, 99.88108819, 5.77434, 101.07231],
    marginBounds: [20.44215889, 99.88108819, 5.77434, 101.07231],
    zoom: 5.5
  },
  openBalloonIndex: -1,
  hoverMarkerIndex: -1,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MAP_MODE:
      const { mode } = action.payload;
      return {
        ...state, mode: { ...state.mode, ...mode }
      }
    case SET_ACTIVE_TYPE:
      const { active } = action.payload;
      return {
        ...state, active
      }
    case CHANGE_BOUNDS:
      const { center, zoom, bounds, marginBounds } = action.payload;
      return {
        ...state,
        mapInfo: {
          center, zoom, bounds, marginBounds
        },
        openBalloonIndex: -1,
        dataFiltered: state.data
      }
    case MARKER_HOVER_INDEX_CHANGE:
      const { hoverMarkerIndex } = action.payload;
      return {
        ...state,
        hoverMarkerIndex: hoverMarkerIndex
      }
    case SHOW_BALLON:
      const { openBalloonIndex } = action.payload;
      return {
        ...state,
        openBalloonIndex: openBalloonIndex === state.openBalloonIndex ? -1 : openBalloonIndex
      }
    case LOAD_MAP.REQUEST:
      return { ...state, items: [], isLoading: true }
    case LOAD_MAP.SUCCESS:
      const { heatmaps } = action.payload.maps;
      return {
        ...state,
        isLoading: false,
        heatmaps,
        mapInfo: {
          ...state.mapInfo,
          zoom: 6,
        },
      }
    case LOAD_MAP.FAILURE:
      return { ...state, items: [], isLoading: false }
    default:
      return state
  }
}

