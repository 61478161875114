import * as React from 'react';
import { EditingState } from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';

import { checkAction } from 'lib/authFunc'

const getRowId = row => row.id;

const AddButton = ({ onExecute, disableBtn }) => (
  <div style={{ textAlign: 'center' }}>
    <Button
      color="primary"
      onClick={onExecute}
      title="Create new row"
      disabled={disableBtn}
    >
      New
    </Button>
  </div>
);

const EditButton = ({ onExecute, disableBtn }) => (
  <IconButton onClick={onExecute} disabled={disableBtn} title="Edit row">
    <EditIcon />
  </IconButton>
);

const DeleteButton = ({ onExecute, disableBtn }) => (
  <IconButton onClick={onExecute} disabled={disableBtn} title="Delete row">
    <DeleteIcon />
  </IconButton>
);

const CommitButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Save changes">
    <SaveIcon />
  </IconButton>
);

const CancelButton = ({ onExecute }) => (
  <IconButton color="secondary" onClick={onExecute} title="Cancel changes">
    <CancelIcon />
  </IconButton>
);

const commandComponents = {
  add: AddButton,
  edit: EditButton,
  delete: DeleteButton,
  commit: CommitButton,
  cancel: CancelButton,
};

const Cell = (props) => {
  const { column, value } = props;
  if (column.name === 'active') {
    return <TableCell>
      <Checkbox
        color="primary"
        checked={value === 1} />
    </TableCell>
  }

  return <VirtualTable.Cell {...props} />;
};

const EditCell = (props) => {
  const { column, onValueChange, value } = props

  if (column.name === 'active') {
    return <TableCell>
      <Checkbox
        color="primary"
        onChange={event => onValueChange(event.target.checked === true ? 1 : -1)}
        checked={value === 1} />
    </TableCell>
  }
  return <TableEditRow.Cell {...props} />
}

const editingStateColumnExtensions = [
  { columnName: 'name', width: 225, editingEnabled: false },
  { columnName: 'row_x', width: 80 },
  { columnName: 'row_y', width: 105 },
  { columnName: 'row_z', width: 106 },
  { columnName: 'active', width: 125 }
]


export class StorageBin extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      currentUrl: '',
      columns: [
        { name: 'name', title: 'Storage Bin' },
        { name: 'row_x', title: 'Rack (X)' },
        { name: 'row_y', title: 'Level (Y)' },
        { name: 'row_z', title: 'Block (Z)' },
        { name: 'active', title: 'Active' },
      ],
      disableBtn: false
    }
  }

  command = ({ id, onExecute }, disableBtn) => {
    const CommandButton = commandComponents[id];
    return (
      <CommandButton
        onExecute={onExecute}
        disableBtn={disableBtn}
      />
    );
  }

  commitChanges = ({ added, changed, deleted }) => {
    let { rows } = this.state;
    if (added) {
      const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      rows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          warehouse_id: this.props.warehouseId,
          ...row,
        })),
      ];
      this.props.onCreate(rows[rows.length - 1])
    }
    if (changed) {
      rows = rows.map(row => {
        if (changed[row.id]) {
          let data = changed[row.id]
          data.warehouse_id = this.props.warehouseId
          data.old_name = row.name
          this.props.onEdit(row.id, data)
          return { ...row, ...changed[row.id] }
        }
        return row
      })
    }
    if (deleted) {
      const deletedSet = new Set(deleted);
      let idx = rows.findIndex(row => parseInt(row.id) === parseInt(deleted[0]))

      this.props.onDelete(deleted[0], rows[idx])
      rows = rows.filter(row => !deletedSet.has(row.id));
    }
    this.setState({ rows });
  }

  componentDidUpdate() {
    const { currentUrl, rows } = this.state
    if (rows.length === 0 || currentUrl !== window.location.pathname) {
      this.setState({ rows: this.props.rows, currentUrl: window.location.pathname })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { rows } = nextProps
    this.setState({ rows: rows })
  }

  validateRow = addedRows => {
    this.setState({ disableBtn: false })
  }

  validateRowAdd = (addedRows) => {
    this.setState({ disableBtn: true })
    if(addedRows.length === 0) this.setState({ disableBtn: false })
  }


  render() {
    const { rows, columns } = this.state;

    return (
      <Grid
        rows={rows}
        columns={columns}
        getRowId={getRowId}
      >
        <EditingState
          onCommitChanges={this.commitChanges}
          columnExtensions={editingStateColumnExtensions}
          onAddedRowsChange={this.validateRowAdd}
          onRowChangesChange={this.validateRow}
          onEditingRowIdsChange={this.validateRowAdd}
        />
        <VirtualTable
          height="auto"
          cellComponent={Cell}
        />
        <TableHeaderRow />
        <TableEditRow cellComponent={EditCell} />
        {this.props.warehouseId > 0 && checkAction("whbin edit") &&
          <TableEditColumn
            showAddCommand
            showEditCommand
            showDeleteCommand
            commandComponent={props => this.command(props, this.state.disableBtn)}
          />}
      </Grid>
    );
  }
}

StorageBin.defaultProps = {
  data: []
}

