import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import WebGroupTable from './WebGroupTable'
import WebGroupList from './WebGroupList'
import EditWebGroup from './EditWebGroup'
import CreateWebGroup from './CreateWebGroup'

import { Breadcrumb } from 'lib/widgets'
import { Layoutstyles } from './AddonStyles'

const WebGroupLayout = ({ classes }) => (
  <div className={classes.root}>
    <Grid container spacing={24}>
      <Grid item xs>
        <Breadcrumb
          menu="ข้อมูลหลัก"
          submenu="กลุ่มสิทธิ์เว็บ"
          menuLink="/master-data/web-groups"
        />
      </Grid>
    </Grid>
    <Grid container spacing={24}>
      <Switch>
        <Route exact path="/master-data/web-groups" component={WebGroupTable} />
        <Route path="/master-data/web-groups" component={WebGroupList} />
      </Switch>
      <Switch>
        <Route path="/master-data/web-groups/new" component={CreateWebGroup} />
        <Route path="/master-data/web-groups/:id" component={EditWebGroup} />
      </Switch>
    </Grid>
  </div>
)

export default withStyles(Layoutstyles)(WebGroupLayout)
