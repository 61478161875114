import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { headerWithAuth, handleUnAutherize } from 'lib/authFunc'
import * as actions from './actions'

const header = headerWithAuth()

function* loadCategories(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.get,
      process.env.REACT_APP_API_URL + `/api/product-categories${term}`,
      header
    )
    yield put(actions.loadCategories.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadCategories.failure(error.response))
  }
}

function* loadCategory(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.get, process.env.REACT_APP_API_URL + `/api/product-categories/${id}`, header)
    yield put(actions.loadCategory.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadCategory.failure(error.response))
  }
}

function* createCategory(action) {
  try {
    const { category } = action.payload
    const res = yield call(axios.post, process.env.REACT_APP_API_URL + '/api/product-categories', { category }, header)
    yield put(actions.createCategory.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.createCategory.failure(error.response))
  }
}

function* editCategory(action) {
  try {
    const { category, id } = action.payload
    const res = yield call(axios.patch, process.env.REACT_APP_API_URL + `/api/product-categories/${id}`, category, header)

    yield put(actions.editCategory.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.editCategory.failure(error.response))
  }
}

function* deleteCategory(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.delete, process.env.REACT_APP_API_URL + `/api/product-categories/${id}`, header)
    yield put(actions.deleteCategory.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.deleteCategory.failure(error.response))
  }
}


export default function* watchComments() {
  yield all([
    takeEvery(actions.LOAD_CATEGORIES.REQUEST, loadCategories),
    takeEvery(actions.LOAD_CATEGORY.REQUEST, loadCategory),
    takeEvery(actions.CREATE_CATEGORY.REQUEST, createCategory),
    takeEvery(actions.EDIT_CATEGORY.REQUEST, editCategory),
    takeEvery(actions.DELETE_CATEGORY.REQUEST, deleteCategory)
  ])
}
