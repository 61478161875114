import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as actions from '../actions'
import DocForm from './DocForm'
import { defaultState } from '../defaultState'

class CreateDoc extends Component {
  createDoc = async data => {
    this.props.createGoodsReceive(data)
  }

  render() {
    const { successSave } = this.props
    return (
      <DocForm
        onSubmit={this.createDoc}
        formAction="สร้างใหม่"
        data={defaultState}
        successSave={successSave}
      />
    )
  }
}

const mapStateToProps = ({ goodsreceives }) => ({
  successSave: goodsreceives.successSave
})
const mapDispatchToProps = {
  createGoodsReceive: actions.createGoodsReceive.request
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateDoc)
