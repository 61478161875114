import { createTypes, createAction } from 'lib/action'
 
const LOAD_MAP = createTypes('customeranalysis', 'list')
const LOAD_CUSTOMER = createTypes('customeranalysis', 'summary')
const LOAD_LINE = createTypes('customeranalysis', 'line')
const LOAD_PURCHASE = createTypes('customeranalysis', 'purchase')
const LOAD_TOPSPEND = createTypes('customeranalysis', 'topspend')
const LOAD_NEW_TOPSPEND = createTypes('customeranalysis', 'newtopspend')
const LOAD_LOWERSPEND = createTypes('customeranalysis', 'lowerspend')
const LOAD_COMPARED = createTypes('customeranalysis', 'compared')

const CHANGE_BOUNDS = 'changebounds'
const MARKER_HOVER_INDEX_CHANGE = 'markerhover'
const SHOW_BALLON = 'showballon'
const SET_MAP_MODE = 'setmapmode'
const SET_ACTIVE_TYPE = 'setactive'

const loadCompared = {
  request: term => createAction(LOAD_COMPARED.REQUEST, { term }),
  success: maps => createAction(LOAD_COMPARED.SUCCESS, { maps }),
  failure: () => createAction(LOAD_COMPARED.FAILURE)
}

const loadCustomerSummary = {
  request: term => createAction(LOAD_CUSTOMER.REQUEST, { term }),
  success: maps => createAction(LOAD_CUSTOMER.SUCCESS, { maps }),
  failure: () => createAction(LOAD_CUSTOMER.FAILURE)
}

const loadCustomerAnalysis = {
  request: term => createAction(LOAD_MAP.REQUEST, { term }),
  success: maps => createAction(LOAD_MAP.SUCCESS, { maps }),
  failure: () => createAction(LOAD_MAP.FAILURE)
}

const loadCustomerGrowthRate = {
  request: term => createAction(LOAD_LINE.REQUEST, { term }),
  success: maps => createAction(LOAD_LINE.SUCCESS, { maps }),
  failure: () => createAction(LOAD_LINE.FAILURE)
}

const loadPurchasingSummary = {
  request: term => createAction(LOAD_PURCHASE.REQUEST, { term }),
  success: maps => createAction(LOAD_PURCHASE.SUCCESS, { maps }),
  failure: () => createAction(LOAD_PURCHASE.FAILURE)
}

const loadTopspend = {
  request: term => createAction(LOAD_TOPSPEND.REQUEST, { term }),
  success: maps => createAction(LOAD_TOPSPEND.SUCCESS, { maps }),
  failure: () => createAction(LOAD_TOPSPEND.FAILURE)
}

const loadNewTopSpend = {
  request: term => createAction(LOAD_NEW_TOPSPEND.REQUEST, { term }),
  success: maps => createAction(LOAD_NEW_TOPSPEND.SUCCESS, { maps }),
  failure: () => createAction(LOAD_NEW_TOPSPEND.FAILURE)
}

const loadLowerSpend = {
  request: term => createAction(LOAD_LOWERSPEND.REQUEST, { term }),
  success: maps => createAction(LOAD_LOWERSPEND.SUCCESS, { maps }),
  failure: () => createAction(LOAD_LOWERSPEND.FAILURE)
}

const changeBounds = (center, zoom, bounds, marginBounds) => ({
  type: CHANGE_BOUNDS,
  payload: {
    center, zoom, bounds, marginBounds
  }
})


const markerHoverIndexChange = hoverMarkerIndex => ({
  type: MARKER_HOVER_INDEX_CHANGE,
  payload: {
    hoverMarkerIndex
  }
})

const showBallon = openBalloonIndex => ({
  type: SHOW_BALLON,
  payload: {
    openBalloonIndex
  }
})

const setMapMode = mode => ({
  type: SET_MAP_MODE,
  payload: {
    mode
  }
})

const setActive = active => ({
    type: SET_ACTIVE_TYPE,
    payload: {
      active
    }
})

export {
  LOAD_MAP,
  LOAD_PURCHASE,
  LOAD_CUSTOMER,
  LOAD_COMPARED,
  CHANGE_BOUNDS,
  MARKER_HOVER_INDEX_CHANGE,
  SHOW_BALLON,
  SET_MAP_MODE,
  SET_ACTIVE_TYPE,
  LOAD_LINE,
  LOAD_TOPSPEND,
  LOAD_NEW_TOPSPEND,
  LOAD_LOWERSPEND,
  changeBounds,
  markerHoverIndexChange,
  showBallon,
  loadCustomerAnalysis,
  setMapMode,
  setActive,
  loadCustomerGrowthRate,
  loadPurchasingSummary,
  loadTopspend,
  loadNewTopSpend,
  loadLowerSpend,
  loadCustomerSummary,
  loadCompared
}
