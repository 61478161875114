import { createTypes, createAction } from 'lib/action'
 
const LOAD_BRANDS = createTypes('brands', 'list')
const LOAD_BRAND = createTypes('brands', 'show')
const CREATE_BRAND = createTypes('brands', 'create')
const EDIT_BRAND = createTypes('brands', 'edit')
const DELETE_BRAND = createTypes('brands', 'delete')
const FILTER_BRAND = 'backup'
const SET_BRAND = 'api/brands'
const GET_BRAND = 'GET_BRAND'
const FETCH_BRANDS_FROM_STORE = "FETCH_BRANDS_FROM_STORE"

const loadBrands = {
  request: term => createAction(LOAD_BRANDS.REQUEST, { term }),
  success: brands => createAction(LOAD_BRANDS.SUCCESS, { brands }),
  failure: () => createAction(LOAD_BRANDS.FAILURE)
}

const loadBrand = {
  request: id => createAction(LOAD_BRAND.REQUEST, { id }),
  success: brand => createAction(LOAD_BRAND.SUCCESS, { brand }),
  failure: () => createAction(LOAD_BRAND.FAILURE)
}

const createBrand = {
  request: brand => createAction(CREATE_BRAND.REQUEST, { brand }),
  success: brand => createAction(CREATE_BRAND.SUCCESS, { brand }),
  failure: error => createAction(CREATE_BRAND.FAILURE, { error })
}

const editBrand = {
  request: (id, brand) => createAction(EDIT_BRAND.REQUEST, { id, brand }),
  success: brand => createAction(EDIT_BRAND.SUCCESS, { brand }),
  failure: () => createAction(EDIT_BRAND.FAILURE)
}

const deleteBrand = {
  request: (id) => createAction(DELETE_BRAND.REQUEST, { id } ),
  success: id => createAction(DELETE_BRAND.SUCCESS, { id } ),
  failure: () => createAction(DELETE_BRAND.FAILURE)
}

const filterData = (brands, search) => ({
  type: FILTER_BRAND,
  payload: {
    brands,
    search 
  }
})

const loadBrandFromArray = id => ({
  type: GET_BRAND,
  payload: id
})

const fecthBrandFromArray = () => ({
  type : FETCH_BRANDS_FROM_STORE,
  payload: {}
})


export {
  LOAD_BRANDS,
  LOAD_BRAND,
  CREATE_BRAND,
  EDIT_BRAND,
  DELETE_BRAND,
  SET_BRAND,
  FILTER_BRAND,
  GET_BRAND,
  FETCH_BRANDS_FROM_STORE,
  loadBrands,
  loadBrand,
  loadBrandFromArray,
  createBrand,
  editBrand,
  deleteBrand,
  filterData,
  fecthBrandFromArray
}
