import React from 'react'
import { Switch, Route } from 'react-router-dom'

import CompanyTable from './CompanyTable'
import CompanyList from './CompanyList'
import EditCompany from './EditCompany'
import CreateCompany from './CreateCompany'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Breadcrumb } from 'lib/widgets'
import { Layoutstyles } from './AddonStyles'

const CompanyLayout = ({ classes }) => (
  <div className={classes.root}>
    <Grid container spacing={24}>
      <Grid item xs>
        <Breadcrumb
          menu="ข้อมูลหลัก"
          submenu="บริษัท"
          menuLink="/master-data/companies"
        />
      </Grid>
    </Grid>
    <Grid container spacing={24}>
      <Switch>
        <Route exact path="/master-data/companies" component={CompanyTable} />
        <Route path="/master-data/companies" component={CompanyList} />
      </Switch>
      <Switch>
        <Route path="/master-data/companies/new" component={CreateCompany} />
        <Route path="/master-data/companies/:id" component={EditCompany} />
      </Switch>
    </Grid>
  </div>
)
export default withStyles(Layoutstyles)(CompanyLayout)
