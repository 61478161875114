import React from 'react';

export default () => (
    <div id="notfound">
    <div className="notfound">
        <div className="notfound-404"></div>
        <h1>401</h1>
        <h2>Oops! Unauthorized</h2>
        <p>คุณไม่มีสิทธิ์ เข้าใช้หน้านี้ ติดต่อ Admin เพื่อเพิ่มสิทธิ์การใช้งาน</p>
    </div>
</div>
)