import {
  LOAD_GIS,
  LOAD_GI,
  CREATE_GI,
  EDIT_GI,
  DELETE_GI,
  CREATE_GI_ITEM,
  EDIT_GI_ITEM,
  DELETE_GI_ITEM,
  DELETEALL_GI_ITEM,
  FILTER_GI,
  GET_GI,
  FETCH_GIS_FROM_GI,
  CHANGE_STATUS_GI
} from './actions'

import { dataFilter, replaceIdxValue } from 'lib/helper'

const initialState = {
  isLoading: false,
  successSave: null,
  deleteSuccess: null,
  items: [],
  backupItems: [],
  data: [],
  errorMsg: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_GI:
      return {
        ...state,
        items: dataFilter(action.payload.goodsissue, action.payload.search),
        backupItems: state.backupItems
      }
    case GET_GI:
      const id = state.backupItems.findIndex(
        data => parseInt(data.id) === parseInt(action.payload)
      )
      return {
        ...state,
        successSave: null,
        data: [state.backupItems[id]]
      }
    case FETCH_GIS_FROM_GI:
      return {
        ...state,
        successSave: null,
        items: state.backupItems,
        isLoading: false
      }
    case CREATE_GI.REQUEST:
    case EDIT_GI.REQUEST:
    case CREATE_GI_ITEM.REQUEST:
    case EDIT_GI_ITEM.REQUEST:
    case DELETE_GI_ITEM.REQUEST:
      return { ...state, items: [], isLoading: true, successSave: null, deleteSuccess: null }
    case LOAD_GIS.REQUEST:
    case LOAD_GI.REQUEST:
    case DELETE_GI.REQUEST:
    case CHANGE_STATUS_GI.REQUEST:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: true,
        successSave: null,
        deleteSuccess: null
      }
    case LOAD_GIS.SUCCESS:
      return {
        ...state,
        items: action.payload.goodsissues.data,
        backupItems: action.payload.goodsissues.data,
        isLoading: false,
        meta: action.payload.goodsissues.meta,
        successSave: null,
        deleteSuccess: null
      }
    case LOAD_GI.SUCCESS:
      return {
        ...state,
        data: [action.payload.goodsissue],
        isLoading: false,
        successSave: null
      }
    case CREATE_GI.SUCCESS:
      return {
        ...state,
        items: [action.payload.goodsissue, ...state.backupItems],
        backupItems: [action.payload.goodsissue, ...state.backupItems],
        data: [action.payload.goodsissue],
        isLoading: false,
        successSave: true
      }
    case EDIT_GI.SUCCESS:
      return {
        ...state,
        data: [action.payload.goodsissue],
        isLoading: false,
        successSave: true
      }
    case DELETE_GI.SUCCESS:
      const index = state.backupItems.findIndex(
        data => parseInt(data.id) === parseInt(action.payload.id)
      )
      return {
        ...state.backupItems.splice(index, 1),
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: null,
        deleteSuccess: true
      }
    case CHANGE_STATUS_GI.SUCCESS:
      const idx1 = state.backupItems.findIndex(
        data =>
          parseInt(data.id) === parseInt(action.payload.goodsissue.data[0].id)
      )
      return {
        ...state,
        items: replaceIdxValue(
          state.backupItems,
          idx1,
          action.payload.goodsissue.data[0]
        ),
        backupItems: replaceIdxValue(
          state.backupItems,
          idx1,
          action.payload.goodsissue.data[0]
        ),
        isLoading: false,
        successSave: true,
        deleteSuccess: null,
        errorMsg: ''
      }
    case CREATE_GI_ITEM.SUCCESS:
      const createidx = state.backupItems.findIndex(
        data =>
          parseInt(data.id) === parseInt(action.payload.detail.gi_order_id)
      )
      state.backupItems[createidx].relations.detail = [
        ...state.backupItems[createidx].relations.detail,
        action.payload.detail
      ]
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        data: [state.backupItems[createidx]],
        isLoading: false,
        successSave: null
      }
    case EDIT_GI_ITEM.SUCCESS:
      const editidx = state.backupItems.findIndex(
        data =>
          parseInt(data.id) === parseInt(action.payload.detail.gi_order_id)
      )
      const binEditIdx = state.backupItems[editidx].relations.detail.findIndex(
        gi => parseInt(gi.id) === parseInt(action.payload.detail.id)
      )
      state.backupItems[editidx].relations.detail = replaceIdxValue(
        state.backupItems[editidx].relations.detail,
        binEditIdx,
        action.payload.detail
      )
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        data: [state.backupItems[editidx]],
        isLoading: false,
        successSave: null
      }
    case DELETE_GI_ITEM.SUCCESS:
      const delidx = state.backupItems.findIndex(
        data => parseInt(data.id) === parseInt(action.payload.data.gi_order_id)
      )
      // const binIdx = state.backupItems[delidx].relations.detail.findIndex(
      //   data => parseInt(data.id) === parseInt(action.payload.data.id)
      // )

      state.backupItems[delidx].relations.detail = action.payload.data.detail

      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        data: [state.backupItems[delidx]],
        isLoading: false,
        successSave: null
      }
    case DELETEALL_GI_ITEM.SUCCESS:
      const gridx = state.backupItems.findIndex(
        data => parseInt(data.id) === parseInt(action.payload.id)
      )
      state.backupItems[gridx].relations.detail = []
      return {
        data: [state.backupItems[gridx]],
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: null
      }
    case LOAD_GIS.FAILURE:
    case LOAD_GI.FAILURE:
    case CREATE_GI.FAILURE:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: false
      }
    case CREATE_GI_ITEM.FAILURE:
    case EDIT_GI_ITEM.FAILURE:
    case EDIT_GI.FAILURE:
    case DELETE_GI.FAILURE:
    case DELETE_GI_ITEM.FAILURE:
    case DELETEALL_GI_ITEM.FAILURE:
      return { ...state, items: [], isLoading: false, successSave: null, deleteSuccess: true }
    case CHANGE_STATUS_GI.FAILURE:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: false,
        deleteSuccess: null,
        errorMsg: action.payload.error
      }
    default:
      return state
  }
}
