import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Breadcrumb } from 'lib/widgets'
import { Layoutstyles } from '../../AddonStyles'
import CustomerApp from './CustomerApp'

const CustomerLayout = ({ classes }) => (
  <div className={classes.root}>
    <Grid container spacing={24}>
      <Grid item xs>
        <Breadcrumb
          menu="ฝ่ายขาย"
          submenu="วิเคราะห์ข้อมูลลูกค้า"
          menuLink="/salesforce/customers-analytic"
        />
      </Grid>
    </Grid>
    <Grid container spacing={24}>
        <Grid item xs={12}>
          <CustomerApp />
        </Grid>
    </Grid>
  </div>
)
export default withStyles(Layoutstyles)(CustomerLayout)
