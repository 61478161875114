import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { headerWithAuth, handleUnAutherize } from 'lib/authFunc'
import * as actions from './actions'

const header = headerWithAuth()

function* loadBranches(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.get,
      process.env.REACT_APP_API_URL + `/api/branches${term}`,
      header
    )
    yield put(actions.loadBranches.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadBranches.failure(error.response))
  }
}

function* loadBranch(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.get, process.env.REACT_APP_API_URL + `/api/branches/${id}`, header)
    yield put(actions.loadBranch.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadBranch.failure(error.response))
  }
}

function* createBranch(action) {
  try {
    const { branch } = action.payload
    const res = yield call(axios.post, process.env.REACT_APP_API_URL + '/api/branches', { branch }, header)
    yield put(actions.createBranch.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.createBranch.failure(error.response))
  }
}

function* editBranch(action) {
  try {
    const { branch, id } = action.payload
    const res = yield call(axios.patch, process.env.REACT_APP_API_URL + `/api/branches/${id}`, branch, header)

    yield put(actions.editBranch.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.editBranch.failure(error.response))
  }
}

function* deleteBranch(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.delete, process.env.REACT_APP_API_URL + `/api/branches/${id}`, header)
    yield put(actions.deleteBranch.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.deleteBranch.failure(error.response))
  }
}


export default function* watchComments() {
  yield all([
    takeEvery(actions.LOAD_BRANCHES.REQUEST, loadBranches),
    takeEvery(actions.LOAD_BRANCH.REQUEST, loadBranch),
    takeEvery(actions.CREATE_BRANCH.REQUEST, createBranch),
    takeEvery(actions.EDIT_BRANCH.REQUEST, editBranch),
    takeEvery(actions.DELETE_BRANCH.REQUEST, deleteBranch)
  ])
}
