import { createTypes, createAction } from 'lib/action'
 
const LOAD_GTS = createTypes('goodstransfers', 'list')
const LOAD_GT = createTypes('goodstransfer', 'show')

const CREATE_GT = createTypes('goodstransfer', 'create')
const EDIT_GT = createTypes('goodstransfer', 'edit')
const DELETE_GT = createTypes('goodstransfer', 'delete')

const CHANGE_STATUS_GT = createTypes('goodstransfer', 'change_status')

const CREATE_GT_ITEM = createTypes('goodstransferdetail', 'create')
const EDIT_GT_ITEM = createTypes('goodstransferdetail', 'edit')
const DELETE_GT_ITEM = createTypes('goodstransferdetail', 'delete')
const DELETEALL_GT_ITEM = createTypes('goodstransferdetail', 'deleteall')

const FILTER_GT = 'backup'
const SET_GT = 'api/data'
const GET_GT = 'GET_GT'
const FETCH_GTS_FROM_GT = "FETCH_GTS_FROM_GT"

const loadGoodsTransfers = {
  request: term => createAction(LOAD_GTS.REQUEST, { term }),
  success: goodstransfers => createAction(LOAD_GTS.SUCCESS, { goodstransfers }),
  failure: () => createAction(LOAD_GTS.FAILURE)
}

const loadGoodsTransfer = {
  request: id => createAction(LOAD_GT.REQUEST, { id }),
  success: goodstransfer => createAction(LOAD_GT.SUCCESS, { goodstransfer }),
  failure: () => createAction(LOAD_GT.FAILURE)
}

const changeStatusGT = {
  request: (id, status) => createAction(CHANGE_STATUS_GT.REQUEST, { id, status }),
  success: goodstransfer => createAction(CHANGE_STATUS_GT.SUCCESS, { goodstransfer }),
  failure: error => createAction(CHANGE_STATUS_GT.FAILURE, { error })
}

const createGoodsTransfer = {
  request: goodstransfer => createAction(CREATE_GT.REQUEST, { goodstransfer }),
  success: goodstransfer => createAction(CREATE_GT.SUCCESS, { goodstransfer }),
  failure: error => createAction(CREATE_GT.FAILURE, { error })
}

const editGoodsTransfer = {
  request: (id, goodstransfer) => createAction(EDIT_GT.REQUEST, { id, goodstransfer }),
  success: goodstransfer => createAction(EDIT_GT.SUCCESS, { goodstransfer }),
  failure: (error) => createAction(EDIT_GT.FAILURE, { error })
}

const deleteGoodsTransfer = {
  request: id => createAction(DELETE_GT.REQUEST, { id } ),
  success: id => createAction(DELETE_GT.SUCCESS, { id } ),
  failure: () => createAction(DELETE_GT.FAILURE)
}

const createDocItem = {
  request: detail => createAction(CREATE_GT_ITEM.REQUEST, { detail } ),
  success: detail => createAction(CREATE_GT_ITEM.SUCCESS, { detail } ),
  failure: () => createAction(CREATE_GT_ITEM.FAILURE)
}

const editDocItem = {
  request: (id, detail) => createAction(EDIT_GT_ITEM.REQUEST, { id, detail } ),
  success: detail => createAction(EDIT_GT_ITEM.SUCCESS, { detail } ),
  failure: () => createAction(EDIT_GT_ITEM.FAILURE)
}

const deleteDocItem = {
  request: id => createAction(DELETE_GT_ITEM.REQUEST, { id } ),
  success: data => createAction(DELETE_GT_ITEM.SUCCESS, { data } ),
  failure: () => createAction(DELETE_GT_ITEM.FAILURE)
}

const deleteAllDocItem = {
  request: id => createAction(DELETEALL_GT_ITEM.REQUEST, { id } ),
  success: id => createAction(DELETEALL_GT_ITEM.SUCCESS, { id } ),
  failure: () => createAction(DELETEALL_GT_ITEM.FAILURE)
}


const filterData = (goodstransfer, search) => ({
  type: FILTER_GT,
  payload: {
    goodstransfer,
    search 
  }
})

const loadGoodsTransferFromArray = id => ({
  type: GET_GT,
  payload: id
})

const fecthGoodsTransferFromArray = () => ({
  type : FETCH_GTS_FROM_GT,
  payload: {}
})


export {
  LOAD_GTS,
  LOAD_GT,
  CREATE_GT,
  EDIT_GT,
  DELETE_GT,
  CREATE_GT_ITEM,
  EDIT_GT_ITEM,
  DELETE_GT_ITEM,
  DELETEALL_GT_ITEM,
  SET_GT,
  FILTER_GT,
  GET_GT,
  FETCH_GTS_FROM_GT,
  CHANGE_STATUS_GT,
  loadGoodsTransfers,
  loadGoodsTransfer,
  loadGoodsTransferFromArray,
  createGoodsTransfer,
  editGoodsTransfer,
  deleteGoodsTransfer,
  filterData,
  fecthGoodsTransferFromArray,
  createDocItem,
  editDocItem,
  deleteDocItem,
  deleteAllDocItem,
  changeStatusGT
}
