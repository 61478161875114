import React from 'react'
import Grid from '@material-ui/core/Grid'

import TextField from '@material-ui/core/TextField'
import { SelectWidget } from 'lib/widgets'

const style = {
    marginBottom: '15px'
}
export default ({ values, handleChange, handleSelect }) => (
  <div style={{ padding: '10px', flexGrow: 1 }}>
    <Grid item xs={12}  style={style}>
    <SelectWidget
        url="api/companies"
        value={values.company_id}
        name="company_id"
        title="บริษัท"
        onChange={handleSelect('company_id')}
    />
    </Grid>
    <Grid item xs={12}  style={style}>
      <TextField
        label="ชื่อ"
        name="name"
        value={values.name}
        onChange={handleChange('name')}
        fullWidth
      />
    </Grid>
    <Grid item xs={12}  style={style}>
      <TextField
        label="ชื่อย่อ"
        name="search_key"
        value={values.search_key}
        onChange={handleChange('search_key')}
        fullWidth
      />
    </Grid>
    
  </div>
)
