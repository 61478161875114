import React from 'react';
import {Pie} from 'react-chartjs-2';

const SummaryPieChart = ({ onProcess, finishedJob }) => {
	const data = {
		labels: [
			'งานที่เสร็จ',
			'งานค้าง'
		],
		datasets: [{
			data: [parseFloat(finishedJob), parseFloat(onProcess)],
			backgroundColor: [
			'#36A2EB',	
			'#FF6384',
			],
			hoverBackgroundColor: [
			'#36A2EB',	
			'#FF6384',
			]
		}]
	};
    return <div>
        <h6>ผลสรุป</h6>
        <Pie data={data} />
      </div>
}
    
export default SummaryPieChart