import React, { useEffect, useState } from 'react'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'

import { getIdtoArray } from 'lib/helper'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

function MultipleSelect(props) {
  const { name, options, onChange, value, disabled, defaultValue } = props
  let hasDefault = typeof defaultValue !== 'undefined' ? true : false
  const [personName, setPersonName] = useState([])
  const [initalState, setInitalState] = useState(hasDefault)

  function handleChange(event) {
    let value = event.target.value

    if (value.includes('all')) {
      if (options.length !== personName.length)
        value = getIdtoArray(options, 'label')

      if (value.length > options.length) value = []
    }

    setPersonName(value)

    if (typeof onChange === 'function') {
      onChange(name, value)
    }
  }

  function setValue() {
    if(typeof options === 'undefined' ) return

    if(typeof defaultValue === 'object' && defaultValue.length > 0) {
      setPersonName(defaultValue)
      setInitalState(false)
      return
    }

    let idx = options.findIndex(data => parseInt(data.value) === parseInt(defaultValue))
    if(idx === -1) return 
    setPersonName([options[idx].label])
    setInitalState(false)
  }

  useEffect(() => {
    if (value === '' && personName.length > 0) setPersonName([])
    if(initalState) {
      setValue()
    }

  })

  return (
    <Select
      multiple
      value={personName}
      onChange={handleChange}
      input={<Input id="select-multiple-checkbox" name={name} disabled={disabled}/>}
      renderValue={selected => selected.join(', ')}
      MenuProps={MenuProps}
      fullWidth
      style={{ maxWidth: '300px', minWidth: '100%' }}
    >
      <MenuItem value="all">
        <Checkbox checked={typeof personName !== 'undefined' && typeof options !== 'undefined' 
          ? (personName.length === options.length) : false }/>
        <ListItemText primary="เลือกทั้งหมด" />
      </MenuItem>
      {typeof options === 'object' && options.map(name => (
        <MenuItem key={name.label} value={name.label}>
          <Checkbox checked={personName.indexOf(name.label) > -1} />
          <ListItemText primary={name.label} />
        </MenuItem>
      ))}
    </Select>
  )
}

export default MultipleSelect
