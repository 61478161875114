export const defaultState = {
    id: '',
    active: '1',
    disableForm : false,
    isOpenWeb : true,
    isOpenUser : true,
    webGroup : [],
    users : [],
    checkWebList : [],
    checkUserList : [],
    name: '',
    formLoading : true,
    relations: {
        detail: []
    },
    advSearch : {
        name : ''
    }
}