export const defaultState = {
    active: '1',
    disableForm : false,
    isOpenWeb : true,
    isOpenUser : true,
    formLoading : true,
    id: '',
    warehouse_id: '',
    bin_id: '',
    uom_id: '',
    relative_priority: '',
    max_weigh: '',
    max_qty: '',
    usage: '',
    available: '',
    storageUrl : 'api/bins',
    prevWarehouse: '',
    warehose:[],
    bkwarehose:[],
    bin:[],
    advSearch : {
        bin_id : '',
        warehouse_id: ''
    }
}