import React from 'react';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import { usePromiseTracker } from "react-promise-tracker";

import ComponentList from './ComponentList'


const useStyles = theme => ({
    root: {
        width: '100%',
        minWidth: 360,
        backgroundColor: theme.palette.background.paper,
        paddingTop: '0px',
        paddingBottom: '0px'
    },
    inline: {
        display: 'inline',
    },
    avatar: {
        width: '30px',
        height: '30px'
    },
    date: {
        textAlign: 'end',
        fontSize: '9px'
    },
    boldText: {
        fontWeight: 'bold'
    },
    girdPrimary: {
        marginBottom: '5px'
    },
    noti: {
        paddingTop: '0px',
        paddingBottom: '0px'
    }
});

function NotiList(props) {
    const { classes, data, onUpdate, onDelete, loadingProgress } = props
    const { promiseInProgress } = usePromiseTracker();
    return (
        <List className={classes.root} >
            <ListItem alignItems="flex-start">
                <ListItemText primary={
                    <Grid container justify="space-between">
                        <Grid item xs={4}>
                            แจ้งเตือน
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: 'right' }}>
                        { !promiseInProgress &&
                            <Link  component="button" onClick={() => onUpdate('all', 1)}>
                                อ่านทั้งหมด
                            </Link>
                        }
                        </Grid>
                    </Grid>
                } />
            </ListItem>

            <ComponentList
                items={data}
                loadingProgress={loadingProgress}
                classes={classes}
                onUpdate={onUpdate}
                onDelete={onDelete} />

            <Divider component="li" />
            <ListItem button component="a" href="/notification">
                <ListItemText  primary="ดูทั้งหมด" style={{ textAlign: 'center' }} />
            </ListItem>
        </List>
    );
}

export default withStyles(useStyles)(NotiList)