import { all, put, call, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import axios from 'axios'
import { headerWithAuth, handleUnAutherize } from 'lib/authFunc'
import * as actions from './actions'

const header = headerWithAuth()

function* loadUsers(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.get,
      process.env.REACT_APP_API_URL + `/api/users${term}`,
      header
    )

    yield put(actions.loadUsers.success(res.data))

  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadUsers.failure(error.message))
  }
}

function* loadUser(action) {
  try {
    const { id } = action.payload
    const res = yield call(
      axios.get,
      process.env.REACT_APP_API_URL + `/api/users/${id}`,
      header
    )
    yield put(actions.loadUser.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadUser.failure(error.response))
  }
}

function* createUser(action) {
  try {
    const { user } = action.payload
    const res = yield call(
      axios.post, process.env.REACT_APP_API_URL + '/api/users', { user }, header)
    if (typeof res.data.error !== 'undefined') {
      yield put(actions.createUser.failure(res.data.error))
    } else {
      yield put(push(`/master-data/users/${res.data.id}`))
    }
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.createUser.failure(error))
  }
}

function* editUser(action) {
  try {
    const { user, id } = action.payload
    const res = yield call(axios.patch, process.env.REACT_APP_API_URL + `/api/users/${id}`, user, header)
    if (typeof res.data.error !== 'undefined') {
      yield put(actions.editUser.failure(res.data.error))
    } else {
      yield put(actions.editUser.success(res.data))
    }
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.editUser.failure(error))
  }
}

function* deleteUser(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.delete, process.env.REACT_APP_API_URL + `/api/users/${id}`, header)
    yield put(actions.deleteUser.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.deleteUser.failure(error.response))
  }
}


export default function* watchComments() {
  yield all([
    takeEvery(actions.LOAD_USERS.REQUEST, loadUsers),
    takeEvery(actions.LOAD_USER.REQUEST, loadUser),
    takeEvery(actions.CREATE_USER.REQUEST, createUser),
    takeEvery(actions.EDIT_USER.REQUEST, editUser),
    takeEvery(actions.DELETE_USER.REQUEST, deleteUser)
  ])
}
