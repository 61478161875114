import React from 'react'
import { Switch, Route } from 'react-router-dom'
import UserLayout from './user/components/UserLayout'
import WebGroupLayout from './web/components/WebGroupLayout'
import MobileGroupLayout from './mobile/components/MobileGroupLayout'
import CompanyLayout from './company/components/CompanyLayout'
import BranchLayout from './branch/components/BranchLayout'
import BrandLayout from './brand/components/BrandLayout'
import CategoryLayout from './category/components/CategoryLayout'
import UnitLayout from './unit/components/UnitLayout'
import StoreLayout from './store/components/StoreLayout'
import BinDetailLayout from './bindetail/components/BinDetailLayout'
import ProductLayout from './product/components/ProductLayout'
import BatchLayout from './batch/components/BatchLayout'

import NoRights from '../ui/components/NoRights'

import { checkPermission } from 'lib/authFunc'

const getCurrentPath = () => {
  const url = window.location.href
  let rights = ""

  if(url.match(/users/g))  rights = 'user'
  if(url.match(/web-groups/g))  rights = 'group'
  if(url.match(/mobile-groups/g))  rights = 'groupmobile'
  if(url.match(/companies/g))  rights = 'company'
  if(url.match(/branches/g))  rights = 'branch'
  if(url.match(/brands/g))  rights = 'brand'
  if(url.match(/categories/g))  rights = 'productcat'
  if(url.match(/units/g))  rights = 'uom'
  if(url.match(/stores/g))  rights = 'whbin'
  if(url.match(/storagebin-details/g))  rights = 'storagebindetail'
  if(url.match(/products/g))  rights = 'product'
  if(url.match(/batches/g))  rights = 'batch'

  return rights
}


export default () => {

  let rights = getCurrentPath()

  if (!checkPermission(rights) && rights !== '') {
    return <NoRights />
  }

  return <Switch>
    <Route path="/master-data/users" component={UserLayout} />
    <Route path="/master-data/web-groups" component={WebGroupLayout} />
    <Route path="/master-data/mobile-groups" component={MobileGroupLayout} />
    <Route path="/master-data/companies" component={CompanyLayout} />
    <Route path="/master-data/branches" component={BranchLayout} />
    <Route path="/master-data/brands" component={BrandLayout} />
    <Route path="/master-data/categories" component={CategoryLayout} />
    <Route path="/master-data/units" component={UnitLayout} />
    <Route path="/master-data/stores" component={StoreLayout} />
    <Route path="/master-data/storagebin-details" component={BinDetailLayout} />
    <Route path="/master-data/products" component={ProductLayout} />
    <Route path="/master-data/batches" component={BatchLayout} />
  </Switch>
}
