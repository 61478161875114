import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as actions from '../actions'
import StoreForm from './StoreForm'

import { hasMessage } from 'lib/fxUI'
import { defaultState } from '../defaultState'


class EditStore extends Component {

  componentDidMount() {
    const id = this.getStoreId()
    this.props.loadStore(id)
  }

  componentDidUpdate(prevProps) {
    const search = this.props.location.pathname
    if (search === prevProps.location.pathname) return

    const id = this.getStoreId()
    this.props.loadStoreFromArray(id)
  }

  getStoreId = () => this.props.match.params.id

  editStore = async user => {
    this.props.editStore(this.getStoreId(), user)
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue })
  }

  render() {
    const { customer, successSave, isLoading } = this.props
    let data = []

    if (typeof customer !== 'undefined') {
      data = typeof customer[0] !== 'undefined' ? customer[0] : defaultState
    }

    if (data.length === 0) {
      return <div className="lmask" id="lmask" />
    }

    return (
      <Fragment>
      {successSave !== null && hasMessage(successSave, '')}
        <StoreForm
          onSubmit={this.editStore}
          formAction="แก้ไข"
          data={data}
          successSave={successSave}
          isLoading={isLoading}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ customers }) => ({
  customer: customers.customer,
  isLoading: customers.isLoading,
  successSave: customers.successSave
})

const mapDispatchToProps = {
  loadStore: actions.loadStore.request,
  editStore: actions.editStore.request,
  loadStoreFromArray: actions.loadStoreFromArray
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
)(EditStore)
