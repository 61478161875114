import React from "react";
import MUIDataTable from "mui-datatables";
import WorkMobile from './WorkMobile'

class WorkTable extends React.Component {
  state = {
    data : this.props.data
  }
  getColor = (tableMeta) => {
    let minute = parseFloat(tableMeta), color = '#e6e6e6'

    if(minute > 9 && minute <= 30) color = "#ffce56"
    if(minute > 30 && minute <= 60) color = "#ffa556"
    if(minute > 60) color = "#FF6384"
    return color
  }

  advSearch = () => {

  }

  componentDidMount() {
    this.interval = setInterval(() => this.setState({ data: this.props.data }), 5000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const columns = [
      { label: 'ประเภท', name: 'doc_type', options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span style={{ background: this.getColor(tableMeta.rowData[6]), padding: '5px 15px 5px 15px' }}>{ value }</span>
        }
      } },
      { label: 'เลขที่เอกสาร', name: 'order_no' },
      { label: 'เลขที่อ้างอิง', name: 'ref_no' },
      { label: 'สถานะ', name: 'status_name' },
      { label: 'เวลาที่สร้าง', name: 'created_at' },
      { label: 'ชื่อลูกค้า', name: 'customer_name' },
      { label: '', name: 'minute', options: { display: false } },
    ]

    const options = {
      filterType: "dropdown",
      responsive: "stacked",
      sort: false,
      filter: false,
      print: false,
      download: false,
      viewColumns: false,
      selectableRows: false
    }

    return (
      window.innerWidth > 500 ? 
      <MUIDataTable
        title={"ตารางานค้างทำ"}
        data={this.state.data}
        columns={columns}
        options={options}
      /> :
      <WorkMobile 
        data={this.state.data}
        advSearch={this.advSearch} 
        api={`api/overview-warehouse/work?date_range=24hr&branch_id=1&per_page=10`}
        getColor={this.getColor}
      />
    );
  }
}

export default WorkTable

WorkTable.defaultProps = {
  data : []
}