import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import debounce from 'lodash/debounce'
import * as actions from '../actions'
import {
  ActionBtn,
  ButtonTable,
  ConfirmDlg,
  SearchPanel,
  MenuMobile
} from 'lib/widgets'

import MUIDataTable from 'mui-datatables'
import { MuiThemeProvider } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Tablestyle } from '../../AddonStyles'

import AdvanceSearch from './AdvanceSearch'
import { defaultState } from '../defaultState'
import { hasMessage } from 'lib/fxUI'

import { createCondition } from 'lib/helper'

class UserTable extends Component {
  state = {
    term: '',
    search: '',
    term_from_advsearch: "",
    delId: '',
    options: {},
    page: 0,
    sortName: '',
    rowsPerPage: 10,
    count: 100,
    isModalOpen: false,
    showAdvSearch: false,
    deleteSuccess: null,
    width: window.innerWidth
  }

  componentDidMount() {
    this.fetchUsers()
  }

  componentWillReceiveProps(nextProps) {
    const { deleteSuccess } = nextProps

    this.setState({ deleteSuccess })
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  }

  fetchUsers = async () => {
    const { term } = this.state
    this.props.loadUsers(`?exclude=relation&`+term)
  }

  delaySearchUsers = debounce(async value => {
    this.setState({ search: value })
    const cond = '?exclude=relation&strict=false&'+createCondition({ name: '', surname: '', username: '' }, value)
    this.props.loadUsers(cond)
  }, 750)

  searchUsers = event => {
    return this.delaySearchUsers(event)
  }

  advSearch = data => {
    let cond = data.replace(/\?/g, '')
    this.props.loadUsers(`?exclude=true&${cond}`)
  }

  setAdvSearch = data => {
    this.setState({ term: data.replace(/\?/g, ''), term_from_advsearch: data.replace(/\?/g, '') })
    this.advSearch(data)
  }

  showAdvSearch = state => {
    this.setState({ showAdvSearch: state })
  }

  navigateToCreateForm = () => {
    this.props.history.push('/master-data/users/new')
  }

  editUserForm = id => {
    this.props.history.push(`/master-data/users/${id}`)
  }

  deleteUser = id => {
    const { delId } = this.state
    this.props.deleteUser(delId)
    this.setState({ isModalOpen: false })
  }

  toggleConfirmDlg = (id = '') => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      delId: id,
      deleteSuccess: null
    })
  }

  sortingData = (changedColumn, direction) => {
    let sortby = (changedColumn === 'fullname') ? 'name' : changedColumn
    const { page, rowsPerPage, search } = this.state
    const sort = direction === 'ascending' ? 'asc' : 'desc'
    let condStr = 'strict=false&'+createCondition({ name: '', surname: '', username: '' }, search)
    if(this.state.term_from_advsearch !== "") condStr += "&"+this.state.term_from_advsearch
    let term = `?page=${page}&per_page=${rowsPerPage}&sort_by=${sortby}&sort=${sort}&${condStr}`
    this.setState(
      {
        term: term,
        sortName: changedColumn,
        options: { sort: true, sortDirection: sort }
      },
      this.advSearch(term)
    )
  }

  handleTableChange = (action, tableState) => {
    let term = ''
    let condStr = 'strict=false&'+createCondition({ name: '', surname: '', username: '' }, this.state.search)
    if(this.state.term_from_advsearch !== "") condStr += "&"+this.state.term_from_advsearch
    switch (action) {
      case 'changeRowsPerPage':
        term = `per_page=${tableState.rowsPerPage}&${condStr}`
        this.setState(
          {
            term: term,
            rowsPerPage: tableState.rowsPerPage,
            page: 0
          },
          this.advSearch(term)
        )
        break
      case 'changePage':
        term = `page=${tableState.page + 1}&per_page=${tableState.rowsPerPage}&${condStr}`
        this.setState(
          {
            term: term,
            page: tableState.page
          },
          this.advSearch(term)
        )
        break
      default:
        break
    }
  }

  resetAlertState = () => {
    this.setState({ deleteSuccess: null }, this.fetchUsers)
  }

  renderTable = (isLoading, columns, users, optionsTable) => {

    const isMobile = this.state.width <= 500
    if(isMobile) {
      return <MenuMobile 
      data={users} 
      columns={{
        col1: 'fullname',
        col2: 'employee_code',
        col3: 'username',
        col4: 'user_rights',
      }}
      title={{
        title1: () => 'ชื่อ-นามสกุล',
        title2: () => 'รหัส',
        title3: () =>  'Username',
        title4: () =>  'สิทธิ์'
      }}
      url={`api/users`}
      onLoad={this.advSearch}
      onDelete={this.toggleConfirmDlg}
      deleteSuccess={this.props.deleteSuccess}
      linkUrl={`/master-data/users`}
      />
    } 

      if(typeof users === 'object')
        return <Fragment>
        { isLoading && <div className="lmask" id="lmask" /> }
        <MuiThemeProvider theme={Tablestyle()}>
          <MUIDataTable
            title={'ผู้ใช้งาน'}
            data={users}
            columns={columns}
            options={optionsTable}
          />
        </MuiThemeProvider>
      </Fragment>
  }

  render() {
    const { isLoading, users, meta } = this.props
    const count = typeof meta === 'undefined' ? 100 : meta.total

    const columns = [
      {
        name: '',
        label: '#',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const { page, rowsPerPage } = tableMeta.tableState
            return page * rowsPerPage + tableMeta.rowIndex + 1
          }
        }
      },
      { name: 'employee_code', label: 'รหัส' },
      { name: 'fullname', label: 'ชื่อ-นามสกุล' },
      { name: 'username', label: 'ผู้ใช้งาน' },
      { name: 'user_rights', label: 'สิทธิ์', options: { sort: false } },
      {
        name: 'id',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <ButtonTable
                id={value}
                view="user delete"
                onOpen={this.editUserForm}
                onDelete={() => this.toggleConfirmDlg(value)}
              />
            )
          }
        }
      }
    ]

    const { page, rowsPerPage, sortName, options, deleteSuccess } = this.state

    const optionsTable = {
      filter: false,
      print: false,
      download: false,
      viewColumns: false,
      filterType: 'dropdown',
      serverSide: true,
      count: count,
      page: page,
      rowsPerPage: rowsPerPage,
      selectableRows: false,
      onTableChange: this.handleTableChange,
      onSearchChange: this.searchUsers,
      onColumnSortChange: this.sortingData,
      onSearchClose: () => {
        this.setState({ search : '' })
        this.advSearch(this.state.term)
      },
      textLabels: {
        body: {
          noMatch: "Sorry, no matching records found",
          toolTip: "Sort",
        }
      },
      customToolbar: () => {
        return (
          <ActionBtn
            onCreate={this.navigateToCreateForm}
            onSearch={this.showAdvSearch}
            view="user edit"
          />
        );
      }
    }

    if (sortName !== '') {
      let idx = columns.findIndex(data => data.name === sortName)
      if (idx === -1) return
      columns[idx].options = options
    }

    return (
      <Fragment>
        {deleteSuccess !== null && hasMessage(deleteSuccess, 'ลบข้อมูลสำเร็จ', this.resetAlertState)}
        <Fragment>
          <Grid item xs={12}>
            { this.renderTable(isLoading, columns, users, optionsTable) }
          </Grid>
        </Fragment>

        <ConfirmDlg
          title="ยืนยันการลบข้อมูล"
          content="คุณต้องการลบข้อมูลผู้ใช้งานรายนี้ ?"
          onToggle={this.toggleConfirmDlg}
          onConfirm={this.deleteUser}
          isOpen={this.state.isModalOpen}
        />
        <SearchPanel
          toggleDrawer={this.showAdvSearch}
          isOpen={this.state.showAdvSearch}
          onSubmit={this.setAdvSearch}
          Component={AdvanceSearch}
          formValue={defaultState.advSearch}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ users }) => ({
  isLoading: users.isLoading,
  users: users.items,
  meta: users.meta,
  backupItems: users.backupItems,
  deleteSuccess: users.deleteSuccess
})

const mapDispatchToProps = {
  loadUsers: actions.loadUsers.request,
  deleteUser: actions.deleteUser.request,
  filterData: actions.filterData
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserTable)
