import { createTypes, createAction } from 'lib/action'
 
const LOAD_STORES = createTypes('stores', 'list')
const LOAD_STORE = createTypes('stores', 'show')

const CREATE_STORE = createTypes('stores', 'create')
const EDIT_STORE = createTypes('stores', 'edit')
const DELETE_STORE = createTypes('stores', 'delete')

const CREATE_STORE_BIN = createTypes('storagebin', 'create')
const EDIT_STORE_BIN = createTypes('storagebin', 'edit')
const DELETE_STORE_BIN = createTypes('storagebin', 'delete')

const FILTER_STORE = 'backup'
const SET_STORE = 'api/stores'
const GET_STORE = 'GET_STORE'
const FETCH_STORES_FROM_STORE = "FETCH_STORES_FROM_STORE"

const loadStores = {
  request: term => createAction(LOAD_STORES.REQUEST, { term }),
  success: stores => createAction(LOAD_STORES.SUCCESS, { stores }),
  failure: () => createAction(LOAD_STORES.FAILURE)
}

const loadStore = {
  request: id => createAction(LOAD_STORE.REQUEST, { id }),
  success: store => createAction(LOAD_STORE.SUCCESS, { store }),
  failure: () => createAction(LOAD_STORE.FAILURE)
}

const createStore = {
  request: store => createAction(CREATE_STORE.REQUEST, { store }),
  success: store => createAction(CREATE_STORE.SUCCESS, { store }),
  failure: error => createAction(CREATE_STORE.FAILURE, { error })
}

const editStore = {
  request: (id, store) => createAction(EDIT_STORE.REQUEST, { id, store }),
  success: store => createAction(EDIT_STORE.SUCCESS, { store }),
  failure: () => createAction(EDIT_STORE.FAILURE)
}

const deleteStore = {
  request: (id) => createAction(DELETE_STORE.REQUEST, { id } ),
  success: id => createAction(DELETE_STORE.SUCCESS, { id } ),
  failure: () => createAction(DELETE_STORE.FAILURE)
}

const createStorageBin = {
  request: bin => createAction(CREATE_STORE_BIN.REQUEST, { bin } ),
  success: bin => createAction(CREATE_STORE_BIN.SUCCESS, { bin } ),
  failure: () => createAction(CREATE_STORE_BIN.FAILURE)
}

const editStorageBin = {
  request: (id, bin) => createAction(EDIT_STORE_BIN.REQUEST, { id, bin } ),
  success: bin => createAction(EDIT_STORE_BIN.SUCCESS, { bin } ),
  failure: () => createAction(EDIT_STORE_BIN.FAILURE)
}

const deleteStorageBin = {
  request: (id, bin) => createAction(DELETE_STORE_BIN.REQUEST, { id, bin } ),
  success: data => createAction(DELETE_STORE_BIN.SUCCESS, { data } ),
  failure: () => createAction(DELETE_STORE_BIN.FAILURE)
}


const filterData = (stores, search) => ({
  type: FILTER_STORE,
  payload: {
    stores,
    search 
  }
})

const loadStoreFromArray = id => ({
  type: GET_STORE,
  payload: id
})

const fecthStoreFromArray = () => ({
  type : FETCH_STORES_FROM_STORE,
  payload: {}
})


export {
  LOAD_STORES,
  LOAD_STORE,
  CREATE_STORE,
  EDIT_STORE,
  DELETE_STORE,
  CREATE_STORE_BIN,
  EDIT_STORE_BIN,
  DELETE_STORE_BIN,
  SET_STORE,
  FILTER_STORE,
  GET_STORE,
  FETCH_STORES_FROM_STORE,
  loadStores,
  loadStore,
  loadStoreFromArray,
  createStore,
  editStore,
  deleteStore,
  filterData,
  fecthStoreFromArray,
  createStorageBin,
  editStorageBin,
  deleteStorageBin
}
