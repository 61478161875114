import React from 'react'
import { Switch, Route } from 'react-router-dom'

import ProductTable from './ProductTable'
import ProductList from './ProductList'
import EditProduct from './EditProduct'
import CreateProduct from './CreateProduct'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Breadcrumb } from 'lib/widgets'
import { Layoutstyles } from './AddonStyles'

const ProductLayout = ({ classes }) => (
  <div className={classes.root}>
    <Grid container spacing={24}>
      <Grid item xs>
        <Breadcrumb
          menu="ข้อมูลหลัก"
          submenu="สินค้า"
          menuLink="/master-data/products"
        />
      </Grid>
    </Grid>
    <Grid container spacing={24}>
      <Switch>
        <Route exact path="/master-data/products" component={ProductTable} />
        <Route path="/master-data/products" component={ProductList} />
      </Switch>
      <Switch>
        <Route path="/master-data/products/new" component={CreateProduct} />
        <Route path="/master-data/products/:id" component={EditProduct} />
      </Switch>
    </Grid>
  </div>
)
export default withStyles(Layoutstyles)(ProductLayout)
