import {
  LOAD_PRODUCTS,
  LOAD_PRODUCT,
  CREATE_PRODUCT,
  EDIT_PRODUCT,
  DELETE_PRODUCT,
  FILTER_PRODUCT,
  GET_PRODUCT,
  FETCH_PRODUCTS_FROM_STORE,
  CREATE_PRODUCT_UNIT,
  EDIT_PRODUCT_UNIT,
  DELETE_PRODUCT_UNIT,
  EDIT_PRODUCT_PRICE
} from './actions'

import { dataFilter, replaceIdxValue } from 'lib/helper'

const initialState = {
  isLoading: false,
  successSave: null,
  items: [],
  backupItems: [],
  product: [],
  deleteSuccess: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_PRODUCT:
      return {
        ...state,
        items: dataFilter(action.payload.products, action.payload.search),
        backupItems: state.backupItems
      }
    case GET_PRODUCT:
      const id = state.backupItems.findIndex(
        product => parseInt(product.id) === parseInt(action.payload)
      )
      return {
        ...state,
        successSave: null,
        product: [state.backupItems[id]]
      }
    case FETCH_PRODUCTS_FROM_STORE:
      return {
        ...state,
        successSave: null,
        items: state.backupItems,
        isLoading: false
      }
    case LOAD_PRODUCTS.REQUEST:
    case LOAD_PRODUCT.REQUEST:
    case DELETE_PRODUCT.REQUEST:
    return { ...state, 
      items:state.backupItems,
      backupItems: state.backupItems,
      isLoading: true,
      successSave: null,
      deleteSuccess: null
    }
    case CREATE_PRODUCT.REQUEST:
    case EDIT_PRODUCT.REQUEST:
      return { ...state, items: [], isLoading: true }
    case LOAD_PRODUCTS.SUCCESS:
      return {
        ...state,
        items: action.payload.products.data,
        backupItems: action.payload.products.data,
        isLoading: false,
        meta: action.payload.products.meta
      }
    case LOAD_PRODUCT.SUCCESS:
      return {
        ...state,
        product: [action.payload.product],
        isLoading: false,
        successSave: null
      }
    case CREATE_PRODUCT.SUCCESS:
      return {
        ...state,
        items: [action.payload.product, ...state.backupItems],
        backupItems: [action.payload.product, ...state.backupItems],
        isLoading: false,
        successSave: true
      }
    case EDIT_PRODUCT.SUCCESS:
      const idx = state.backupItems.findIndex(
        product => parseInt(product.id) === parseInt(action.payload.product.id)
      )
      return {
        ...state,
        items: replaceIdxValue(state.backupItems, idx, action.payload.product),
        backupItems: replaceIdxValue(
          state.backupItems,
          idx,
          action.payload.product
        ),
        product: [action.payload.product],
        isLoading: false,
        successSave: true
      }
    case DELETE_PRODUCT.SUCCESS:
      const index = state.backupItems.findIndex(
        product => parseInt(product.id) === parseInt(action.payload.id)
      )
      return {
        ...state.backupItems.splice(index, 1),
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: null,
        deleteSuccess: true
      }

    case CREATE_PRODUCT_UNIT.SUCCESS:
      const createidx = state.backupItems.findIndex(
        product =>
          parseInt(product.id) === parseInt(action.payload.uom.id)
      )
      state.backupItems[createidx] = action.payload.uom
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        product: [action.payload.uom],
        isLoading: false,
        successSave: null
      }
    case EDIT_PRODUCT_UNIT.SUCCESS:
      const editidx = state.backupItems.findIndex(
        product =>
          parseInt(product.id) === parseInt(action.payload.uom.id)
      )

      state.backupItems[editidx] = action.payload.uom

      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        product: [action.payload.uom],
        isLoading: false,
        successSave: null
      }
    case DELETE_PRODUCT_UNIT.SUCCESS:
      const delidx = state.backupItems.findIndex(
        product =>
          parseInt(product.id) === parseInt(action.payload.data.id)
      )

      state.backupItems[delidx] = action.payload.data

      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        product: [action.payload.data],
        isLoading: false,
        successSave: null
      }
    case EDIT_PRODUCT_PRICE.SUCCESS:
      const editPriceidx = state.backupItems.findIndex(
        product =>
          parseInt(product.id) === parseInt(action.payload.pricebook.id)
      )

      state.backupItems[editPriceidx] = action.payload.pricebook

      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        product: [action.payload.pricebook],
        isLoading: false,
        successSave: null
      }

    case LOAD_PRODUCTS.FAILURE:
    case LOAD_PRODUCT.FAILURE:
    case CREATE_PRODUCT.FAILURE:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: false
      }
    case EDIT_PRODUCT.FAILURE:
    case DELETE_PRODUCT.FAILURE:
    case CREATE_PRODUCT_UNIT.FAILURE:
    case EDIT_PRODUCT_UNIT.FAILURE:
    case DELETE_PRODUCT_UNIT.FAILURE:
    case EDIT_PRODUCT_PRICE.FAILURE:
      return { ...state, items: [], isLoading: false, successSave: false }
    default:
      return state
  }
}
