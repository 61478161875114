import {
  LOAD_STORES,
  LOAD_STORE,
  CREATE_STORE,
  EDIT_STORE,
  DELETE_STORE,
  CREATE_STORE_BIN,
  EDIT_STORE_BIN,
  DELETE_STORE_BIN,
  FILTER_STORE,
  GET_STORE,
  FETCH_STORES_FROM_STORE
} from './actions'

import { dataFilter, replaceIdxValue } from 'lib/helper'

const initialState = {
  isLoading: false,
  successSave: null,
  items: [],
  backupItems: [],
  store: [],
  deleteSuccess: null,
  search: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_STORE:
      return {
        ...state,
        items: dataFilter(action.payload.stores, action.payload.search),
        backupItems: state.backupItems
      }
    case GET_STORE:
      const id = state.backupItems.findIndex(store => parseInt(store.id) === parseInt(action.payload))
      return {
        ...state,
        successSave: null,
        store: [state.backupItems[id]]
      }
    case FETCH_STORES_FROM_STORE:
      return {
        ...state,
        successSave: null,
        items: state.backupItems,
        isLoading: false
      }
    case LOAD_STORES.REQUEST:
    case LOAD_STORE.REQUEST:
    case DELETE_STORE.REQUEST:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: true,
        successSave: null,
        deleteSuccess: null
      }
    case CREATE_STORE.REQUEST:
    case EDIT_STORE.REQUEST:
    case CREATE_STORE_BIN.REQUEST:
    case EDIT_STORE_BIN.REQUEST:
    case DELETE_STORE_BIN.REQUEST:
      return { ...state, items: [], isLoading: true }
    case LOAD_STORES.SUCCESS:
      return {
        ...state,
        items: action.payload.stores.data,
        backupItems: action.payload.stores.data,
        isLoading: false,
        meta: action.payload.stores.meta
      }
    case LOAD_STORE.SUCCESS:
      return { ...state, store: [action.payload.store], isLoading: false, successSave: null }
    case CREATE_STORE.SUCCESS:
      return {
        ...state,
        items: [action.payload.store, ...state.backupItems],
        backupItems: [action.payload.store, ...state.backupItems],
        isLoading: false,
        successSave: true
      }
    case EDIT_STORE.SUCCESS:
      const idx = state.backupItems.findIndex(store => parseInt(store.id) === parseInt(action.payload.store.id))
      return {
        ...state,
        items: replaceIdxValue(state.backupItems, idx, action.payload.store),
        backupItems: replaceIdxValue(state.backupItems, idx, action.payload.store),
        store: [action.payload.store],
        isLoading: false,
        successSave: true
      }
    case DELETE_STORE.SUCCESS:
      const index = state.backupItems.findIndex(store => parseInt(store.id) === parseInt(action.payload.id))
      return {
        ...state.backupItems.splice(index, 1),
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: null,
        deleteSuccess: true
      }
    case CREATE_STORE_BIN.SUCCESS:
      const createidx = state.backupItems.findIndex(store => parseInt(store.id) === parseInt(action.payload.bin.id))
      state.backupItems[createidx] = action.payload.bin
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        store: [action.payload.bin],
        isLoading: false,
        successSave: null
      }
    case EDIT_STORE_BIN.SUCCESS:
      const editidx = state.backupItems.findIndex(store => parseInt(store.id) === parseInt(action.payload.bin.warehouse_id))
      state.backupItems[editidx] = action.payload.bin
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        store: [action.payload.bin],
        isLoading: false,
        successSave: null
      }
    case DELETE_STORE_BIN.SUCCESS:
      const delidx = state.backupItems.findIndex(store => parseInt(store.id) === parseInt(action.payload.data.id))
      state.backupItems[delidx] = action.payload.data

      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        store: [action.payload.data],
        isLoading: false,
        successSave: null
      }
    case LOAD_STORES.FAILURE:
    case LOAD_STORE.FAILURE:
    case CREATE_STORE.FAILURE:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: false
      }
    case CREATE_STORE_BIN.FAILURE:
    case EDIT_STORE_BIN.FAILURE:
    case EDIT_STORE.FAILURE:
    case DELETE_STORE.FAILURE:
    case DELETE_STORE_BIN.FAILURE:
      return { ...state, items: [], isLoading: false, successSave: false }
    default:
      return state
  }
}

