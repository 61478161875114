import {
  LOAD_BRANDS,
  LOAD_BRAND,
  CREATE_BRAND,
  EDIT_BRAND,
  DELETE_BRAND,
  FILTER_BRAND,
  GET_BRAND,
  FETCH_BRANDS_FROM_STORE
} from './actions'

import { dataFilter, replaceIdxValue } from 'lib/helper'

const initialState = {
  isLoading: false,
  successSave: null,
  items: [],
  backupItems: [],
  brand: [],
  deleteSuccess: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_BRAND:
      return {
        ...state,
        items: dataFilter(action.payload.brands, action.payload.search),
        backupItems: state.backupItems
      }
    case GET_BRAND:
      const id = state.backupItems.findIndex(brand => parseInt(brand.id) === parseInt(action.payload))
      return {
        ...state,
        successSave: null,
        brand: [state.backupItems[id]]
      }
    case FETCH_BRANDS_FROM_STORE:
      return {
        ...state,
        successSave: null,
        items: state.backupItems,
        isLoading: false
      }
    case LOAD_BRANDS.REQUEST:
    case LOAD_BRAND.REQUEST:
    case DELETE_BRAND.REQUEST:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: true,
        successSave: null,
        deleteSuccess: null
      }
    case CREATE_BRAND.REQUEST:
    case EDIT_BRAND.REQUEST:
      return { ...state, items: [], isLoading: true }
    case LOAD_BRANDS.SUCCESS:
      return {
        ...state,
        items: action.payload.brands.data,
        backupItems: action.payload.brands.data,
        isLoading: false,
        meta: action.payload.brands.meta,
        successSave: null,
        deleteSuccess: null
      }
    case LOAD_BRAND.SUCCESS:
      return { ...state, brand: [action.payload.brand], isLoading: false, successSave: null }
    case CREATE_BRAND.SUCCESS:
      return {
        ...state,
        items: [action.payload.brand, ...state.backupItems],
        backupItems: [action.payload.brand, ...state.backupItems],
        isLoading: false,
        successSave: true
      }
    case EDIT_BRAND.SUCCESS:
      const idx = state.backupItems.findIndex(brand => parseInt(brand.id) === parseInt(action.payload.brand.id))
      return {
        ...state,
        items: replaceIdxValue(state.backupItems, idx, action.payload.brand),
        backupItems: replaceIdxValue(state.backupItems, idx, action.payload.brand),
        brand: [action.payload.brand],
        isLoading: false,
        successSave: true
      }
    case DELETE_BRAND.SUCCESS:
      const index = state.backupItems.findIndex(brand => parseInt(brand.id) === parseInt(action.payload.id))
      return {
        ...state.backupItems.splice(index, 1),
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: null,
        deleteSuccess: true
      }
    case LOAD_BRANDS.FAILURE:
    case LOAD_BRAND.FAILURE:
    case CREATE_BRAND.FAILURE:
      return {
        ...state,
        items: state.backupItems,
        backupItems: state.backupItems,
        isLoading: false,
        successSave: false
      }
    case EDIT_BRAND.FAILURE:
    case DELETE_BRAND.FAILURE:
      return { ...state, items: [], isLoading: false, successSave: false }
    default:
      return state
  }
}

