import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import * as actions from '../actions'
import MobileGroupForm from './MobileGroupForm'

import { hasMessage } from 'lib/fxUI'
import { defaultState } from '../defaultState'

class CreateMobileGroup extends Component {
  createMobileGroup = async data => {
    this.props.createMobileGroup(data)
  }

  render() {
    const { successSave, isLoading } = this.props
    return (
      <Fragment>
      { successSave !== null && hasMessage(successSave, '') }
      <MobileGroupForm
        onSubmit={this.createMobileGroup}
        formAction="สร้างใหม่"
        mobilegroup={defaultState}
        successSave={successSave}
        isLoading={isLoading}
      />
     </Fragment>
    )
  }
}

const mapStateToProps = ({ mobilegroups }) => ({
  successSave: mobilegroups.successSave,
  isLoading: mobilegroups.isLoading
})
const mapDispatchToProps = {
  createMobileGroup: actions.createMobileGroup.request
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateMobileGroup)
