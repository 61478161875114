import { createTypes, createAction } from 'lib/action'
 
const LOAD_MOBILEGROUPS = createTypes('mobilegroups', 'list')
const LOAD_MOBILEGROUP = createTypes('mobilegroups', 'show')
const CREATE_MOBILEGROUP = createTypes('mobilegroups', 'create')
const EDIT_MOBILEGROUP = createTypes('mobilegroups', 'edit')
const DELETE_MOBILEGROUP = createTypes('mobilegroups', 'delete')
const FILTER_MOBILEGROUP = 'backup'
const SET_MOBILEGROUP = 'api/mobilegroups'
const GET_MOBILEGROUP = 'GET_MOBILEGROUP'
const FETCH_MOBILEGROUPS_FROM_STORE = "FETCH_MOBILEGROUPS_FROM_STORE"

const loadMobileGroups = {
  request: term => createAction(LOAD_MOBILEGROUPS.REQUEST, { term }),
  success: mobilegroups => createAction(LOAD_MOBILEGROUPS.SUCCESS, { mobilegroups }),
  failure: () => createAction(LOAD_MOBILEGROUPS.FAILURE)
}

const loadMobileGroup = {
  request: id => createAction(LOAD_MOBILEGROUP.REQUEST, { id }),
  success: mobilegroup => createAction(LOAD_MOBILEGROUP.SUCCESS, { mobilegroup }),
  failure: () => createAction(LOAD_MOBILEGROUP.FAILURE)
}

const createMobileGroup = {
  request: mobilegroup => createAction(CREATE_MOBILEGROUP.REQUEST, { mobilegroup }),
  success: mobilegroup => createAction(CREATE_MOBILEGROUP.SUCCESS, { mobilegroup }),
  failure: error => createAction(CREATE_MOBILEGROUP.FAILURE, { error })
}

const editMobileGroup = {
  request: (id, mobilegroup) => createAction(EDIT_MOBILEGROUP.REQUEST, { id, mobilegroup }),
  success: mobilegroup => createAction(EDIT_MOBILEGROUP.SUCCESS, { mobilegroup }),
  failure: () => createAction(EDIT_MOBILEGROUP.FAILURE)
}

const deleteMobileGroup = {
  request: (id) => createAction(DELETE_MOBILEGROUP.REQUEST, { id } ),
  success: id => createAction(DELETE_MOBILEGROUP.SUCCESS, { id } ),
  failure: () => createAction(DELETE_MOBILEGROUP.FAILURE)
}

const filterData = (mobilegroups, search) => ({
  type: FILTER_MOBILEGROUP,
  payload: {
    mobilegroups,
    search 
  }
})

const loadMobileGroupFromArray = id => ({
  type: GET_MOBILEGROUP,
  payload: id
})

const fecthMobileGroupFromArray = () => ({
  type : FETCH_MOBILEGROUPS_FROM_STORE,
  payload: {}
})


export {
  LOAD_MOBILEGROUPS,
  LOAD_MOBILEGROUP,
  CREATE_MOBILEGROUP,
  EDIT_MOBILEGROUP,
  DELETE_MOBILEGROUP,
  SET_MOBILEGROUP,
  FILTER_MOBILEGROUP,
  GET_MOBILEGROUP,
  FETCH_MOBILEGROUPS_FROM_STORE,
  loadMobileGroups,
  loadMobileGroup,
  loadMobileGroupFromArray,
  createMobileGroup,
  editMobileGroup,
  deleteMobileGroup,
  filterData,
  fecthMobileGroupFromArray
}
