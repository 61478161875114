import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as actions from '../actions'
import CategoryForm from './CategoryForm'
import { defaultState } from '../defaultState'

class EditCategory extends Component {
  componentDidMount() {
    const id = this.getCategoryId()
    this.props.loadCategory(id)
  }

  componentDidUpdate(prevProps) {
    const search = this.props.location.pathname
    if (search === prevProps.location.pathname) return

    const id = this.getCategoryId()
    this.props.loadCategoryFromArray(id)
  }

  getCategoryId = () => this.props.match.params.id

  editCategory = async data => {
    this.props.editCategory(this.getCategoryId(), data)
  }

  render() {
    const { category, successSave, isLoading } = this.props

    let data = []

    if (typeof category !== 'undefined') {
      data = typeof category[0] !== 'undefined' ? category[0] : defaultState
    }

    if (data.length === 0) {
      return <div className="lmask" id="lmask" />
    }

    return (
      <CategoryForm
        onSubmit={this.editCategory}
        formAction="แก้ไข"
        data={data}
        successSave={successSave}
        isLoading={isLoading}
      />
    )
  }
}

const mapStateToProps = ({ categories }) => ({
  category: categories.category,
  successSave: categories.successSave,
  isLoading: categories.isLoading
})

const mapDispatchToProps = {
  loadCategory: actions.loadCategory.request,
  editCategory: actions.editCategory.request,
  loadCategoryFromArray: actions.loadCategoryFromArray
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
)(EditCategory)
