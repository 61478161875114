import React, { Component, Fragment } from 'react'

import * as actions from '../actions'
import { connect } from 'react-redux'
import { compose } from 'redux'
import debounce from 'lodash/debounce'
import { Link } from 'react-router-dom'
import { createCondition } from 'lib/helper'
import {
  ActionBtn,
  ButtonTable,
  ConfirmDlg,
  SearchPanel,
  MenuMobile
} from 'lib/widgets'

import MUIDataTable from 'mui-datatables'
import { MuiThemeProvider } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { Tablestyle } from '../../AddonStyles'
import AdvanceSearch from './AdvanceSearch'
import { subComponent as SubComponent } from './RelateFunc'
import { emptySubRow, hasMessage } from 'lib/fxUI'

import { getGRNextStatus } from '../../helpFx'
import { defaultState } from '../defaultState'

const actionLink = (status, id, onClick) => {
  let Btn = '',
    title = ''
  switch (status) {
    case 0:
      title = 'Confirm'
      break
    case 1:
    case 2:
      title = 'Cancel'
      break
    default:
      break
  }

  if (title !== '') {
    return (
      <Link to="#" onClick={() => onClick(id, getGRNextStatus(status))}>{title}</Link>
    )
  }

  return Btn
}

class PhysicalTable extends Component {
  state = {
    term: '',
    search: '',
    term_from_advsearch: "",
    delId: '',
    options: {},
    page: 0,
    sortName: '',
    rowsPerPage: 10,
    count: 100,
    isModalOpen: false,
    showAdvSearch: false,
    successSave: null,
    deleteSuccess: null,
    width: window.innerWidth,
  }

  componentWillReceiveProps(nextProps) {
    const { successSave, deleteSuccess } = nextProps

    this.setState({ successSave, deleteSuccess })
  }

  componentDidMount() {
    this.fetchPhysicalCounts()
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  }

  fetchPhysicalCounts = async () => {
    const { term } = this.state
    this.props.loadPhysicalCounts(term)
  }

  delaySearchData = debounce(async value => {
    this.setState({ search: value })
    const cond = 'strict=false&'+createCondition({ order_no: '' }, value)
    this.props.loadPhysicalCounts('?'+cond)
  }, 750)

  searchdata = event => {
    return this.delaySearchData(event)
  }

  setAdvSearch = data => {
    this.setState({ term: data.replace(/\?/g, ''), term_from_advsearch: data.replace(/\?/g, '') })
    this.advSearch(data)
  }

  advSearch = data => {
    this.props.loadPhysicalCounts(data)
  }

  navigateToCreateForm = () => {
    this.props.history.push('/warehouse/physical-count/new')
  }

  editForm = id => {
    this.props.history.push(`/warehouse/physical-count/${id}`)
  }

  deletePhysicalCount = id => {
    const { delId } = this.state
    this.props.deletePhysicalCount(delId)
    this.setState({ isModalOpen: false })
  }

  toggleConfirmDlg = (id = '') => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      delId: id,
      successSave: null,
      deleteSuccess: null
    })
  }

  showAdvSearch = state => {
    this.setState({ showAdvSearch: state })
  }

  getRealColumn = name => {
    let col = ''

    switch (name) {
      case 'company_name':
        col = 'company_id'
        break
      case 'branch_name':
        col = 'branch_id'
        break
      case 'warehouse_name':
        col = 'warehouse_id'
        break
      default:
        col = name
        break
    }

    return col
  }

  sortingData = (changedColumn, direction) => {
    let sortby = this.getRealColumn(changedColumn)
    const { page, rowsPerPage, search } = this.state
    const sort = direction === 'ascending' ? 'asc' : 'desc'
    let condStr = 'strict=false&'+createCondition({ order_no: '' }, search)
    if(this.state.term_from_advsearch !== "") condStr += "&"+this.state.term_from_advsearch
    let term = `?page=${page}&per_page=${rowsPerPage}&sort_by=${sortby}&sort=${sort}&${condStr}`
    this.setState(
      {
        term: term,
        sortName: changedColumn,
        options: { sort: true, sortDirection: sort }
      },
      this.advSearch(term)
    )
  }

  handleTableChange = (action, tableState) => {
    let term = ''
    let condStr = 'strict=false&'+createCondition({ order_no: '' }, this.state.search)
    if(this.state.term_from_advsearch !== "") condStr += "&"+this.state.term_from_advsearch
    switch (action) {
      case 'changeRowsPerPage':
        term = `?per_page=${tableState.rowsPerPage}&${condStr}`
        this.setState(
          {
            term: term,
            rowsPerPage: tableState.rowsPerPage,
            page: 0
          },
          this.advSearch(term)
        )
        break
      case 'changePage':
        term = `?page=${tableState.page + 1}&per_page=${tableState.rowsPerPage}&${condStr}`
        this.setState(
          {
            term: term,
            page: tableState.page
          },
          this.advSearch(term)
        )
        break
      default:
        break
    }
  }

  handleClickRow = id => {
    let ele = document.getElementById(id)
    let show = ele.style.display === 'none' ? '' : 'none'
    ele.removeAttribute('style')
    ele.style.display = show
  }

  handleChangeStatus = (id, status) => {
    this.props.changeStatusPC(id, status)
  }

  resetSaveState = () => {
    this.setState({ 
      successSave: null,
      deleteSuccess: null 
    })
  }

  renderTable = (isLoading, columns, physicalcounts, optionsTable) => {
    const isMobile = this.state.width <= 500

    if(isMobile) {
      return <MenuMobile 
      data={physicalcounts} 
      columns={{
        col1: 'status_name',
        col2: 'order_no',
        col3: 'branch_name',
        col4: 'warehouse_name',
      }}
      title={{
        title1: () => 'สถานะ',
        title2: () => 'เลขที่เอกสาร',
        title3: () =>  'สาขา',
        title4: () =>  'คลังสินค้า'
      }}
      url={`api/physical-counts`}
      onLoad={this.advSearch}
      linkUrl={`/warehouse/physical-count`}
      deleteOnly={false}
      onDelete={this.toggleConfirmDlg}
      deleteSuccess={this.props.deleteSuccess}
      actionLink={actionLink}
      changeStatus={this.handleChangeStatus}
      />
    } 

    if(typeof physicalcounts === 'object')
      return <Fragment>
      { isLoading && <div className="lmask" id="lmask" /> }
      <MuiThemeProvider theme={Tablestyle()}>
        <MUIDataTable
          title={'นับสต๊อกสินค้า'}
          data={physicalcounts}
          columns={columns}
          options={optionsTable}
        />
      </MuiThemeProvider>
    </Fragment>
  }

  render() {
    const { isLoading, physicalcounts, meta, errorMsg } = this.props
    const {
      rowsPerPage,
      page,
      sortName,
      options,
      successSave,
      deleteSuccess
    } = this.state
    const count = typeof meta === 'undefined' ? 100 : meta.total
    const columns = [
      {
        name: '',
        label: '#',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return page * rowsPerPage + tableMeta.rowIndex + 1
          }
        }
      },
      { label: 'เลขที่เอกสาร', name: 'order_no' },
      { label: 'บริษัท', name: 'company_name', options: {
        filter: false,
        sort: false } 
      },
      { label: 'สาขา', name: 'branch_name' },
      { label: 'คลังสินค้า', name: 'warehouse_name' },
      { label: 'สถานะ', name: 'status_name' },
      {
        label: '  ',
        name: 'id',
        options: {
          customBodyRender: (row, tableState) => {
            if (typeof tableState.rowData === 'object') {
              let val = tableState.rowData
              return actionLink(val[8], row, this.handleChangeStatus)
            }
          }
        }
      },
      {
        name: 'id',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            console.log('value', value)
            let val = tableMeta.rowData
            return (
              <ButtonTable
                id={value}
                onOpen={this.editForm}
                delDisable={val[8] !== 0 ? true : false}
                onDelete={() => this.toggleConfirmDlg(value)}
                view="physicalcount delete"
              />
            )
          }
        }
      },
      { label: '', name: 'status', options: { display: false } }
    ]

    const subRow = [
      { label: 'สถานที่จัดเก็บ', name: 'storagebin_name' },
      { label: 'สถานะ', name: 'storagebin_name' },
      { label: 'วันที่ทำรายการ', name: 'storagebin_name' },
      { label: 'Accept', name: 'storagebin_name' },
    ]

    const subRow2 = [
      { label: 'Batch', name: 'external_batch' },
      { label: 'สินค้า', name: 'sum_order_qty' },
      { label: 'Book QTY', name: 'sum_acc_qty' },
      { label: 'Count QTY', name: 'sum_remain_qty' },
      { label: 'หน่วยนับ', name: 'sum_remain_qty' }
    ]

    const optionsTable = {
      filter: false,
      print: false,
      download: false,
      viewColumns: false,
      filterType: 'dropdown',
      serverSide: true,
      expandableRows: true,
      count: count,
      page: page,
      rowsPerPage: rowsPerPage,
      selectableRows: false,
      onTableChange: this.handleTableChange,
      onSearchChange: this.searchdata,
      onColumnSortChange: this.sortingData,
      textLabels: {
        body: {
          noMatch: "Sorry, no matching records found",
          toolTip: "Sort",
        }
      },
      onSearchClose: () => {
        this.setState({ search : '' })
        this.advSearch(this.state.term)
      },
      renderExpandableRow: (rowData, rowMeta) => {
        const items = physicalcounts[rowMeta.dataIndex].relations.detailGroup
        const colSpan = rowData.length + 1
        if (items.length === 0) return emptySubRow(colSpan)
        return (
          <SubComponent
            colSpan={colSpan}
            subRow={subRow}
            items={items}
            rowMeta={rowMeta}
            subRow2={subRow2}
            handleClickRow={this.handleClickRow}
          />
        )
      },
      customToolbar: () => {
        return (
          <ActionBtn
          onCreate={this.navigateToCreateForm}
          onSearch={this.showAdvSearch}
          view="physicalcount edit"
        />
        );
      }
    }

    if (sortName !== '') {
      let idx = columns.findIndex(data => data.name === sortName)
      if (idx === -1) return
      columns[idx].options = options
    }


    return (
      <Fragment>

          <Fragment>
            {successSave !== null && hasMessage(successSave, errorMsg, this.resetSaveState)}
            {deleteSuccess !== null && hasMessage(deleteSuccess, 'ลบข้อมูลสำเร็จ', this.resetSaveState)}
                <Grid item xs={12}>
                  { this.renderTable(isLoading, columns, physicalcounts, optionsTable) }
                </Grid>
          </Fragment>

        <ConfirmDlg
          title="ยืนยันการลบข้อมูล"
          content="คุณต้องการลบข้อมูลนี้ ?"
          onToggle={this.toggleConfirmDlg}
          onConfirm={this.deletePhysicalCount}
          isOpen={this.state.isModalOpen}
        />
        <SearchPanel
          toggleDrawer={this.showAdvSearch}
          isOpen={this.state.showAdvSearch}
          onSubmit={this.setAdvSearch}
          Component={AdvanceSearch}
          formValue={defaultState.advSearch}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ physicalcounts }) => ({
  isLoading: physicalcounts.isLoading,
  physicalcounts: physicalcounts.items,
  meta: physicalcounts.meta,
  backupItems: physicalcounts.backupItems,
  successSave: physicalcounts.successSave,
  deleteSuccess: physicalcounts.deleteSuccess,
  errorMsg: physicalcounts.errorMsg
})

const mapDispatchToProps = {
  changeStatusPC: actions.changeStatusPC.request,
  loadPhysicalCounts: actions.loadPhysicalCounts.request,
  deletePhysicalCount: actions.deletePhysicalCount.request,
  filterData: actions.filterData
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PhysicalTable)
