import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as actions from '../actions'
import ProductForm from './ProductForm'

import { defaultState } from '../defaultState'
import { hasMessage } from 'lib/fxUI'

class EditProduct extends Component {
  componentDidMount() {
    const id = this.getProductId()
    this.props.loadProduct(id)
  }

  componentDidUpdate(prevProps) {
    const search = this.props.location.pathname
    if (search === prevProps.location.pathname) return

    const id = this.getProductId()
    this.props.loadProductFromArray(id)
  }

  getProductId = () => this.props.match.params.id

  editProduct = async data => {
    this.props.editProduct(this.getProductId(), data)
  }

  createUnitItem = async data => {
    this.props.createUnitItem(data)
  }

  editUnitItem = async (id, data) => {
    this.props.editUnitItem(id, data)
  }

  deleteUnitItem = async (id, data) => {
    this.props.deleteUnitItem(id, data)
  }

  editPriceBookItem = async (id, data) => {
    this.props.editPriceBookItem(id, data)
  }

  render() {
    const { product, successSave, isLoading } = this.props
    let data = []

    if (typeof product !== 'undefined') {
      data = typeof product[0] !== 'undefined' ? product[0] : defaultState
    }

    if (data.length === 0) {
      return <div className="lmask" id="lmask" />
    }

    return (
      <Fragment>
        {successSave !== null && hasMessage(successSave, '')}
        <ProductForm
          onSubmit={this.editProduct}
          createUnitItem={this.createUnitItem}
          editUnitItem={this.editUnitItem}
          deleteUnitItem={this.deleteUnitItem}
          editPriceBookItem={this.editPriceBookItem}
          formAction="แก้ไข"
          data={data}
          successSave={successSave}
          isLoading={isLoading}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ products }) => ({
  product: products.product,
  successSave: products.successSave,
  isLoading: products.isLoading
})

const mapDispatchToProps = {
  loadProduct: actions.loadProduct.request,
  editProduct: actions.editProduct.request,
  loadProductFromArray: actions.loadProductFromArray,
  createUnitItem: actions.createUnitItem.request,
  editUnitItem: actions.editUnitItem.request,
  deleteUnitItem: actions.deleteUnitItem.request,
  editPriceBookItem: actions.editPriceBookItem.request
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
)(EditProduct)
