import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { headerWithAuth, handleUnAutherize } from 'lib/authFunc'
import * as actions from './actions'

const header = headerWithAuth()

function* loadSalesOrders(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.get,
      process.env.REACT_APP_API_URL + `/api/sale-orders${term}`,
      header
    )
    yield put(actions.loadSalesOrders.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadSalesOrders.failure(error.response))
  }
}

function* loadSalesOrder(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.get, process.env.REACT_APP_API_URL + `/api/sale-orders/${id}`, header)
    yield put(actions.loadSalesOrder.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadSalesOrder.failure(error.response))
  }
}

function* acceptPayment(action) {
  try {
    const { collections } = action.payload
    const res = yield call(axios.post, process.env.REACT_APP_API_URL + '/api/so-collections', { collections }, header)
    yield put(actions.acceptPayment.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.acceptPayment.failure(error))
  }
}

function* cancelSalesOrder(action) {
  try {
    const { salesorder, id } = action.payload
    const res = yield call(axios.patch, process.env.REACT_APP_API_URL + `/api/sale-orders/${id}`, salesorder, header)

    yield put(actions.cancelSalesOrder.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.cancelSalesOrder.failure(error.response))
  }
}


export default function* watchComments() {
  yield all([
    takeEvery(actions.LOAD_SALESORDERS.REQUEST, loadSalesOrders),
    takeEvery(actions.LOAD_SALESORDER.REQUEST, loadSalesOrder),
    takeEvery(actions.CANCEL_SALESORDER.REQUEST, cancelSalesOrder),
    takeEvery(actions.ACCEPT_SALESORDER.REQUEST, acceptPayment)
  ])
}
