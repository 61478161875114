import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as actions from '../actions'
import DocForm from './DocForm'

import { defaultState } from '../defaultState'
import { hasMessage } from 'lib/fxUI'


class EditDoc extends Component {
  componentDidMount() {
    const id = this.getDocId()
    this.props.loadGoodsIssue(id)
  }

  componentDidUpdate(prevProps) {
    const search = this.props.location.pathname
    if (search === prevProps.location.pathname) return

    const id = this.getDocId()
    this.props.loadGoodsIssueFromArray(id)
  }

  getDocId = () => this.props.match.params.id

  editDoc = async data => {
    this.props.editGoodsIssue(this.getDocId(), data)
  }

  createDocItem = async data => {
    this.props.createDocItem(data)
  }

  editDocItem = async (id, data) => {
    this.props.editDocItem(id, data)
  }

  deleteDocItem = async id => {
    this.props.deleteDocItem(id)
  }

  deleteAllDocItem = async id => {
    this.props.deleteAllDocItem(id)
  }

  render() {
    const { goodsissue, successSave, isLoading } = this.props
    let data = []

    if(typeof goodsissue !== 'undefined') {
      data = typeof goodsissue[0] !== 'undefined' ? goodsissue[0] : defaultState
    } 

    if(data.length === 0) {
      return <div className="lmask" id="lmask" />
    }

    return (
      <Fragment>
        { successSave !== null && hasMessage(successSave, '') }
      <DocForm
        onSubmit={this.editDoc}
        onCreateItem={this.createDocItem}
        onEditItem={this.editDocItem}
        onDeleteItem={this.deleteDocItem}
        ondeleteAllDocItem={this.deleteAllDocItem}
        formAction="แก้ไข"
        isLoading={isLoading}
        data={data}
        successSave={successSave}
      />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ goodsissues }) => ({
  goodsissue: goodsissues.data,
  isLoading: goodsissues.isLoading,
  successSave: goodsissues.successSave
})

const mapDispatchToProps = {
  loadGoodsIssue: actions.loadGoodsIssue.request,
  editGoodsIssue: actions.editGoodsIssue.request,
  createDocItem: actions.createDocItem.request,
  editDocItem: actions.editDocItem.request,
  deleteDocItem: actions.deleteDocItem.request,
  deleteAllDocItem: actions.deleteAllDocItem.request,
  loadGoodsIssueFromArray: actions.loadGoodsIssueFromArray
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
)(EditDoc)
