import axios from 'axios'
import { headerWithAuth } from 'lib/authFunc'

const header = headerWithAuth()

const companyWithBranch = async () => {
    try {
        const company = await axios.get(process.env.REACT_APP_API_URL + '/api/master-data/companies', header)
        return company.data.data
    } catch (_) {
        console.log('Error')
    }
}

const warehoseWithBin = async () => {
    try {
        const company = await axios.get(process.env.REACT_APP_API_URL + '/api/warehouses', header)
        return company.data.data
    } catch (_) {
        console.log('Error')
    }
}

const loadProvice = async () => {
    try {
        const company = await axios.get(process.env.REACT_APP_API_URL + '/api/master-data/provinces', header)
        return company.data.data
    } catch (_) {
        console.log('Error')
    }
}

export {
    companyWithBranch,
    warehoseWithBin,
    loadProvice
}