import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link'

import { numberFormat, formatDate } from 'lib/helper'


function SalesOrder({ classes, data, curDate }) {

  return (
    <Fragment>
      <Paper elevation={1} className={classes.headPaper}>
        <Typography variant="h6" component="h6" style={{ color: 'white' }}>
          รายการ SalesOrder { curDate !== '' && formatDate(curDate, 'd-m-Y') }
        </Typography>

      </Paper>
      <List className={classes.rootList}>
        <Divider component="li" />
        {data.length === 0 &&
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="ไม่มีรายการขาย"
              secondary={
                <Typography color="textPrimary">
                  กรุณาเลือกคลิกเพื่อเลือกวันที่ต้องการแสดงผล
                  </Typography>
              }
            />
          </ListItem>
        }
        {data.map((item, i) => (
          <Link
            key={i}
            component={RouterLink}
            to={`/salesforce/sale-orders/${item.id}`}
            target="_blank"
          >
            <ListItem alignItems="flex-start" >
              <ListItemText
                primary={item.document_no}
                secondary={
                  <Fragment>
                    <Typography component="span" color="textPrimary">
                      ร้านค้า {item.cus_name}
                    </Typography>
                    <Typography component="span" color="textPrimary">
                      ผู้ขาย {item.user}
                    </Typography>
                    <Typography component="span" color="textPrimary">
                      ยอดสุทธิ {numberFormat(item.net)} บาท
                  </Typography>
                  </Fragment>
                }
              />
            </ListItem>
            <Divider component="li" />
          </Link>
        ))}
      </List>
    </Fragment>
  );
}

SalesOrder.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default SalesOrder;
