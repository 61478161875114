export const defaultState = {
    active: '1',
    value: 0,
    is_favorite: '',
    disableForm : false,
    formLoading: true,
    prevProvince: '',
    name: '',
    nickname: '',
    shop_type_id: '',
    customer_group: '',
    id_card: '',
    taxid: '',
    sale_profile_id: '',
    houseno: '',
    soi: '',
    street: '',
    province_id: '',
    district_id: '',
    subdistrict: '',
    postal_code: '',
    phone: '',
    mobile: '',
    latitude: '',
    longitude: '',
    remark: '',
    districtUrl: 'api/master-data/districts',
    province:[],
    bkprovince: [],
    district:[],
    advSearch : {
        shop_type_id : '',
        name: '',
        province_id: '',
        district_id: '',
        sale_profile_id: '',
        updated_at: null
    }
}