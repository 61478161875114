import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { push } from 'connected-react-router'
import { headerWithAuth, handleUnAutherize } from 'lib/authFunc'
import * as actions from './actions'

const header = headerWithAuth()

function* loadPhysicalCounts(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.get,
      process.env.REACT_APP_API_URL + `/api/physical-counts${term}`,
      header
    )
    yield put(actions.loadPhysicalCounts.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadPhysicalCounts.failure(error.response))
  }
}

function* loadPhysicalCount(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.get, process.env.REACT_APP_API_URL + `/api/physical-counts/${id}`, header)
    yield put(actions.loadPhysicalCount.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadPhysicalCount.failure(error.response))
  }
}

function* createPhysicalCount(action) {
  try {
    const { physicalcount } = action.payload
    const res = yield call(axios.post, process.env.REACT_APP_API_URL + '/api/physical-counts', { physicalcount }, header)
    yield put(push(`/warehouse/physical-count/${res.data.id}`))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.createPhysicalCount.failure(error))
  }
}

function* editPhysicalCount(action) {
  try {
    const { physicalcount, id } = action.payload
    const res = yield call(axios.patch, process.env.REACT_APP_API_URL + `/api/physical-counts/${id}`, physicalcount, header)

    yield put(actions.editPhysicalCount.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.editPhysicalCount.failure(error.response))
  }
}

function* deletePhysicalCount(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.delete, process.env.REACT_APP_API_URL + `/api/physical-counts/${id}`, header)
    yield put(actions.deletePhysicalCount.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.deletePhysicalCount.failure(error.response))
  }
}

function* changeStatusPC(action) {
  try {
    const { status, id } = action.payload
    const res = yield call(axios.patch, process.env.REACT_APP_API_URL + `/api/physical-counts/change-status/${id}/${status}`, {}, header)
    if (typeof res.data.error !== 'undefined') {
      yield put(actions.changeStatusPC.failure(res.data.error))
    } else {
      yield put(actions.changeStatusPC.success(res.data))
    }
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.changeStatusPC.failure(error))
  }
}



export default function* watchComments() {
  yield all([
    takeEvery(actions.LOAD_PCS.REQUEST, loadPhysicalCounts),
    takeEvery(actions.LOAD_PC.REQUEST, loadPhysicalCount),
    takeEvery(actions.CREATE_PC.REQUEST, createPhysicalCount),
    takeEvery(actions.EDIT_PC.REQUEST, editPhysicalCount),
    takeEvery(actions.DELETE_PC.REQUEST, deletePhysicalCount),
    takeEvery(actions.CHANGE_STATUS_PC.REQUEST, changeStatusPC),
  ])
}
