import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { defaultState } from '../defaultState'

import SalesOrderItem from './SalesOrderItem'
import SalesOrderHeader from './SalesOrderHeader'
import PaymentItem from './PaymentItem'
import Summary from './Summary'
import PaymentModal from './PaymentModal'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import { Formstyles } from './AddonStyles'
import { SearchBtn } from 'lib/widgets'

class SalesOrderForm extends Component {
  static defaultProps = {
    data: defaultState
  }

  state = defaultState

  toggleFx = name => {
    let isOpen = this.state[`${name}`]
    this.setState({ [`${name}`]: !isOpen })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.disableForm === false &&
      window.location.pathname !== '/salesforce/sale-orders/new'
    ) {
      this.disableForm(true)
      this.setState({ isOpen: false })
    }
  }

  componentDidMount() {
    if (window.location.pathname !== '/salesforce/sale-orders/new') {
      this.disableForm(true)
    }
  }

  disableForm = disableForm => {
    this.setState({ disableForm })
  }

  openModal = () => {
    this.setState({ isOpen: true })
  }

  cancelSO = () => {
    this.props.cancelSalesOrder({ status: -1 })
  }

  acceptPayment = data => {
    this.props.acceptPayment(data)
    this.setState({ isOpen: false })
  }

  showPaymentBtn = (status, cancel) => {

    let can = cancel === -1 ? false : this.cancelSO
    const { classes } = this.props
    if (status === 'ยังไม่ได้ชำระ' || status === 'ชำระบางส่วน') {
      return (
        <SearchBtn 
          classes={classes} 
          isSearch={false}
          onPayment={this.openModal}
          onCancel={can} 
          displayPrint={true}
          />
      )
    } else {
      return (
        <SearchBtn 
          classes={classes} 
          isSearch={false}
          onCancel={can}
          displayPrint={true}
          />
      )
    }
  }


  render() {
    const { classes, data, isLoading } = this.props
    let fulladdr = data.full_addr.split('|'),
      net = 0,
      payment = 0

    if (typeof data.relations !== 'undefined') {
      net = data.relations.detail.reduce(
        (total, item) => parseFloat(total) + parseFloat(item.net_price),
        0
      )

      payment = data.payment.reduce(
        (total, item) => parseFloat(total) + parseFloat(item.detail_amt),
        0
      )

    }

    return (
      <Grid container spacing={24}>
        {  isLoading && <div className="lmask" id="lmask" />}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div className={classes.root}>
              <Grid container spacing={24}>
                <SalesOrderHeader data={data} fulladdr={fulladdr} />
                <SalesOrderItem data={data} />
                <PaymentItem data={data} />
                <Summary data={data} NetAmt={net} />
                <PaymentModal
                  soId={data.id}
                  total={net-payment}
                  isOpen={this.state.isOpen}
                  acceptPayment={this.acceptPayment}
                />
                {data.status === -1 && (
                  <div className="watermark"> ยกเลิกเรียบร้อย </div>
                )}
              </Grid>
            </div>
          </Paper>
          { this.showPaymentBtn(data.payment_status, data.status) }
        </Grid>
        
      </Grid>
    )
  }
}
export default withStyles(Formstyles)(SalesOrderForm)
