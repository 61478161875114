import React from 'react'
import { Route } from 'react-router-dom'
import BatchList from './BatchList'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Breadcrumb } from 'lib/widgets'
import { Layoutstyles } from './AddonStyles'

const BatchLayout = ({ classes }) => (
  <div className={classes.root}>
    <Grid container spacing={24}>
      <Grid item xs>
        <Breadcrumb
          menu="ข้อมูลหลัก"
          submenu="จัดการ Batch"
          menuLink="/master-data/batches"
        />
      </Grid>
    </Grid>
    <Grid container spacing={24}>
      <Route exact path="/master-data/batches" component={BatchList} />
    </Grid>
  </div>
)

export default withStyles(Layoutstyles)(BatchLayout)
