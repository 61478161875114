import React, { useState, useEffect } from 'react';
import { Motion, spring } from 'react-motion';

const clusterMarker = (props) => {

  const [defaultMotionStyle, setDefaultMotionStyle] = useState({ scale: 0.6 })
  const [motionStyle, setMotionStyle] = useState({
    scale: spring({
      damping: 7,
      precision: 0.001,
      stiffness: 320,
      val: 1
    })
  })

  useEffect(() => {
    setDefaultMotionStyle({
      scale: props.$prerender ? props.defaultScale : props.initialScale 
    })
    return () => setDefaultMotionStyle(null)
  }, [props.initialScale])

  useEffect(() => {
    let unmounted  = false;
    if (!unmounted  ) {
        setMotionStyle({
          scale: spring(
            props.hovered ? props.hoveredScale : props.defaultScale,
            { stiffness : props.stiffness, damping: props.damping, precision: props.precision }
          ),
      })
    }
    return () => unmounted  = true
  }, [props.hovered])

  return <Motion
    defaultStyle={defaultMotionStyle}
    style={motionStyle}
  >
  {
    ({ scale }) => (
      <div
        className="marker"
        style={{
          transform: `translate3D(0,0,0) scale(${scale}, ${scale})`,
        }}
      >
        <div>
          { props.text }
        </div>
      </div>
    )
  }
  </Motion>
};

clusterMarker.defaultProps = {
  text: '0',
  initialScale: 0.6,
  defaultScale: 1,
  hoveredScale: 1.15,
  hovered: false,
  stiffness: 320,
  damping: 7,
  precision: 0.001
}

export default clusterMarker

