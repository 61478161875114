import React from 'react'
import MUIDataTable from 'mui-datatables'
import Grid from '@material-ui/core/Grid'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { createMuiTheme } from '@material-ui/core/styles';

const Tablestyle = () => createMuiTheme({
    overrides: {
      MuiToolbar: {
        gutters: {
          paddingRight: '0px !important'
        }
      },
      MuiPaper: {
        rounded: {
          padding: '15px'
        },
        elevation4: {
          boxShadow: 'none'
        }
      },
      MuiTableCell: {
        root: {
          padding: '0px 0px 0px 4px !important',
          borderBottom: '0px'
        },
        body: {
          color: 'rgba(47, 47, 47, 0.87)',
          fontSize: '0.85rem',
          fontWeight: '100'
        },
        head: {
          color: 'rgba(0, 0, 0, 0.7)',
          fontSize: '0.85rem',
          fontWeight: '700',
        }
      },
      MuiTableRow: {
        head: {
          height: '35px',
          fontWeight: '700'
        },
        root: {
          height: '30px',
          '&:nth-of-type(even)': {
            backgroundColor: '#dce5e852'
          }
        }
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: '#cfd8dc'
        }
      },
      typography: {
        useNextVariants: true,
      },
      MuiIconButton: {
        root: {
          padding: '5 10 5 10px'
        }
      },
      MuiSvgIcon: {
        root: {
          fontSize: '20px'
        }
      },
      ButtonTable: {
        button: {
          margin: '3px'
        }
      }
    },
    typography: { useNextVariants: true }
  })

function EmptyTable(props)
{
    const options = {
        filter: false,
        print: false,
        download: false,
        viewColumns: false,
        selectableRows: false,
        textLabels: {
            body: {
                noMatch: "Waiting for data...",
            }
        }
    }
    const { title, columnsList } = props
    return (
      <Grid item xs={12}>
        <MuiThemeProvider theme={Tablestyle()}>
          <MUIDataTable
            title={title}
            data={[]}
            columns={columnsList}
            options={options}
          />
        </MuiThemeProvider>
      </Grid>
    )
}

export default EmptyTable