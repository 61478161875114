import { createTypes, createAction } from 'lib/action'
 
const LOAD_MAP = createTypes('maps', 'list')

const CHANGE_BOUNDS = 'changebounds'
const MARKER_HOVER_INDEX_CHANGE = 'markerhover'
const SHOW_BALLON = 'showballon'
const SET_MAP_MODE = 'setmapmode'
const SET_ACTIVE_TYPE = 'setactive'

const loadMaps = {
  request: term => createAction(LOAD_MAP.REQUEST, { term }),
  success: maps => createAction(LOAD_MAP.SUCCESS, { maps }),
  failure: () => createAction(LOAD_MAP.FAILURE)
}

const changeBounds = (center, zoom, bounds, marginBounds) => ({
  type: CHANGE_BOUNDS,
  payload: {
    center, zoom, bounds, marginBounds
  }
})


const markerHoverIndexChange = hoverMarkerIndex => ({
  type: MARKER_HOVER_INDEX_CHANGE,
  payload: {
    hoverMarkerIndex
  }
})

const showBallon = openBalloonIndex => ({
  type: SHOW_BALLON,
  payload: {
    openBalloonIndex
  }
})

const setMapMode = mode => ({
  type: SET_MAP_MODE,
  payload: {
    mode
  }
})

const setActive = active => ({
    type: SET_ACTIVE_TYPE,
    payload: {
      active
    }
})

export {
  LOAD_MAP,
  CHANGE_BOUNDS,
  MARKER_HOVER_INDEX_CHANGE,
  SHOW_BALLON,
  SET_MAP_MODE,
  SET_ACTIVE_TYPE,
  changeBounds,
  markerHoverIndexChange,
  showBallon,
  loadMaps,
  setMapMode,
  setActive
}
