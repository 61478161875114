import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

export default ({ values, handleChange }) => (
  <div style={{ padding: '10px', flexGrow: 1 }}>
    <Grid item xs={12}>
      <TextField
        label="ชื่อกลุ่มสิทธิ์"
        name="name"
        value={values.name}
        onChange={handleChange('name')}
        fullWidth
      />
    </Grid>
  </div>
)
