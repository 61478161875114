import React from 'react'
import { numberFormat } from 'lib/helper'

const style = {
  width: '100%',
  borderBottom: '1px solid #d4d4d4',
  marginBottom: '10px',
  marginTop: '10px'
}

const padding = {
  paddingTop: '3px',
  paddingBottom: '3px'
}

const MobileComponent = ({ data }) => {
  let lineNo = 1
  let sum = 0
  return <div className="col-md-12" id="payment" style={{ marginTop: '20px', marginBottom: '20px' }}>
    <div className="panel panel-default">
      <div className="panel-heading">
        <h6 className="panel-title">
          <strong>รายการรับชำระเงิน</strong>
        </h6>
      </div>
      <div className="panel-body">


        {typeof data.payment !== 'undefined' &&
          data.payment.map((item, i) => {
            sum += parseFloat(item.detail_amt)
            return (
              <table key={i} style={style}>
                <tbody>
                  <tr>
                    <td style={padding}>ชำระครั้งที่</td>
                    <td style={padding}>{lineNo++}</td>
                  </tr>
                  <tr>
                    <td style={padding}>วันที่ชำระ</td>
                    <td style={padding}>{item.created_at}</td>
                  </tr>
                  <tr>
                    <td style={padding}>ประเภทการชำระเงิน</td>
                    <td style={padding}>{item.type_payment}</td>
                  </tr>
                  <tr>
                    <td style={padding}>หมายเหตุ</td>
                    <td style={padding}>{item.remark}</td>
                  </tr>
                  <tr>
                    <td style={padding}>จำนวนเงิน</td>
                    <td style={padding}>{numberFormat(item.detail_amt)}</td>
                  </tr>
                </tbody>
              </table>
            )
          })}

      </div>
    </div>
  </div>
}

const WebComponent = ({ data }) => {
  let lineNo = 1
  let sum = 0
  return <div className="col-md-12" id="payment">
    <div className="panel panel-default">
      <div className="panel-heading">
        <h6 className="panel-title">
          <strong>รายการรับชำระเงิน</strong>
        </h6>
      </div>
      <div className="panel-body">
        <div className="table-responsive">
          <table className="table table-condensed">
            <thead>
              <tr>
                <td>
                  <strong>ชำระครั้งที่</strong>
                </td>
                <td className="text-center">
                  <strong>วันที่ชำระ</strong>
                </td>
                <td className="text-center">
                  <strong>ประเภทการชำระเงิน</strong>
                </td>
                <td className="text-right">
                  <strong>หมายเหตุ</strong>
                </td>
                <td className="text-right">
                  <strong>จำนวนเงิน</strong>
                </td>
              </tr>
            </thead>
            <tbody>
              {typeof data.payment !== 'undefined' &&
                data.payment.map((item, i) => {
                  sum += parseFloat(item.detail_amt)
                  return (
                    <tr key={i}>
                      <td>{lineNo++}</td>
                      <td>{item.created_at}</td>
                      <td className="text-center">{item.type_payment}</td>
                      <td>{item.remark}</td>
                      <td className="text-right">{numberFormat(item.detail_amt)}</td>
                    </tr>
                  )
                })}
              <tr>
                <td className="thick-line" colSpan="3" />
                <td className="thick-line text-center">
                  <strong>ยอดชำระแล้วทั้งสิ้น</strong>
                </td>
                <td className="thick-line text-right">{numberFormat(sum)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
}


export default ({ data }) => {
  return window.innerWidth <= 500 ?
    <MobileComponent data={data} /> :
    <WebComponent data={data} />
}
