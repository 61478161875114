import React from "react";
import MUIDataTable from "mui-datatables";
import WorkMobile from './WorkMobile'

class CompleteTable extends React.Component {
  render() {

    const columns = [
      { label: 'ประเภท', name: 'doc_type' },
      { label: 'เลขที่เอกสาร', name: 'order_no' },
      { label: 'เลขที่อ้างอิง', name: 'ref_no' },
      { label: 'สถานะ', name: 'status_name' },
      { label: 'เวลาที่สร้าง', name: 'created_at' },
      { label: 'ชื่อลูกค้า', name: 'customer_name' },
    ]

    const options = {
      filterType: "dropdown",
      responsive: "stacked",
      sort: false,
      filter: false,
      print: false,
      download: false,
      viewColumns: false,
      selectableRows: false,
    };

    return (
      window.innerWidth > 500 ? 
      <MUIDataTable
        title={"งานที่เสร็จสิ้น"}
        data={this.props.data}
        columns={columns}
        options={options}
      /> :
      <WorkMobile 
        data={this.props.data}
        advSearch={this.advSearch} 
        api={`api/overview-warehouse/work?date_range=24hr&branch_id=1&per_page=10`}
      />
    );
  }
}

export default CompleteTable

CompleteTable.defaultProps = {
  data : []
}