import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import * as actions from '../actions'
import Grid from '@material-ui/core/Grid';
import MapComponent from './MapComponent'

class CustomerHeatMap extends Component {

    loadMarker = (val, checkype) => {
        this._loadMaps(val, checkype)
    }

    _loadMaps = (val, checkype = 'net', companyId = 1, branchId = 1) => {
        const data = {
            company_id: companyId,
            branch_id: branchId,
            isNew: val,
            check_type: checkype
        }
        this.props.loadMaps(data)
    }

    componentDidMount() {
        this._loadMaps('all')
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.companyId !== this.props.companyId
            || nextProps.branchId !== this.props.branchId) {
            this._loadMaps('all', 'net', nextProps.companyId, nextProps.branchId)
        }
    }

    render() {
        const { isLoading } = this.props
        return (
            <Fragment>
                <Grid item xs={12} sm={12} style={{ height: '100vh', width: '100%' }}>
                    {isLoading && <div className="lmask" id="lmask" />}
                    <MapComponent {...this.props} />
                </Grid>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ maps }) => ({
    heatmaps: maps.heatmaps,
    mapInfo: maps.mapInfo,
    isLoading: maps.isLoading,
})

const mapDispatchToProps = {
    loadMaps: actions.loadMaps.request,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerHeatMap)

CustomerHeatMap.defaultProps = {
    company_id: 1,
    branch_id: 1
}
