import React from 'react'
import { Switch, Route } from 'react-router-dom'

import SalesOrderTable from './SalesOrderTable'
import SalesOrderList from './SalesOrderList'
import EditSalesOrder from './EditSalesOrder'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Breadcrumb } from 'lib/widgets'
import { Layoutstyles } from './AddonStyles'

const SalesOrderLayout = ({ classes }) => (
  <div className={classes.root}>
    <Grid container spacing={24}>
      <Grid item xs>
        <Breadcrumb
          menu="ฝ่ายขาย"
          submenu="รายการขาย"
          menuLink="/salesforce/sale-orders"
        />
      </Grid>
    </Grid>
    <Grid container spacing={24}>
      <Switch>
        <Route
          exact
          path="/salesforce/sale-orders"
          component={SalesOrderTable}
        />
        <Route path="/salesforce/sale-orders" component={SalesOrderList} />
      </Switch>
      <Switch>
        <Route path="/salesforce/sale-orders/:id" component={EditSalesOrder} />
      </Switch>
    </Grid>
  </div>
)
export default withStyles(Layoutstyles)(SalesOrderLayout)
