import { createTypes, createAction } from 'lib/action'
 
const LOAD_CUSTOMERS = createTypes('customers', 'list')
const LOAD_CUSTOMER = createTypes('customers', 'show')
const EDIT_CUSTOMER = createTypes('customers', 'edit')

const FILTER_CUSTOMER = 'backup'
const SET_CUSTOMER = 'api/customers'
const GET_CUSTOMER = 'GET_CUSTOMER'
const FETCH_CUSTOMERS_FROM_CUSTOMER = "FETCH_CUSTOMERS_FROM_CUSTOMER"

const loadStores = {
  request: term => createAction(LOAD_CUSTOMERS.REQUEST, { term }),
  success: customers => createAction(LOAD_CUSTOMERS.SUCCESS, { customers }),
  failure: () => createAction(LOAD_CUSTOMERS.FAILURE)
}

const loadStore = {
  request: id => createAction(LOAD_CUSTOMER.REQUEST, { id }),
  success: customer => createAction(LOAD_CUSTOMER.SUCCESS, { customer }),
  failure: () => createAction(LOAD_CUSTOMER.FAILURE)
}

const editStore = {
  request: (id, customer) => createAction(EDIT_CUSTOMER.REQUEST, { id, customer }),
  success: customer => createAction(EDIT_CUSTOMER.SUCCESS, { customer }),
  failure: () => createAction(EDIT_CUSTOMER.FAILURE)
}

const filterData = (customers, search) => ({
  type: FILTER_CUSTOMER,
  payload: {
    customers,
    search 
  }
})

const loadStoreFromArray = id => ({
  type: GET_CUSTOMER,
  payload: id
})

const fecthStoreFromArray = () => ({
  type : FETCH_CUSTOMERS_FROM_CUSTOMER,
  payload: {}
})


export {
  LOAD_CUSTOMERS,
  LOAD_CUSTOMER,
  EDIT_CUSTOMER,
  SET_CUSTOMER,
  FILTER_CUSTOMER,
  GET_CUSTOMER,
  FETCH_CUSTOMERS_FROM_CUSTOMER,
  loadStores,
  loadStore,
  loadStoreFromArray,
  editStore,
  filterData,
  fecthStoreFromArray
}
