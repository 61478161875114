import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import UserTable from './UserTable'
import UserList from './UserList'
import EditUser from './EditUser'
import CreateUser from './CreateUser'

import { Breadcrumb } from 'lib/widgets'

import { Layoutstyles } from './AddonStyles'

const UserLayout = ({ classes }) => (
  <div className={classes.root}>
    <Grid container spacing={24}>
      <Grid item xs>
        <Breadcrumb menu="ข้อมูลหลัก" submenu="ผู้ใช้งาน" menuLink="/master-data/users"/>
      </Grid>
    </Grid>
    <Grid container spacing={24}>
      <Switch>
        <Route exact path="/master-data/users" component={UserTable} />
        <Route path="/master-data/users" component={UserList} />
      </Switch>
      <Switch>
        <Route path="/master-data/users/new" component={CreateUser} />
        <Route path="/master-data/users/:id" component={EditUser} />
      </Switch>
    </Grid>
  </div>
)

export default withStyles(Layoutstyles)(UserLayout)
